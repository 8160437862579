import React, { useEffect, useState } from "react";
import ButtonListComponent from "./ButtonList";
import FolderView from "./FolderView";
import apiServices from "../../../service";
import BreadCrumb from "./Breadcrumb";
import OverlayLoader from "../../../components/OverlayLoader";
import Search from "../../../components/Search";
import "./style.css";
import ContentLoader from "../../../components/Loader/ContentLoader";

interface FileItem {
  type: "file";
  name: string;
  path: string;
  lastModified: string;
  metadata: {
    customerid: string;
    userid: string;
    description: string;
    visible: string;
    documentid: string;
  };
}

interface FolderItem {
  type: "folder";
  name: string;
  path: string;
  lastModified: string;
  children: (FolderItem | FileItem)[];
}

type Item = FolderItem | FileItem;

function buildFileStructure(
  paths: any[],
  lastModifiedMap: Map<string, string>,
  searchQuery: string
) {
  let root: any = [];
  let searchResults: any[] = [];

  paths.forEach(({ name, path, metadata }) => {
    const parts = path.split("/");
    let current = root;

    parts.forEach((part: any, index: any) => {
      if (part === "") return;

      let existing = current.find((item: any) => item.name === part);
      if (!existing) {
        const isFolder = index !== parts.length - 1 || path.endsWith("/");
        const type = isFolder ? "folder" : "file";
        const lastModified = lastModifiedMap.get(path) || "";
        existing = {
          name: part,
          type,
          lastModified,
          path,
          children: [],
          metadata: isFolder ? {} : metadata,
        };
        current.push(existing);
      }

      current = existing.children;
    });
  });

  const filterItems = (items: any[], query: string): any[] => {
    return items.reduce((filtered: any[], item: any) => {
      const newItem = { ...item };

      if (newItem.children.length > 0) {
        newItem.children = filterItems(newItem.children, query);
      }

      if (newItem.name.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(newItem);
        searchResults.push(newItem);
      } else if (newItem.children.length > 0) {
        if (
          newItem.children.some((child: any) =>
            child.name.toLowerCase().includes(query.toLowerCase())
          )
        ) {
          filtered.push(newItem);
          searchResults.push(newItem);
        }
      }

      return filtered;
    }, []);
  };

  if (searchQuery) {
    root = filterItems(root, searchQuery);
  }

  const sortStructure = (items: any[]) => {
    items.sort((a, b) => {
      if (a.type === b.type) return a.name.localeCompare(b.name);
      return a.type === "folder" ? -1 : 1;
    });
    items.forEach((item) => {
      if (item.type === "folder") {
        sortStructure(item.children);
      }
    });
  };

  sortStructure(root);
  return { root, searchResults };
}

export default function ClientDocumentStructure(props: any) {
  const [refresh, setRefresh] = useState(true);
  const [search, setSearch] = useState("");
  const [fileStructure, setFileStructure] = useState<Item[]>([]);
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [lastModifiedMap, setLastModifiedMap] = useState<Map<string, string>>(
    new Map()
  );
  const [basePath, setBasePath] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Item[]>([]);

  const navigateTo = (
    path: string[],
    children: [] = [],
    isbreadcrumb = false
  ) => {
    setCurrentPath(path);
    if (search) setSearchResults(children);
    if (isbreadcrumb) setSearch("");
  };

  const findBasePath = (fileName: any): void => {
    setBasePath(`${fileName.split("/")[1]}`);
  };

  const GetAllFiles = (id: any) => {
    setLoading(true);
    apiServices.clientDocument
      .getAllList(id)
      .then((res: any) => {
        setLoading(false);
        const lastModifiedMap = new Map<string, string>();
        const [fileItem] = res.data.filteredData;
        findBasePath(fileItem.Key);
        const files = res.data.filteredData.map((file: any) => {
          const parts = file.Key.split("/");

          const relativePath = parts.slice(2).join("/");
          lastModifiedMap.set(parts.slice(2).join("/"), file.LastModified);
          return {
            name: parts.slice(2).join("/"),
            path: relativePath,
            lastModified: file.LastModified,
            metadata: file.Metadata,
          };
        });
        setLastModifiedMap(lastModifiedMap);
        setFileList(files);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    GetAllFiles(props.activeCustomerId);
  }, []);

  useEffect(() => {
    const filteredFiles = fileList.filter((file) =>
      file.name.toLowerCase().includes(search.toLowerCase())
    );

    if (filteredFiles.length > 0) {
      const { root, searchResults } = buildFileStructure(
        filteredFiles,
        lastModifiedMap,
        search
      );
      setFileStructure(root);
      setSearchResults(searchResults);
    } else {
      setFileStructure([]);
      setSearchResults([]);
    }
  }, [fileList, lastModifiedMap, search]);

  const getCurrentFolder = (path: string[], structure: Item[]): Item[] => {
    let current: Item[] = structure;
    for (const segment of path) {
      const found = (current as FolderItem[]).find(
        (item) => item.name === segment && item.type === "folder"
      );
      if (found && found.type === "folder") {
        current = found.children;
      } else {
        break;
      }
    }
    return current;
  };

  const currentFolder = getCurrentFolder(currentPath, fileStructure);

  return loading ? (
    <ContentLoader />
  ) : (
    <div className="client-document-container">
      <div className="button-container">
        <div className="flex-between">
          <BreadCrumb path={currentPath} navigateTo={navigateTo} />
          <div className="flex-end">
            <Search setSearch={setSearch} search={search} />
            <ButtonListComponent
              setRefresh={() => setRefresh(!refresh)}
              currentPath={currentPath}
              GetAllFiles={GetAllFiles}
              notify={props.notify}
              activeCustomerId={props.activeCustomerId}
              basePath={basePath}
            />
          </div>
        </div>

        {fileList.length > 0 ? (
          <FolderView
            structure={currentFolder}
            navigateTo={navigateTo}
            currentPath={currentPath}
            searchResults={searchResults}
            activeCustomerId={props.activeCustomerId}
            search={search}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "55vh",
              color: "#f00",
              fontWeight: 600,
              fontSize: 17,
            }}
          >
            There are no active records - click Add Folder or Add Document to
            create new file
          </div>
        )}
      </div>
    </div>
  );
}
