import * as React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
} from "@fluentui/react-components";

interface TableColumn {
  columnKey: string;
  label: string;
}

interface TableItem {
  [key: string]: any;
}

interface TableProps {
  columns: TableColumn[];
  items: TableItem[];
  maxWidth?: string;
}

const TableComponent: React.FC<TableProps & { level: number }> = ({
  columns,
  items,
  maxWidth,
  level,
}) => {
  return (
    <Table
      aria-label="Reusable table"
      className="table_content"
      style={{ maxWidth, margin: `0 3% 0 ${level * 20}px` }}
    >
      <TableHeader>
        <TableRow>
          {columns.map((column) => (
            <TableHeaderCell
              key={column.columnKey}
              style={{ fontWeight: "bold" }}
            >
              {column.label}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            {columns.map((column) => (
              <TableCell key={column.columnKey}>
                <TableCellLayout media={item[column.columnKey]?.icon}>
                  {item[column.columnKey]?.label}
                </TableCellLayout>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableComponent;
