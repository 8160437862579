import React, { useState, useEffect } from "react";
import "./styles.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  Divider,
  Field,
  Input,
  Link,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  Tooltip,
  useId,
  useToastController,
} from "@fluentui/react-components";
import Dropzone from "react-dropzone";
import UploadIcon from "../../../assets/uploadicon.svg";
import close from "../../../assets/close.svg";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { Delete16Regular, Edit16Filled } from "@fluentui/react-icons";
import apiServices from "../../../service";
import FormikInputRequest from "../../../service/hook/useFormik";
import OverlayLoader from "../../../components/OverlayLoader";
import ASCButton from "../../../components/Button";

const EmailTemplates = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [mode, setMode] = useState<"view" | "add" | "edit">("view");
  const [showAdd, setShowAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [templateListing, setTemplateListing] = useState<any>([]);
  const initialValues = { title: "", description: "", files: "" };
  const [loading, setLoading] = useState<boolean>(false);
  const [sampleTemplate, setSampleTemplate] = useState<any>([]);
  const [openSampleTemplate, setOpenSampleTemplate] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [cuLoading, setCULoading] = useState<boolean>(false);

  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const templateList = async () => {
    setLoading(true);
    try {
      const res = await apiServices.TemplateEngine.getEmailTemplate();
      setTemplateListing(res.data);
    } catch (error) {
      notify(error, "error");
      console.error("Error fetching the template list:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    templateList();
  }, []);
  const formikData = FormikInputRequest({
    initialValues,
    onSubmitFn: async (values: any) => {
      if (showAdd) {
        // Add functionality remains unchanged
        const { files, description, title } = values;
        const formData = new FormData();
        formData.append("files", files);

        try {
          // setLoading(true);
          setCULoading(true);

          const response = await apiServices.TemplateEngine.templateEmailUpload(
            {
              description,
              title,
              files: formData,
            }
          );
          notify(response.data.message, "success");
          await templateList();
          setUploadedFiles([]);
          formikData.resetForm();
          toggleAddMode(false);
          setMode("view");
        } catch (error) {
          notify(error, "error");
          console.error("Upload Failed:", error);
        } finally {
          // setLoading(false);
          setCULoading(false);
        }
      } else if (showEdit) {
        // Edit functionality
        const { title, description } = formikData.values;

        // Check for unchanged data
        if (
          title === selectedRow.title &&
          description === selectedRow.description &&
          !uploadedFiles.length
        ) {
        } else {
          // Always create a valid FormData object
          const formData = new FormData();
          if (uploadedFiles.length > 0) {
            formData.append("files", uploadedFiles[0]); // Assuming only one file
          }

          try {
            // setLoading(true);
            setCULoading(true);

            // Prepare payload for edit
            const payload = {
              title: title || selectedRow.title,
              description: description || selectedRow.description,
              files: formData,
            };

            // API call for update
            const response =
              await apiServices.TemplateEngine.updateTemplateLetter(payload);
            notify(response.data.data.message, "success");

            await templateList();
            setUploadedFiles([]);
            formikData.resetForm();
            setShowEdit(false);
            setMode("view");
          } catch (error) {
            notify(error, "error");
            console.error("Update Failed:", error);
          } finally {
            // setLoading(false);
            setCULoading(false);
          }
        }
      }
    },
    validate: (values: any) => {
      const errors: any = {};

      if (showAdd) {
        if (!values.title) {
          errors.title = "Title is required";
        } else if (values.title.length > 100) {
          errors.title = "Title must be less than 100 characters";
        }
      }

      // File upload validation (only for "Add" mode)
      if (showAdd && !values.files) {
        errors.files = "File upload is required";
      }

      return errors;
    },
  });

  function onChangeValue(key: string, value: any) {
    formikData.setFieldValue(key, value);
  }

  const columns: MRT_ColumnDef<any>[] = [
    { accessorKey: "title", header: "Title" },
    { accessorKey: "description", header: "Description" },
    {
      accessorKey: "signedUrl",
      header: "Template",
      Cell: ({ row }: any) => {
        const extractFileName = (filePath: string): string => {
          const match = filePath.match(/[^/]+$/);
          return match ? match[0] : "Unknown File";
        };
        const fileName = extractFileName(row.original.templateFilePath);

        return (
          <a
            href={row.original.signedUrl}
            style={{ textDecoration: "none", color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileName}
          </a>
        );
      },
    },
    { accessorKey: "status", header: "Status" },
  ];

  useEffect(() => {
    if (!selectedRow && templateListing.length > 0) {
      setSelectedRow(templateListing[0]);
    }
  }, [templateListing]);

  const handleRowSelection = (row: any) => {
    setSelectedRow(row.original);
  };

  const toggleAddMode = (isAddMode: boolean) => {
    setShowAdd(isAddMode);
    // setMode(isAddMode ? "add" : "view");
  };

  const handleEdit = (row: any) => {
    setSelectedRow(row.original);
    // setMode("edit");
    setShowEdit(true);
  };

  const removeFile = () => {
    setUploadedFiles([]);
    formikData.setFieldValue("files", null);
  };
  const removeFiles = () => {
    setUploadedFiles([]);
    formikData.setFieldValue("files", []); // Use [] instead of null
  };

  const renderDropzone = () => (
    <Dropzone
      onDrop={(acceptedFiles: File[]) => {
        setUploadedFiles([]);
        if (acceptedFiles.length > 0) {
          const files = acceptedFiles[0];
          setUploadedFiles([...acceptedFiles]);
          formikData.setFieldValue("files", files);
        }
      }}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="container">
          <div
            {...getRootProps({ className: "dropzone" })}
            className="file-drops"
            style={{ textAlign: "center" }}
          >
            <input {...getInputProps()} />
            <img src={UploadIcon} width="35px" alt="upload-icon" />
            <p style={{ textAlign: "center", cursor: "pointer" }}>
              Click here to upload file
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );

  const renderUploadedFile = () =>
    uploadedFiles.map((file, index) => (
      <div className="list-upload-items" key={index}>
        <li>
          {file.name} - {(file.size / 1024).toFixed(2)} KB
          <img
            className="file-upload-close"
            src={close}
            onClick={removeFile}
            width={20}
            alt="remove-file"
          />
        </li>
      </div>
    ));
  const handleDelete = async (row: any) => {
    setLoading(true);
    setSelectedRow(row.original);

    const id = row.original.templateFilePath;

    try {
      const res = await apiServices.TemplateEngine.deleteEmailTemplate(id);
      notify(res.data.message, "success");
      await templateList();
    } catch (error) {
      notify(error, "error");
      console.error("Error deleting the template:", error);
    } finally {
      setLoading(false);
    }
  };

  const sampleTemplates = async () => {
    setLoading(true);
    try {
      const res = await apiServices.TemplateEngine.getSampleEmail();
      setSampleTemplate(res.data);
    } catch (error) {
      console.error("Error fetching the template list:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    sampleTemplates();
  }, []);
  const columnsData: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "Name",
      header: "Title",
    },
    {
      accessorKey: "Description",
      header: "Description",
    },
    {
      accessorKey: "SignedUrl",
      header: "Template Link",
      Cell: ({ row }: any) => {
        const extractFileName = (filePath: string): string => {
          const match = filePath.match(/[^/]+$/);
          return match ? match[0] : "Unknown File";
        };
        const fileName = extractFileName(row.original.TemplateFilePath);

        return (
          <a
            href={row.original.SignedUrl}
            style={{ textDecoration: "none", color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileName}
          </a>
        );
      },
    },
  ];

  return (
    <>
      <OverlayLoader isLoading={loading} />
      <Toaster
        toasterId={toasterId}
        position="top-end"
        pauseOnHover
        pauseOnWindowBlur
        timeout={1000}
      />

      <div className="container">
        {mode === "view" && (
          <div className="add-btn">
            <div>
              <ASCButton
                shape="rounded"
                appearance="primary"
                className="asc-button-primary"
                onClick={() => toggleAddMode(true)}
                label="Add New"
              />
            </div>
            <div>
              <ASCButton
                shape="rounded"
                appearance="secondary"
                onClick={() => {
                  sampleTemplates();
                  setOpenSampleTemplate(true);
                }}
                label="Get Sample Templates"
              />
            </div>
          </div>
        )}

        {templateListing.length === 0 && mode === "view" && !showAdd && (
          <div className="notification">
            <span className="notification-font">
              There are no active records. Click 'Add New' to create a new
              record.
            </span>
          </div>
        )}

        {mode === "view" && templateListing.length > 0 && (
          <div className="section-container">
            {selectedRow && (
              <div className="email-template">
                <div className="section">
                  <div>
                    <span className="view-bold">Title :</span>
                  </div>
                  <div>{selectedRow?.title}</div>
                </div>
                <div className="section">
                  <div>
                    <span className="view-bold">Description :</span>
                  </div>
                  <div>{selectedRow.description}</div>
                </div>
                <div className="section">
                  <div>
                    <span className="view-bold">Template :</span>
                  </div>
                  {/* <div>
                    <a
                      href={selectedRow?.signedUrl}
                      download={selectedRow?.TemplateFilePath}
                      style={{ textDecoration: "none", color: "#3498db" }}
                    >
                      {" "}
                      {selectedRow?.templateFilePath}
                    </a>
                  </div> */}
                  <div>
                    <a
                      href={selectedRow?.signedUrl}
                      download={
                        selectedRow?.templateFilePath
                          ? selectedRow.templateFilePath.split("/").pop()
                          : undefined
                      }
                      style={{ textDecoration: "none", color: "#3498db" }}
                    >
                      {selectedRow?.templateFilePath
                        ? selectedRow.templateFilePath.split("/").pop()
                        : "Download File"}
                    </a>
                  </div>
                </div>
                <div className="section">
                  <div>
                    <span className="view-bold">Status :</span>
                  </div>
                  <div>{selectedRow?.status}</div>
                </div>
              </div>
            )}
            <MaterialReactTable
              data={templateListing}
              columns={columns}
              muiTableBodyCellProps={{
                style: { padding: "4px 8px", fontSize: "0.875rem" },
              }}
              muiTableContainerProps={{
                style: {
                  minHeight: "265px",
                  maxHeight: "265px",
                  overflow: "auto",
                },
              }}
              enableRowActions
              initialState={{
                columnPinning: { right: ["mrt-row-actions"] },
                pagination: { pageSize: 5, pageIndex: 0 },
              }}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleRowSelection(row),
                sx: {
                  cursor: "pointer",
                  backgroundColor:
                    selectedRow && row.original.id === selectedRow.id
                      ? "white"
                      : "transparent ",
                },
              })}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Actions",
                  muiTableHeadCellProps: { align: "center" },
                  muiTableBodyCellProps: { align: "center" },
                },
              }}
              renderRowActions={({ row }) => (
                <div>
                  <Tooltip content="Edit" relationship="label">
                    <ASCButton
                      shape="rounded"
                      disabled={row?.original?.status !== "Draft"}
                      appearance="subtle"
                      icon={<Edit16Filled />}
                      style={{ color: "var(--button-color)" }}
                      onClick={() => handleEdit(row)}
                    />
                  </Tooltip>
                  <Tooltip content="Delete" relationship="label">
                    <ASCButton
                      shape="rounded"
                      appearance="subtle"
                      style={{ color: "red" }}
                      icon={<Delete16Regular />}
                      onClick={() => handleDelete(row)}
                    />
                  </Tooltip>
                </div>
              )}
            />
          </div>
        )}
      </div>

      <Dialog modalType="alert" open={openSampleTemplate}>
        <DialogSurface>
          <DialogBody>
            <span style={{ fontWeight: 600, fontSize: "16px" }}>
              Sample Templates{" "}
            </span>
            <DialogContent>
              <MaterialReactTable
                columns={columnsData}
                data={sampleTemplate}
                manualPagination
                enableGlobalFilter={true}
                enableColumnActions={false}
                enableColumnFilters={true}
                // muiTableBodyRowProps={({ row }) => ({
                //   onClick: () => handleRowSelection(row),
                // })}
                muiTableBodyCellProps={{
                  style: { padding: "4px 8px", fontSize: "0.875rem" },
                }}
                enableMultiRowSelection={false}
                enableFullScreenToggle={false}
                muiTablePaginationProps={{
                  showFirstButton: false,
                  showLastButton: false,
                }}
              />
            </DialogContent>
            <DialogActions>
              <ASCButton
                shape="rounded"
                size="small"
                onClick={() => {
                  setOpenSampleTemplate(false);
                }}
                label="Close"
              />
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <Dialog modalType="alert" open={showAdd}>
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <OverlayLoader isLoading={loading} />
              <div className="email-template-add-popup">
                <span style={{ fontWeight: 600, fontSize: "15px" }}>
                  Add New
                </span>
                {/* <Divider /> */}
                <div className="section">
                  <div>
                    <Field
                      label={
                        <span>
                          Title<span style={{ color: "red" }}>*</span>
                        </span>
                      }
                    >
                      <Input
                        name="title"
                        value={formikData.values.title}
                        className="input-width-popup"
                        onChange={(ev: any) => {
                          onChangeValue("title", ev.target.value);
                          // dispatch(setFormData());
                        }}
                      />
                      {formikData.errors.title && formikData.touched.title && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {typeof formikData.errors.title === "string"
                            ? formikData.errors.title
                            : ""}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="section">
                  <div>
                    <Field label="Description">
                      <Input
                        className="input-width-popup"
                        name="description"
                        value={formikData.values.description}
                        onChange={(ev: any) => {
                          onChangeValue("description", ev.target.value);
                        }}
                      />
                    </Field>
                  </div>
                </div>
                <div className="section">
                  <div>
                    <Field
                      label={
                        <span>
                          File Upload
                          <span style={{ color: "red" }}>* &nbsp;( </span>
                          <span style={{ color: "red", fontWeight: 600 }}>
                            .html only )
                          </span>
                        </span>
                      }
                    >
                      <div className="flex-block">
                        {renderDropzone()}{" "}
                        <ul className="file-name">{renderUploadedFile()}</ul>
                      </div>
                      {formikData.errors.files && formikData.touched.files && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {typeof formikData.errors.files === "string"
                            ? formikData.errors.files
                            : ""}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="action-button">
                <ASCButton
                  shape="rounded"
                  appearance="primary"
                  className="asc-button-primary"
                  onClick={() => {
                    formikData.validateForm().then((errors) => {
                      if (Object.keys(errors).length === 0) {
                        formikData.handleSubmit();
                      }
                    });
                  }}
                  disabled={
                    !formikData.isValid || // Disable if form is invalid
                    formikData.isSubmitting || // Disable while submitting
                    (showAdd && !formikData.values.files) || // Disable if file is not uploaded in "Add" mode
                    (!showAdd && !formikData.dirty) // Disable if no changes are made in "Edit" mode
                  }
                  label={cuLoading ? "Saving..." : "Save & Upload"}
                />
                <ASCButton
                  shape="rounded"
                  appearance="secondary"
                  onClick={() => {
                    toggleAddMode(false);
                    formikData.resetForm();
                  }}
                  label="Cancel"
                />
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      {selectedRow && (
        <Dialog modalType="alert" open={showEdit}>
          <DialogSurface>
            <DialogBody>
              <DialogContent>
                <div className="email-template-add-popup">
                  <span style={{ fontWeight: 600, fontSize: "15px" }}>
                    Edit
                  </span>
                  {/* <Divider /> */}
                  <div className="section">
                    <div>
                      <Field label="Title">
                        <Input
                          readOnly
                          name="title"
                          value={formikData.values.title || selectedRow.title} // Fallback to selected row data
                          className="input-width-popup"
                          onChange={(ev: any) =>
                            onChangeValue("title", ev.target.value)
                          }
                        />
                      </Field>
                    </div>
                  </div>
                  <div className="section">
                    <div>
                      <Field label="Description">
                        <Input
                          className="input-width-popup"
                          name="description"
                          value={
                            formikData.values.description ||
                            selectedRow.description
                          } // Fallback to selected row data
                          onChange={(ev: any) =>
                            onChangeValue("description", ev.target.value)
                          }
                        />
                      </Field>
                    </div>
                  </div>
                  <span>
                    <span>
                      File Upload
                      <span style={{ color: "red" }}>* &nbsp;( </span>
                      <span style={{ color: "red", fontWeight: 600 }}>
                        .html only )
                      </span>
                    </span>{" "}
                    <div className="flex-block">
                      <Dropzone
                        onDrop={(acceptedFiles: File[]) => {
                          setUploadedFiles([]);
                          if (acceptedFiles.length > 0) {
                            const files = acceptedFiles[0];
                            setUploadedFiles([...acceptedFiles]);
                            formikData.setFieldValue("files", files);
                          }
                        }}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="container">
                            <div
                              {...getRootProps({ className: "dropzone" })}
                              className="file-drops"
                              style={{ textAlign: "center" }}
                            >
                              <input {...getInputProps()} />
                              <img
                                src={UploadIcon}
                                width="35px"
                                alt="upload-icon"
                              />
                              <p style={{ textAlign: "center" }}>
                                Click here to upload file
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>

                      {/* Conditional Rendering for Selected Row's File Link */}
                      {selectedRow.signedUrl && uploadedFiles.length === 0 && (
                        <div className="list-upload-items">
                          <li>
                            <a
                              href={selectedRow?.signedUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              download={selectedRow?.signedUrl}
                              style={{
                                textDecoration: "none",
                                color: "#3498db",
                              }}
                              onClick={(e) => {
                                setSelectedRow((prev: any) => ({
                                  ...prev,
                                  signedUrl: "",
                                }));
                              }}
                            >
                              {selectedRow?.title || "Download File"}
                            </a>

                            <img
                              className="file-upload-close"
                              src={close}
                              // onClick={() => {
                              //   removeFiles();

                              // }}
                              onClick={(e) => {
                                setSelectedRow((prev: any) => ({
                                  ...prev,
                                  signedUrl: "",
                                }));
                              }}
                              width={20}
                              alt="remove-file"
                            />
                          </li>
                        </div>
                      )}

                      {/* Render Newly Uploaded Files */}
                      <ul>
                        {uploadedFiles.map((file: any, index: any) => (
                          <div className="list-upload-items" key={index}>
                            <li>
                              {file.name} - {(file.size / 1024).toFixed(2)} KB
                              <img
                                className="file-upload-close"
                                src={close}
                                onClick={() => {
                                  removeFiles();
                                }}
                                width={20}
                                alt="remove-file"
                              />
                            </li>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </span>
                  {formikData.errors.files && formikData.touched.files && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {typeof formikData.errors.files === "string"
                        ? formikData.errors.files
                        : ""}
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <div className="action-button">
                  <ASCButton
                    shape="rounded"
                    appearance="primary"
                    className="asc-button-primary"
                    onClick={() => {
                      formikData.handleSubmit();
                    }}
                    disabled={
                      formikData?.values?.files?.length === 0 ||
                      uploadedFiles?.length === 0
                    }
                    label={cuLoading ? "Updating..." : "Save & Upload"}
                  />

                  <ASCButton
                    shape="rounded"
                    appearance="secondary"
                    onClick={() => {
                      setShowEdit(false);
                      setMode("view");
                      formikData.resetForm();
                    }}
                    label="Cancel"
                  />
                </div>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
};

export default EmailTemplates;
