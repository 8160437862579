import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const formatedDatepickerDate = (date: any) => {
  if (date !== undefined && date !== null && date !== "null" && date !== "") {
    let mydate = moment(date, "DD/MM/YYYY");
    return moment(mydate).format("MM/DD/YYYY");
  } else {
    return null;
  }
};

export const formatedDate = (date: any) => {
  if (date !== undefined && date !== null && date !== "null" && date !== "") {
    let frmdate = moment(date).format("DD-MM-YYYY");
    return frmdate;
  } else {
    return "";
  }
};

export const formatedTime = (date: any) => {
  if (date !== undefined && date !== null && date !== "null" && date !== "") {
    // Parse the date as UTC and format the time
    let frmTime = moment(date).utc().format("hh:mm A");
    return frmTime;
  } else {
    return "";
  }
};
// export const formatedDuration = (
//   startDate: any,
//   endDate: any,
//   date?: any
// ): string => {
//   const start = moment(date || startDate);
//   const end = moment(endDate);
//   const duration = moment.duration(end.diff(start));

//   let hours = Math.floor(duration.asHours());
//   const minutes = duration.minutes();
//   const seconds = duration.seconds();

//   // Ignore hours if it's exactly 24 or 0
//   if (hours === 24) {
//     hours = 0;
//   }

//   const formatted = [
//     hours > 0 ? `${hours}h` : "", // Only include hours if > 0
//     minutes > 0 ? `${minutes}m` : "", // Include minutes if > 0
//     `${seconds}s`, // Always include seconds
//   ]
//     .filter(Boolean) // Remove empty values
//     .join(" ");

//   return formatted;
// };

export const formattedDuration = (date?: string | null): string => {
  if (!date) return ""; // Return empty string if date is null or undefined
  const time = moment.utc(date);
  return time.isValid() ? time.format("HH:mm:ss") : moment().format("HH:mm:ss");
};
export const formattedTime = (date?: string | null): string => {
  if (!date) return ""; // Return empty string if date is null or undefined
  const time = moment.utc(date);
  return time.isValid() ? time.format("HH:mm") : moment().format("HH:mm");
};

export const getSalutations = (values: any) => {
  let salutation = "";
  if (
    values &&
    values !== undefined &&
    Object.keys(values).length !== 0 &&
    values.constructor === Object
  ) {
    if (values.title && values.title !== "") {
      salutation += values.title + ".";
    }
    if (values.first_names && values.first_names !== "") {
      salutation += " " + values.first_names;
    }
    if (values.last_name && values.last_name !== "") {
      salutation += " " + values.last_name + "";
    }
  }
  return salutation;
};

export const checkIfKeyExist = (objectArray: [], keyName: string) => {
  let keyExist = objectArray.find((key: any) => key.context === keyName);
  return keyExist;
};

export const filterArrayByKey = (opts: any, key: string) => {
  let filteredData = opts.filter((item: any) => item.context === key);
  return filteredData && filteredData.length > 0 ? filteredData : [];
};

export const findValueByKey = (opts: any, key: string) => {
  let match = opts.filter((opt: any) => {
    return opt.context === key;
  });
  return match[0] ? match[0].value : null;
};

export const camalize = (str: string) => {
  if (str !== undefined) {
    return str
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })

      .replace(/^(.)/, function ($1) {
        return $1.toUpperCase();
      });
  } else {
    return "";
  }
};

export const removeEmptyValues = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      obj[propName] = "";
    }
  }
  return obj;
};

export const getAssetTypeByCaseType = (caseType: any) => {
  let caseTypeList = {
    "0": "Bank/Building Socities",
    "1": "Share Holdings",
    "2": "Home and Personal",
    "3": "Investment",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getAssetTypeByCaseTypeASReq = (caseType: any) => {
  let caseTypeList = {
    "0": "Banks/B Societies",
    "1": "Shares",
    "2": "Home/Personal",
    "3": "Unit-linked",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getAssetTypeByCaseTypeId = (caseType: any) => {
  let caseTypeList = {
    "Banks/B Societies": "0",
    Shares: "1",
    "Home/Personal": "2",
    "Unit-linked": "3",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getAssetCategoryByCaseType = (caseType: any) => {
  let caseTypeList = {
    "Banks/B Societies": "Bank/Building Socities",
    Shares: "Share Holdings",
    "Home/Personal": "Home and Personal",
    "Unit-linked": "Investment",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const generateUUID = () => {
  const newUUID = uuidv4();
  return newUUID;
};

export const getClientType = (typeValue: number) => {
  let clientTypes = {
    "0": "Client",
    "1": "Partner",
    "2": "Joint",
  };
  return (clientTypes as { [key: string]: any })[typeValue] !== undefined
    ? (clientTypes as { [key: string]: any })[typeValue]
    : "";
};

export const getYes_NoConversion = (typeValue: number) => {
  let LinkedToMortgage = {
    "0": "No",
    "1": "Yes",
  };
  return (LinkedToMortgage as { [key: string]: any })[typeValue] !== undefined
    ? (LinkedToMortgage as { [key: string]: any })[typeValue]
    : "";
};

export const getClientCat = (typeValue: number) => {
  let clientTypes = {
    Client: "0",
    Partner: "1",
    Joint: "2",
  };
  return (clientTypes as { [key: string]: any })[typeValue] !== undefined
    ? (clientTypes as { [key: string]: any })[typeValue]
    : "";
};

export const getClientNoteCategories = (categoryId: number) => {
  let noteCategories = {
    0: "Standard Note",
    1: "Letter Produced",
    2: "Email Sent",
    3: "Email Saved",
    4: "Report Exported",
    5: "Mail Merge",
    7: "Fact-find - Dependants",
    8: "Fact-find - Incomes",
    9: "Fact-find - Outgoings",
    10: "Fact-find - Employments",
    11: "Fact-find - Assets",
    12: "Fact-find - Liabilities",
    13: "Fact-find - Policies",
    14: "Fact-find - Attitude to Risk",
    15: "Fact-find - Objectives",
    16: "Fact-find - Summary",
    18: "Fact-find - Pensions",
    19: "Fact-find - Investments",
    20: "Fact-find - Protection",
    21: "Fact-find - Mortgages",
    22: "Document Uploaded",
    23: "Document Downloaded",
  };
  return (noteCategories as { [key: string]: any })[categoryId] !== undefined
    ? (noteCategories as { [key: string]: any })[categoryId]
    : "";
};

export const getAssetFundCodeSource = (categoryId: number) => {
  let codeSources = {
    0: "Undefined",
    1: "MEX",
    2: "SEDOL",
    3: "Product Provider Specific",
    4: "ISIN",
  };
  return (codeSources as { [key: string]: any })[categoryId] !== null
    ? (codeSources as { [key: string]: any })[categoryId]
    : "";
};

export const getToken = () => {
  return localStorage.getItem("assystUserToken");
};

export const removeToken = () => {
  localStorage.removeItem("assystUserToken");
};

export const setToken = (val: any) => {
  localStorage.setItem("assystUserToken", val);
};
export const removeRefreshToken = () => {
  localStorage.removeItem("assystUserRefreshToken");
};
export const setRefreshToken = (val: any) => {
  localStorage.setItem("assystUserRefreshToken", val);
};
export const setUserId = (val: any) => {
  return localStorage.setItem("assystUserId", val);
};
export const getUserId = () => {
  return localStorage.getItem("assystUserId");
};
export const getRefreshToken = () => {
  return localStorage.getItem("assystUserRefreshToken");
};
export const removeUserId = () => {
  localStorage.removeItem("assystUserId");
};
// declare all characters
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateString = (length: number) => {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const checkUserPermission = (loggedUser: any, permission: string) => {
  if (
    loggedUser !== undefined &&
    loggedUser.userData !== undefined &&
    loggedUser.userData.realm_access !== undefined &&
    loggedUser.userData.realm_access.roles !== undefined &&
    loggedUser.userData.realm_access.roles.length > 0
  ) {
    if (loggedUser.userData.realm_access.roles.includes(permission)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const getPolicyCategoryByCaseType = (caseType: any) => {
  let caseTypeList = {
    "Life Assurance": "Life Assurance",
    Pensions: "Pensions",
    Investments: "Investments",
    "Savings Plans": "Savings Plans",
    "Income Protection": "Income Protection",
    "Health Assurance": "Health Assurance",
    General: "General",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const getpolicyCategoryByCaseType = (caseType: any) => {
  let caseTypeList = {
    "Life Assurance": "Life Assurance",
    Pensions: "Pensions",
    Investments: "Investments",
    "Savings Plans": "Savings Plans",
    "Income Protection": "Income Protection",
    "Health Assurance": "Health Assurance",
    General: "General",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};

export const formatedDate2 = (date: any) => {
  if (date !== undefined && date !== null && date !== "") {
    let frmdate = moment(date).format("YYYY-MM-DD");
    return frmdate;
  } else {
    return "";
  }
};
export const getUserAvatharName = (name: any) => {
  const nameParts = name.split(" ");
  const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
  const lastNameInitial = nameParts[1] ? nameParts[1][0] : "";
  return firstNameInitial + lastNameInitial;
};
export const isObjectEmpty = (object: any) => {
  return Object.keys(object).length === 0;
};
export const getCommissionCategory = (category: any) => {
  switch (category) {
    case 0:
      return "Generic";
    case 1:
      return "Case-related";
    case 2:
      return "Other Fees/Charges";
    default:
      return "";
  }
};
export const getUserDefinedType = (category: any) => {
  switch (category) {
    case 0:
      return "String";
    case 1:
      return "Integer";
    case 2:
      return "Real";
    case 3:
      return "Date";
    default:
      return "";
  }
};

export const getAmountType = (category: any) => {
  switch (category) {
    case 0:
      return "Money";
      break;
    case 1:
      return "Percent";
      break;

    default:
      return "";
      break;
  }
};
export const convertStringToArray = (data?: string) => {
  let convertedString: any = [];
  if (data != undefined) {
    convertedString = data.split(",");
  }

  return convertedString;
};
export const isObjectValue = (variable: any) => {
  return (
    variable !== null &&
    typeof variable === "object" &&
    !Array.isArray(variable)
  );
};
export const getFileNameFromUrl = (url: string) => {
  if (url != undefined && url !== "") {
    return url.substring(url.lastIndexOf("/") + 1);
  } else {
    return "";
  }
};
export const formatCondition = (condition: string): string => {
  try {
    const parsedCondition: any = JSON.parse(condition);
    const {
      tableOne: {
        label: fieldNameOne,
        fieldValue: fieldValueOne,
        condition: conditionOne,
      },
      tableTwo,
      condition: overallCondition,
    } = parsedCondition;

    const tableOnePart = `${fieldNameOne} ${conditionOne} ${fieldValueOne}`;
    let tableTwoPart = "";
    let conditionPart = "";

    if (tableTwo) {
      const {
        label: fieldNameTwo,
        fieldValue: fieldValueTwo,
        condition: conditionTwo,
      } = tableTwo;
      tableTwoPart = `${fieldNameTwo} ${conditionTwo} ${fieldValueTwo}`;
    }

    if (overallCondition) {
      conditionPart = ` ${overallCondition} `;
    }

    return tableTwoPart
      ? `${tableOnePart}${conditionPart}${tableTwoPart}`
      : tableOnePart;
  } catch (error) {
    console.error("Error parsing condition:", error);
    return "Invalid condition";
  }
};

export const isNullOrEmpty = (value: any): boolean => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === "string" && value.trim() === "") {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (
    typeof value === "object" &&
    value !== null &&
    Object.keys(value).length === 0
  ) {
    return true;
  }
  return false;
};
export const ContextsList = [
  { key: "name-and-address", value: "CLIENT_NAME_ADDRESS" },
  { key: "contact", value: "CLIENT_CONTACT" },
  { key: "nationality", value: "CLIENT_NATIONALITY" },
  { key: "personal", value: "CLIENT_PERSONAL" },
  { key: "vulnerability", value: "CLIENT_VULNERABILITY" },
  { key: "health-note", value: "CLIENT_HEALTH_NOTE" },
  { key: "employment", value: "CLIENT_EMPLOYMENT" },
  { key: "attitude-to-risk", value: "CLIENT_ATTITUDE_TO_RISK" },
  { key: "identity", value: "CLIENT_IDENTITY" },
  { key: "objectives", value: "CLIENT_OBJECTIVES" },
  { key: "administration", value: "ADMINISTRATION" },
  { key: "compliance", value: "COMPLIANCE" },
  { key: "service-type", value: "CLIENT_SERVICE_TYPE" },
  { key: "user-defined", value: "CLIENT_USER_DEFINED" },
  { key: "depentant", value: "CLIENT_DEPENDANT" },
];
export const getCaseCategoryByCaseType = (caseType: any) => {
  let caseTypeList = {
    "Unit-linked": "investment",
    "Home/Personal": "home-personal",
    "Banks/B Societies": "bank-societies",
    Shares: "shares",
    Mortgages: "morgages",
    "Loans/Lease/HP": "loans-lease-hp",
    "Credit Cards": "credit-cards",
    "Life Assurance": "Life Assurance",
    Pensions: "Pensions",
    Investments: "Investments",
    "Savings Plans": "Savings Plans",
    "Income Protection": "Income Protection",
    "Health Assurance": "Health Assurance",
    General: "General",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};
export const getAssetTypeByCaseTypeId2 = (caseType: any) => {
  let caseTypeList = {
    "bank-societies": "0",
    shares: "1",
    "home-personal": "2",
    investment: "3",
    morgages: "Mortgages",
    "loans-lease-hp": "Loans/Lease/HP",
    "credit-cards": "Credit Cards",
    "Life Assurance": "Life Assurance",
    Pensions: "Pensions",
    Investments: "Investments",
    "Savings Plans": "Savings Plans",
    "Income Protection": "Income Protection",
    "Health Assurance": "Health Assurance",
    General: "General",
  };
  return (caseTypeList as { [key: string]: any })[caseType] !== undefined
    ? (caseTypeList as { [key: string]: any })[caseType]
    : "";
};
export const caseContextList: any = {
  assets: {
    investment: {
      asset: "ASSET_INVESTMENT_ASSET",
      fund: "ASSET_INVESTMENT_FUND",
      payment: "ASSET_INVESTMENT_PAYMENTS",
      compliance: "ASSET_INVESTMENT_PAYMENTS_COMPLIANCE",
      commission: "ASSET_INVESTMENT_PAYMENTS_COMMISSION",
      distribution: "ASSET_INVESTMENT_COMMISSION_DISTRIBUTION",
      withdrawal: "ASSET_INVESTMENT_WITHDRAWALS",
      action: "ASSET_INVESTMENT_ACTIONS",
      valuation: "ASSET_INVESTMENT_VALUATION",
      review: "ASSET_INVESTMENT_REVIEW",
    },
    shares: {
      asset: "ASSET_SHAREHOLDINGS_ASSET",
      fund: "ASSET_SHARE_HOLDINGS_FUND",
      payment: "ASSET_SHARE_HOLDINGS_PAYMENT",
      compliance: "ASSET_SHAREHOLDINGS_PAYMENT_COMPLIANCE",
      commission: "ASSET_SHAREHOLDINGS_PAYMENT_COMMISSION",
      withdrawal: "ASSET_SHARE_HOLDING_WITHDRAWALS",
      review: "ASSET_SHARE_HOLDINGS_REVIEW",
      valuation: "ASSET_SHAREHOLDING_VALUATION",
    },
    "home-personal": {
      asset: "ASSET_HOME_AND_PERSONAL_ASSET",
      review: "ASSET_HOME_AND_PERSONAL_REVIEW",
      valuation: "ASSET_HOME_AND_PERSONAL_VALUATION",
    },
    "bank-societies": {
      asset: "ASSET_BANK_OR_BUILDING_SOCIETIES_ASSET",
      fund: "ASSET_BANK_OR_BUILDING_SOCIETIES_FUND",
      payment: "ASSET_BANK_OR_BUILDING_SOCIETIES_PAYMENT",
      compliance: "ASSET_BANK_OR_BUILDING_SOCIETIES_COMPLIANCE",
      commission: "ASSET_BANK_OR_BUILDING_SOCIETIES_PAYMENT_COMMISSION",
      withdrawal: "ASSET_BANK_OR_BUILDING_SOCIETIES_WITHDRAWALS",
      review: "ASSET_BANK_OR_BUILDING_SOCIETIES_REVIEW",
      valuation: "ASSET_BANK_OR_BUILDING_SOCIETIES_VALUATION",
    },
  },
  liabilities: {
    morgages: {
      liability: "LIABILITIES_MORTGAGES_LIABILITY",
      review: "LIABILITIES_MORTGAGES_REVIEW",
      payment: "LIABILITIES_MORTGAGES_PAYMENT",
      compliance: "LIABILITIES_MORTGAGES_PAYMENT_COMPLAINCE",
      commission: "LIABILITIES_MORTGAGES_PAYMENT_COMMISSION",
      action: "LIABILITIES_MORTGAGES_ACTIONS",
    },
    "loans-lease-hp": {
      liability: "LIABILITIES_LOAN_HIRE_PURCHASE_LIABILITY",
      review: "LIABILITIES_LOAN_HIRE_PURCHASE_REVIEW",
      payment: "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENT",
      compliance: "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENTS_COMPLAINCE",
      commission: "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENTS_COMMISSION",
      action: "LIABILITIES_LOAN_HIRE_PURCHASE_ACTIONS",
    },
    "credit-cards": {
      liability: "LIABILITIES_CREDIT_CARDS_LIABILITY",
      review: "LIABILITIES_CREDIT_CARDS_REVIEW",
      payment: "LIABILITIES_CREDIT_CARDS_PAYMENTS",
      compliance: "LIABILITIES_CREDIT_CARDS_PAYMENTS_COMPLAINCE",
      commission: "LIABILITIES_CREDIT_CARDS_PAYMENTS_COMMISSION",
    },
  },
  policies: {
    "Life Assurance": {
      policy: "POLICIES_LIFE_ASSURANCE_POLICIES",
      review: "POLICIES_LIFE_ASSURANCE_REVIEW",
      contractenquiry: "POLICIES_LIFE_ASSURANCE_CONTRACT_ENQUIRY",
      fund: "POLICIES_LIFE_ASSURANCE_FUNDS",
      payment: "POLICIES_LIFE_ASSURANCE_PAYMENT",
      compliance: "POLICIES_LIFE_ASSURANCE_PAYMENTS_COMPLAINCE",
      commission: "POLICIES_LIFE_ASSURANCE_PAYMENTS_COMMISSION",
      withdrawal: "POLICIES_LIFE_ASSURANCE_WITHDRAWALS",
      action: "POLICIES_LIFE_ASSURANCE_ACTIONS",
      valuation: "POLICIES_LIFE_ASSURANCE_VALUATION",
    },
    Pensions: {
      policy: "POLICIES_PENSIONS_POLICY",
      review: "POLICIES_PENSIONS_REVIEW",
      contractenquiry: "POLICIES_PENSIONS_CONTRACT_ENQUIRY",
      fund: "POLICIES_PENSIONS_FUNDS",
      payment: "POLICIES_PENSIONS_PAYMENT",
      complaince: "POLICIES_PENSIONS_PAYMENTS_COMPLAINCE",
      commission: "POLICIES_PENSIONS_PAYMENTS_COMMISSION",
      withdrawal: "POLICIES_PENSIONS_WITHDRAWALS",
      action: "POLICIES_PENSIONS_ACTIONS",
      valuation: "POLICIES_PENSIONS_VALUATION",
    },
    Investments: {
      policy: "POLICIES_INVESTMENTS_POLICY",
      review: "POLICIES_INVESTMENTS_REVIEW",
      contractenquiry: "POLICIES_INVESTMENTS_CONTRACT_ENQUIRY",
      fund: "POLICIES_INVESTMENTS_FUNDS",
      payment: "POLICIES_INVESTMENTS_PAYMENT",
      compliance: "POLICIES_INVESTMENTS_PAYMENTS_COMPLAINCE",
      commission: "POLICIES_INVESTMENTS_PAYMENTS_COMMISSION",
      withdrawal: "POLICIES_INVESTMENTS_WITHDRAWALS",
      action: "POLICIES_INVESTMENTS_ACTIONS",
      valuation: "POLICIES_INVESTMENTS_VALUATION",
    },
    "Savings Plans": {
      policy: "POLICIES_SAVINGS_PLANS_POLICY",
      review: "POLICIES_SAVINGS_PLANS_REVIEW",
      contractenquiry: "POLICIES_SAVINGS_PLANS_CONTRACT_ENQUIRY",
      fund: "POLICIES_SAVINGS_PLANS_FUNDS",
      payment: "POLICIES_SAVINGS_PLANS_PAYMENT",
      complaince: "POLICIES_SAVINGS_PLANS_COMPLAINCE",
      commission: "POLICIES_SAVINGS_PLANS_COMMISSION",
      withdrawal: "POLICIES_SAVINGS_PLANS_WITHDRAWALS",
      action: "POLICIES_SAVINGS_PLANS_ACTIONS",
      valuation: "POLICIES_SAVINGS_PLANS_VALUATION",
    },
    "Income Protection": {
      policy: "POLICIES_INCOME_PROTECTION_POLICY",
      review: "POLICIES_INCOME_PROTECTION_REVIEW",
      contractenquiry: "POLICIES_INCOME_PROTECTION_CONTRACT_ENQUIRY",
      fund: "POLICIES_INCOME_PROTECTION_FUNDS",
      payment: "POLICIES_INCOME_PROTECTION_PAYMENT",
      complaince: "POLICIES_INCOME_PROTECTION_COMPLAINCE",
      commission: "POLICIES_INCOME_PROTECTION_COMMISSION",
      withdrawal: "POLICIES_INCOME_PROTECTION_WITHDRAWALS",
      action: "POLICIES_INCOME_PROTECTION_ACTIONS",
      valuation: "POLICIES_INCOME_PROTECTION_VALUATION",
    },
    "Health Assurance": {
      policy: "POLICIES_HEALTH_ASSURANCE_POLICY",
      review: "POLICIES_HEALTH_ASSURANCE_REVIEW",
      contractenquiry: "POLICIES_HEALTH_ASSURANCE_CONTRACT_ENQUIRY",
      fund: "POLICIES_HEALTH_ASSURANCE_FUNDS",
      payment: "POLICIES_HEALTH_ASSURANCE_PAYMENT",
      complaince: "POLICIES_HEALTH_ASSURANCE_COMPLAINCE",
      commission: "POLICIES_HEALTH_ASSURANCE_COMMISSION",
      withdrawal: "POLICIES_HEALTH_ASSURANCE_WITHDRAWALS",
      action: "POLICIES_HEALTH_ASSURANCE_ACTIONS",
      valuation: "POLICIES_HEALTH_ASSURANCE_VALUATION",
    },
    General: {
      policy: "POLICIES_GENERAL_POLICY",
      review: "POLICIES_GENERAL_REVIEW",
      contractenquiry: "POLICIES_GENERAL_CONTRACT_ENQUIRY",
      fund: "POLICIES_GENERAL_FUNDS",
      payment: "POLICIES_GENERAL_PAYMENT",
      complaince: "POLICIES_GENERAL_PAYMENTS_COMPLAINCE",
      commission: "POLICIES_GENERAL_PAYMENTS_COMMISSION",
      withdrawal: "POLICIES_GENERAL_WITHDRAWALS",
      action: "POLICIES_GENERAL_ACTIONS",
      valuation: "POLICIES_GENERAL_VALUATION",
    },
  },
};
export const ItemDisplayKeys: any = {
  employment: {
    title: "employer_name",
    subTitle: "occupation",
  },
  "attitude-to-risk": {
    title: "category",
    subTitle: "rating",
  },
  vulnerability: {
    title: "driver",
    subTitle: "characteristics",
  },
  assets: {
    title: "owner",
    subTitle: "owner_name1",
  },
  liabilities: {
    title: "borrower",
    subTitle: "borrower_name1",
  },
  policies: {
    title: "assured_name1",
    subTitle: "assured_name2",
  },
};
export const getItemDisplayValue = (
  selectedItem: any,
  section: string,
  itemDisplayKeys: any
) => {
  if (!selectedItem) {
    return "Detail";
  }

  const { title: titleKey = "title", subTitle: subtitleKey = "subtitle" } =
    section && itemDisplayKeys[section] ? itemDisplayKeys[section] : {};

  const title = selectedItem[titleKey] || "Detail";
  let subtitle = selectedItem[subtitleKey];
  if (subtitle && subtitle.length > 10) {
    subtitle = `${subtitle.substring(0, 10)}...`;
  }

  return subtitle ? `${title}(${subtitle})` : title;
};
export const formatAmount = (
  amount: string | number,
  amountFlag: number
): string => {
  const parsedAmount = parseFloat(amount as string);

  if (isNaN(parsedAmount)) return "";

  if (amountFlag === 1) {
    return parsedAmount % 1 === 0
      ? `${parseInt(parsedAmount.toString(), 10)}%`
      : `${parsedAmount.toFixed(2)}%`;
  }

  return parsedAmount % 1 === 0
    ? parseInt(parsedAmount.toString(), 10).toString()
    : parsedAmount.toFixed(2);
};
export const isValidNoteText = (noteText: string) => {
  const strippedText = noteText.replace(/<\/?[^>]+(>|$)/g, "").trim();
  return strippedText.length > 0;
};
export const CaseCategoryMap: any = {
  assets: 2,
  liabilities_mortgages_actions: 6,
  liabilities_loan_hire_purchase_actions: 7,
};
export const calculateAge = (dob: string) => {
  if (!dob) return null;
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const isBirthdayPassed =
    today.getMonth() > birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() >= birthDate.getDate());

  if (!isBirthdayPassed) {
    age--;
  }

  return age;
};

export const convertLocalTimeToUtcTimestamp = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a date in the LOCAL timezone with today's date
  const localDate = new Date();
  localDate.setHours(hours, minutes, seconds, 0); // Uses local time

  // Convert the local time to UTC
  const utcTimestamp = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    )
  );

  return utcTimestamp.toISOString();
};

export const stripHtml = (html: string) => {
  return html.replace(/<[^>]*>/g, "").trim(); // Remove all HTML tags and trim whitespace
};
