import * as React from "react";
import { makeStyles, Button, Label } from "@fluentui/react-components";
import { ChevronLeftFilled, ChevronRightFilled } from "@fluentui/react-icons";
import ASCButton from "../../components/Button";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    minWidth: "min-content",
    alignItems: "end",
    justifyContent: "end",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
});

export const Paginate = ({
  handlePrevious,
  handleNext,
  page,
  nextPage,
}: any) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <ASCButton
        shape="rounded"
        size="small"
        onClick={handlePrevious}
        disabled={page === 1}
        appearance="transparent"
        icon={<ChevronLeftFilled />}
        label="Previous"
      />

      <ASCButton
        shape="rounded"
        size="small"
        onClick={handleNext}
        disabled={nextPage === false}
        appearance="transparent"
        icon={<ChevronRightFilled />}
        iconPosition="after"
        label="Next"
      />
    </div>
  );
};
