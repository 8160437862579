import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StringState {
  value: string;
}

const initialState: StringState = {
  value: "",
};

const stringSlice = createSlice({
  name: "updatestring",
  initialState,
  reducers: {
    setUpdateSearch: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    clearSearch: (state) => {
      state.value = "";
    },
  },
});

export const { setUpdateSearch, clearSearch } = stringSlice.actions;
export default stringSlice.reducer;
