import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  makeStyles,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import React from "react";
import ASCButton from "../../../components/Button";
const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  widthcontent: {
    width: "450px",
  },
});
function StopcommissionPopup(props: any) {
  const styles = useStyles();
  return (
    <div style={{ maxWidth: "200px" }}>
      <Dialog open={props.stopReceiptpopup}>
        <DialogSurface className={styles.widthcontent}>
          <DialogBody className={styles.content}>
            <div>
              <h4>Stop Commission Receipts</h4>
            </div>
            <p>Please enter a date when the last receipt to be received</p>
            <p>
              Last Due date Receipted : <span>16/10/2024</span>
            </p>
            <p>Next Due date received : 16/10/2024</p>
            <RadioGroup defaultValue="lastduedate">
              <Radio value="lastduedate" label="Stop all Due Date Received" />
              <div style={{ display: "flex" }}>
                <Radio
                  value="beforeduedate"
                  label="Stop all receipt after this date "
                />
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  name={"review_completed"}
                />
              </div>
            </RadioGroup>
            <DialogActions>
              <div className="button-end-popup">
                <Button
                  shape="rounded"
                  className="asc-button-primary"
                  appearance="primary"
                  type="submit"
                  size="small"
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <Button
                  shape="rounded"
                  appearance="secondary"
                  type="submit"
                  size="small"
                  onClick={() => props.setStopReceiptpopup(false)}
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

export default StopcommissionPopup;
