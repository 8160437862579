import React from "react";
import {
  OverlayDrawer,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerBody,
} from "@fluentui/react-components";

import { Dismiss24Regular } from "@fluentui/react-icons";
import ASCButton from "../../../components/Button";
import CaseDetails from "./CaseDetails";

interface CaseDetailDrawerProps {
  isOpen: boolean;
  caseDetail: any;
  handleDetailPopup: (open: boolean) => void;
}

const CaseDetailDrawer: React.FC<CaseDetailDrawerProps> = ({
  isOpen,
  caseDetail,
  handleDetailPopup,
}) => {
  return (
    <OverlayDrawer
      position="end"
      open={isOpen}
      onOpenChange={(_, { open }) => handleDetailPopup(open)}
      className="common-drawer"
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <ASCButton
              shape="rounded"
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => handleDetailPopup(false)}
            />
          }
        >
          Case Details
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <CaseDetails activeItem={caseDetail} />
      </DrawerBody>
    </OverlayDrawer>
  );
};

export default CaseDetailDrawer;
