import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import {
  generateString,
  getRefreshToken,
  getUserId,
  removeRefreshToken,
  removeToken,
  removeUserId,
  setRefreshToken,
  setToken,
} from "../utils";
import { jwtDecode } from "jwt-decode";

import apiServices from "../service";
type userType = {
  [x: string]: any;
};
const initialState = {
  userToken: localStorage.getItem("assystUserToken") || "",
  loading: false,
  userData: <any>{},
  userProfileInfo: <any>{},

  userId: localStorage.getItem("assystUserId") || "",
  refreshToken: localStorage.getItem("assystUserRefreshToken") || "",
};
export const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    signOut: (state) => {
      state.loading = false;
      state.userData = {};
      state.userToken = "";
      state.userId = "";
      state.userProfileInfo = {};
    },
    loginSuccess: (state, action) => {
      const { accessToken, user, refreshToken, userId } = action.payload;
      state.userToken = accessToken;
      state.userData = user;
      state.userId = userId;
      state.loading = false;
      state.refreshToken = refreshToken;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.userData = {};
      state.userToken = "";
      state.userId = "";
    },
    fetchUserDataSuccess: (state, action) => {
      const { accessToken, user } = action.payload;
      state.userToken = accessToken;
      state.userData = user;
      state.loading = false;
      state.userId = "";
    },
    fetchUserDataFailure: (state, action) => {
      state.loading = false;
      state.userData = {};
      state.userToken = "";
      state.userId = "";
    },
    fetchUserProfileData: (state, action) => {
      state.userProfileInfo = action.payload;
    },
  },
});

export const {
  signOut,
  loginSuccess,
  loginFailure,
  fetchUserDataSuccess,
  fetchUserDataFailure,
  fetchUserProfileData,
} = authSlice.actions;

export default authSlice.reducer;

// Asynchronous thunk actions
export const doUserLogin = (credentials: userType) => {
  return async (dispatch: Dispatch) => {
    let accessToken = "";
    apiServices.auth
      .login(credentials)
      .then((response) => {
        if (response.data.data) {
          accessToken = response.data.data[0].access_token;
          setToken(accessToken);
          const decodedToken: any = jwtDecode(accessToken);
          dispatch(
            loginSuccess({
              accessToken: accessToken,
              user: {
                ...decodedToken,
              },
            })
          );
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => {
        // alert(err.message);
        console.log("err", err);
      });
  };
};
export const getUserInfo = () => {
  return async (dispatch: Dispatch) => {
    let fakeToken = "";
    setToken(fakeToken);
    dispatch(
      loginSuccess({
        accessToken: fakeToken,
        user: {
          name: "",
          age: 45,
          occupation: "",
        },
      })
    );
  };
};
export const doLogout = () => {
  return async (dispatch: Dispatch) => {
    dispatch(signOut());
    removeToken();
    removeRefreshToken();
    removeUserId();
  };
};
export const isTokenValid = (token: any) => {
  let currentDate = new Date();
  const decodedToken: { exp: number } = jwtDecode(token);
  if (decodedToken) {
    if (decodedToken.exp * 1000 > currentDate.getTime()) {
      return true;
    } else {
      return false;
    }
  }
};
export const setCurrentUser = (token: any) => {
  return async (dispatch: Dispatch) => {
    setToken(token);
    const decodedToken: any = jwtDecode(token);
    dispatch(
      loginSuccess({
        accessToken: token,
        user: {
          ...decodedToken,
        },
        userId: getUserId(),
        refreshToken: getRefreshToken(),
      })
    );
  };
};
export const getUserProfileInfo = (userId: any) => {
  return async (dispatch: Dispatch) => {
    if (userId && userId != "") {
      apiServices.userProfile
        .getDetail(userId)
        .then((response: any) => {
          if (response.data.data) {
            dispatch(fetchUserProfileData(response.data.data));
          } else {
            dispatch(fetchUserProfileData({}));
          }
        })
        .catch((err) => {
          dispatch(fetchUserProfileData({}));
          console.log(err);
        });
    } else {
      dispatch(fetchUserProfileData({}));
    }
  };
};
