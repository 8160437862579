import React, { useEffect, useState } from "react";
import { IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import {
  Button,
  Label,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogActions,
  DialogContent,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  DialogTitle,
  PresenceBadge,
  Badge,
  Spinner,
  Toast,
  ToastTitle,
  ToastTrigger,
  Link,
  Dropdown,
  useToastController,
  useId,
  ToastBody,
  Toaster,
  MenuItem,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  Field,
  Divider,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  AttachRegular,
  DismissRegular,
  ChevronDownRegular,
} from "@fluentui/react-icons";
import ClientSelection from "./ClientSelection";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.css";
import "../../client/style.css";
import Dropzone from "react-dropzone";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
import { generateUUID } from "../../../utils";
import "./styles.css";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import ASCButton from "../../../components/Button";

interface Template {
  code: string;
  title: string;
  Body: string;
  Description: string;
}
type ReplacementMap = { [key: string]: string };

type selectedValue = {
  customer_id: string;
  first_names: string;
  last_name: string;
  email: string;
};
const modules = {
  toolbar: false,
};
const TemplateDropdown: React.FC = () => {
  // const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );
  const [template, setTemplate] = useState<Template[]>([]);

  // const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [clients, setClients] = useState<selectedValue[]>([]);
  const [messageBody, setMessageBody] = useState<string>("");
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [selectedClient, setSelectedClient] = useState<selectedValue[]>([]);
  const [previewList, setPreviewList] = useState<any>([]);
  const [mailCount, setMailCount] = useState(0);
  const [totalMails, setTotalMails] = useState(0);
  const [sendingMail, setSendingMail] = useState<boolean>(false);
  const [mailStatus, setMailStatus] = useState([]);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const { userProfileInfo } = useSelector((state: any) => state.authUser);
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: File[] }>(
    {}
  );
  const [messageContent, setMessageContent] = useState<any>("");
  const [getPreview, setGetPreview] = useState<any>([]);
  const [sampleTemplate, setSampleTemplate] = useState<any>([]);
  const [openSampleTemplate, setOpenSampleTemplate] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  // const [template, setTemplate] = useState<Template[]>([]);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
      width: "50%",
      borderRadius: "6px",
    },
    callout: {
      maxHeight: "200px",
    },
  };

  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );

  // const handleDropdownChangetemplate = (
  //   event: React.FormEvent<HTMLDivElement>,
  //   item?: any
  // ): void => {
  //   if (item) {
  //     setSelectedTemplate(item.text);
  //     setSelectedTemplateId(item.key);
  //     generatePreviewList(item.key, selectedClient);
  //   }
  // };

  const NOTIFICATION_ID = generateUUID();
  const handleContinue = (): void => {
    setShowPopup(false);
    setShowConfirmation(true);
  };

  const handleConfirmation = (confirmed: boolean): void => {
    setShowConfirmation(false);
    if (confirmed) {
      resetPage();
    }
  };

  const generatePreviewList = (templateId: string, selClients: any) => {
    if (templateId !== undefined && selClients && selClients.length > 0) {
      selClients.map((client: any) => getMessageBody(templateId, client));
    }
  };

  // const getTemplateList = () => {
  //   apiServices.notification
  //     .getList()
  //     .then((response: any) => {
  //       if (!response.data.isError) {
  //         if (response.data) {
  //           setTemplate(response.data);
  //         }
  //       }
  //     })
  //     .catch((e: Error) => {
  //       console.log(e);
  //     });
  // };

  const handleMenuItemClick = (text: string, code: string) => {
    setSelectedTemplate(text);
    setSelectedTemplateId(code);
  };

  const renderedMenuItems = template.map((item: Template) => (
    <MenuItem
      style={{ width: "400px" }}
      key={item.code}
      onClick={() => handleMenuItemClick(item.title, item.code)}
    >
      {item.title}
    </MenuItem>
  ));

  const getMessageBody = (templateId: string, customer: any) => {
    setLoading(true);
    apiServices.notification
      .createContent(templateId, customer.customer_id)
      .then((response: any) => {
        setLoading(false);
        if (response.data) {
          const { Body, Subject, Placeholders } = response.data;
          // const messageBody = generateMessage(response.data.templateContent);
          const messageBody = response.data.templateContent;
          setMessageContent(response.data.templateContent);
          const newPreviewItem = {
            body: messageBody,
            subject: Subject,
            placeholders: Placeholders,
            customer,
            edited: false,
          };
          setPreviewList((prevList: any) => [...prevList, newPreviewItem]);
        }
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log(e);
      });
  };

  // const generateMessage = (
  //   template: string,
  //   replacements: { [key: string]: string }
  // ) => {
  //   let result = template;

  //   for (const [placeholder, value] of Object.entries(replacements)) {
  //     const regex = new RegExp(`{{${placeholder}}}`, "g");
  //     result = result.replace(regex, value);
  //   }
  //   return result;
  // };

  // useEffect(() => {
  //   getTemplateList();
  // }, []);

  const handleCallPreview = () => {
    if (selectedTemplateId) {
      generatePreviewList(selectedTemplateId, selectedClient);
    }
  };
  const handleClientSelection = (selectedIds: any) => {
    if (selectedIds && selectedIds.length > 0) {
      setSelectedClient(selectedIds);
    }
  };

  const removeFile = (clientId: string, index: number) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      newFiles[clientId].splice(index, 1);
      return newFiles;
    });
  };

  const handleSendMail = () => {
    if (previewList.length > 0) {
      setShowConfirmation(false);
      setSendingMail(true);

      const editedItems = previewList.filter(
        (item: any) => item.edited === true
      );
      const nonEditedItems = previewList.filter(
        (item: any) => item.edited === false
      );

      const initialStatus = previewList.map((item: any) => ({
        customer: item.customer,
        status: "pending",
        item,
      }));
      setMailStatus(initialStatus);

      const updateStatus = (index: number, status: string) => {
        setMailStatus((prevList) => {
          const newList: any = [...prevList];
          newList[index] = { ...newList[index], status };
          return newList;
        });
      };

      const sendIndividualEmail = (item: any, index: number) => {
        const formData = new FormData();
        formData.append("customerId", item.customer.customer_id);
        formData.append("subject", selectedTemplate);
        formData.append("cc", userProfileInfo.user_email);
        formData.append("from", userProfileInfo.user_email);
        formData.append("body", item.body);
        formData.append("notificationId", NOTIFICATION_ID);
        const files = uploadedFiles[item.customer.customer_id] || [];
        files.forEach((file: File) => {
          formData.append("files", file);
        });
        return apiServices.notification
          .sendEmail(formData)
          .then((res) => {
            notify(res.data.message, "", "success");
            updateStatus(index, "completed");
            setMailCount((prevCount) => prevCount + 1);
          })
          .catch((err) => {
            console.log(err);
            updateStatus(index, "failed");
            setMailCount((prevCount) => prevCount + 1);
          });
      };

      const sendBulkEmail = async (items: any[]) => {
        const customerIds = items.map((item) => item.customer.customer_id);
        const bulkEmailItems = previewList.filter((item: any) =>
          customerIds.includes(item.customer.customer_id)
        );

        let payload = {
          from: userProfileInfo.user_email,
          subject: selectedTemplate,
          body: selectedTemplateId,
          to: customerIds,
          cc: [userProfileInfo.user_email],
          notificationId: NOTIFICATION_ID,
        };

        const initialStatus = bulkEmailItems.map((item: any) => ({
          customer: item.customer,
          status: "pending",
          item,
        }));

        setMailStatus(initialStatus);
        setLoading(true);

        try {
          const res = await apiServices.notification.sendBulkEmail(payload);
          notify(res.data.message, "", "success");
          setMailStatus((prevStatus: any) =>
            prevStatus.map((status: any) => {
              if (customerIds.includes(status.customer.customer_id)) {
                return { ...status, status: "completed" };
              }
              return status;
            })
          );
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
          setMailStatus((prevStatus: any) =>
            prevStatus.map((status: any) =>
              customerIds.includes(status.customer.customer_id)
                ? { ...status, status: "failed" }
                : status
            )
          );
        }
      };

      if (editedItems.length > 0) {
        const individualPromises = editedItems.map((item: any, index: number) =>
          sendIndividualEmail(item, index)
        );
        Promise.all(individualPromises)
          .then(() => {
            if (nonEditedItems.length > 0) {
              return sendBulkEmail(nonEditedItems);
            }
          })
          .then(() => {
            if (nonEditedItems.length > 0) {
            }
          })
          .catch((error) => {
            console.error("Error sending emails:", error);
          });
        setTotalMails(previewList.length);
        setMailCount(0);
        setShowConfirmation(false);
        setSendingMail(true);
        setLoading(false);
      } else if (nonEditedItems.length > 0) {
        sendBulkEmail(nonEditedItems);
        setTotalMails(previewList.length);
        setMailCount(0);
        setShowConfirmation(false);
        setSendingMail(true);
        setLoading(false);
      } else {
        setShowConfirmation(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      mailStatus.length > 0 &&
      mailStatus.every((item: any) => item.status === "completed")
    ) {
      const timer = setTimeout(() => {
        setSendingMail(false);
      }, 1000);
      resetPage();
      return () => clearTimeout(timer);
    }
  }, [mailStatus]);

  const resetPage = () => {
    setSelectedTemplate("");
    setSelectedTemplateId("");
    setShowPopup(false);
    setShowConfirmation(false);
    setSelectedOptions([]);
    setSelectedClient([]);
    setMessageBody("");
    setPreviewList([]);
    setUploadedFiles({});
  };
  const columnsData: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "title", // Key to identify column data
      header: "Title",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "signedUrl",
      header: "Link",
      Cell: ({ row }: any) => (
        <>
          <a href={row.original.signedUrl} style={{ textDecoration: "none" }}>
            {row.original.title} - {row.original.templateFilePath}
          </a>{" "}
        </>
      ),
    },
  ];
  const handleRowSelection = (row: any) => {
    setSelectedRow(row.original);
  };

  const MAX_DISPLAY_COUNT = 3;

  ////////////////////
  const getClientPlaceholder = () => {
    if (selectedClient.length === 0) {
      return "Select a Client / Multiple Clients";
    } else if (selectedClient.length === 1) {
      return `${selectedClient[0].first_names} ${selectedClient[0].last_name}`;
    } else if (selectedClient.length === 2) {
      return `${selectedClient[0].first_names} ${selectedClient[0].last_name}, ${selectedClient[1].first_names} ${selectedClient[1].last_name}`;
    } else {
      return `${selectedClient[0].first_names}, ${
        selectedClient[1].first_names
      } + ${selectedClient.length - 2} others`;
    }
  };
  const getEmailList = () => {
    apiServices.TemplateEngine.getEmailTemplate().then((res) => {
      const filteredTemplates = res.data.filter(
        (item: { status: string }) => item.status === "Completed"
      );
      setTemplate(filteredTemplates);
    });
  };

  useEffect(() => {
    getEmailList();
  }, []);

  return (
    <>
      <div className="main-container">
        <div>
          <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
          <OverlayLoader isLoading={loading} />

          <div className="send_mail_section">
            <Field label="Select Template :">
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Button
                    shape="rounded"
                    style={{
                      fontWeight: "normal",
                      width: "350px",
                      color: "#606060",
                      border: "1px solid grey",
                      justifyContent: "flex-start",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                    // disabled={selectedClient.length === 0} // Disable if no clients are selected
                  >
                    <span style={{ flexGrow: 1, textAlign: "left" }}>
                      {selectedTemplate || "Select Template"}
                    </span>
                    <ChevronDownRegular />
                  </Button>
                </MenuTrigger>
                <MenuPopover
                  style={{
                    minWidth: "350px",
                    minHeight: "fit-content",
                    maxHeight: "400px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <MenuList style={{ minWidth: "350px" }}>
                    {renderedMenuItems}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </Field>

            <Field label="Select Client (s) :">
              <Dropdown
                disabled={selectedTemplate.length === 0}
                style={{ width: "350px" }}
                placeholder={getClientPlaceholder()}
                onClick={() => {
                  setShowClientSelection(true);
                }}
              ></Dropdown>
            </Field>
          </div>

          {selectedTemplate.length > 0 && (
            <>
              <div className="send_mail_client">
                <Label className="label">From :</Label>
                <Label>{userProfileInfo.user_email || ""}</Label>
                <Label className="label">Cc : </Label>
                <Label>{userProfileInfo.user_email || ""}</Label>
                <Label className="label">Subject</Label>
                <Label>{selectedTemplate}</Label>
              </div>
            </>
          )}
          {selectedTemplate.length > 0 && <Divider />}

          <div
            style={{
              overflow: "scroll",
              minHeight: "260px",
              maxHeight: "260px",
            }}
          >
            {previewList.length > 0 &&
              selectedTemplate.length > 0 &&
              previewList.map((item: any, index: number) => (
                <Accordion
                  key={index}
                  collapsible
                  defaultOpenItems={index === 0 ? [index] : []}
                >
                  <AccordionItem value={index}>
                    <AccordionHeader>
                      {item.customer.first_names}
                    </AccordionHeader>
                    {item.body && (
                      <AccordionPanel className="custom_accordion">
                        {enableEdit &&
                        selectedItemId === item.customer.customer_id ? (
                          <>
                            <Dropzone
                              onDrop={(acceptedFiles: File[]) => {
                                if (acceptedFiles.length > 0) {
                                  setUploadedFiles((prevFiles) => {
                                    const newFiles = { ...prevFiles };
                                    if (!newFiles[item.customer.customer_id]) {
                                      newFiles[item.customer.customer_id] = [];
                                    }
                                    newFiles[item.customer.customer_id].push(
                                      acceptedFiles[0]
                                    );
                                    return newFiles;
                                  });
                                }
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps({ className: "flex-end" })}
                                >
                                  <input {...getInputProps()} />
                                  <AttachRegular
                                    fontSize="20px"
                                    onClick={() =>
                                      setSelectedItemId(
                                        item.customer.customer_id
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </Dropzone>
                            <ReactQuill
                              value={item.body}
                              onChange={(value) => {
                                const updatedList = [...previewList];
                                updatedList[index].body = value;
                                setPreviewList(updatedList);
                              }}
                              modules={modules}
                              style={{ border: "none", padding: 0 }}
                            />
                            <div className="file_upload_list">
                              {uploadedFiles[item.customer.customer_id] &&
                                uploadedFiles[item.customer.customer_id].map(
                                  (file: any, index: number) => (
                                    <div
                                      className="file_upload_detail"
                                      key={`${item.customer.customer_id}-${index}`}
                                    >
                                      <div>
                                        <p>{file.name}</p>
                                        <span style={{ fontSize: 12 }}>
                                          {(file.size / 1024).toFixed(2)} KB
                                        </span>
                                      </div>

                                      <DismissRegular
                                        onClick={() =>
                                          removeFile(
                                            item.customer.customer_id,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  )
                                )}
                            </div>
                            <div className="action_buttons">
                              <ASCButton
                                shape="rounded"
                                className="asc-button-primary"
                                appearance="primary"
                                onClick={() => {
                                  setEnableEdit(false);
                                  setSelectedItemId("");
                                }}
                                size="small"
                                label="Cancel"
                              />
                              <ASCButton
                                shape="rounded"
                                appearance="secondary"
                                onClick={() => {
                                  setEnableEdit(false);
                                  setSelectedItemId("");
                                  setPreviewList((prevList: any) =>
                                    prevList.map((item: any, i: number) =>
                                      i === index
                                        ? { ...item, edited: true }
                                        : item
                                    )
                                  );
                                }}
                                size="small"
                                label="Save"
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex-end">
                              <ASCButton
                                shape="rounded"
                                icon={<Edit24Filled />}
                                size="small"
                                style={{ border: "none" }}
                                onClick={() => {
                                  setEnableEdit(true);
                                  setSelectedItemId(item.customer.customer_id);
                                }}
                              />
                            </div>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.body }}
                            ></div>
                            <div className="file_upload_list">
                              {uploadedFiles[item.customer.customer_id] &&
                                uploadedFiles[item.customer.customer_id].map(
                                  (file: any, index: number) => (
                                    <div
                                      className="file_upload_detail"
                                      key={`${item.customer.customer_id}-${index}`}
                                    >
                                      <div>
                                        <p>{file.name}</p>
                                        <span style={{ fontSize: 12 }}>
                                          {(file.size / 1024).toFixed(2)} KB
                                        </span>
                                      </div>
                                      {/* <DismissRegular
                                    onClick={() =>
                                      removeFile(
                                        props.selectClient.customer_id,
                                        index
                                      )
                                    }
                                  /> */}
                                    </div>
                                  )
                                )}
                            </div>
                          </>
                        )}
                      </AccordionPanel>
                    )}
                  </AccordionItem>
                </Accordion>
              ))}
          </div>
          {selectedTemplate.length > 0 && selectedClient.length > 0 && (
            <div className="message_buttons">
              <ASCButton
                shape="rounded"
                appearance="secondary"
                onClick={resetPage}
                size="small"
                label="Cancel"
              />
              <ASCButton
                shape="rounded"
                className="asc-button-primary"
                appearance="primary"
                onClick={() => {
                  handleContinue();
                }}
                size="small"
                label={selectedClient.length === 1 ? "Send" : "Send All"}
              />
            </div>
          )}
          <Dialog
            open={showClientSelection}
            onOpenChange={(event, data) => setShowClientSelection(data.open)}
          >
            <DialogSurface>
              <DialogBody>
                <span className="mui-table">Client Selection</span>
                <DialogContent>
                  <ClientSelection
                    handleClientSelection={handleClientSelection}
                    setClientList={setClients}
                  />
                </DialogContent>
                <DialogActions>
                  <ASCButton
                    shape="rounded"
                    appearance="secondary"
                    onClick={() => setShowClientSelection(false)}
                    size="small"
                    label="Close"
                  />
                  <ASCButton
                    shape="rounded"
                    className="asc-button-primary"
                    appearance="primary"
                    onClick={() => {
                      setShowClientSelection(false);
                      handleCallPreview();
                    }}
                    size="small"
                    label="Select"
                  />
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
          {showConfirmation && (
            <Dialog
              open={showConfirmation}
              onOpenChange={(event, data) => setShowConfirmation(data.open)}
            >
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>Confirmation for sending mail</DialogTitle>
                  <DialogActions>
                    <ASCButton
                      shape="rounded"
                      appearance="secondary"
                      onClick={() => handleConfirmation(false)}
                      size="small"
                      label="Cancel"
                    />
                    <ASCButton
                      shape="rounded"
                      className="asc-button-primary"
                      appearance="primary"
                      onClick={() => handleSendMail()}
                      size="small"
                      label="Send Email"
                    />
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          )}
          {sendingMail && (
            <Dialog
              open={sendingMail}
              onOpenChange={(event, data) => setSendingMail(data.open)}
            >
              <DialogSurface>
                <DialogBody>
                  <DialogTitle>Sending Mail</DialogTitle>
                  <DialogContent>
                    {mailStatus.map((item: any, index) => (
                      <div key={index} className="mail_status">
                        {item.status === "completed" ? (
                          <PresenceBadge status="available" />
                        ) : item.status === "failed" ? (
                          <PresenceBadge status="do-not-disturb" />
                        ) : (
                          <Spinner size="tiny" />
                        )}
                        <span>
                          {item.customer.last_name}, {item.customer.first_names}
                        </span>
                      </div>
                    ))}
                  </DialogContent>

                  <DialogActions>
                    <ASCButton
                      shape="rounded"
                      appearance="secondary"
                      disabled={mailCount == totalMails ? false : true}
                      onClick={() => {
                        setSendingMail(false);
                        resetPage();
                      }}
                      size="small"
                      label="Close"
                    />
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          )}
        </div>
      </div>
    </>
  );
};

export default TemplateDropdown;
