import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { useSelector, useDispatch } from "react-redux";
import {
  getToken,
  isObjectEmpty,
  setRefreshToken,
  setToken,
  setUserId,
} from "../utils";
import { AppDispatch } from "../redux/store";
import { jwtDecode } from "jwt-decode";
import {
  getUserInfo,
  isTokenValid,
  setCurrentUser,
  doUserLogin,
  loginSuccess,
  doLogout,
  getUserProfileInfo,
} from "../redux/authSlice";
import apiServices from "../service";
import SplashScreen from "../components/SplashScreen";
import DialogModal from "../components/DialogModal/DialogModal";
import { loginSessionExpired, accessDenied } from "../redux/appSettingsSlice";
import { fetchSettings } from "../redux/formSlice";

type Status = "checking" | "authenticated" | "no-authenticated";
let status: Status = "no-authenticated";
export const AppRouter = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const { userToken, userData, userId, userProfileInfo } = useSelector(
    (state: any) => state.authUser
  );
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  const { loginSessionExpiry, userPermissionDenied } = useSelector(
    (state: any) => state.appSettings
  );
  const accessToken = getToken();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const authCode = searchParams.get("code");
  const issuer = searchParams.get("iss");
  const isError = searchParams.get("isError");
  const errorMessage = searchParams.get("message");
  const isOrganizationRequired = searchParams.get("organization_required");
  const userEmail = searchParams.get("login_hint");
  useEffect(() => {
    if (userToken && userToken !== "") {
      dispatch(setCurrentUser(userToken)).then(() => {
        setLoading(false);
        setIsAuthenticated(true);
      });
      if (
        userId &&
        userId != "" &&
        userProfileInfo &&
        isObjectEmpty(userProfileInfo)
      ) {
        dispatch(getUserProfileInfo(userId));
      }
    } else if (isError && errorMessage) {
      if (isError && isOrganizationRequired) {
        setLoginError(true);
        setLoginErrorMessage(errorMessage);
        navigate("/login", {
          state: { isOrganizationRequired: true, userEmail: userEmail },
        });
      } else {
        if (isError) {
          setLoginError(true);
          setLoginErrorMessage(errorMessage);
        }
      }
    } else if (authCode && issuer) {
      getUserData({ code: authCode, issuer: issuer });
    } else {
      setLoading(false);
      setIsAuthenticated(false);
    }
  }, [userToken]);

  const getUserData = (data: any) => {
    apiServices.auth
      .getUserInfo({
        code: data.code,
        issuer: data.issuer,
      })
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.token &&
          response.data.data.token.access_token !== undefined
        ) {
          let accessToken = response.data.data.token.access_token;
          setToken(accessToken);
          setUserId(response.data.data?.userId);
          if (
            response.data &&
            response.data.data &&
            response.data.data.token &&
            response.data.data.token.refresh_token !== undefined
          ) {
            setRefreshToken(response.data.data.token.refresh_token);
          }
          const decodedToken: any = jwtDecode(accessToken);
          dispatch(
            loginSuccess({
              accessToken: accessToken,

              user: {
                ...decodedToken,
              },
              userId: response.data.data?.userId,
              refreshToken: response.data.data.token.refresh_token,
            })
          );
          setLoading(false);
          setIsAuthenticated(true);
          if (response.data.data?.userId && response.data.data?.userId != "") {
            getUserProfileInfo(response.data.data?.userId);
          }

          navigate(
            {
              search: "",
            },
            { replace: true }
          );
        } else {
          // setLoading(false);
          // setIsAuthenticated(false);
          if (
            response.data.error &&
            Object.keys(response.data.error).length !== 0
          ) {
            setLoginError(true);

            setLoginErrorMessage(response.data.error.message);
          }
        }
      })
      .catch((err) => {
        // setLoading(false);
        // setIsAuthenticated(false);
        setLoginError(true);

        setLoginErrorMessage("Something went wrong");
        console.log(err);
      });
  };

  if (loading)
    return (
      <SplashScreen
        loginError={loginError}
        loginErrorMessage={loginErrorMessage}
        setLoading={setLoading}
        setIsAuthenticated={setIsAuthenticated}
      />
    );
  return (
    <>
      <Routes>
        {!loading && isAuthenticated ? (
          <Route path="/*" element={<PrivateRoutes />} />
        ) : (
          <Route path="/*" element={<PublicRoutes />} />
        )}

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>

      <DialogModal
        open={loginSessionExpiry}
        defaultOpen={false}
        style={{ width: "max-content" }}
        title="Session Timeout"
        description="Your login session expired. Please login again."
        onClick={() => {
          dispatch(loginSessionExpired(false));
          dispatch(doLogout());
          // window.location.reload();
        }}
      />
      <DialogModal
        open={userPermissionDenied}
        defaultOpen={false}
        style={{ width: "max-content" }}
        title="Access Denied"
        description="You don't have permission to access this page. Please contact Administrator."
        onClick={() => {
          dispatch(accessDenied(false));
          // dispatch(doLogout());
          // window.location.reload();
        }}
      />
    </>
  );
};
