import React, { useEffect, useState } from "react";
import "../style.css";
import {
  useId,
  makeStyles,
  Button,
  shorthands,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
} from "@fluentui/react-icons";
import { fetchSettings } from "../../../redux/formSlice";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import FormContainerWithList from "../../../components/FormContainerWithList";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
import { removeEmptyValues } from "../../../utils";
import {
  IdentityKeyMapping,
  transformKeys,
} from "../../../utils/DataKeyMapping";
import { identity } from "lodash";
import ContentLoader from "../../../components/Loader/ContentLoader";
import { useNavigate, useParams } from "react-router-dom";
// import FormContainer from "../../../components/Form/FormContainer";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "0px"),
    rowGap: "20px",
  },
});

type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const IdentityForm = (props: ComponentProps) => {
  const styles = useStyles();
  const [formContext, setFormContext] = useState("CLIENT_IDENTITY");
 const navigate = useNavigate();
 const { clientId, action, selectedItemId } = useParams();
  const [initialData, setInitialData] = useState({});
      const [dataList, setDataList] = useState<{ [key: string]: any }[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj.data &&
      formSettingsObj.data.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    getDataList();
  }, [props.activeCustomerId]);
 useEffect(() => {
      if (props.section != undefined) {
        if (!action) {
          if (dataList.length > 0) {
            if (props.module != undefined && props.module == "partner") {
              navigate(
                `/clients/${clientId}/partner/identity/${dataList[0].identity_id}/view`,
                {
                  state: { selectedItem: dataList[0], section: "identity" },
                }
              );
            } else {
              navigate(
                `/clients/${props.activeCustomerId}/identity/${dataList[0].identity_id}/view`,
                {
                  state: { selectedItem: dataList[0], section: "identity" },
                }
              );
            }
          }
        }
      }
      if (action != undefined) {
        if (action.length > 5) {
          if (props.module != undefined && props.module == "partner") {
            navigate(`/clients/${clientId}/partner/identity/${action}/view`);
          } else {
            navigate(
              `/clients/${props.activeCustomerId}/identity/${action}/view`
            );
          }
        } 
      }
     
    }, [props.section, action, selectedItemId, dataList]);
  const loadListData = () => {
    getDataList();
  };
  const getDataList = () => {
    setLoading(true);
    apiServices.client
      .getDetailByTab(props.activeCustomerId, "identity")

      .then((response: any) => {
        setLoading(false);
        let singleData = {};
        if (!response.data.isError && response.data.data.list) {
          const transformedData = transformKeys(
            response.data.data.list,
            IdentityKeyMapping
          );
setDataList(transformedData)
          singleData = {
            ...singleData,
            identity: transformedData,
          };
        }
        setInitialData(removeEmptyValues(singleData));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return loading ? (
    <ContentLoader />
  ) : (
    <div className="form-container-wrapper">
      <FormContainerWithList
        context={formContext}
        config={configForm}
        activeCustomerId={props.activeCustomerId}
        notify={props.notify}
        initialData={initialData}
        reloadData={loadListData}
        section="identity"
        module={props.module}
      />
    </div>
  );
};

export default IdentityForm;
