import { createSlice } from '@reduxjs/toolkit';

export const valueSlice = createSlice({
  name: 'value',
  initialState: {
    storedValue: '', 
  },
  reducers: {
    setValue: (state, action) => {
      state.storedValue = action.payload; 
    },
  },
});

export const { setValue } = valueSlice.actions;

export default valueSlice.reducer;
