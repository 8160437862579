import * as React from "react";
import {
  Tree,
  TreeItem,
  TreeItemLayout,
  Spinner,
} from "@fluentui/react-components";
import apiServices from "../../service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setHeaderValue } from "../../redux/headerValue";
import { setTableData } from "../../redux/clientSlice";
import { updateTableDataList } from "../../redux/tableDataSlice";
import { setLoadingTableData } from "../../redux/loader";

export const DynamicTree = () => {
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);
  const [treeValue, setTreeValue] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const clientValue = useSelector(
    (state: RootState) => state.clientid.storedValue
  );
  const tableDataList = useSelector(
    (state: RootState) => state.tableDatas.tableDataList
  );

  const TreeData = () => {
    setLoading(true);
    const id = clientValue;

    apiServices.ClientPartnerSplit.getList(id)
      .then((res) => {
        const filteredData =
          res?.data?.filter(
            (item: any) =>
              item.TableName !== "Customer" &&
              item.TableName !== "Business" &&
              item.TableName !== "Valuation" &&
              item.TableName !== "Withdrawal" &&
              item.TableName !== "Appointment" &&
              item.TableName !== "Fund" &&
              // item.TableName !== "Note" &&
              item.TableName !== "CommissionAllocation" &&
              item.TableName !== "Commission" &&
              item.TableName !== "Payment"
          ) || [];
        setTreeValue(filteredData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    TreeData();
  }, []);

  const handleClick = (tableName: string, count: number) => {
    setSelectedItem(tableName);
    dispatch(setHeaderValue(tableName));
    handleFetchData(tableName);
  };
  React.useEffect(() => {
    if (treeValue.length > 0) {
      const firstItem = treeValue[0];
      setSelectedItem(firstItem.TableName);
      dispatch(setHeaderValue(firstItem.TableName));
      handleFetchData(firstItem.TableName);
    }
  }, [treeValue]);

  const tableDataListing = async (tableName: string) => {
    dispatch(setLoadingTableData(true));
    try {
      const id = clientValue;
      let response;
      switch (tableName) {
        case "Asset":
          response = await apiServices.assets.getAssets(id);
          return response.data.data
            .filter(
              (item: any) =>
                item.investment_status !== "Completed" &&
                item.investment_status !== "completed" &&
                item.investment_status !== "Success" &&
                item.investment_status !== "success" &&
                item.bank_status !== "Completed" &&
                item.bank_status !== "completed" &&
                item.share_status !== "Completed" &&
                item.share_status !== "completed"
            )

            .map((item: any) => ({
              case_type: item.case_type,
              bank_sort_code: item.bank_sort_code,
              joint_indicator: item.joint_indicator,
              owner: item.owner,
              owner_name1: item.owner_name1,
              owner_name2: item.owner_name2,
              current_value: item.current_value,
              value_date: item.value_date,
              purpose: item.purpose,
              note_text: item.note_text,
              report_note: item.report_note,
              investment_subtype: item.investment_subtype,
              share_subtype: item.share_subtype,
              investment_account_number: item.investment_account_number,
              bank_account_number: item.bank_account_number,
              investment_start_date: item.investment_start_date,
              investment_end_date: item.investment_end_date,
              investment_status: item.investment_status,
              bank_status: item.bank_status,
              share_status: item.share_status,
              status_date: item.status_date,
              investment_provider: item.investment_provider,
              provider_contact_name: item.provider_contact_name,
              provider_phone: item.provider_phone,
              provider_note: item.provider_note,
              share_company: item.share_company,
              company_contact_name: item.company_contact_name,
              company_phone: item.company_phone,
              company_note: item.company_note,
              share_current_number: item.share_current_number,
              share_current_price: item.share_current_price,
              acquired_date: item.acquired_date,
              share_original_value: item.share_original_value,
              home_description: item.home_description,
              bank_name: item.bank_name,
              bank_account_name: item.bank_account_name,
              balance_value: item.balance_value,
              balance_date: item.balance_date,
              bank_interest_rate: item.bank_interest_rate,
              recordId: item.asset_id,
            }));

        case "Liability":
          response = await apiServices.liability.getLiabilities(id);
          return response.data.data
            .filter(
              (item: any) =>
                item.status !== "Completed" &&
                item.status !== "Success" &&
                item.status !== "completed"
            )
            .map((item: any) => ({
              recordId: item.liability_id,
              case_type: item.case_type,
              joint_indicator: item.joint_indicator,
              borrower: item.borrower,
              borrower_name1: item.borrower_name1,
              borrower_name2: item.borrower_name2,
              liability_type: item.liability_type,
              subtype: item.subtype,
              repayment_method: item.repayment_method,
              purpose: item.purpose,
              start_date: item.start_date,
              end_date: item.end_date,
              term: item.term,
              amount_borrowed: item.amount_borrowed,
              amount_outstanding: item.amount_outstanding,
              interest_rate: item.interest_rate,
              interest_basis: item.interest_basis,
              status: item.status,
              status_date: item.status_date,
              note_text: item.note_text,
              report_note: item.report_note,
              account_number: item.account_number,
              mortgage_fixed_rate_end: item.mortgage_fixed_rate_end,
              property_value: item.property_value,
              property_address1: item.property_address1,
              loan_valuation_percentage: item.loan_valuation_percentage,
              provider: item.provider,
              provider_contact_name: item.provider_contact_name,
              provider_phone: item.provider_phone,
              provider_address1: item.provider_address1,
              property_town: item.property_town,
              property_county: item.property_county,
              property_country: item.property_country,
              provider_town: item.provider_town,
              provider_county: item.provider_county,
              provider_country: item.provider_country,
              property_postcode: item.property_postcode,
              provider_postcode: item.provider_postcode,
              provider_note: item.provider_note,
              covered: item.covered,
            }));

        case "Policy":
          response = await apiServices.policy.getPolicies(id);
          return response.data.data
            .filter(
              (item: any) =>
                item.status !== "Completed" &&
                item.status !== "completed" &&
                item.status !== "Success" &&
                item.status !== "success" &&
                item.service_status !== "Completed" &&
                item.service_status !== "completed"
            )
            .map((item: any) => ({
              recordId: item.policy_id,
              case_type: item.case_type,
              policy_holder: item.policy_holder,
              assured_detail: item.assured_detail,
              assured_name1: item.assured_name1,
              assured_name2: item.assured_name2,
              subtype: item.subtype,
              provider: item.provider,
              provider_contact_name: item.provider_contact_name,
              provider_phone: item.provider_phone,
              provider_note: item.provider_note,
              purpose: item.purpose,
              start_date: item.start_date,
              end_date: item.end_date,
              term: item.term,
              scheme_name: item.scheme_name,
              policy_number: item.policy_number,
              other_reference: item.other_reference,
              status: item.status,
              status_date: item.status_date,
              service_status: item.service_status,
              premium_indexed: item.premium_indexed,
              periodic_benefit: item.periodic_benefit,
              benefit_frequency: item.benefit_frequency,
              benefits_indexed: item.benefits_indexed,
              benefit_text: item.benefit_text,
              admin_note: item.admin_note,
              report_note: item.report_note,
              link_to_mortgage: item.link_to_mortgage,
              rated: item.rated,
              waiver: item.waiver,
              surrender_value: item.surrender_value,
              future_value: item.future_value,
              age_admitted: item.age_admitted,
              in_trust: item.in_trust,
              in_trust_note: item.in_trust_note,
              valuation_date: item.valuation_date,
              assigned_to: item.assigned_to,
              contracted_out: item.contracted_out,
              transfer_value: item.transfer_value,
              total_fund_value: item.total_fund_value,
              expression_of_wish: item.expression_of_wish,
              lump_sum_benefit: item.lump_sum_benefit,
              sum_assured: item.sum_assured,
              expression_of_wishnote: item.expression_of_wishnote,
            }));

        case "Income":
          response = await apiServices.income.getList(id);
          return response.data.data.result.map((item: any) => ({
            recordId: item.income_id,
            income_type: item.income_type,
            owner: item.owner,
            frequency: item.frequency,
            gross_amount: item.gross_amount,
            net_amount: item.net_amount,
            start_date: item.start_date,
            end_date: item.end_date,
          }));

        case "Expense":
          response = await apiServices.outgoings.getList(id);
          return response.data.data.result.map((item: any) => ({
            recordId: item.expense_id,
            client_amount: item.client_amount,
            partner_amount: item.partner_amount,
            joint_amount: item.joint_amount,
            frequency: item.frequency,
          }));

        case "Dependant":
          response = await apiServices.dependant.getList(id);
          return response.data.data.map((item: any) => ({
            recordId: item.dependant_id,
            first_names: item.first_names,
            last_name: item.last_name,
            date_of_birth: item.date_of_birth,
            relation: item.relation,
            parent: item.parent,
          }));

        case "Address":
          response = await apiServices.addressBook.getList(id);
          return response.data.data.map((item: any) => ({
            recordId: item.address_id,
            organisation: item.organisation,
            contact_name: item.contact_name,
            address_1: item.address_1,
            postcode: item.postcode,
            effective_date: item.effective_date,
          }));

        case "Note":
          response = await apiServices.factFindNote.getList(id);
          return response.data.data((item: any) => ({
            recordId: item.note_id,
            note_text: item.note_text,
            note_time: item.note_time,
            note_type: item.note_type,
          }));

        default:
          console.error("Invalid Table Name:", tableName);
          return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    } finally {
      dispatch(setLoadingTableData(false));
    }
  };
  const handleFetchData = async (tableName: string) => {
    dispatch(setLoadingTableData(true));
    try {
      const result = await tableDataListing(tableName);
      if (result.length > 0) {
        dispatch(updateTableDataList({ tableName, data: result }));
        dispatch(setTableData(result));
      } else {
        console.warn("No data to display for:", tableName);
      }
    } catch (error) {
      console.error("Error in handleFetchData:", error);
    } finally {
      dispatch(setLoadingTableData(false));
    }
  };

  return (
    <div>
      {loading ? (
        <div className="spinner-container-tree">
          <Spinner size="medium" />
        </div>
      ) : (
        <div>
          <Tree aria-label="Dynamic Tree">
            {treeValue?.map((item: any, index: number) => (
              <TreeItem key={index} itemType="leaf">
                <TreeItemLayout
                  onClick={() => handleClick(item.TableName, item.Count)}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      selectedItem === item.TableName
                        ? "#e0f7fa"
                        : "transparent",
                  }}
                >
                  {item.TableName} ({item.Count})
                </TreeItemLayout>
              </TreeItem>
            ))}
          </Tree>
        </div>
      )}
    </div>
  );
};
