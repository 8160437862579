import { createSlice } from "@reduxjs/toolkit";

// Create the distributionRuleSlice
export const distributionRuleSlice = createSlice({
  name: "ruleid",
  initialState: {
    storedValue: [],
    selectedRule: {},
  },
  reducers: {
    setRuleCategory: (state, action) => {
      state.storedValue = action.payload;
    },
    setSelectedRule: (state, action) => {
      state.selectedRule = action.payload;
    },
  },
});

// Export actions
export const { setRuleCategory, setSelectedRule } = distributionRuleSlice.actions;

// Export reducer
export default distributionRuleSlice.reducer;
