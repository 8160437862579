import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../service";
import {
  CustomerDetailsKeyMapping,
  transformKeys,
} from "../utils/DataKeyMapping";

interface ActiveClientState {
  customer_id: string;
  basicDetails: Record<string, any>;
  partnerDetails: Record<string, any>;
  loading: boolean;
}

const initialState: ActiveClientState = {
  customer_id: "",
  basicDetails: {},
  partnerDetails: {},
  loading: false,
};

export const getCustomerInfoById = createAsyncThunk(
  "activeClient/getCustomerInfoById",
  async (id: string, { rejectWithValue }) => {
    if (!id) return rejectWithValue("Invalid customer ID");
    try {
      const response: any = await apiServices.client.getDetailByTab(
        id,
        "name_and_address"
      );
      const { isError, data } = response.data;
      if (isError || !data || Object.keys(data).length === 0) {
        return rejectWithValue("Failed to fetch customer details");
      }
      const transformedData = transformKeys(data, CustomerDetailsKeyMapping);
      const result = {
        customer_id: id,
        ...transformedData[0],
      };
      return result;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const activeClientSlice = createSlice({
  name: "activeClient",
  initialState,
  reducers: {
    resetActiveClientState: (state) => {
      return { ...initialState };
    },
    updateAssociatedWith: (state, action) => {
      if (state.basicDetails) {
        state.basicDetails.associated_with = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerInfoById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerInfoById.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_id = action.payload.customer_id || "";
        state.basicDetails = action.payload;
      })
      .addCase(getCustomerInfoById.rejected, (state, action) => {
        state.loading = false;
        console.error("Error fetching customer details:", action.payload);
      });
  },
});
export const { resetActiveClientState, updateAssociatedWith } =
  activeClientSlice.actions;
export default activeClientSlice.reducer;
