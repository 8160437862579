import * as Yup from "yup";
import { createYupSchema } from "./yupSchemaCreator";
import { checkIfKeyExist } from "./index";
export const formBuilder = (options: any) => {
  let initialValues: { [key: string]: any } = {};
  let validationObj: { [key: string]: any }[] = [];
  let inputValues: { [key: string]: any }[] = [];
  let validationRules: { [key: string]: any } = {};
  let formRefId: string = "";
  if (
    options?.formSettings?.formConfig &&
    options?.formSettings?.formCollections
  ) {
    if (checkIfKeyExist(options.formSettings.formConfig, options.context)) {
      let configObj: any = checkIfKeyExist(
        options.formSettings.formConfig,
        options.context
      );
      if (
        configObj &&
        configObj.renderForm &&
        configObj.renderForm?.reference_id
      ) {
        formRefId = configObj.renderForm?.reference_id;
        let formCol = (
          options.formSettings.formCollections as { [key: string]: any }
        )[configObj.renderForm?.reference_id];
        validationObj = (
          options.formSettings.formValidations as { [key: string]: any }
        )[configObj.renderForm?.reference_id];

        if (formCol && configObj.renderForm.general_fields.length > 0) {
          formCol.fields.general
            .filter(
              (opt: any) =>
                (configObj?.renderForm.general_fields as string[]).includes(
                  opt.field
                ) && opt.enabled === true
            )
            .sort((a: any, b: any) => a.order - b.order)
            .map((row: any) => {
              initialValues[row.field] = row.value;
              if (row.prefix !== undefined) {
                initialValues[row.prefix.field] =
                  row.prefix.value !== undefined ? row.prefix.value : "";
              }
              inputValues.push(row);
            });
        } else {
          if (formCol) {
            formCol.fields.general
              .filter((opt: any) => opt.enabled === true)
              .sort((a: any, b: any) => a.order - b.order)
              .map((row: any) => {
                initialValues[row.field] = row.value;
                if (row.prefix !== undefined) {
                  initialValues[row.prefix.field] =
                    row.prefix.value !== undefined ? row.prefix.value : "";
                }
                inputValues.push(row);
              });
          }
        }
        if (formCol && configObj.renderForm.custom_fields.length > 0) {
          formCol.fields.custom
            .filter(
              (opt: any) =>
                (configObj?.renderForm.custom_fields as string[]).includes(
                  opt.field
                ) && opt.enabled == true
            )
            .sort((a: any, b: any) => a.order - b.order)
            .map((row: any) => {
              initialValues[row.field] = row.value;
              if (row.prefix !== undefined) {
                initialValues[row.prefix.field] =
                  row.prefix.value !== undefined ? row.prefix.value : "";
              }
              inputValues.push(row);
            });
        }
      }
      if (validationObj !== undefined && validationObj.length > 0) {
        const objInputValues = inputValues.map((a) => a.field);
        const objValidation = validationObj.filter((obj) => {
          return objInputValues.includes(obj.field);
        });

        let yepSchema = createYupSchema(objValidation);
        // let yepSchema = createYupSchema(validationObj);
        validationRules = yepSchema;
      }
    }
  }
  const validateSchema = Yup.object().shape(validationRules);
  return {
    validationSchema: validateSchema,
    initialValues,
    inputs: inputValues,
    formRefId,
  };
};
