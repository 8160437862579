import * as React from "react";
import {
  Divider,
  SkeletonItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import type { SkeletonProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    width: "100%",
    overflowX: "auto",
  },
  row: {
    display: "grid",
    alignItems: "center",
    ...shorthands.padding("8px", "16px"),
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke2),
    gridTemplateColumns: "min-content repeat(6, 1fr)", // First column for radio button
    columnGap: tokens.spacingHorizontalS,
  },
  header: {
    display: "grid",
    alignItems: "center",
    ...shorthands.padding("8px", "16px"),
    gridTemplateColumns: "min-content repeat(6, 1fr)",
    columnGap: tokens.spacingHorizontalS,
  },
});

export const TableListLoader = ({
  rows = 6, // Number of rows
  columns = 5, // Number of columns excluding radio button
  ...props
}: SkeletonProps & { rows?: number; columns?: number }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {/* Table Header Skeleton */}
      <div className={styles.header}>
        <SkeletonItem shape="circle" size={20} />
        {Array.from({ length: columns }).map((_, colIndex) => (
          <SkeletonItem
            key={colIndex}
            shape="rectangle"
            style={{ height: "20px" }}
          />
        ))}
      </div>

      <Divider />

      {/* Table Body Skeleton */}
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <div key={rowIndex} className={styles.row}>
          <SkeletonItem shape="circle" size={20} />
          {Array.from({ length: columns }).map((_, colIndex) => (
            <SkeletonItem
              key={colIndex}
              shape="rectangle"
              style={{ height: "16px" }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
