import { Dispatch, SetStateAction, useEffect } from "react";
import "./style.css";
import { Outlet } from "react-router-dom";
import ModalDialog from "../../components/ModalDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SystemManagerSidebar from "../../layout/sidebar/SystemManagerSidebar";
import Breadcrumbs from "../../layout/breadcrumb/Breadcrumbs";

interface PropsType {
  setTabs: Dispatch<SetStateAction<string>>;
}

const SystemManagerLayout = ({ setTabs }: PropsType) => {
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);

  return (
    <div style={{ display: "flex", height: "100vh", gap: 10 }}>
      <aside
        style={{
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <SystemManagerSidebar />
      </aside>
      <main style={{ flex: 1, padding: "0.6rem", overflowY: "auto" }}>
        <Breadcrumbs />
        <Outlet />
        <ModalDialog options={dialogModalOptions} />
      </main>
    </div>
  );
};

export default SystemManagerLayout;
