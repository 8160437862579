import {
  Button,
  Input,
  Link,
  Select,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  useToastController,
} from "@fluentui/react-components";
import React, { ChangeEvent, useEffect, useId, useState } from "react";
import TextEditor from "../../../components/TextEditor";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import apiServices from "../../../service";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ASCButton from "../../../components/Button";

const CommissionForm = (props: any) => {
  const selectId = useId();
  const toasterId = useId();
  const data: any = useSelector(
    (state: RootState) => state.commission.filteredData
  );
  const [formField, setFormField] = useState(data);

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };
  const { dispatchToast } = useToastController(toasterId);
  const handleSubmit = (values: any) => {};

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setFormField({ ...formField, [e.target.name]: e.target.value });
  }
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);

  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";

      fieldLbl =
        "commission_type:MasterDatawithoutNewEntry,payment_source:FixedList,frequency:FixedList,true_false:FixedList";

      if (fieldLbl !== "") {
        const res = await apiServices.selectionList.getOptions(fieldLbl);
        if (res.data && res.data.data) {
          setSelectFieldOptions(res.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  useEffect(() => {
    getSelectionListOptions();
  }, []);
  const updatecommissionData = () => {
    let newData;
    if (formField.category === "liability") {
      newData = {
        liabilities_mortgages_payment_commission: {
          customer_id: formField.customer_id,
          case_id: formField.case_id,
          payment_id: formField.payment_id,
          amount_to_company: formField.amount_to_company,
          commission_type: formField.commission_type,
          end_date: formField.end_date,
          frequency: formField.frequency,
          nb_register: formField.nb_register,
          note_text: formField.note_text,
          payment_count: formField.payment_count,
          payment_source: formField.payment_source,
          start_date: formField.start_date,
          status: formField.status,
        },
      };
      apiServices.commission
        .updateCommission(formField.commission_id, newData)
        .then((response: any) => {
          if (!response.data.isError) {
            notify("Action Updated Successfully", "success");
            props.setReceiptEditpopup(false);
          } else {
            notify("Something went wrong", "error");
          }
        })
        .catch((error: Error) => {
          notify("Something went wrong", "error");
        });
    }
  };
  return (
    <div>
      <Toaster
        toasterId={toasterId}
        position="top-end"
        pauseOnHover
        pauseOnWindowBlur
        timeout={1000}
      />
      <div className="commission_action_main">
        <div className="settings-table-content-label">Status</div>
        <span>abc</span>
        <div className="settings-table-content-label">Commission type</div>
        <Select
          id={selectId}
          name={"commission_type"}
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              commission_type: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((option: any) => option.field === "commission_type")
            ?.options.map((opt: { value: string; label: string }) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">Source of Payment</div>
        <Select
          id={selectId}
          name={"payment_source"}
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              payment_source: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((option: any) => option.field === "payment_source")
            ?.options.map((opt: { value: string; label: string }) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">Amount to Company</div>
        <Input
          onChange={onChange}
          name={"amount_to_company"}
          value={formField.amount_to_company}
        />
        <div className="settings-table-content-label">Frequency</div>
        <Select
          id={selectId}
          name="frequency"
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              frequency: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((item: any) => item.field === "frequency")
            ?.options.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">
          Commission Start Date
        </div>
        <DatePicker
          as="input"
          className=""
          placeholder=""
          allowTextInput
          formatDate={onFormatDate}
          onChange={onChange}
          name={"start_date"}
          value={formField.start_date ? new Date(formField.start_date) : null}
          onSelectDate={(date) => {
            setFormField((prevState: any) => ({
              ...prevState,
              start_date: date,
            }));
          }}
        />
        <div className="settings-table-content-label">End Date</div>
        <DatePicker
          as="input"
          className=""
          placeholder=""
          allowTextInput
          formatDate={onFormatDate}
          onChange={onChange}
          name={"end_date"}
          value={formField.end_date ? new Date(formField.end_date) : null}
          onSelectDate={(date) => {
            setFormField((prevState: any) => ({
              ...prevState,
              end_date: date,
            }));
          }}
        />
        <div className="settings-table-content-label">Number of Payment</div>
        <Input
          onChange={onChange}
          name={"payment_count"}
          value={formField.payment_count}
        />
        <div className="settings-table-content-label">
          Show AMT on NB Register
        </div>
        <Select
          id={selectId}
          name="nb_register"
          onChange={(e) =>
            setFormField((prevState: any) => ({
              ...prevState,
              nb_register: e.target.value,
            }))
          }
        >
          {selectFieldOptions
            .find((item: any) => item.field === "true_false")
            ?.options.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </Select>
        <div className="settings-table-content-label">Note</div>
        <TextEditor
          inputData={formField.note_text}
          onChange={(text: string) => {
            setFormField({ ...formField, note_text: text });
          }}
          name={"note_text"}
        />
      </div>
      <div className="button-end-popup">
        <ASCButton
          shape="rounded"
          onClick={() => {
            props.setStopReceiptpopup(true);
          }}
          label="Stop Receipts"
        />
        <Button
          shape="rounded"
          className="asc-button-primary"
          appearance="primary"
          type="submit"
          size="small"
          style={{ marginRight: 6 }}
          onClick={() => {
            updatecommissionData();
          }}
        >
          Save
        </Button>
        <Button
          shape="rounded"
          appearance="secondary"
          type="submit"
          size="small"
          onClick={() => props.setReceiptEditpopup(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CommissionForm;
