import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUserId } from "../utils";
import axiosInstance from "../service/apiService";
const API_BASE_URL = process.env.REACT_APP_BASE_URL + "user/user-perference";
// Async thunk for fetching user preferences
export const fetchPreferences = createAsyncThunk<any, any>(
  "savePreference/fetchPreferences",
  async (params, { rejectWithValue }) => {
    const requestData = {
      type: params.type,
    };
    const userId = getUserId();
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}/${userId}?type=${requestData.type}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
// Async thunk for saving user preferences
export const savePreferences = createAsyncThunk<any, any>(
  "savePreference/savePreferences",
  async (params, { rejectWithValue }) => {
    const requestData = {
      type: params.type,
      data: params.data,
    };
    const userId = getUserId();
    try {
      const response = await axiosInstance.patch(
        `${API_BASE_URL}/${userId}`,
        requestData,
        {}
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const postPreferences = createAsyncThunk<any, any>(
  "savePreference/postPreferences",
  async (params, { rejectWithValue }) => {
    const requestData = {
      type: params.type,
      data: params.data,
    };
    const userId = getUserId();
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/${userId}`,
        requestData,
        {}
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
const initialState = {
  preferences: {},
  status: "",
  message: "",
  error: null,
};
const savePreferenceSlice = createSlice({
  name: "savePreference",
  initialState,
  reducers: {
    clearPreferences: (state) => {
      state.preferences = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPreferences.fulfilled, (state, action: any) => {
        state.status = "succeeded";
        state.preferences = action.payload?.Data || [];
      })
      .addCase(fetchPreferences.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(savePreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(savePreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.preferences = action.payload;
      })
      .addCase(savePreferences.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(postPreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postPreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.preferences = action.payload;
      })
      .addCase(postPreferences.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { clearPreferences } = savePreferenceSlice.actions;
export default savePreferenceSlice.reducer;
