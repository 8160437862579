import React from "react";
import {
  banksBSocieties,
  homePersonal,
  shares,
  unitLinked,
} from "../../../shared/AssetDetails";
import {
  formatedDate,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import {
  CreditCards,
  LoansLeaseHP,
  Mortgages,
} from "../../../shared/LiabilityDetails";
import {
  General,
  HealthAssurance,
  IncomeProtection,
  Investments,
  lifeAssurance,
  pension,
  SavingsPlan,
} from "../../../shared/policyDetails";

const renderDate = (date: string) => (date ? formatedDate(date) : "-");
const renderRichText = (text: string) =>
  text ? <ReactQuillComponent value={text} /> : "-";
const renderYesorNo = (value: number) =>
  value ? getYes_NoConversion(value) : "-";

const CaseDetails = ({ activeItem }: { activeItem: any }) => {
  const renderDetails = (dataArray: any[], keyPrefix: string) => {
    return (
      <div>
        {Object.keys(activeItem).length !== 0 &&
          dataArray.length > 0 &&
          dataArray.map((item: any, index: number) => (
            <div key={`${keyPrefix}_${index}`} className="details">
              <p className="details_label">{item.label} </p>
              <p>
                {(() => {
                  switch (item.value) {
                    case "joint_indicator":
                      return getClientType(activeItem.joint_indicator) || "-";
                    case "mortgage_link":
                      return renderYesorNo(activeItem.mortgage_link);
                    case "start_date":
                      return renderDate(activeItem.start_date);
                    case "end_date":
                      return renderDate(activeItem.end_date);
                    case "valuation_date":
                      return renderDate(activeItem.valuation_date);
                    case "amount_outstanding_date":
                      return renderDate(activeItem.amount_outstanding_date);
                    case "provider_note":
                    case "benefit_text":
                    case "admin_note":
                    case "report_note":
                    case "in_trust_note":
                      return renderRichText(activeItem[item.value]);
                    case "sum_assured":
                      return activeItem.lump_sum_benefit || "-";
                    case "link_to_mortgage":
                      return getYes_NoConversion(activeItem.link_to_mortgage);
                    case "policy_holder":
                      return getClientType(activeItem.policy_holder) || "-";
                    case "start_date":
                      return renderDate(activeItem.start_date);
                    case "end_date":
                      return renderDate(activeItem.end_date);
                    case "valuation_date":
                      return renderDate(activeItem.valuation_date);
                    case "provider_note":
                      return renderRichText(activeItem.provider_note);
                    case "benefit_text":
                      return renderRichText(activeItem.benefit_text);
                    case "in_trust_note":
                      return renderRichText(activeItem.in_trust_note);
                    case "aadmin_note":
                      return renderRichText(activeItem.admin_note);
                    case "areport_note":
                      return renderRichText(activeItem.report_note);
                    case "aassured_name1":
                      return activeItem?.assured_name1 || "-";
                    case "aassured_name2":
                      return activeItem?.assured_name2 || "-";
                    case "value_date":
                      return activeItem.case_type === "General"
                        ? renderDate(activeItem.value_date)
                        : "-";
                    default:
                      return activeItem[item.value] || "-";
                  }
                })()}
              </p>
            </div>
          ))}
      </div>
    );
  };
  if (
    !activeItem ||
    !activeItem.case_type ||
    Object.keys(activeItem).length === 0
  ) {
    return null;
  }

  return (
    <div className="case-detail-container">
      {(() => {
        switch (activeItem.case_type) {
          case "Unit-linked":
            return renderDetails(unitLinked, "Unit-linked");
          case "Shares":
            return renderDetails(shares, "Shares");
          case "Home/Personal":
            return renderDetails(homePersonal, "Home/Personal");
          case "Banks/B Societies":
            return renderDetails(banksBSocieties, "Banks/B Societies");
          case "Mortgages":
            return renderDetails(Mortgages, "mortgage");
          case "Loans/Lease/HP":
            return renderDetails(LoansLeaseHP, "loanleasehp");
          case "Credit Cards":
            return renderDetails(CreditCards, "creditcards");
          case "Life Assurance":
            return renderDetails(lifeAssurance, "lifeassurance");
          case "Pensions":
            return renderDetails(pension, "pension");
          case "Investments":
            return renderDetails(Investments, "investments");
          case "Savings Plans":
            return renderDetails(SavingsPlan, "Savings Plans");
          case "Income Protection":
            return renderDetails(IncomeProtection, "Income Protection");
          case "Health Assurance":
            return renderDetails(HealthAssurance, "Health Assurance");
          case "General":
            return renderDetails(General, "General");
          default:
            return <p>No details available</p>;
        }
      })()}
    </div>
  );
};

export default CaseDetails;
