import { Button, Checkbox } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { Default } from "./Tree";

import {
  ArrowSortDown20Filled,
  ArrowSortUp20Filled,
  ChevronDoubleLeft20Filled,
  ChevronLeft20Filled,
  ChevronRight20Filled,
} from "@fluentui/react-icons";
import OverlayLoader from "../../components/OverlayLoader";
import ASCButton from "../../components/Button";

const TreeComponent = ({
  onBackClick,
  onMoveToSave,
  itemsToExport,
  setItemsToExport,
  cancelTree,
  editData,
  isLoading,
  setLoading,
  checkboxOn,
  setCheckBoxOn,
}: {
  cancelTree: () => void;
  onBackClick: () => void;
  onMoveToSave: (payload: any) => void;
  itemsToExport: { field: string; path: string }[];
  setItemsToExport: React.Dispatch<
    React.SetStateAction<{ field: string; path: string }[]>
  >;
  editData: any;
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  checkboxOn: { header: boolean };
  setCheckBoxOn: React.Dispatch<React.SetStateAction<{ header: boolean }>>;
}) => {
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [selectedPath, setSelectedPath] = useState<string | null>(null);
  const [trimmedField, setTrimmedFields] = useState<any>();
  const [exportDetails, setExportDetails] = useState<
    { field: string; trimmedField: string | null }[]
  >([]);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxOn({ header: event.target.checked });
  };

  const handleSelection = (
    fields: string,
    fullPath: string,
    customPath: string,
    ModuleFields?: string,
    Fields?: string,
    trimmedPath?: string
  ) => {
    setTrimmedFields(trimmedPath);
    setSelectedField(fields);
    setSelectedPath(fullPath);
  };
  useEffect(() => {
    if (Array.isArray(editData) && editData.length > 0) {
      const updatedItems = editData.map((path) => {
        const fieldMatch = path.match(/- ([^-]+)$/);
        const field = fieldMatch ? fieldMatch[1].trim() : "Unknown Field";
        return { field, path };
      });
      setItemsToExport(updatedItems);

      const updatedExportDetails = updatedItems.map((item) => ({
        field: item.field,
        trimmedField: item.path.split(".")[0] || "Unknown",
      }));
      setExportDetails(updatedExportDetails);
    } else {
    }
  }, [editData, setItemsToExport, setExportDetails]);

  const moveToExport = () => {
    if (
      selectedField &&
      !itemsToExport.some((item) => item.field === selectedField)
    ) {
      setItemsToExport((prev) => [
        ...prev,
        { field: selectedField, path: selectedPath || "" },
      ]);

      setExportDetails((prev) => [
        ...prev,
        { field: selectedField, trimmedField },
      ]);

      setSelectedField(null);
      setSelectedPath(null);
    }
  };

  const moveToAvailable = (field: string) => {
    setItemsToExport((prev) => prev.filter((i) => i.field !== field));
    setExportDetails((prev) => prev.filter((item) => item.field !== field));
  };

  const resetExportedItems = () => {
    setItemsToExport([]);
    setSelectedField(null);
    setSelectedPath(null);
  };
  const formatExportDetails = () => {
    const grouped = exportDetails.reduce((acc, { field, trimmedField }) => {
      const key = trimmedField ?? "Unknown";

      if (!acc[key]) acc[key] = new Set();
      acc[key].add(field);
      return acc;
    }, {} as Record<string, Set<string>>);

    const formattedArray = Object.entries(grouped).flatMap(
      ([module, fields]) => {
        const trimmedModule = module.split(" - ")[0];

        return Array.from(fields).map((field) => {
          const item = itemsToExport.find((i) => i.field === field);
          const path = item ? item.path : "Unknown";
          return {
            table: trimmedModule, // Use trimmedModule for the table
            fieldName: field,
            label: `${trimmedModule}.${field},path:${path}`, // Use trimmedModule in label as well
            partner: trimmedModule.toLowerCase() !== "client",
          };
        });
      }
    );

    return formattedArray;
  };

  const payload = formatExportDetails();
  const moveItemUp = () => {
    if (!selectedPath) return;

    setItemsToExport((prev) => {
      const index = prev.findIndex((item) => item.field === selectedPath);
      if (index > 0) {
        const updated = [...prev];
        [updated[index], updated[index - 1]] = [
          updated[index - 1],
          updated[index],
        ];
        return updated;
      }
      return prev;
    });
  };

  const moveItemDown = () => {
    if (!selectedPath) return;

    setItemsToExport((prev) => {
      const index = prev.findIndex((item) => item.field === selectedPath);
      if (index < prev.length - 1) {
        const updated = [...prev];
        [updated[index], updated[index + 1]] = [
          updated[index + 1],
          updated[index],
        ];
        return updated;
      }
      return prev;
    });
  };

  return (
    <div>
      <OverlayLoader isLoading={isLoading} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div>
          <span style={{ fontWeight: 700 }}>Field Selection</span>
        </div>
        <span>Choose the fields to export here</span>
        <div
          className="main"
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
        >
          <div>
            <span>Items Available:</span>
          </div>
          <div>
            <span style={{ marginInline: "28px" }}>Items to Export:</span>
          </div>
        </div>
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              border: "1px solid #D1D1D1",
              height: "298px",
              width: "308px",
              overflowX: "auto",
            }}
          >
            <Default
              selectedField={selectedField}
              onSelect={handleSelection}
              excludedItems={itemsToExport.map((item) => item.field)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <Button
                shape="rounded"
                onClick={moveToExport}
                size="small"
                style={{ minWidth: "20px" }}
                disabled={!selectedField && itemsToExport?.length === 0}
              >
                <ChevronRight20Filled />
              </Button>
            </div>
            <div>
              <Button
                shape="rounded"
                onClick={() => selectedPath && moveToAvailable(selectedPath)}
                size="small"
                style={{ minWidth: "20px" }}
                disabled={itemsToExport?.length === 0}
              >
                <ChevronLeft20Filled />
              </Button>
            </div>
            <div>
              <Button
                shape="rounded"
                onClick={resetExportedItems}
                size="small"
                style={{ minWidth: "20px" }}
              >
                <ChevronDoubleLeft20Filled />
              </Button>
            </div>
          </div>

          <div
            style={{
              border: "1px solid #D1D1D1",
              height: "298px",
              width: "308px",
              overflowX: "auto",
            }}
          >
            {itemsToExport.length > 0
              ? itemsToExport.map(({ field, path }) => (
                  <div
                    key={field}
                    onClick={() => setSelectedPath(field)}
                    style={{
                      padding: "6px",
                      cursor: "pointer",
                      background:
                        selectedPath === field ? "#f5f5f5" : "transparent",
                    }}
                  >
                    <span>{path}</span>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div
          className="main"
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
        >
          <div></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <div>
              <span style={{ marginInline: "30px" }}>Change order:</span>
            </div>

            <div>
              <Button
                shape="rounded"
                size="small"
                onClick={moveItemUp}
                style={{ minWidth: "20px" }}
              >
                <ArrowSortUp20Filled />
              </Button>
            </div>
            <div>
              <Button
                shape="rounded"
                size="small"
                style={{ minWidth: "20px" }}
                onClick={moveItemDown}
              >
                <ArrowSortDown20Filled />
              </Button>
            </div>
          </div>
        </div>
        <div
          className="main"
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
        >
          <div></div>
          <div>
            <span style={{ marginInline: "25px" }}>
              <Checkbox
                checked={checkboxOn.header}
                onChange={handleCheckboxChange}
              />{" "}
              Include headings
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "5px",
            flexDirection: "row",
          }}
        >
          <div>
            <ASCButton
              shape="rounded"
              appearance="secondary"
              size="small"
              onClick={onBackClick}
              label="Back"
            />
          </div>
          <div>
            <ASCButton
              shape="rounded"
              disabled={itemsToExport.length === 0}
              appearance="primary"
              className="asc-button-primary"
              size="small"
              onClick={() => onMoveToSave(payload)} // Pass payload here
              label="Next"
            />
          </div>
          <div>
            <ASCButton
              shape="rounded"
              size="small"
              appearance="secondary"
              onClick={cancelTree}
              label="Cancel"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeComponent;
