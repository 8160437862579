import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";


interface ClientRow {
  customer_id: string;
  first_names: string;
  last_name: string;
  email: string;
}


function ClientSelection(props: any) {
  const [clients, setClients] = useState<ClientRow[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedClients, setSelectedClients] = useState<ClientRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);


  const columns = useMemo<MRT_ColumnDef<ClientRow>[]>(
    () => [
      {
        accessorKey: "first_names",
        header: "First Name",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
    ],
    []
  );


  async function getClientList() {
    setLoading(true);
    try {
      const resp = await apiServices.client.getClientLists(
        pagination.pageIndex + 1,
        pagination.pageSize,
        searchText
      );


      setRowCount(resp?.data?.data?.count || 0);
      const transformedData = resp?.data?.data?.customerList.map(
        (client: any) => ({
          customer_id: client.customer_id,
          first_names: client?.first_names || "",
          last_name: client?.last_name || "",
          email: client?.email || "",
        })
      );


      setClients(transformedData);
      props.setClientList(transformedData);


      const selectedIds = new Set(selectedClients.map((c) => c.customer_id));
      const restoredSelection: MRT_RowSelectionState = {};
      transformedData.forEach((client : any) => {
        if (selectedIds.has(client.customer_id)) {
          restoredSelection[client.customer_id] = true;
        }
      });


      setRowSelection(restoredSelection);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    getClientList();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);


  useEffect(() => {
    const newSelectedClients = clients.filter(
      (client) => rowSelection[client.customer_id]
    );

    setSelectedClients((prevSelected) => {
      const selectedMap = new Map(prevSelected.map((c) => [c.customer_id, c]));
      newSelectedClients.forEach((client) =>
        selectedMap.set(client.customer_id, client)
      );


      return Array.from(selectedMap.values());
    });
  }, [rowSelection, clients]);


  useEffect(() => {
    if (props.handleClientSelection) {
      props.handleClientSelection(selectedClients);
    }
  }, [selectedClients, props]);


  return (
    <div>
      <OverlayLoader isLoading={loading} />
      <div className="table-in-popup">
        <MaterialReactTable<ClientRow>
          columns={columns}
          data={clients}
          onRowSelectionChange={setRowSelection}
          onPaginationChange={setPagination}
          rowCount={rowCount}
          manualPagination
          state={{ pagination, rowSelection }}
          enableGlobalFilter
          enableColumnActions={false}
          positionToolbarAlertBanner="none"
          enableColumnFilters
          enableFullScreenToggle={false}
          enableRowSelection={(row) => !!row.original.email}
          enableMultiRowSelection
          getRowId={(row) => row.customer_id}
          muiTablePaginationProps={{
            showFirstButton: false,
            showLastButton: false,
          }}
          muiTableBodyCellProps={({ cell, row }) => {
            if (cell.column.id === "mrt-row-select") {
              return {
                sx: {
                  pointerEvents: row.original.email ? "auto" : "none",
                  opacity: row.original.email ? 1 : 0.5,
                },
                disabled: !row.original.email,
              };
            }
            return {
              style: {
                padding: "6px 12px",
                fontSize: "0.875rem",
              },
            };
          }}
          onGlobalFilterChange={setSearchText}
        />
      </div>
    </div>
  );
}


export default ClientSelection;