import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StringState {
  value: string;
}

const initialState: StringState = {
  value: "",
};

const stringSlice = createSlice({
  name: "string",
  initialState,
  reducers: {
    setString: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    clearString: (state) => {
      state.value = "";
    },
  },
});

export const { setString, clearString } = stringSlice.actions;
export default stringSlice.reducer;
