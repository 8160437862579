import React, { useState } from "react";
import {
  Input,
  Button,
  Text,
  Label,
  Divider,
} from "@fluentui/react-components";
import ASCButton from "../../../components/Button";
interface Client {
  id: string;
  fname: string;
}
const FinishPage = ({
  CancelFinishPage,
  BackToFieldSelection,
  selectedClients,
  title,
  description,
  setTitle,
  setDescription,
  finalTitle,
  setFinalTitle,
  finalDescription,
  setFinalDescription,
  finishSelection,
}: {
  CancelFinishPage: () => void;
  BackToFieldSelection: () => void;
  selectedClients: Client[];
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  finalTitle: string;
  setFinalTitle: React.Dispatch<React.SetStateAction<string>>;
  finalDescription: string;
  setFinalDescription: React.Dispatch<React.SetStateAction<string>>;
  finishSelection: () => void;
}) => {
  const [selectedSpecId, setSelectedSpecId] = React.useState<number | null>(
    null
  );

  const specifications = [
    { id: 1, name: "Export all data" },
    { id: 2, name: "Sample 1" },
    { id: 3, name: "Sample 2" },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div>
          <span style={{ fontWeight: 700, fontSize: "16px" }}>
            Completing the Extract
          </span>
        </div>
        <div>
          <span>
            This is the list of clients meeting your extract or selection
            conditions. Click the Finish button to open a new window and display
            the Client Details.
          </span>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div
            className="title"
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "1fr 3fr",
            }}
          >
            <Label style={{ fontWeight: "600" }}>Title</Label>
            <Input
              style={{
                border: "1px solid #CDCDCD",
                borderRadius: "0px",
              }}
              value={title ? title : finalTitle}
              onChange={(e) => setFinalTitle(e.target.value)}
            />
          </div>

          <div
            className="description"
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "1fr 3fr",
            }}
          >
            <Label style={{ fontWeight: "600" }}>Description</Label>
            <Input
              style={{
                border: "1px solid #CDCDCD",
                borderRadius: "0px",
              }}
              value={description ? description : finalDescription}
              onChange={(e) => setFinalDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #D1D1D1",
          maxHeight: "200px",
          minHeight: "200px",
          overflowX: "auto",
        }}
      >
        {selectedClients.map((spec) => (
          <div
            key={spec.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <div style={{ padding: "4px" }}>
              <span>{spec.fname}</span>
            </div>
          </div>
        ))}
      </div>
      {selectedClients.length > 0 && (
        <span>
          Matching records count:{" "}
          <span style={{ fontWeight: 700 }}>{selectedClients.length}</span>
        </span>
      )}

      <Divider />
      <br />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div>
          <ASCButton
            shape="rounded"
            size="small"
            onClick={BackToFieldSelection}
            label="Back"
          />
        </div>
        <div>
          <ASCButton
            shape="rounded"
            size="small"
            onClick={finishSelection}
            label="Finish"
          />
        </div>
        <div>
          <ASCButton
            shape="rounded"
            size="small"
            onClick={CancelFinishPage}
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default FinishPage;
