import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../service";

const initialState: any = {
  listItems: [],
  funds: {
    listItems: [],
    activeItem: {},
    totalFund: {},
    loading: false,
    activeItemloading: false,
  },
  payments: {
    listItems: [],
    activeItem: {},
    totalPayment: {},
    loading: false,
    activeItemloading: false,
    commissions: {
      listItems: [],
      activeItem: {},
      loading: false,
      activeItemloading: false,
    },
  },
  valuations: {
    listItems: [],
    activeItem: {},
    loading: false,
    activeItemloading: false,
  },
  actions: {
    listItems: [],
    activeItem: {},
    loading: false,
    activeItemloading: false,
  },
  withdrawals: {
    listItems: [],
    activeItem: {},
    totalWithdrawals: {},
    loading: false,
    activeItemloading: false,
  },
  activeItem: {},
  loading: false,
  activeItemloading: false,
};

export const getAssetListItems = createAsyncThunk(
  "assets/getAssetListItems",
  async (id: string, { rejectWithValue }) => {
    if (!id) return rejectWithValue("Invalid customer ID");
    try {
      const response: any = await apiServices.assets.getAssets(id);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset list");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetDetailById = createAsyncThunk(
  "assets/getAssetDetailById",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.assets.getDetail(id, customerId);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetValuationsListItems = createAsyncThunk(
  "assets/getAssetValuationsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.valuation.getvaluation(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetValuationDetailById = createAsyncThunk(
  "assets/getAssetValuationDetailById",
  async (valuationId: string, { getState, rejectWithValue }) => {
    if (!valuationId) return rejectWithValue("Invalid valuation ID");

    // Access the current state
    const state: any = getState();

    const valuationsList = state.assets.valuations.listItems;

    // Find the valuation detail by ID in the listItems array
    const valuationDetail = valuationsList.find(
      (item: any) => item.valuation_id === valuationId
    );

    if (valuationDetail) {
      return valuationDetail; // Return the detail if found
    } else {
      return rejectWithValue("Valuation detail not found in state");
    }
  }
);
export const getAssetActionsListItems = createAsyncThunk(
  "assets/getAssetActionsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Actions.getActions(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetActionDetailById = createAsyncThunk(
  "assets/getAssetActionDetailById",
  async (actionId: string, { getState, rejectWithValue }) => {
    if (!actionId) return rejectWithValue("Invalid actionId ID");

    // Access the current state
    const state: any = getState();

    const actionsList = state.assets.actions.listItems;

    // Find the valuation detail by ID in the listItems array
    const actionDetail = actionsList.find(
      (item: any) => item.business_id === actionId
    );

    if (actionDetail) {
      return actionDetail; // Return the detail if found
    } else {
      return rejectWithValue("action detail not found in state");
    }
  }
);
export const getAssetWithdrawalsListItems = createAsyncThunk(
  "assets/getAssetWithdrawalsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Withdrawals.getList(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetWithdrawalDetailById = createAsyncThunk(
  "assets/getAssetWithdrawalDetailById",
  async (withdrawalId: string, { getState, rejectWithValue }) => {
    if (!withdrawalId) return rejectWithValue("Invalid withdrawalId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.assets.withdrawals.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.withdrawal_id === withdrawalId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("withdrawal detail not found in state");
    }
  }
);
export const getAssetFundsListItems = createAsyncThunk(
  "assets/getAssetFundsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.fund.getList(customerId, id);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetFundDetailById = createAsyncThunk(
  "assets/getAssetFundDetailById",
  async (fundId: string, { getState, rejectWithValue }) => {
    if (!fundId) return rejectWithValue("Invalid fundId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.assets.funds.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find((item: any) => item.fund_id === fundId);

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("fund detail not found in state");
    }
  }
);
export const getAssetPaymentsListItems = createAsyncThunk(
  "assets/getAssetPaymentsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Payments.getPayments(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetPaymentDetailById = createAsyncThunk(
  "assets/getAssetPaymentDetailById",
  async (paymentId: string, { getState, rejectWithValue }) => {
    if (!paymentId) return rejectWithValue("Invalid paymentId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.assets.payments.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.payment.payment_id === paymentId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("paymenty detail not found in state");
    }
  }
);
export const getAssetPaymentsCommissionsListItems = createAsyncThunk(
  "assets/getAssetPaymentsCommissionsListItems",
  async (
    {
      paymentId,
      caseId,
      customerId,
    }: { paymentId: string; caseId: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!paymentId || !caseId || !customerId)
      return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.commission.getCommission(
        paymentId,
        customerId,
        caseId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getAssetPaymentsCommissionDetailById = createAsyncThunk(
  "assets/getAssetPaymentsCommissionDetailById",
  async (commissionId: string, { getState, rejectWithValue }) => {
    if (!commissionId) return rejectWithValue("Invalid paymentId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.assets.payments.commissions.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.commission_id === commissionId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("paymenty detail not found in state");
    }
  }
);
export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetListItems.pending, (state) => {
        state.loading = true;
        state.listItems = [];
      })
      .addCase(getAssetListItems.fulfilled, (state, action) => {
        state.loading = false;
        state.listItems = action.payload;
      })
      .addCase(getAssetListItems.rejected, (state, action) => {
        state.loading = false;
        console.error("Error fetching asset list:", action.payload);
      })
      .addCase(getAssetDetailById.pending, (state) => {
        state.activeItemloading = true;
        state.activeItem = {};
      })
      .addCase(getAssetDetailById.fulfilled, (state, action) => {
        state.activeItemloading = false;
        state.activeItem = action.payload;
      })
      .addCase(getAssetDetailById.rejected, (state, action) => {
        state.activeItemloading = false;
        console.error("Error fetching asset details:", action.payload);
      })
      .addCase(getAssetValuationsListItems.pending, (state) => {
        state.valuations.loading = true;
        state.valuations.listItems = [];
      })
      .addCase(getAssetValuationsListItems.fulfilled, (state, action) => {
        state.valuations.loading = false;
        state.valuations.listItems = action.payload;
      })
      .addCase(getAssetValuationsListItems.rejected, (state, action) => {
        state.valuations.loading = false;
        console.error("Error fetching asset valuations:", action.payload);
      })
      .addCase(getAssetValuationDetailById.pending, (state) => {
        state.valuations.activeItemloading = true;
        state.valuations.activeItem = {};
      })
      .addCase(getAssetValuationDetailById.fulfilled, (state, action) => {
        state.valuations.activeItemloading = false;
        state.valuations.activeItem = action.payload;
      })
      .addCase(getAssetValuationDetailById.rejected, (state, action) => {
        state.valuations.activeItemloading = false;
        console.error("Error fetching asset valuation detail:", action.payload);
      })
      .addCase(getAssetActionsListItems.pending, (state) => {
        state.actions.loading = true;
        state.actions.listItems = [];
      })
      .addCase(getAssetActionsListItems.fulfilled, (state, action) => {
        state.actions.loading = false;
        state.actions.listItems = action.payload;
      })
      .addCase(getAssetActionsListItems.rejected, (state, action) => {
        state.actions.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getAssetActionDetailById.pending, (state) => {
        state.actions.activeItemloading = true;
        state.actions.activeItem = {};
      })
      .addCase(getAssetActionDetailById.fulfilled, (state, action) => {
        state.actions.activeItemloading = false;
        state.actions.activeItem = action.payload;
      })
      .addCase(getAssetActionDetailById.rejected, (state, action) => {
        state.actions.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getAssetWithdrawalsListItems.pending, (state) => {
        state.withdrawals.loading = true;
        state.withdrawals.listItems = [];
        state.withdrawals.totalWithdrawals = {};
      })
      .addCase(getAssetWithdrawalsListItems.fulfilled, (state, action) => {
        state.actions.loading = false;
        state.withdrawals.listItems = action.payload?.result || [];
        state.withdrawals.totalWithdrawals = action.payload?.total;
      })
      .addCase(getAssetWithdrawalsListItems.rejected, (state, action) => {
        state.withdrawals.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getAssetWithdrawalDetailById.pending, (state) => {
        state.withdrawals.activeItemloading = true;
        state.withdrawals.activeItem = {};
      })
      .addCase(getAssetWithdrawalDetailById.fulfilled, (state, action) => {
        state.withdrawals.activeItemloading = false;
        state.withdrawals.activeItem = action.payload;
      })
      .addCase(getAssetWithdrawalDetailById.rejected, (state, action) => {
        state.withdrawals.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getAssetFundsListItems.pending, (state) => {
        state.funds.loading = true;
        state.funds.listItems = [];
        state.funds.totalFund = {};
      })
      .addCase(getAssetFundsListItems.fulfilled, (state, action) => {
        state.funds.loading = false;
        state.funds.listItems = action.payload?.result || [];
        state.funds.totalFund = action.payload?.total_fund;
      })
      .addCase(getAssetFundsListItems.rejected, (state, action) => {
        state.funds.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getAssetFundDetailById.pending, (state) => {
        state.funds.activeItemloading = true;
        state.funds.activeItem = {};
      })
      .addCase(getAssetFundDetailById.fulfilled, (state, action) => {
        state.funds.activeItemloading = false;
        state.funds.activeItem = action.payload;
      })
      .addCase(getAssetFundDetailById.rejected, (state, action) => {
        state.funds.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getAssetPaymentsListItems.pending, (state) => {
        state.payments.loading = true;
        state.payments.listItems = [];
        state.payments.totalPayment = {};
      })
      .addCase(getAssetPaymentsListItems.fulfilled, (state, action) => {
        state.payments.loading = false;
        state.payments.listItems = action.payload?.result || [];
        state.payments.totalPayment = action.payload?.total;
      })
      .addCase(getAssetPaymentsListItems.rejected, (state, action) => {
        state.payments.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getAssetPaymentDetailById.pending, (state) => {
        state.payments.activeItemloading = true;
        state.payments.activeItem = {};
      })
      .addCase(getAssetPaymentDetailById.fulfilled, (state, action) => {
        state.payments.activeItemloading = false;
        state.payments.activeItem = action.payload;
      })
      .addCase(getAssetPaymentDetailById.rejected, (state, action) => {
        state.payments.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getAssetPaymentsCommissionsListItems.pending, (state) => {
        state.payments.commissions.loading = true;
        state.payments.commissions.listItems = [];
      })
      .addCase(
        getAssetPaymentsCommissionsListItems.fulfilled,
        (state, action) => {
          state.payments.commissions.loading = false;
          state.payments.commissions.listItems = action.payload;
        }
      )
      .addCase(
        getAssetPaymentsCommissionsListItems.rejected,
        (state, action) => {
          state.payments.commissions.loading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      )
      .addCase(getAssetPaymentsCommissionDetailById.pending, (state) => {
        state.payments.commissions.activeItemloading = true;
        state.payments.commissions.activeItem = {};
      })
      .addCase(
        getAssetPaymentsCommissionDetailById.fulfilled,
        (state, action) => {
          state.payments.commissions.activeItemloading = false;
          state.payments.commissions.activeItem = action.payload;
        }
      )
      .addCase(
        getAssetPaymentsCommissionDetailById.rejected,
        (state, action) => {
          state.payments.commissions.activeItemloading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      );
  },
});
export const {} = assetsSlice.actions;
export default assetsSlice.reducer;
