import React, { useEffect, useState } from "react";
import FormContent from "../../../components/Form/FormContent";
import { formBuilder } from "../../../utils/formGenerator";
import { useSelector, useDispatch } from "react-redux";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { RootState, AppDispatch } from "../../../redux/store";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  Label,
} from "@fluentui/react-components";
import {
  Edit16Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
} from "@fluentui/react-icons";
import apiServices from "../../../service";
import { setFormData, resetFormData } from "../../../redux/reducer";
import FundCalculation from "../../../components/CalculationTable/fundCalculation";
import { checkUserPermission, formatedDate2 } from "../../../utils";
import { UserPermissionsList } from "../../../config";
import WithdrawalCalculation from "../../../components/CalculationTable/withdrawalCalculation";
import PaymentCalculation from "../../../components/CalculationTable/paymentCalculation";
import OverlayLoader from "../../../components/OverlayLoader";
import { FormErrorMessage } from "../../../components/FormErrorMessage/FormErrorMessage";
import DeletePopup from "../../../components/DeletePopup/Deletepopup";
import DeleteDependencyPopup from "../../../components/DeletePopup/DeleteDepedencty";
const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type configFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};
type FormContainerDetailPageProps = {
  style?: React.CSSProperties;
  title?: string;
  mode?: string;
  context: string;
  [x: string]: any;
};
const FormContainerDetailPage = (props: FormContainerDetailPageProps) => {
  let { context } = props;
  const classes = useStyles();
  const [configForm, setConfigForm] = useState<configFm>();
  const [formContext, setFormContext] = useState("");
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [optionList, setOptionList] = useState<any>([]);
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [formError, setFormError] = useState<any[]>([]);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const [getConsultant, setgetConsultant] = useState("");

  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [getCommissionType, setgetCommissionType] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [dependencyDeletePopup, setDependencyDeletePopup] = useState(false);
  const [dependencymessage, setDependencyMessage] = useState("");
  const [getRuleId, setGetRuleId] = useState<{ [key: string]: any }>({});
  function ruleId(id: { [key: string]: any }) {
    setGetRuleId(id);

    if (props?.item?.field) {
      formik.setFieldValue(props?.item?.field, id.id);
    }
  }

  const updateConsultantName = (name: any) => {
    setgetConsultant(name);
  };

  const updateCommissionType = (name: any) => {
    setgetCommissionType(name);
  };
  const deleteData = () => {
    setLoading(true);
    if (
      context === "ASSET_INVESTMENT_FUND" ||
      context === "ASSET_SHARE_HOLDINGS_FUND" ||
      context === "ASSET_BANK_OR_BUILDING_SOCIETIES_FUND"
    ) {
      apiServices.fund
        .delete(props.assetSubActiveItem.fund_id)
        .then((response: any) => {
          props.notify(response.data.message, "", "success");
          props.handleReloadSublist(response);
          setLoading(false);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setLoading(false);
        });
    } else if (
      context === "ASSET_INVESTMENT_WITHDRAWALS" ||
      context === "ASSET_SHARE_HOLDING_WITHDRAWALS" ||
      context === "ASSET_BANK_OR_BUILDING_SOCIETIES_WITHDRAWALS"
    ) {
      apiServices.Withdrawals.delete(props.assetSubActiveItem.withdrawal_id)
        .then((response: any) => {
          props.notify(response.data.message, "", "success");
          props.handleReloadSublist(response);
          setLoading(false);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setLoading(false);
        });
    } else if (
      context === "ASSET_INVESTMENT_VALUATION" ||
      context === "ASSET_SHAREHOLDING_VALUATION" ||
      context === "ASSET_HOME_AND_PERSONAL_VALUATION" ||
      context === "ASSET_BANK_OR_BUILDING_SOCIETIES_VALUATION"
    ) {
      apiServices.valuation
        .delete(props.assetSubActiveItem.valuation_id)
        .then((response: any) => {
          props.notify(response.data.message, "", "success");
          props.handleReloadSublist(response);
          setLoading(false);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setLoading(false);
        });
    } else if (context === "ASSET_INVESTMENT_ACTIONS") {
      apiServices.Actions.delete(props.assetSubActiveItem.business_id)
        .then((response: any) => {
          props.notify(response.data.message, "", "success");
          props.handleReloadSublist(response);
          setLoading(false);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setLoading(false);
        });
    } else if (
      context === "ASSET_INVESTMENT_PAYMENTS" ||
      context === "ASSET_SHARE_HOLDINGS_PAYMENT" ||
      context === "ASSET_BANK_OR_BUILDING_SOCIETIES_PAYMENT"
    ) {
      apiServices.Payments.delete(props.assetSubActiveItem.payment.payment_id)
        .then((response: any) => {
          if (response.data.isdependancy) {
            setDependencyDeletePopup(true);
            setDependencyMessage(response.data.message);
          } else {
            props.notify(response.data.message, "", "success");
            props.handleReloadSublist(response);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setLoading(false);
        });
    }
    setDeletePopup(false);
    // dispatch(setDialogModalOptions({ open: false }));
  };

  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      setLoading(true);
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });
        setLoading(true);
        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            setLoading(false);
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }

      // const response = await axios.get("/option2.json");
      // const optiondata = response.data;
      // if (optiondata.data && optiondata.data.length) {
      //   setSelectFieldOptions(optiondata.data);
      // }
      // setOptionData(optiondata);
      // optiondata[context] && setOptionList(optiondata[context]);
      // optionList && setIsOptionLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (context !== "") {
      setFormContext(context);
      generateDynamicForm(context);
    }
  }, [
    context,
    formSettingsObj,
    props.detailPageMode,
    props.assetActiveTabLevel2Subtab,
  ]);
  const customHandleChange = (event: any) => {
    handleOnChange(event);
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;
    dispatch(setFormData());
    updatePresent(data);
    if (isFormDataUpdated) {
      if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
        dispatch(resetFormData());
        updated = false;
      } else {
        dispatch(setFormData());
        updated = true;
      }
    }

    if (props.mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(props.mode === "add" ? "New" : "");
    }
  };
  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };

  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  const generateDynamicForm = (formContext: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let { initialValues, inputs, validationSchema } = formBuilder({
        context: context,
        formSettings: formSettingsObj.data,
      });
      let filteredSelectionOptions = inputs
        .filter(
          (opt: any) =>
            opt.listType !== undefined &&
            [
              "ModifiableList",
              "FixedList",
              "MasterDatawithNewEntry",
              "MasterDatawithoutNewEntry",
            ].includes(opt.listType) &&
            opt.fieldOptionLabel !== undefined
        )
        .map((opt) => ({
          label: opt.fieldOptionLabel,
          listType: opt.listType,
          formField: opt.field,
        }));
      setSelectFieldOptionLabels(filteredSelectionOptions);
      if (filteredSelectionOptions.length > 0) {
        getSelectionListOptions(filteredSelectionOptions);
      }
      if (
        props.detailPageMode &&
        ["add", "edit"].includes(props.detailPageMode) &&
        props.assetActiveTabLevel2Subtab
      ) {
        if (props.assetActiveTabLevel2Subtab === "paymentCommission") {
          inputs = inputs.filter((item: any) => item.field !== "status");
        } else if (
          props.assetActiveTabLevel2Subtab === "commissionDistribution"
        ) {
          inputs = inputs.filter((item: any) => item.field !== "status");
          inputs = inputs.filter((item: any) => item.field !== "current_rule");
          inputs = inputs.filter((item: any) => item.field !== "case_type");
        }
      }
      if (
        props.detailPageMode &&
        ["view"].includes(props.detailPageMode) &&
        props.assetActiveTabLevel2Subtab &&
        props.assetActiveTabLevel2Subtab === "commissionDistribution"
      ) {
        inputs = inputs.filter((item: any) => item.field !== "rule_id");
      }

      setFormFieldData({ initialValues, inputs, validationSchema });
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === context
      );

      if (configObj) {
        setConfigForm(configObj);
      }
    }
  };

  const formik = useFormik({
    initialValues:
      props.mode && ["edit", "view"].includes(props.mode)
        ? props.initialFormData
        : formFieldData.initialValues
        ? formFieldData.initialValues
        : {},

    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });
  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);
  const formSubmitted = (values: any) => {
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    let reqBody: { [key: string]: any } = {};
    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
      if (reqBody.commission_type !== undefined) {
        setgetCommissionType(reqBody.commission_type);
      }
    }

    let addReq: any = {};
    if (
      configForm &&
      configForm.renderForm &&
      configForm.renderForm.reference_id
    ) {
      addReq[configForm.renderForm.reference_id] = reqBody;
      if (props.assetActiveTabLevel2 === "payment") {
        if (props.assetActiveTabLevel2Subtab === "paymentCommission") {
          addReq[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.customer_id;
          addReq[configForm.renderForm.reference_id]["case_id"] =
            props.activeItem.asset_id;
          addReq[configForm.renderForm.reference_id]["payment_id"] =
            props.activePaymentItem.payment.payment_id;
          addReq[configForm.renderForm.reference_id]["commission_type"] =
            getCommissionType === ""
              ? reqBody.commission_type
              : getCommissionType;
          addReq[configForm.renderForm.reference_id]["payment_source"] =
            reqBody.payment_source;
          addReq[configForm.renderForm.reference_id]["amount_to_company"] =
            parseFloat(reqBody.amount_to_company);
          addReq[configForm.renderForm.reference_id]["frequency"] =
            reqBody.frequency;
          addReq[configForm.renderForm.reference_id]["start_date"] =
            formatedDate2(reqBody.start_date);
          addReq[configForm.renderForm.reference_id]["end_date"] =
            formatedDate2(reqBody.end_date);
          addReq[configForm.renderForm.reference_id]["nb_register"] = parseInt(
            reqBody.nb_register
          );
          addReq[configForm.renderForm.reference_id]["payment_count"] =
            reqBody.payment_count;
          addReq[configForm.renderForm.reference_id]["status"] =
            parseInt(reqBody.status) || 0;
          addReq[configForm.renderForm.reference_id]["note_text"] =
            reqBody.note_text;
        } else if (
          props.assetActiveTabLevel2Subtab === "commissionDistribution"
        ) {
          addReq[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.customer_id;
          addReq[configForm.renderForm.reference_id]["commission_id"] =
            props.activeCommissionItem.commission_id;
          addReq[configForm.renderForm.reference_id]["case_type"] =
            reqBody.case_type === "" ? "Asset(Investment)" : reqBody.case_type;
          addReq[configForm.renderForm.reference_id]["commission_rule_id"] =
            reqBody.commission_rule_id ? reqBody.commission_rule_id : "";
          addReq[configForm.renderForm.reference_id]["party_type"] =
            reqBody.party_type;
          addReq[configForm.renderForm.reference_id]["amount"] = parseFloat(
            reqBody.amount
          );
          addReq[configForm.renderForm.reference_id]["current_rule"] =
            reqBody.current_rule === "" ? "Custom" : reqBody.case_type;
          addReq[configForm.renderForm.reference_id]["status"] =
            parseInt(reqBody.status) || 0;
          addReq[configForm.renderForm.reference_id]["note_text"] =
            reqBody.note_text;
          delete addReq[configForm.renderForm.reference_id]["rule_id"];
        } else if (props.assetActiveTabLevel2Subtab === "paymentcomplaince") {
          addReq[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.customer_id;
          addReq[configForm.renderForm.reference_id]["case_id"] =
            props.activeItem.asset_id;
          addReq[configForm.renderForm.reference_id]["consultant_id"] =
            reqBody.consultant_id;

          addReq[configForm.renderForm.reference_id]["consultant"] =
            reqBody.consultant_id === "" ? "" : reqBody.consultant;
          addReq[configForm.renderForm.reference_id]["client_agreement"] =
            formatedDate2(reqBody.client_agreement);
          addReq[configForm.renderForm.reference_id]["fact_find_completed"] =
            formatedDate2(reqBody.fact_find_completed);
          addReq[configForm.renderForm.reference_id]["high_risk"] =
            reqBody.high_risk;
          addReq[configForm.renderForm.reference_id]["high_risk_note"] =
            reqBody.high_risk_note;
          addReq[configForm.renderForm.reference_id]["type_of_sale"] =
            reqBody.type_of_sale;
          addReq[configForm.renderForm.reference_id]["business_card"] =
            formatedDate2(reqBody.business_card);
          addReq[configForm.renderForm.reference_id]["submitted"] =
            formatedDate2(reqBody.submitted);
          addReq[configForm.renderForm.reference_id]["replacement"] =
            reqBody.replacement;
          addReq[configForm.renderForm.reference_id]["replacement_reason"] =
            reqBody.replacement_reason;
          addReq[configForm.renderForm.reference_id]["sanctions_check"] =
            formatedDate2(reqBody.sanctions_check);
          addReq[configForm.renderForm.reference_id]["terms_of_business_sent"] =
            formatedDate2(reqBody.terms_of_business_sent);
          addReq[configForm.renderForm.reference_id]["idd"] = formatedDate2(
            reqBody.idd
          );
          addReq[configForm.renderForm.reference_id]["menu"] = formatedDate2(
            reqBody.menu
          );
          addReq[configForm.renderForm.reference_id][
            "data_protection_form_sent"
          ] = formatedDate2(reqBody.data_protection_form_sent);
          addReq[configForm.renderForm.reference_id]["risk_profile"] =
            formatedDate2(reqBody.risk_profile);
          addReq[configForm.renderForm.reference_id][
            "verification_of_identity"
          ] = formatedDate2(reqBody.verification_of_identity);
          addReq[configForm.renderForm.reference_id]["research_note"] =
            reqBody.research_note;
          addReq[configForm.renderForm.reference_id]["illustration"] =
            formatedDate2(reqBody.illustration);
          addReq[configForm.renderForm.reference_id]["research_completed"] =
            formatedDate2(reqBody.research_completed);
          addReq[configForm.renderForm.reference_id]["key_features_sent"] =
            formatedDate2(reqBody.key_features_sent);
          addReq[configForm.renderForm.reference_id]["key_features_received"] =
            formatedDate2(reqBody.key_features_received);
          addReq[configForm.renderForm.reference_id][
            "suitability_letter_sent"
          ] = formatedDate2(reqBody.suitability_letter_sent);
          addReq[configForm.renderForm.reference_id]["docs_received"] =
            formatedDate2(reqBody.docs_received);
          addReq[configForm.renderForm.reference_id]["docs_sent"] =
            formatedDate2(reqBody.docs_sent);
        } else {
          addReq[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.customer_id;
          addReq[configForm.renderForm.reference_id]["case_id"] =
            props.activeItem.asset_id;
          addReq[configForm.renderForm.reference_id]["consultant"] =
            reqBody.consultant_id === "" ? "" : getConsultant;
          addReq[configForm.renderForm.reference_id]["start_date"] =
            formatedDate2(reqBody.start_date);
          addReq[configForm.renderForm.reference_id]["end_date"] =
            formatedDate2(reqBody.end_date);
          addReq[configForm.renderForm.reference_id]["amount"] = parseFloat(
            reqBody.amount
          );
          addReq[configForm.renderForm.reference_id]["frequency"] =
            reqBody.frequency;
          addReq[configForm.renderForm.reference_id]["reinvestment"] =
            reqBody.reinvestment;
          addReq[configForm.renderForm.reference_id]["note_text"] =
            reqBody.note_text;
          addReq[configForm.renderForm.reference_id]["payment_type"] =
            reqBody.payment_type;
          if (reqBody.new_business_register) {
            addReq[configForm.renderForm.reference_id][
              "new_business_register"
            ] = reqBody.new_business_register;
          }
        }
      } else if (props.assetActiveTabLevel2 === "withdrawals") {
        addReq[configForm.renderForm.reference_id]["customer_id"] =
          props.activeItem.customer_id;
        addReq[configForm.renderForm.reference_id]["case_id"] =
          props.activeItem.asset_id;
        addReq[configForm.renderForm.reference_id]["start_date"] =
          formatedDate2(reqBody.start_date);
        addReq[configForm.renderForm.reference_id]["end_date"] = formatedDate2(
          reqBody.end_date
        );
        addReq[configForm.renderForm.reference_id]["business_category"] = 2;
        addReq[configForm.renderForm.reference_id]["amount"] =
          reqBody.amount === "" ? 0 : parseFloat(reqBody.amount);
        addReq[configForm.renderForm.reference_id]["frequency"] =
          reqBody.frequency;
        addReq[configForm.renderForm.reference_id]["high_risk"] =
          reqBody.high_risk;
        addReq[configForm.renderForm.reference_id]["high_risk_note"] =
          reqBody.high_risk_note;
        addReq[configForm.renderForm.reference_id]["reinvestment"] =
          reqBody.reinvestment;
        addReq[configForm.renderForm.reference_id]["note_text"] =
          reqBody.note_text;
      } else if (props.assetActiveTabLevel2 === "actions") {
        addReq[configForm.renderForm.reference_id]["customer_id"] =
          props.activeItem.customer_id;
        addReq[configForm.renderForm.reference_id]["case_id"] =
          props.activeItem.asset_id;
        addReq[configForm.renderForm.reference_id]["business_date"] =
          formatedDate2(reqBody.business_date);
        addReq[configForm.renderForm.reference_id]["business_action"] =
          reqBody.business_action;
        addReq[configForm.renderForm.reference_id]["business_assigned"] =
          reqBody.business_assigned;
        addReq[configForm.renderForm.reference_id]["business_status"] =
          reqBody.business_status;
        addReq[configForm.renderForm.reference_id]["business_category"] = 2;
      } else if (props.assetActiveTabLevel2 === "valuations") {
        addReq[configForm.renderForm.reference_id]["customer_id"] =
          props.activeItem.customer_id;
        addReq[configForm.renderForm.reference_id]["case_id"] =
          props.activeItem.asset_id;
        addReq[configForm.renderForm.reference_id]["effective_date"] =
          reqBody.effective_date;
        addReq[configForm.renderForm.reference_id]["valuation_amount"] =
          parseFloat(reqBody.valuation_amount);
        addReq[configForm.renderForm.reference_id]["note_text"] =
          reqBody.note_text;
      } else if (props.assetActiveTabLevel2 === "fund") {
        addReq[configForm.renderForm.reference_id]["customer_id"] =
          props.activeItem.customer_id;
        addReq[configForm.renderForm.reference_id]["case_id"] =
          props.activeItem.asset_id;
        addReq[configForm.renderForm.reference_id]["fund_code"] =
          reqBody.fund_code;
        addReq[configForm.renderForm.reference_id]["fund_code_source"] =
          parseInt(reqBody.fund_code_source);
        addReq[configForm.renderForm.reference_id]["fund_name"] =
          reqBody.fund_name;
        addReq[configForm.renderForm.reference_id]["fund_units"] =
          reqBody.fund_units != null && reqBody.fund_units != ""
            ? parseFloat(reqBody.fund_units)
            : 0;
        addReq[configForm.renderForm.reference_id]["unit_price"] =
          reqBody.unit_price != null && reqBody.unit_price != ""
            ? parseFloat(reqBody.unit_price)
            : 0;
        addReq[configForm.renderForm.reference_id]["currency_code"] =
          reqBody.currency_code;
        addReq[configForm.renderForm.reference_id]["price_date"] =
          formatedDate2(reqBody.price_date);
        if (parseFloat(reqBody.fund_units) && parseFloat(reqBody.unit_price)) {
          addReq[configForm.renderForm.reference_id]["fund_value"] =
            parseFloat(reqBody.fund_units) * parseFloat(reqBody.unit_price);
        } else {
          addReq[configForm.renderForm.reference_id]["fund_value"] =
            reqBody.fund_value != null && reqBody.fund_value != ""
              ? parseFloat(reqBody.fund_value)
              : 0;
        }

        addReq[configForm.renderForm.reference_id]["sector"] = reqBody.sector;
        addReq[configForm.renderForm.reference_id]["total_expense_ratio"] =
          parseFloat(reqBody.total_expense_ratio);

        if (reqBody.risk_rating !== undefined && reqBody.risk_rating !== "") {
          addReq[configForm.renderForm.reference_id]["risk_rating"] =
            reqBody.risk_rating.toString();
        } else {
          addReq[configForm.renderForm.reference_id]["risk_rating"] = "0";
        }
      } else {
      }
    }

    if (props.detailPageMode === "add") {
      setLoading(true);
      setFormError([]);
      if (props.assetActiveTabLevel2 === "payment") {
        if (props.assetActiveTabLevel2Subtab === "paymentCommission") {
          apiServices.commission
            .addCommission(addReq)
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");

                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else if (
          props.assetActiveTabLevel2Subtab === "commissionDistribution"
        ) {
          const addValue = Object.values(addReq)[0];
          apiServices.distribution
            .add(addValue)
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");

                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else {
          apiServices.Payments.addPayment(addReq)
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        }
      } else if (props.assetActiveTabLevel2 === "withdrawals") {
        apiServices.Withdrawals.add(addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else if (props.assetActiveTabLevel2 === "valuations") {
        apiServices.valuation
          .addvaluation(addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else if (props.assetActiveTabLevel2 === "actions") {
        apiServices.Actions.addActions(addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else if (props.assetActiveTabLevel2 === "fund") {
        apiServices.fund
          .add(addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else {
      }
      //  add api
    } else if (props.detailPageMode === "edit") {
      setLoading(true);
      setFormError([]);
      if (props.assetActiveTabLevel2 === "payment") {
        if (props.assetActiveTabLevel2Subtab === "paymentCommission") {
          apiServices.commission
            .updateCommission(props.assetSubActiveItem.commission_id, addReq)
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else if (
          props.assetActiveTabLevel2Subtab === "commissionDistribution"
        ) {
          const updateValue = Object.values(addReq)[0];
          apiServices.distribution
            .update(props.assetSubActiveItem.distribution_id, updateValue)
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");

                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else {
          apiServices.Payments.updatePayment(
            props.assetSubActiveItem.payment.payment_id,
            addReq
          )
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        }
      } else if (props.assetActiveTabLevel2 === "withdrawals") {
        apiServices.Withdrawals.update(
          props.assetSubActiveItem.withdrawal_id,
          addReq
        )
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");

              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })

          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else if (props.assetActiveTabLevel2 === "valuations") {
        apiServices.valuation
          .updatevaluation(props.assetSubActiveItem.valuation_id, addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else if (props.assetActiveTabLevel2 === "actions") {
        apiServices.Actions.updateActions(
          props.assetSubActiveItem.business_id,
          addReq
        )
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })

          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else if (props.assetActiveTabLevel2 === "fund") {
        apiServices.fund
          .update(props.assetSubActiveItem.fund_id, addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })

          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else {
      }
    } else {
    }
  };

  return (
    <>
      <OverlayLoader isLoading={loading} />
      {context && (
        <>
          {props.mode && props.mode === "view" && (
            <>
              {props.assetActiveTabLevel2 === "fund" ? (
                <FundCalculation calculatedData={props.fundValue} />
              ) : null}
              {props.assetActiveTabLevel2 === "withdrawals" ? (
                <WithdrawalCalculation calculatedData={props.withdrawalValue} />
              ) : null}
              {props.assetActiveTabLevel2 === "payment" ? (
                <PaymentCalculation calculatedData={props.paymentValue} />
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div>
                  Record {props?.currentSubRecord} of{" "}
                  {props.assetSubitemsList.length}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {checkUserPermission(
                      loggedUser,
                      UserPermissionsList.ALLOW_UPDATE_CLIENT
                    ) && (
                      <Button
                        style={{ marginRight: 2, marginBottom: 6 }}
                        icon={<Edit16Filled />}
                        size="small"
                        onClick={() => {
                          props.setDetailPageMode("edit");
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <div>
                    {checkUserPermission(
                      loggedUser,
                      UserPermissionsList.ALLOW_UPDATE_CLIENT
                    ) && (
                      <Button
                        icon={<Delete16Regular />}
                        style={{
                          marginRight: 2,
                          marginBottom: 6,
                          color: "red",
                        }}
                        size="small"
                        onClick={() => {
                          setDeletePopup(true);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <DeletePopup
            deletePopup={deletePopup}
            setDeletePopup={setDeletePopup}
            deleteData={deleteData}
          />
          <DeleteDependencyPopup
            dependencyDeletePopup={dependencyDeletePopup}
            dependencymessage={dependencymessage}
            setDependencyDeletePopup={setDependencyDeletePopup}
          />
          {formError && formError.length > 0 && (
            <FormErrorMessage
              errorMessages={formError}
              onDismiss={() => setFormError([])}
            />
          )}
          {["edit", "add"].includes(props?.mode || "") && (
            <div className={classes.btnwrapper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Label
                  size="medium"
                  weight="semibold"
                  className={classes.message}
                >
                  {formMode !== "" && (
                    <>
                      <span>**</span> {formMode} <span>**</span>
                    </>
                  )}
                </Label>
              </div>

              <div>
                {props.assetActiveTabLevel2Subtab ===
                "commissionDistribution" ? (
                  <>
                    <Button
                      appearance="secondary"
                      size="medium"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        dispatch(
                          setDialogModalOptions({
                            open: true,
                            rule: true,
                            ruleId: ruleId,
                            getRuleId: getRuleId,
                            cancel: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({ open: false })
                                );
                              },
                              label: "Cancel",
                            },
                            no: {
                              onclick: () => {
                                // setSeletedRule(selectedruleItem);
                                dispatch(
                                  setDialogModalOptions({ open: false })
                                );
                              },
                              label: "Select",
                              type: "primary",
                            },
                          })
                        );
                      }}
                    >
                      Choose Distribution Rule
                    </Button>
                    <Button
                      appearance="secondary"
                      size="medium"
                      style={{ marginRight: "10px" }}
                    >
                      Stop Distribution
                    </Button>
                  </>
                ) : (
                  ""
                )}

                <Button
                  icon={<ArrowUndo16Regular />}
                  onClick={undo}
                  disabled={!isFormDataUpdated}
                ></Button>
                <Button
                  icon={<ArrowRedo16Regular />}
                  onClick={redo}
                  disabled={future.length === 0 ? true : false}
                ></Button>
              </div>
              <Button
                disabled={!isFormDataUpdated}
                appearance="transparent"
                onClick={() => {
                  formik.resetForm();
                  dispatch(resetFormData());
                }}
              >
                Reset All
              </Button>
              {props.mode &&
                props.mode === "edit" &&
                props.showListItems === false && (
                  <Button
                    appearance="transparent"
                    icon={<Delete16Regular />}
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          content_line_1: "Do you want to delete?",
                          no: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "No",
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Yes",
                            type: "primary",
                          },
                        })
                      );
                    }}
                  ></Button>
                )}
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            <FormContent
              {...{
                formik,
                formFieldData,
                title: "",
                mode: props.mode,
                optionList,
                handleOnChange,
                customHandleChange,
                context,
                selectFieldOptions,
                newSelectOptionAdded,
                updateConsultantName,
                updateCommissionType,
                activePartnerId: props.activePartnerId,
                setActivePartnerId: props.setActivePartnerId,
              }}
            />

            {props.mode !== "view" && (
              <div className={classes.buttonWrapper}>
                <Button
                  className="asc-button-primary"
                  appearance="primary"
                  onClick={() => {
                    if (isFormDataUpdated) {
                      formik.handleSubmit();
                    }
                  }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    if (isFormDataUpdated) {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          content_line_1:
                            "We have detected an attempt to move away from the current page.",
                          content_line_2:
                            "This would cause all changes made to be lost",
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                              dispatch(resetFormData());
                              props.assetSubitemsList.length === 0
                                ? props.setMode("")
                                : props.setMode("view");
                            },
                            label: "Leave the Page",
                          },

                          no: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Stay on Page",
                            type: "primary",
                          },
                        })
                      );
                    } else {
                      formik.resetForm();
                      props.assetSubitemsList.length === 0
                        ? props.setMode("")
                        : props.setMode("view");
                    }
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FormContainerDetailPage;
