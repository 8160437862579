import React, { useState } from "react";
import DistributinList from "./DistributionList";
import { Button, Input, Label } from "@fluentui/react-components";
import DistributionList from "./DistributionList";

const DistributionDatalist = () => {
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Filters");
  const [filterPopup, setFilterPopup] = useState(false);
  const [amountData, setAmountData] = useState("");
  return (
    <div className="page-content-area">
      <div className="acionlist_filter">
        
      </div>
      <DistributionList
        setFilterPopup={setFilterPopup}
        filterPopup={filterPopup}
        selectedMenuItem={selectedMenuItem}
        selectedProviders={selectedProviders}
      />
    </div>
  );
};

export default DistributionDatalist;
