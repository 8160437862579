import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../service";

interface TimeAllocationProps {
  data: Record<string, any>[]; // Array of objects
  count: number;
  activeItem: Record<string, any> | null;
  loading: boolean;
}

const initialState: TimeAllocationProps = {
  data: [],
  count: 0,
  activeItem: {},
  loading: false,
};

// Fetch time allocation list
export const getTimeAllocationList = createAsyncThunk(
  "timeAllocation/getTimeAllocationList",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await apiServices.timeAllocation.getList(id);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

// Delete time allocation item
export const deleteTimeAllocation = createAsyncThunk(
  "timeAllocation/deleteTimeAllocation",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      await apiServices.timeAllocation.delete(id);
      return id; // Return the deleted ID
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const timeAllocationSlice = createSlice({
  name: "timeAllocation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTimeAllocationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTimeAllocationList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload || [];
      })
      .addCase(getTimeAllocationList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteTimeAllocation.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (item) => item.timeallocation_id !== action.payload
        );
      });
  },
});

export default timeAllocationSlice.reducer;
