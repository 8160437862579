import React, { useEffect, useState } from "react";
import ReceiptList from "./ReceiptList";
import dammyData from "./Dammydata.json";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { setlistItems } from "../../../redux/Commission/commissionSlice";
const List = () => {
  // const [listItems, setlistItems] = useState<any>([]);
  const [optionDate, setOptionDate] = useState("includealldate");
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [resopse, setResopnse] = useState<any>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Filters");
  const [filterPopup, setFilterPopup] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  function dateSelection(item: any) {
    setOptionDate(item);
  }

  useEffect(() => {
    setResopnse(dammyData);
  }, []);
  useEffect(() => {
    getReceiptlistDta();
  });
  // Receipt List data mapping

  const getReceiptlistDta = () => {
    const resultData = resopse.map((object: any) => {
      let newItem;
      if (!object.category) {
        return null; // Skip objects without a category
      }
      if (object.category === "otherfees") {
        newItem = {
          ...object,
          expected: object.amount_to_company || "0.0000",
          actual: object.amount_to_company || "0.0000",
          provider: object.provider || "",
          category: object.category || "",
          client_name: object.first_names & object.last_name || "",
          reference: object.category || "",
          due_date: object.category || "",
          commission_type: object.commission_type || "",
          commission_note: object.note_text || "",
          case_owner: object.case_owner || "",
          type: object.category || "",
          subtype: object.category || "",
          consutant: object.commission_type || "",
          fund_value: object.note_text || "",
          value_date: object.case_owner || "",
          benifit: object.commission_type || "",
          periodic_benefit: object.note_text || "",
          ben_freq: object.case_owner || "",
          payment: object.category || "",
          frequency: object.category || "",
          start_date: object.commission_type || "",
        };
      } else if (object.category === "liability") {
        newItem = {
          ...object,
          expected: object.amount_to_company || "0.0000",
          actual: object.amount_to_company || "0.0000",
          provider: object.provider || "",
          category: object.category || "",
          client_name: object.first_names & object.last_name || "",
          reference: object.category || "",
          due_date: object.category || "",
          commission_type: object.calculated_date || "",
          commission_note: object.note_text || "",
          case_owner: object.case_owner || "",
          type: object.category || "",
          subtype: object.category || "",
          consutant: object.commission_type || "",
          fund_value: object.note_text || "",
          value_date: object.case_owner || "",
          benifit: object.commission_type || "",
          periodic_benefit: object.note_text || "",
          ben_freq: object.case_owner || "",
          payment: object.category || "",
          frequency: object.category || "",
          start_date: object.calculated_date || "",
        };
      }
      return newItem;
    });

    // Filter out any null values from skipped entries
    dispatch(setlistItems(resultData.filter((item: any) => item !== null)));
  };

  return (
    <div className="page-content-area">
      <ReceiptList
        // listItems={listItems}
        setFilterPopup={setFilterPopup}
        filterPopup={filterPopup}
        selectedMenuItem={selectedMenuItem}
        selectedProviders={selectedProviders}
        getReceiptlistDta={getReceiptlistDta}
      />
    </div>
  );
};

export default List;
