import React, { useEffect, useState } from "react";
import { Checkbox } from "@fluentui/react-components";
import type { CheckboxProps } from "@fluentui/react-components";
import {
  FlatTree,
  TreeItem,
  TreeItemLayout,
  HeadlessFlatTreeItemProps,
  useHeadlessFlatTree_unstable,
  Tree,
} from "@fluentui/react-components/unstable";

import apiServices from "../../service";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
const SELECTION_MODE = "multiselect"; // change to "single" for single selection

type CustomItem = HeadlessFlatTreeItemProps & {
  content: string;
  description?: string;
  dueInterval?: Number;
  dueBy?: string;
  assigned?: string;
};

const LoadStandardAction = (props: any) => {
  const [selectedItem, setSelectedItem] = useState<CustomItem[]>([]);
  const [standardActions, setStandardActions] = useState([]);

  useEffect(() => {
    if (props.type === "case") {
      const getCaseDetails = async () => {
        try {
          const response = await apiServices.tackingActions.getList();
          let caseData: CustomItem[] = []; // Specify the type here
          if (response.data && response.data.data) {
            let actionData: any = [];
            Object.entries(response.data.data).forEach(
              ([category, items]: any) => {
                caseData.push({
                  value: category,
                  content: category,
                });
                actionData.push({
                  category: category,
                  items: items,
                });

                items.forEach((item: any) => {
                  caseData.push({
                    value: item.id,
                    content: item.case_type,
                    parentValue: category,
                    description: item.description,
                    dueInterval: item.due_interval,
                    // dueBy:
                    assigned: item.assigned,
                  });
                });
              }
            );
            setSelectedItem(caseData);
            setStandardActions(actionData);
          }
        } catch (error) {
          console.error("Error fetching case details:", error);
        }
      };
      getCaseDetails();
    } else {
      const getClientDetails = async () => {
        try {
          const response = await apiServices.tackingActions.getClient();
          let clientData: CustomItem[] = []; // Specify the type here
          if (response.data && response.data.data) {
            let actionData: any = [];
            Object.entries(response.data.data).forEach(
              ([category, items]: any) => {
                clientData.push({
                  value: category,
                  content: category,
                });
                actionData.push({
                  category: category,
                  items: items,
                });

                items.forEach((item: any) => {
                  clientData.push({
                    value: item.id,
                    content: item.case_type,
                    parentValue: category,
                    description: item.description,
                    dueInterval: item.due_interval,
                    // dueBy:
                    assigned: item.assigned,
                  });
                });
              }
            );
            setSelectedItem(clientData);
            setStandardActions(actionData);
          }
        } catch (error) {
          console.error("Error fetching case details:", error);
        }
      };
      getClientDetails();
    }
  }, []);
  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };

  return (
    <div className="load-standard-main">
      <div className="load-standard-sub-level">
        <p>
          Enter the Effective Date, then use the Action List Tree to select the
          set of standard actions you want to use.
        </p>
        <p>Click the Save button to create the action record</p>
        <p>
          Effective Date :
          <span style={{ padding: "10px" }}>
            <DatePicker
              as="input"
              className=""
              placeholder=""
              allowTextInput
              formatDate={onFormatDate}
              onSelectDate={(date: any) =>
                props.setLoadStandardActionDate(date)
              }
              value={props.loadStandardActionDate || new Date()}
            />
          </span>
        </p>
      </div>
      {props.selectedLoadStandardActions &&
        standardActions &&
        standardActions.length > 0 && (
          <div>
            <Tree aria-label="Default">
              {standardActions.map((row: any) => (
                <>
                  <TreeItem itemType="branch">
                    <TreeItemLayout>{row.category}</TreeItemLayout>
                    {row.items && row.items.length > 0 && (
                      <Tree>
                        {row.items.map((item: any) => (
                          <TreeItem itemType="leaf">
                            <TreeItemLayout>
                              <div className="load-action-container">
                                <div>
                                  <Checkbox
                                    size="large"
                                    value={item.id}
                                    checked={
                                      props.selectedLoadStandardActions.includes(
                                        item.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(ev: any, data: any) => {
                                      if (props.loadActionSelected) {
                                        props.loadActionSelected(
                                          ev.target.value
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <div className="loadDetail-container">
                                  <p>
                                    Description:
                                    <span>{item.description}</span>
                                  </p>
                                  <p>
                                    Due Interval:
                                    <span>{item.due_interval}</span>
                                  </p>
                                  <p>
                                    Due By:
                                    <span>
                                      {props.loadStandardActionDate &&
                                        item.due_interval &&
                                        moment(props.loadStandardActionDate)
                                          .add(item.due_interval, "days")
                                          .format("DD/MM/YYYY")}
                                      {!props.loadStandardActionDate &&
                                        moment(new Date())
                                          .add(item.due_interval, "days")
                                          .format("DD/MM/YYYY")}
                                    </span>
                                  </p>
                                  <p>
                                    Assigned:
                                    <span>{item.assigned}</span>
                                  </p>
                                </div>
                              </div>
                            </TreeItemLayout>
                          </TreeItem>
                        ))}
                      </Tree>
                    )}
                  </TreeItem>
                </>
              ))}
            </Tree>
          </div>
        )}
    </div>
  );
};

export default LoadStandardAction;
