import React, { ChangeEvent, useEffect, useState } from "react";
import CaseNavList from "./CaseNavList";
import CaseFormContainer from "./CaseFormContainer";
import {
  caseContextList,
  formatedDate2,
  getAssetFundCodeSource,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  makeStyles,
  shorthands,
  tokens,
  TabList,
  Tab,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Subtitle2,
  Caption1,
  Caption1Strong,
} from "@fluentui/react-components";
const CaseForm = (props: any) => {
  console.log("props in case form", props);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const { clientId, action, caseId } = useParams();
  const location = useLocation();
  const [formContext, setFormContext] = useState("");
  const [caseType, setCaseType] = React.useState("");
  const [activeTab, setActiveTab] = useState("");
  const [initialFormData, setInitialFormData] = useState({});
  const [formAction, setFormAction] = React.useState("");
  const [currentRecord, setCurrentRecord] = useState(1);

  useEffect(() => {
    if (location.state?.caseType) {
      setCaseType(location.state.caseType);
    }
  }, [location]);
  useEffect(() => {
    if (props.caseType !== undefined) {
      setCaseType(props.caseType);
    }
  }, [props.caseType]);
  useEffect(() => {
    if (props.tab) {
      setActiveTab(props.tab);
    }
  }, [props.tab]);
  useEffect(() => {
    if (action) {
      setFormAction(action);
    } else {
      setFormAction("");
    }
  }, [action]);

  useEffect(() => {
    if (props.section !== undefined) {
      switch (props.section) {
        case "assets":
          if (props.tab) {
            setActiveTab(props.tab ? props.tab : "asset");
          } else {
            if (props.subItem) {
              setActiveTab(props.subItem);
            } else {
              setActiveTab("asset");
            }
          }

          break;
        case "liabilities":
          if (props.tab) {
            setActiveTab(props.tab ? props.tab : "liability");
          } else {
            if (props.subItem) {
              setActiveTab(props.subItem);
            } else {
              setActiveTab("liability");
            }
          }

          break;
        case "policies":
          if (props.tab) {
            setActiveTab(props.tab ? props.tab : "policy");
          } else {
            if (props.subItem) {
              setActiveTab(props.subItem);
            } else {
              setActiveTab("policy");
            }
          }

          break;
        default:
          setActiveTab("");
          break;
      }
    }
  }, [props.section, caseType, props.subItem]);
  useEffect(() => {
    getFormContext();
    if (props.caseDetail) {
      if (activeTab === "asset") {
        setInitialFormData(setAssetFieldValues(props.caseDetail));
      } else if (activeTab === "review") {
        setInitialFormData(setAssetReviewFieldValues(props.caseDetail));
      } else if (activeTab === "fund") {
        setInitialFormData(setFundFieldValues(props.caseDetail));
      } else if (activeTab === "payment") {
        setInitialFormData(setPaymentFieldValues(props.caseDetail));
      } else if (activeTab === "withdrawal") {
        setInitialFormData(setWithdrawalsFieldValues(props.caseDetail));
      } else if (activeTab === "valuation") {
        setInitialFormData(setValuationsFieldValues(props.caseDetail));
      } else if (activeTab === "action") {
        setInitialFormData(setActionsFieldValues(props.caseDetail));
      } else if (activeTab === "compliance") {
        setInitialFormData(setPaymentComplainceFieldValues(props.caseDetail));
      } else if (activeTab === "commission") {
        setInitialFormData(setcommissionFieldValues(props.caseDetail));
      } else if (activeTab === "distribution") {
        setInitialFormData(setDistributionFieldValues(props.caseDetail));
      } else if (activeTab === "liability") {
        setInitialFormData(setLiabilityFieldValues(props.caseDetail));
      } else if (activeTab === "policy") {
        setInitialFormData(setPolicyFieldValues(props.caseDetail));
      }
    }
  }, [activeTab, caseType, props.caseDetail, props.mode]);

  const getFormContext = () => {
    if (props.section && caseType && activeTab) {
      const context =
        caseContextList[props.section]?.[caseType]?.[activeTab] || "";
      setFormContext(context);
    } else {
      setFormContext("");
    }
  };
  const setAssetFieldValues = (params: any) => {
    let paramVal = {};
    if (params?.case_type === "Unit-linked") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          props.mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        investment_type: params.investment_type ? params.investment_type : "",
        investment_subtype: params.investment_subtype
          ? params.investment_subtype
          : "",
        provider_id: params.provider_id ? params.provider_id : "",
        investment_provider: params.investment_provider
          ? params.investment_provider
          : "",
        investment_account_number: params.investment_account_number
          ? params.investment_account_number
          : "",
        current_value: params.current_value ? params.current_value : "",
        value_date: params.value_date ? params.value_date : "",
        purpose: params.purpose ? params.purpose : "",
        investment_start_date: params.investment_start_date
          ? params.investment_start_date
          : "",
        investment_end_date: params.investment_end_date
          ? params.investment_end_date
          : "",
        investment_status: params.investment_status
          ? params.investment_status
          : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Shares") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          props.mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        share_type: params.share_type ? params.share_type : "",
        share_subtype: params.share_subtype ? params.share_subtype : "",
        provider_id: params.provider_id ? params.provider_id : "",
        share_company: params.share_company ? params.share_company : "",
        share_current_number: params.share_current_number
          ? params.share_current_number
          : "",
        share_current_price: params.share_current_price
          ? params.share_current_price
          : "",
        current_value: params.current_value ? params.current_value : "",

        value_date: params.value_date ? params.value_date : "",
        purpose: params.purpose ? params.purpose : "",
        share_date_acquired: params.share_date_acquired
          ? params.share_date_acquired
          : "",
        share_original_value: params.share_original_value
          ? params.share_original_value
          : "",
        share_status: params.share_status ? params.share_status : "",

        service_status: params.service_status ? params.service_status : "",
        status_date: params.status_date ? params.status_date : "",

        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Home/Personal") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          props.mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        home_description: params.home_description
          ? params.home_description
          : "",
        home_date_acquired: params.home_date_acquired
          ? params.home_date_acquired
          : "",
        home_original_value: params.home_original_value
          ? params.home_original_value
          : "",
        current_value: params.current_value ? params.current_value : "",
        value_date: params.value_date ? params.value_date : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        purpose: params.purpose ? params.purpose : "",
        service_status: params.service_status ? params.service_status : "",
        status_date: params.status_date ? params.status_date : "",
      };
    } else if (params?.case_type === "Banks/B Societies") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          props.mode == "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        bank_account_type: params.bank_account_type
          ? params.bank_account_type
          : "",
        bank_name: params.bank_name ? params.bank_name : "",
        bank_sort_code: params.bank_sort_code ? params.bank_sort_code : "",

        bank_account_name: params.bank_account_name
          ? params.bank_account_name
          : "",
        bank_account_number: params.bank_account_number
          ? params.bank_account_number
          : "",

        current_value: params.current_value ? params.current_value : "",

        value_date: params.value_date ? params.value_date : "",
        purpose: params.purpose ? params.purpose : "",

        bank_interest_rate: params.bank_interest_rate
          ? params.bank_interest_rate
          : "",

        bank_date_opened: params.bank_date_opened
          ? params.bank_date_opened
          : "",
        bank_status: params.bank_status ? params.bank_status : "",

        service_status: params.service_status ? params.service_status : "",
        status_date: params.status_date ? params.status_date : "",

        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    }

    return paramVal;
  };
  const setAssetReviewFieldValues = (params: any) => {
    let paramVal = {};
    paramVal = {
      ...paramVal,
      review_assigned: params?.review_assigned || "",
      review_completed: params?.review_completed || "",
      review_date: params?.review_date || "",
      review_interval: params?.review_interval || "",
      review_reason: params?.review_reason || "",
    };
    return paramVal;
  };
  const setFundFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        fund_code: params.fund_code ? params.fund_code : "",
        fund_code_source:
          props.mode === "view"
            ? getAssetFundCodeSource(params.fund_code_source)
            : params?.fund_code_source?.toString(),
        fund_name: params.fund_name ? params.fund_name : "",
        fund_units: params.fund_units ? parseFloat(params.fund_units) : "",
        unit_price: params.unit_price ? parseFloat(params.unit_price) : 0.0,
        currency_code: params.currency_code ? params.currency_code : "",
        price_date: params.price_date ? params.price_date : "",
        fund_value: params.fund_value ? parseFloat(params.fund_value) : "",
        sector: params.sector ? params.sector : "",
        total_expense_ratio: params.total_expense_ratio
          ? parseFloat(params.total_expense_ratio)
          : "",
        risk_rating: params.risk_rating ? params.risk_rating : "",
      };
    }

    return ParamVal;
  };
  const setPaymentFieldValues = (params: any) => {
    let paymentParamVal: any = {};
    if (params?.payment !== undefined) {
      paymentParamVal = {
        ...paymentParamVal,
        start_date: params.payment.start_date ? params.payment.start_date : "",
        end_date: params.payment.end_date ? params.payment.end_date : "",
        amount: params.payment.amount ? params.payment.amount : "",
        frequency: params.payment.frequency ? params.payment.frequency : "",
        new_business_register: params.payment.new_business_register
          ? params.payment.new_business_register
          : "",
        reinvestment: params.payment.reinvestment
          ? params.payment.reinvestment
          : "",
        note_text: params.payment.note_text ? params.payment.note_text : "",
        payment_type: params.payment.payment_type
          ? params.payment.payment_type
          : "",
      };
      if (props.section === "policies") {
        paymentParamVal.payer = params.payment.payer || "";
      }
    }
    return paymentParamVal;
  };
  const setWithdrawalsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        amount: params.amount ? params.amount : "",
        frequency: params.frequency ? params.frequency : "",
        high_risk: params.high_risk ? params.high_risk : "",
        high_risk_note: params.high_risk_note ? params.high_risk_note : "",
        reinvestment: params.reinvestment ? params.reinvestment : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setValuationsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,

        effective_date: params.effective_date ? params.effective_date : "",
        valuation_amount: params.valuation_amount
          ? params.valuation_amount
          : "",

        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setPaymentComplainceFieldValues = (params: any) => {
    let paymentComplainceParamVal = {};
    if (params.payment_complience !== undefined) {
      paymentComplainceParamVal = {
        ...params.payment_complience,
        consultant: params.payment_complience.consultant
          ? params.payment_complience.consultant
          : "",
      };
    }
    return paymentComplainceParamVal;
  };
  const setcommissionFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        status: params.status ? params.status : "",
        commission_type: params.commission_type ? params.commission_type : "",
        payment_source: params.payment_source ? params.payment_source : "",
        frequency: params.frequency ? params.frequency : "",
        amount_to_company: params.amount_to_company
          ? params.amount_to_company
          : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        // nb_register: params.nb_register ? params.nb_register : "",
        nb_register:
          props.mode == "view"
            ? getYes_NoConversion(params.nb_register)
            : params.nb_register,
        payment_count: params.payment_count ? params.payment_count : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };

  const setDistributionFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        commission_id: params.commission_id ? params.commission_id : "",
        customer_id: params.customer_id ? params.customer_id : "",
        status: params.status ? params.status : "",
        party_type: params.party_type ? params.party_type : "",
        party_name: params.party_name ? params.party_name : "",
        case_type: params.case_type ? params.case_type : "",
        current_rule: params.current_rule ? params.current_rule : "",
        commission_rule_id: params.commission_rule_id
          ? params.commission_rule_id
          : "",
        amount: params.amount ? params.amount : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };

  const setActionsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        business_date: params.business_date ? params.business_date : "",
        business_action: params.business_action ? params.business_action : "",
        business_assigned: params.business_assigned
          ? params.business_assigned
          : "",
        business_status: params.business_status ? params.business_status : "",
      };
    }
    return ParamVal;
  };
  const setPolicyFieldValues = (params: any) => {
    let paramVal = {};

    if (params?.case_type === "Life Assurance") {
      paramVal = {
        ...paramVal,
        policy_holder:
          props.mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder.toString(),
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        purpose: params.purpose ? params.purpose : "",
        link_to_mortgage:
          props.mode == "view"
            ? getYes_NoConversion(params.link_to_mortgage)
            : params.link_to_mortgage,
        // link_to_mortgage: params.link_to_mortgage
        //   ? params.link_to_mortgage
        //   : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        withdrawal_note: params.withdrawal_note ? params.withdrawal_note : "",
        surrender_value: params.surrender_value ? params.surrender_value : "",
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",

        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? parseFloat(params.lump_sum_benefit)
          : "",

        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Pensions") {
      paramVal = {
        ...paramVal,
        policy_holder:
          props.mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder.toString(),
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        contracted_out: params.contracted_out ? params.contracted_out : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        transfer_value: params.transfer_value || "",
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        expression_of_wish: params.expression_of_wish
          ? params.expression_of_wish
          : "",
        expression_of_wishnote: params.expression_of_wishnote
          ? params.expression_of_wishnote
          : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Investments") {
      paramVal = {
        ...paramVal,
        policy_holder:
          props.mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder.toString(),
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        surrender_value: params.surrender_value ? params.surrender_value : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        transfer_value: parseInt(params.transfer_value) || 0,
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "Savings Plans") {
      paramVal = {
        ...paramVal,
        policy_holder:
          props.mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder.toString(),
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        surrender_value: params.surrender_value ? params.surrender_value : "",
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "Income Protection") {
      paramVal = {
        ...paramVal,
        policy_holder:
          props.mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder.toString(),
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        deffered_period: params.deffered_period ? params.deffered_period : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "Health Assurance") {
      paramVal = {
        ...paramVal,
        policy_holder:
          props.mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "General") {
      paramVal = {
        ...paramVal,
        policy_holder:
          props.mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder.toString(),
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    }
    return paramVal;
  };
  const setLiabilityFieldValues = (params: any) => {
    let paramVal = {};
    if (params?.case_type === "Mortgages") {
      paramVal = {
        ...paramVal,

        joint_indicator:
          props.mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,

        borrower: params.borrower ? params.borrower : "",
        borrower_name1: params.borrower_name1 ? params.borrower_name1 : "",
        borrower_name2: params.borrower_name2 ? params.borrower_name2 : "",
        liability_type: params.liability_type ? params.liability_type : "",
        subtype: params.subtype ? params.subtype : "",
        repayment_method: params.repayment_method
          ? params.repayment_method
          : "",
        purpose: params.purpose ? params.purpose : "",
        provider: params.provider ? params.provider : "",
        provider_id: params.provider_id ? params.provider_id : "",
        account_number: params.account_number ? params.account_number : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        amount_borrowed: params.amount_borrowed ? params.amount_borrowed : "",
        amount_outstanding: params.amount_outstanding
          ? params.amount_outstanding
          : "",

        amount_outstanding_date: params.amount_outstanding_date
          ? params.amount_outstanding_date
          : "",
        interest_rate: params.interest_rate ? params.interest_rate : "",
        covered: params.covered ? params.covered : "",
        interest_basis: params.interest_basis ? params.interest_basis : "",
        mortgage_fixed_rate_end: params.mortgage_fixed_rate_end
          ? params.mortgage_fixed_rate_end
          : "",
        status: params.status ? params.status : "",
        status_date:
          props.mode === "edit"
            ? formatedDate2(params.status_date)
            : params.status_date,
        property_value: params.property_value ? params.property_value : "",
        loan_valuation_percentage: params.loan_valuation_percentage
          ? params.loan_valuation_percentage
          : "",

        property_address1: params.property_address1
          ? params.property_address1
          : "",
        property_address2: params.property_address2
          ? params.property_address2
          : "",
        property_address3: params.property_address3
          ? params.property_address3
          : "",
        property_town: params.property_town ? params.property_town : "",
        property_county: params.property_county ? params.property_county : "",
        property_country: params.property_country
          ? params.property_country
          : "",
        property_postcode: params.property_postcode
          ? params.property_postcode
          : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_correspondence:
          props.mode === "edit"
            ? params.provider_correspondence
            : props.caseDetail?.provider_name &&
              props.caseDetail.provider_name !== "undefined"
            ? props.caseDetail.provider_name
            : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_address1: params.provider_address1
          ? params.provider_address1
          : "",
        provider_address2: params.provider_address2
          ? params.provider_address2
          : "",
        provider_address3: params.provider_address3
          ? params.provider_address3
          : "",
        provider_town: params.provider_town ? params.provider_town : "",
        provider_county: params.provider_county ? params.provider_county : "",
        provider_country: params.provider_country
          ? params.provider_country
          : "",
        provider_postcode: params.provider_postcode
          ? params.provider_postcode
          : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Loans/Lease/HP") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          props.mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,

        borrower: params.borrower ? params.borrower : "",
        borrower_name1: params.borrower_name1 ? params.borrower_name1 : "",
        borrower_name2: params.borrower_name2 ? params.borrower_name2 : "",
        liability_type: params.liability_type ? params.liability_type : "",
        subtype: params.subtype ? params.subtype : "",
        repayment_method: params.repayment_method
          ? params.repayment_method
          : "",
        purpose: params.purpose ? params.purpose : "",
        provider: params.provider ? params.provider : "",
        provider_id: params.provider_id ? params.provider_id : "",
        account_number: params.account_number ? params.account_number : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        amount_borrowed: params.amount_borrowed ? params.amount_borrowed : "",
        amount_outstanding: params.amount_outstanding
          ? params.amount_outstanding
          : "",

        amount_outstanding_date: params.amount_outstanding_date
          ? params.amount_outstanding_date
          : "",
        interest_rate: params.interest_rate ? params.interest_rate : "",
        interest_basis: params.interest_basis ? params.interest_basis : "",
        mortgage_fixed_rate_end: params.mortgage_fixed_rate_end
          ? params.mortgage_fixed_rate_end
          : "",
        status: params.status ? params.status : "",
        status_date:
          props.mode === "edit"
            ? formatedDate2(params.status_date)
            : params.status_date,
        covered: params.covered ? params.covered : "",

        property_value: params.property_value ? params.property_value : "",
        loan_valuation_percentage: params.loan_valuation_percentage
          ? params.loan_valuation_percentage
          : "",

        property_address1: params.property_address1
          ? params.property_address1
          : "",
        property_address2: params.property_address2
          ? params.property_address2
          : "",
        property_address3: params.property_address3
          ? params.property_address3
          : "",
        property_town: params.property_town ? params.property_town : "",
        property_county: params.property_county ? params.property_county : "",
        property_country: params.property_country
          ? params.property_country
          : "",
        property_postcode: params.property_postcode
          ? params.property_postcode
          : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_address1: params.provider_address1
          ? params.provider_address1
          : "",
        provider_address2: params.provider_address2
          ? params.provider_address2
          : "",
        provider_address3: params.provider_address3
          ? params.provider_address3
          : "",
        provider_town: params.provider_town ? params.provider_town : "",
        provider_county: params.provider_county ? params.provider_county : "",
        provider_country: params.provider_country
          ? params.provider_country
          : "",
        provider_postcode: params.provider_postcode
          ? params.provider_postcode
          : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Credit Cards") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          props.mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        borrower: params.borrower ? params.borrower : "",
        borrower_name1: params.borrower_name1 ? params.borrower_name1 : "",
        borrower_name2: params.borrower_name2 ? params.borrower_name2 : "",
        liability_type: params.liability_type ? params.liability_type : "",
        subtype: params.subtype ? params.subtype : "",
        repayment_method: params.repayment_method
          ? params.repayment_method
          : "",
        purpose: params.purpose ? params.purpose : "",
        provider: params.provider ? params.provider : "",
        provider_id: params.provider_id ? params.provider_id : "",
        account_number: params.account_number ? params.account_number : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        amount_borrowed: params.amount_borrowed ? params.amount_borrowed : "",
        amount_outstanding: params.amount_outstanding
          ? params.amount_outstanding
          : "",

        amount_outstanding_date: params.amount_outstanding_date
          ? params.amount_outstanding_date
          : "",
        interest_rate: params.interest_rate ? params.interest_rate : "",
        interest_basis: params.interest_basis ? params.interest_basis : "",
        mortgage_fixed_rate_end: params.mortgage_fixed_rate_end
          ? params.mortgage_fixed_rate_end
          : "",
        status: params.status ? params.status : "",
        status_date:
          props.mode === "edit"
            ? formatedDate2(params.status_date)
            : params.status_date,
        covered: params.covered ? params.covered : "",

        property_value: params.property_value ? params.property_value : "",
        loan_valuation_percentage: params.loan_valuation_percentage
          ? params.loan_valuation_percentage
          : "",

        property_address1: params.property_address1
          ? params.property_address1
          : "",
        property_address2: params.property_address2
          ? params.property_address2
          : "",
        property_address3: params.property_address3
          ? params.property_address3
          : "",
        property_town: params.property_town ? params.property_town : "",
        property_county: params.property_county ? params.property_county : "",
        property_country: params.property_country
          ? params.property_country
          : "",
        property_postcode: params.property_postcode
          ? params.property_postcode
          : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_address1: params.provider_address1
          ? params.provider_address1
          : "",
        provider_address2: params.provider_address2
          ? params.provider_address2
          : "",
        provider_address3: params.provider_address3
          ? params.provider_address3
          : "",
        provider_town: params.provider_town ? params.provider_town : "",
        provider_county: params.provider_county ? params.provider_county : "",
        provider_country: params.provider_country
          ? params.provider_country
          : "",
        provider_postcode: params.provider_postcode
          ? params.provider_postcode
          : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    }
    return paramVal;
  };
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );

  return (
    <>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />

      {formContext !== "" && formAction != "" && (
        <CaseFormContainer
          {...{
            context: formContext,
            mode: formAction,
            initialFormData,
            currentRecord: currentRecord,
            caseList: props.caseItemList ? props.caseItemList : [],
            section: props.section,
            tab: props.tab,
            caseType,
            activeCustomerId: clientId,
            activePartnerId: clientId,
            activeItem: props.caseDetail,
            notify: notify,
            subItem: props.subItem,
            setCurrentRecord,
            fundValue: props.fundValue,
            paymentValue: props.paymentValue,
            withdrawalValue: props.withdrawalValue,
          }}
        />
      )}
    </>
  );
};

export default CaseForm;
