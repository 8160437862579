import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../service";

interface ProvidersState {
  list: Record<string, any>[];
  count: number;
  activeItem: Record<string, any> | null;
  loading: boolean;
}
interface GetProvidersListParams {
  page?: number;
  limit?: number;
  search?: string;
}
const initialState: ProvidersState = {
  list: [],
  count: 0,
  activeItem: {},
  loading: false,
};

export const getProvidersList = createAsyncThunk(
  "providers/getProvidersList",
  async (
    { page, limit, search }: GetProvidersListParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await apiServices.systemManager.providers.getList(
        page || 1,
        limit || 10,
        search || ""
      );

      const { isError, data, pagination } = response.data;

      if (isError || !data || !Array.isArray(data)) {
        return rejectWithValue("Failed to fetch provider list");
      }

      return {
        list: data,
        count: pagination ? pagination.count : 0,
      };
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvidersList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.list || [];
        state.count = action.payload.count || 0;
      })
      .addCase(getProvidersList.rejected, (state, action) => {
        state.loading = false;
        console.error("Error fetching providers details:", action.payload);
      });
  },
});
export const {} = providersSlice.actions;
export default providersSlice.reducer;
