import React, { useEffect, useState, useMemo } from "react";
import { formatedDate } from "../../../utils";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { Button, Tooltip } from "@fluentui/react-components";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Eye16Filled,
  EyeFilled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import ASCButton from "../../../components/Button";

const tableColumnList = {
  Vulnerbality: [
    {
      accessorKey: "vulnerability_date",
      header: "Date",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.vulnerability_date)}</>
      ),
    },
    {
      accessorKey: "driver",
      header: "Driver",
    },
    {
      accessorKey: "characteristics",
      header: "Characteristics",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "ratings",
      header: "Score",
    },
  ],
};

const VulnerabilityTable = (props: any) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { clientId, action, selectedItemId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.data.length > 0 && Object.keys(rowSelection)[0] !== undefined) {
      let selectedRow = props.data[Object.keys(rowSelection)[0]];
      if (selectedRow) {
        if (props.module != undefined && props.module == "partner") {
          navigate(
            `/clients/${clientId}/partner/${props.section}/${selectedRow.vulnerability_id}/view`
          );
        } else {
          navigate(
            `/clients/${clientId}/${props.section}/${selectedRow.vulnerability_id}/view`
          );
        }
      }
    }
  }, [rowSelection]);

  useEffect(() => {
    if (props.data.length > 0) {
      let index = 0;

      if (props.context === "CLIENT_VULNERABILITY") {
        index = props.data.findIndex(
          (x: any) => x.vulnerability_id === selectedItemId
        );
      } else if (props.context === "PARTNER_VULNERABILITY") {
        index = props.data.findIndex(
          (x: any) => x.vulnerability_id === selectedItemId
        );
      } else {
      }
      setRowSelection({ [index]: true });
      props.setCurrentRecord(index + 1);
    }
  }, [props.data]);

  return (
    <div>
      <MaterialReactTable
        columns={tableColumnList.Vulnerbality}
        data={props.data}
        enableRowSelection
        enableColumnActions={false}
        onRowSelectionChange={setRowSelection}
        enablePagination={false}
        // enableRowNumbers
        enableBottomToolbar={false}
        rowNumberMode="static"
        enableMultiRowSelection={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: row.getToggleSelectedHandler(),
          sx: { cursor: "pointer" },
        })}
        getRowId={(row: any) => row.id}
        enableRowActions={false}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "Actions", //change header text
            // size: 300, //make actions column wider
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          },
        }}
        renderRowActions={({ row, table }) => (
          <div className="table-wraper">
            <Tooltip content="View" relationship="label" positioning="below">
              <ASCButton
                shape="rounded"
                appearance="subtle"
                icon={
                  <Eye16Filled
                    primaryFill="var(--button-color)"
                    onClick={() => {
                      props.handleRowAction("view", row);
                    }}
                  />
                }
              />
            </Tooltip>
            <Tooltip content="Edit" relationship="label" positioning="below">
              <ASCButton
                shape="rounded"
                appearance="subtle"
                icon={
                  <Edit16Filled
                    primaryFill="var(--button-color)"
                    onClick={() => {
                      props.handleRowAction("edit", row);
                    }}
                  />
                }
              />
            </Tooltip>
          </div>
        )}
        initialState={{
          columnPinning: { right: ["mrt-row-actions"] },
        }}
        positionActionsColumn="last"
        positionToolbarAlertBanner="bottom"
        state={{ rowSelection }}
      />
    </div>
  );
};

export default VulnerabilityTable;
