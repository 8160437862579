// someReducer.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface SomeState {
  isFormDataUpdated: boolean; // Add isFormDataUpdated to the state
}

const initialState: SomeState = {
  isFormDataUpdated: false, // Initialize isFormDataUpdated as false
};
export const resetFormDataAsync = createAsyncThunk(
  "someReducer/resetFormDataAsync",
  async (_, { dispatch }) => {
    dispatch(resetFormData()); // Dispatch synchronous action
    return Promise.resolve(); // Ensures `.then()` can be used in the component
  }
);
const someSlice = createSlice({
  name: "someReducer",
  initialState,
  reducers: {
    setFormData: (state) => {
      state.isFormDataUpdated = true; // Set isFormDataUpdated to true when someData changes
    },
    resetFormData: (state) => {
      state.isFormDataUpdated = false; // Set isFormDataUpdated to false when resetting data
    },
  },
});

export const { setFormData, resetFormData } = someSlice.actions;

export default someSlice.reducer;
