import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import {
  ArrowCircleRightRegular,
  ArrowCircleLeft32Regular,
  ChevronLeftFilled,
  ChevronRightFilled,
  ChevronDoubleLeftFilled,
  ChevronDoubleRightFilled,
} from "@fluentui/react-icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { setFormData, resetFormData } from "../../redux/reducer";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import Form from "../../components/Form/Form";
import { NavbarItems } from "../../types/NavbarTypes";
import { BreadcrumbItemType } from "../../types/BreadcrumbItemTypes";
import {
  makeStyles,
  shorthands,
  tokens,
  TabList,
  Tab,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Subtitle2,
  Caption1,
  Caption1Strong,
} from "@fluentui/react-components";
import { formBuilder } from "../../utils/formGenerator";
import FormContainer from "../../components/Form/FormContainer";
import { convertStringToArray, removeEmptyValues } from "../../utils";
import AssetForm from "./asset/AssetForm";
import formSlice, { fetchSettings } from "../../redux/formSlice";
import DependantForm from "./Dependant/DependantForm";
import ClientSummary from "./ClientSummary/ClientSummary";
import OutgoingsForm from "./Outgoings/OutgoingsForm";
import PartnerForm from "./partner/PartnerForm";

import FormContainerWithList from "../../components/FormContainerWithList";
import IncomeForm from "./Income/IncomeForm";
import AddressBookForm from "./AddressBook/AddressBookForm";
import FeesForm from "./FeesCharges/FeesForm";
import ContactForm from "./contact/ContactForm";
import FactFindNoteForm from "./FactFindNote/FactFindNoteForm";
import LiabilityForm from "./liability/LiabilityForm";
import PolicyForm from "./Policy/PolicyForm";
import { addBreadcrumbs } from "../../redux/appSettingsSlice";
import apiServices from "../../service";
import ContactHistory from "./ContactHistory/ContactHistory";
import CaseSummary from "./CaseSummary/CaseSummary";
import OverlayLoader from "../../components/OverlayLoader";
import UserDefinedForm from "./userDefined/userDefinedForm";
import VulnerabilityForm from "./Vulnerability/VulnerabilityForm";
import ClientDocument from "./Documents/clientDocument";
import Summary from "../Commission/CommissionSummary/Summary";
import Timer from "./TimerFunction/TimerFunction";
import { setIsFloatingMenuOpen } from "../../redux/timerSlice";
type ClientActionPageProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
type configFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};
type tabItems = {
  label: string;
  value: string;
}[];
const useStyles = makeStyles({
  root: { ...shorthands.padding("0") },
  contentWrapper: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "1fr 11fr",
    ...shorthands.padding("0 5px"),
    ...shorthands.margin("0 0 10px"),
  },
  sideBarcontainer: {
    backgroundColor: tokens.colorNeutralBackground1,
    minWidth: "128px",
    // minHeight: "48px",
    maxWidth: "300px",
    width: "max-content",
    // boxShadow: `${tokens.shadow16}`,
    ...shorthands.padding("0px"),
    // paddingTop: "4px",
    // paddingBottom: "4px",
    marginTop: "0",
  },
  sideMenu: {
    width: "150px",
    ...shorthands.padding("10px"),
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
  },
  breadcrumbArea: {
    display: "flex",
    alignItems: "center",
    ...shorthands.padding("6px"),
  },
});

const tabListData: tabItems = [
  {
    label: "Name & Address",
    value: "CLIENT_NAME_ADDRESS",
  },
  {
    label: "Contact",
    value: "CLIENT_CONTACT",
  },
  {
    label: "Nationality",
    value: "CLIENT_NATIONALITY",
  },
  {
    label: "Personal",
    value: "CLIENT_PERSONAL",
  },
  {
    label: "Vulnerability",
    value: "CLIENT_VULNERABILITY",
  },
  {
    label: "Health Note",
    value: "CLIENT_HEALTH_NOTE",
  },
  {
    label: "Employment",
    value: "CLIENT_EMPLOYMENT",
  },
  {
    label: "Attitude to Risk",
    value: "CLIENT_ATTITUDE_TO_RISK",
  },
  {
    label: "Identity",
    value: "CLIENT_IDENTITY",
  },
  {
    label: " Objectives",
    value: "CLIENT_OBJECTIVES",
  },
  {
    label: "Administration",
    value: "ADMINISTRATION",
  },
  {
    label: "Compliance",
    value: "COMPLIANCE",
  },
  {
    label: " Service Type",
    value: "CLIENT_SERVICE_TYPE",
  },
  {
    label: "User Defined",
    value: "CLIENT_USER_DEFINED",
  },
];
const commissionClientTablistData: tabItems = [
  {
    label: "Name & Address",
    value: "COMMISSION_CLIENT",
  },

  {
    label: " Service Type",
    value: "COMMISSION_SERVICE_TYPE",
  },
];
const ClientActionPage = (props: ClientActionPageProps) => {
  const [showButtons, setShowButtons] = useState(false);
  const tabListRef = useRef<HTMLDivElement>(null);
  const [configForm, setConfigForm] = useState<configFm>();
  const [verticalTab, setVerticalTab] = useState("Client");
  const [collectionForm, setCollectionForm] = useState({});
  const [selectedValue, setSelectedValue] = useState("CLIENT_NAME_ADDRESS");
  const [formContext, setFormContext] = useState("");
  const [formInitialValue, setFormInitialValue] = useState({});
  const [initialData, setInitialData] = useState({});
  const [tabListItems, setTabListItems] = useState<tabItems>(tabListData);
  const [attitudeToRiskData, setAttitudeToRiskData] = useState([]);
  const [commissionData, setCommissionRiskData] = useState([]);
  // const [vulnerabilityData, setVulnerabilityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabMenuIndex, setTabMenuIndex] = useState({
    previous: -1,
    current: 0,
    next: -1,
  });

  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );

  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const breadcrumbItems = useSelector(
    (state: RootState) => state.appSettings.breadcrumbItems
  );
  const classes = useStyles();
  const selectId = useId();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const onTabSelect = (event: any, data: any) => {
    if (isFormDataUpdated) {
      dispatch(
        setDialogModalOptions({
          open: true,
          content_line_1:
            "We have detected an attempt to move away from the current page",
          content_line_2: "This would cause all changes made to be lost",
          cancel: {
            onclick: () => {
              dispatch(
                setDialogModalOptions({
                  open: false,
                })
              );
              dispatch(resetFormData());
              setSelectedValue(data.value);
            },
            label: "Leave the Page",
          },
          no: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
            },
            label: "Stay on Page",
            type: "primary",
          },
        })
      );
    } else {
      setSelectedValue(data.value);
    }
  };
  const changeTab = (index: number) => {
    if (index !== -1) setSelectedValue(tabListItems[index]["value"]);
  };
  const getTablistLabelByValue = (value: string) => {
    const item = tabListData.find((tabItem) => tabItem.value === value);
    return item ? item.label : null;
  };
  useEffect(() => {
    if (selectedValue != "") {
      setFormContextConfig(selectedValue);
      updateTabMenuIndex(selectedValue);
      handleBreadcrumbs();
    }
  }, [selectedValue, formSettingsObj]);
  useEffect(() => {
    setVerticalTab(props.leftTabMenu);
  }, [props.leftTabMenu]);
  useEffect(() => {
    if (["view", "edit"].includes(props.mode) && props.activeCustomerId) {
      //   call get api
      if (selectedValue === "CLIENT_ATTITUDE_TO_RISK") {
        getAttitudeToRisk(props.activeCustomerId);
      } else if (selectedValue === "COMMISSION_CLIENT") {
        getCommissionClient(props.activeCustomerId);
      } else getClientDetail(props.activeCustomerId);
    }
  }, [props.mode, selectedValue]);
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (props.module != undefined && props.module == "commission") {
      setTabListItems(commissionClientTablistData);
      setSelectedValue("COMMISSION_CLIENT");
    }
  }, [props.module]);

  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const getClientDetail = (id: string) => {
    setLoading(true);
    apiServices.client
      .getDetail(id)
      .then((response: any) => {
        setLoading(false);
        if (!response.data.isError) {
          let singleData = {};
          if (
            response.data.data &&
            Object.keys(response.data.data).length !== 0
          ) {
            if (response.data.data?.customer_id) {
              singleData = {
                ...singleData,
                customer_id: response.data.data.customer_id,
              };
            }
            if (response.data.data.NameAndAddress) {
              singleData = {
                ...singleData,
                ...response.data.data.NameAndAddress,
              };
            }
            if (response.data.data.Contact) {
              singleData = {
                ...singleData,
                ...response.data.data.Contact,
              };
            }
            if (response.data.data.Nationality) {
              singleData = {
                ...singleData,
                ...response.data.data.Nationality,
              };
            }
            if (response.data.data.Personal) {
              singleData = {
                ...singleData,
                ...response.data.data.Personal,
              };
            }
            if (response.data.data.HealthNotes) {
              singleData = {
                ...singleData,
                ...response.data.data.HealthNotes,
              };
            }
            if (response.data.data.Administration) {
              singleData = {
                ...singleData,
                ...response.data.data.Administration,
              };
            }
            if (response.data.data.identity) {
              singleData = {
                ...singleData,
                identity: response.data.data.identity,
              };
            }

            if (response.data.data.compliance) {
              singleData = {
                ...singleData,
                ...response.data.data.compliance,
              };
            }
            if (response.data.data.objectives) {
              singleData = {
                ...singleData,
                objectives: response.data.data.objectives,
              };
            }
            if (response.data.data.employment) {
              singleData = {
                ...singleData,
                employment: response.data.data.employment,
              };
            }
            if (response.data.data.servicetype) {
              singleData = {
                ...singleData,
                servicetype: response.data.data.servicetype,
              };
            }
            if (response.data.data.attitudetorisk) {
              singleData = {
                ...singleData,
                attitudetorisk: response.data.data.attitudetorisk,
              };
            }
          }
          // setInitialData(singleData);
          setInitialData(removeEmptyValues(singleData));
          if (props.setActiveItem) {
            props.setActiveItem(response.data.data);
          }
        }
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log(e);
      });
  };

  const getAttitudeToRisk = (id: string) => {
    apiServices.attitudeToRisk
      .getList(id)
      .then((response: any) => {
        setAttitudeToRiskData(response.data.data);
      })
      .catch((e: Error) => {
        console.log("error", e);
      });
  };

  // Commission get

  const getCommissionClient = (id: string) => {
    apiServices.commissionModule.commissionClient
      .getList(id)
      .then((response: any) => {
        setCommissionRiskData(response.data.data);
      })
      .catch((e: Error) => {
        console.log("error", e);
      });
  };
  const reloadDetail = () => {
    if (selectedValue === "CLIENT_ATTITUDE_TO_RISK") {
      getAttitudeToRisk(props.activeCustomerId);
    } else if (selectedValue === "COMMISSION_CLIENT") {
      getCommissionClient(props.activeCustomerId);
    } else {
      getClientDetail(props.activeCustomerId);
    }
  };
  const setFormContextConfig = (context: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let configObj: any = {};
      if (
        props.module != undefined &&
        props.module == "commission" &&
        formSettingsObj.data.commission !== undefined
      ) {
        configObj = formSettingsObj.data.commission.formConfig.find(
          (item: any) => item.context === context
        );
      } else {
        configObj = formSettingsObj.data.formConfig.find(
          (item: any) => item.context === context
        );
      }
      if (configObj) {
        setConfigForm(configObj);

        let { initialValues, inputs, validationSchema } = formBuilder({
          context: context,
          formSettings:
            props.module != undefined && props.module == "commission"
              ? formSettingsObj.data.commission
              : formSettingsObj.data,
        });

        setFormInitialValue({ initialValues, inputs, validationSchema });
      }
    }
    // let currentTabLabel=  tabListData.find(
    //     (item: any) => item.value === context
    //   );

    // dispatch(addBreadcrumbs([
    //    {
    //       key: 0,
    //       value: "Home",
    //       link: "/",
    //     },
    //     {
    //       key: 1,
    //       value: verticalTab,
    //     },
    //     {
    //       key: 2,
    //       value: currentTabLabel?.label,
    //     }
    // ]))
  };

  const updateTabMenuIndex = (context: string) => {
    if (tabListItems.length > 0) {
      let currentVal = tabListItems.findIndex(
        (obj) => obj["value"] === context
      );
      let prevVal = currentVal === 0 ? -1 : currentVal - 1;
      let nextVal =
        tabListItems.length - 1 !== currentVal ? currentVal + 1 : -1;

      setTabMenuIndex((prevState) => ({
        ...prevState,
        previous: prevVal,
        current: currentVal,
        next: nextVal,
      }));
    }
  };
  const mapPageHeader = (tab: string) => {
    let vTabs = {
      Client: "Client Details",
      partner: "Partner Details",
      dependants: "Dependants",
      income: "Income",
      outgoings: "Outgoings",
      address: "Address Book",
      assets: "Assets",
      liabilities: "Liabilities",
      policies: "Policies",
      fact: "Fact-find Notes",
      fees: "Fees/Charges",
      contact: "Contact",
      documents: "Documents",
      client_summary: "Client Summary Details",
      case_summary: "Case Summary",
      contact_history: "Contact History",
    };
    return (vTabs as { [key: string]: any })[tab] !== undefined
      ? (vTabs as { [key: string]: any })[tab]
      : "";
  };

  const mapVerticalTabs = (tab: string) => {
    let vTabs = {
      Client: "Client",
      partner: "Partner",
      dependants: "Dependants",
      income: "Income",
      outgoings: "Outgoings",
      address: "Address Book",
      assets: "Assets",
      liabilities: "Liabilities",
      policies: "Policies",
      fact: "Fact-find Notes",
      fees: "Fees/Charges",
      contact: "Contact",
      document: "Documents",
    };
    return (vTabs as { [key: string]: any })[tab] !== undefined
      ? (vTabs as { [key: string]: any })[tab]
      : "";
  };
  useEffect(() => {
    handleBreadcrumbs();
  }, [verticalTab]);
  useEffect(() => {
    setFormCollection(configForm);
  }, [configForm]);
  const setFormCollection = (configForm: any) => {
    if (configForm?.renderForm) {
      let formFieldData: keyof typeof formSettingsObj.data.formCollections =
        configForm.renderForm?.reference_id;
      if (formFieldData) {
        setCollectionForm(formSettingsObj.data.formCollections[formFieldData]);
      }
    }
  };
  const handleBreadcrumbs = () => {
    if (verticalTab === "Client") {
      let currentTabLabel = tabListData.find(
        (item: any) => item.value === selectedValue
      );
      dispatch(
        addBreadcrumbs([
          {
            key: 0,
            value: "Home",
            link: "/",
          },
          {
            key: 1,
            value: "Clients",
            link: "/clients",
          },
          {
            key: 2,
            value: verticalTab,
          },
          {
            key: 3,
            value: currentTabLabel?.label,
          },
          // {
          //   key: 2,
          //   value: currentTabLabel?.label,
          // }
        ])
      );
    } else {
      if (
        [
          "Dependants",
          "Income",
          "Outgoings",
          "Address Book",
          "Fact-find Notes",
          "Fees/Charges",
        ].includes(mapVerticalTabs(verticalTab))
      ) {
        dispatch(
          addBreadcrumbs([
            {
              key: 0,
              value: "Home",
              link: "/",
            },
            {
              key: 1,
              value: "Clients",
              link: "/clients",
            },
            {
              key: 2,
              value: "Client",
            },
            {
              key: 3,

              value: mapVerticalTabs(verticalTab),
            },
          ])
        );
      }
    }
  };

  const checkButtonVisibility = useCallback(() => {
    const container = tabListRef.current;
    if (container) {
      const isOverflowing = container.scrollWidth > container.clientWidth;
      setShowButtons(isOverflowing);
    }
  }, []);

  useEffect(() => {
    const container = tabListRef.current;
    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        checkButtonVisibility();
      });

      resizeObserver.observe(container);

      checkButtonVisibility();

      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, [checkButtonVisibility, tabListItems]);

  const handleSlideRight = () => {
    const container = tabListRef.current;
    if (container) {
      container.scrollBy({
        left: 70,
        behavior: "smooth",
      });
    }
  };

  const handleSlideLeft = () => {
    const container = tabListRef.current;
    if (container) {
      container.scrollBy({
        left: -70,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <OverlayLoader isLoading={loading} />
      <div className="cl-layout">
        <aside className="cl-sidebar">
          <div className={classes.sideBarcontainer}>
            <div
              className={`${
                props.isContactHistory ? "sidenav-history" : "sidenav"
              }`}
            >
              {props.module !== undefined && props.module == "commission" ? (
                <>
                  {" "}
                  <a
                    href="#"
                    onClick={() => {
                      if (props.mode !== "add") {
                        if (isFormDataUpdated && verticalTab !== "summary") {
                          dispatch(
                            setDialogModalOptions({
                              open: true,
                              content_line_1:
                                "We have detected an attempt to move away from the current page",
                              content_line_2:
                                "This would cause all changes made to be lost",
                              cancel: {
                                onclick: () => {
                                  dispatch(
                                    setDialogModalOptions({
                                      open: false,
                                    })
                                  );
                                  // props.setFormUpdated(false);
                                  dispatch(resetFormData());
                                  setVerticalTab("summary");
                                },
                                label: "Leave the Page",
                              },
                              no: {
                                onclick: () => {
                                  dispatch(
                                    setDialogModalOptions({ open: false })
                                  );
                                },
                                label: "Stay on Page",
                                type: "primary",
                              },
                            })
                          );
                        } else {
                          setVerticalTab("summary");
                        }
                      }
                    }}
                    className={`${
                      props.mode === "add"
                        ? "disable"
                        : verticalTab === "summary"
                        ? "active"
                        : ""
                    }`}
                  >
                    Summary
                  </a>
                </>
              ) : (
                <>
                  {" "}
                  {!props.isContactHistory && (
                    <>
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_SIDE_TAB
                      ).includes("client_summary") ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            const isDisabled = convertStringToArray(
                              process.env.REACT_APP_DISABLE_SIDE_TAB
                            ).includes("client_summary");
                            if (isDisabled) {
                              e.preventDefault();
                              return;
                            }
                            if (props.mode !== "add") {
                              if (
                                isFormDataUpdated &&
                                verticalTab !== "client_summary"
                              ) {
                                dispatch(
                                  setDialogModalOptions({
                                    open: true,
                                    content_line_1:
                                      "We have detected an attempt to move away from the current page",
                                    content_line_2:
                                      "This would cause all changes made to be lost",
                                    cancel: {
                                      onclick: () => {
                                        dispatch(
                                          setDialogModalOptions({
                                            open: false,
                                          })
                                        );
                                        dispatch(resetFormData());
                                        setVerticalTab("client_summary");
                                      },
                                      label: "Leave the Page",
                                    },
                                    no: {
                                      onclick: () => {
                                        dispatch(
                                          setDialogModalOptions({
                                            open: false,
                                          })
                                        );
                                      },
                                      label: "Stay on Page",
                                      type: "primary",
                                    },
                                  })
                                );
                              } else {
                                setVerticalTab("client_summary");
                              }
                            }
                          }}
                          className={`${
                            props.mode === "add"
                              ? "disable"
                              : verticalTab === "client_summary"
                              ? "active"
                              : ""
                          } ${
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_SIDE_TAB
                            ).includes("client_summary")
                              ? "disabled-link"
                              : ""
                          }`}
                        >
                          Client Summary
                        </a>
                      ) : (
                        ""
                      )}

                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_SIDE_TAB
                      ).includes("case_summary") ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            const isDisabled = convertStringToArray(
                              process.env.REACT_APP_DISABLE_SIDE_TAB
                            ).includes("case_summary");
                            if (isDisabled) {
                              e.preventDefault();
                              return;
                            }

                            if (props.mode !== "add") {
                              if (
                                isFormDataUpdated &&
                                verticalTab !== "case_summary"
                              ) {
                                dispatch(
                                  setDialogModalOptions({
                                    open: true,
                                    content_line_1:
                                      "We have detected an attempt to move away from the current page",
                                    content_line_2:
                                      "This would cause all changes made to be lost",
                                    cancel: {
                                      onclick: () => {
                                        dispatch(
                                          setDialogModalOptions({
                                            open: false,
                                          })
                                        );
                                        // props.setFormUpdated(false);
                                        dispatch(resetFormData());
                                        setVerticalTab("case_summary");
                                      },
                                      label: "Leave the Page",
                                    },
                                    no: {
                                      onclick: () => {
                                        dispatch(
                                          setDialogModalOptions({
                                            open: false,
                                          })
                                        );
                                      },
                                      label: "Stay on Page",
                                      type: "primary",
                                    },
                                  })
                                );
                              } else {
                                setVerticalTab("case_summary");
                              }
                            }
                          }}
                          className={`${
                            props.mode === "add"
                              ? "disable"
                              : verticalTab === "case_summary"
                              ? "active"
                              : ""
                          } ${
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_SIDE_TAB
                            ).includes("case_summary")
                              ? "disabled-link"
                              : ""
                          }`}
                        >
                          Case Summary
                        </a>
                      ) : (
                        ""
                      )}

                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_SIDE_TAB
                      ).includes("contact_history") ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            const isDisabled = convertStringToArray(
                              process.env.REACT_APP_DISABLE_SIDE_TAB
                            ).includes("contact_history");
                            if (isDisabled) {
                              e.preventDefault();
                              return;
                            }
                            if (props.mode !== "add") {
                              if (
                                isFormDataUpdated &&
                                verticalTab !== "contact_history"
                              ) {
                                dispatch(
                                  setDialogModalOptions({
                                    open: true,
                                    content_line_1:
                                      "We have detected an attempt to move away from the current page",
                                    content_line_2:
                                      "This would cause all changes made to be lost",
                                    cancel: {
                                      onclick: () => {
                                        dispatch(
                                          setDialogModalOptions({
                                            open: false,
                                          })
                                        );
                                        // props.setFormUpdated(false);
                                        dispatch(resetFormData());
                                        setVerticalTab("contact_history");
                                      },
                                      label: "Leave the Page",
                                    },
                                    no: {
                                      onclick: () => {
                                        dispatch(
                                          setDialogModalOptions({
                                            open: false,
                                          })
                                        );
                                      },
                                      label: "Stay on Page",
                                      type: "primary",
                                    },
                                  })
                                );
                              } else {
                                setVerticalTab("contact_history");
                              }
                            }
                          }}
                          className={`${
                            props.mode === "add"
                              ? "disable"
                              : verticalTab === "contact_history"
                              ? "active"
                              : ""
                          } ${
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_SIDE_TAB
                            ).includes("contact_history")
                              ? "disabled-link"
                              : ""
                          }`}
                        >
                          Contact History
                        </a>
                      ) : (
                        ""
                      )}
                      {/* <a href="#">Assyst Portal</a> */}

                      <hr />
                    </>
                  )}
                </>
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("Client") ? (
                <a
                  href="#"
                  onClick={(e) => {
                    const isDisabled = convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("Client");
                    if (isDisabled) {
                      e.preventDefault();
                      return;
                    }
                    if (isFormDataUpdated && verticalTab !== "Client") {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          content_line_1:
                            "We have detected an attempt to move away from the current page",
                          content_line_2:
                            "This would cause all changes made to be lost",
                          cancel: {
                            onclick: () => {
                              dispatch(
                                setDialogModalOptions({
                                  open: false,
                                })
                              );
                              // props.setFormUpdated(false);
                              dispatch(resetFormData());
                              setVerticalTab("Client");
                            },
                            label: "Leave the Page",
                          },
                          no: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Stay on Page",
                            type: "primary",
                          },
                        })
                      );
                    } else {
                      setVerticalTab("Client");
                    }
                  }}
                  className={`${verticalTab === "Client" ? "active" : ""} ${
                    convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("Client")
                      ? "disabled-link"
                      : ""
                  }`}
                >
                  Client
                </a>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("partner") ? (
                <a
                  href="#"
                  onClick={(e) => {
                    const isDisabled = convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("partner");
                    if (isDisabled) {
                      e.preventDefault();
                      return;
                    }
                    if (props.mode !== "add") {
                      if (isFormDataUpdated && verticalTab !== "partner") {
                        dispatch(
                          setDialogModalOptions({
                            open: true,
                            content_line_1:
                              "We have detected an attempt to move away from the current page",
                            content_line_2:
                              "This would cause all changes made to be lost",
                            cancel: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({
                                    open: false,
                                  })
                                );
                                dispatch(resetFormData());
                                setVerticalTab("partner");
                              },
                              label: "Leave the Page",
                            },
                            no: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({ open: false })
                                );
                              },
                              label: "Stay on Page",
                              type: "primary",
                            },
                          })
                        );
                      } else {
                        setVerticalTab("partner");
                      }
                    }
                  }}
                  className={`${
                    props.mode === "add"
                      ? "disable"
                      : verticalTab === "partner"
                      ? "active"
                      : ""
                  } ${
                    convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("partner")
                      ? "disabled-link"
                      : ""
                  }`}
                >
                  Partner
                </a>
              ) : (
                ""
              )}

              {props.module !== undefined &&
              props.module == "commission" ? null : !convertStringToArray(
                  process.env.REACT_APP_HIDE_SIDE_TAB
                ).includes("dependants") ? (
                <a
                  href="#"
                  onClick={(e) => {
                    const isDisabled = convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("dependants");
                    if (isDisabled) {
                      e.preventDefault();
                      return;
                    }
                    if (props.mode !== "add") {
                      if (isFormDataUpdated && verticalTab !== "dependants") {
                        dispatch(
                          setDialogModalOptions({
                            open: true,
                            content_line_1:
                              "We have detected an attempt to move away from the current page",
                            content_line_2:
                              "This would cause all changes made to be lost",
                            cancel: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({
                                    open: false,
                                  })
                                );
                                dispatch(resetFormData());
                                setVerticalTab("dependants");
                              },
                              label: "Leave the Page",
                            },
                            no: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({ open: false })
                                );
                              },
                              label: "Stay on Page",
                              type: "primary",
                            },
                          })
                        );
                      } else {
                        setVerticalTab("dependants");
                      }
                    }
                  }}
                  className={`${
                    props.mode === "add"
                      ? "disable"
                      : verticalTab === "dependants"
                      ? "active"
                      : ""
                  } ${
                    convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("dependants")
                      ? "disabled-link"
                      : ""
                  }`}
                >
                  Dependants
                </a>
              ) : (
                ""
              )}
              <hr />
              {props.module !== undefined &&
              props.module == "commission" ? null : (
                <>
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_SIDE_TAB
                  ).includes("income") ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        const isDisabled = convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("income");
                        if (isDisabled) {
                          e.preventDefault();
                          return;
                        }
                        if (props.mode !== "add") {
                          if (isFormDataUpdated && verticalTab !== "income") {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                content_line_1:
                                  "We have detected an attempt to move away from the current page",
                                content_line_2:
                                  "This would cause all changes made to be lost",
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({
                                        open: false,
                                      })
                                    );
                                    dispatch(resetFormData());
                                    setVerticalTab("income");
                                  },
                                  label: "Leave the Page",
                                },
                                no: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Stay on Page",
                                  type: "primary",
                                },
                              })
                            );
                          } else {
                            setVerticalTab("income");
                          }
                        }
                      }}
                      className={`${
                        props.mode === "add"
                          ? "disable"
                          : verticalTab === "income"
                          ? "active"
                          : ""
                      } ${
                        convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("income")
                          ? "disabled-link"
                          : ""
                      }`}
                    >
                      Income
                    </a>
                  ) : (
                    ""
                  )}

                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_SIDE_TAB
                  ).includes("outgoings") ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        const isDisabled = convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("outgoings");
                        if (isDisabled) {
                          e.preventDefault();
                          return;
                        }
                        if (props.mode !== "add") {
                          if (
                            isFormDataUpdated &&
                            verticalTab !== "outgoings"
                          ) {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                content_line_1:
                                  "We have detected an attempt to move away from the current page",
                                content_line_2:
                                  "This would cause all changes made to be lost",
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({
                                        open: false,
                                      })
                                    );
                                    dispatch(resetFormData());
                                    setVerticalTab("outgoings");
                                  },
                                  label: "Leave the Page",
                                },
                                no: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Stay on Page",
                                  type: "primary",
                                },
                              })
                            );
                          } else {
                            setVerticalTab("outgoings");
                          }
                        }
                      }}
                      className={`${
                        props.mode === "add"
                          ? "disable"
                          : verticalTab === "outgoings"
                          ? "active"
                          : ""
                      } ${
                        convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("outgoings")
                          ? "disabled-link"
                          : ""
                      }`}
                    >
                      Outgoings
                    </a>
                  ) : (
                    ""
                  )}

                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_SIDE_TAB
                  ).includes("address") ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        const isDisabled = convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("disable_address");
                        if (isDisabled) {
                          e.preventDefault();
                          return;
                        }

                        if (props.mode !== "add") {
                          if (isFormDataUpdated && verticalTab !== "address") {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                content_line_1:
                                  "We have detected an attempt to move away from the current page",
                                content_line_2:
                                  "This would cause all changes made to be lost",
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                    dispatch(resetFormData());
                                    setVerticalTab("address");
                                  },
                                  label: "Leave the Page",
                                },
                                no: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Stay on Page",
                                  type: "primary",
                                },
                              })
                            );
                          } else {
                            setVerticalTab("address");
                          }
                        }
                      }}
                      className={`${
                        props.mode === "add"
                          ? "disable"
                          : verticalTab === "address"
                          ? "active"
                          : ""
                      } ${
                        convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("disable_address")
                          ? "disabled-link"
                          : ""
                      }`}
                    >
                      Address Book
                    </a>
                  ) : (
                    ""
                  )}
                </>
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("assets") ? (
                <a
                  href="#"
                  onClick={(e) => {
                    const isDisabled = convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("assets");
                    if (isDisabled) {
                      e.preventDefault();
                      return;
                    }
                    if (props.mode !== "add") {
                      if (isFormDataUpdated && verticalTab !== "assets") {
                        dispatch(
                          setDialogModalOptions({
                            open: true,
                            content_line_1:
                              "We have detected an attempt to move away from the current page",
                            content_line_2:
                              "This would cause all changes made to be lost",
                            cancel: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({
                                    open: false,
                                  })
                                );
                                dispatch(resetFormData());
                                setVerticalTab("assets");
                              },
                              label: "Leave the Page",
                            },
                            no: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({ open: false })
                                );
                              },
                              label: "Stay on Page",
                              type: "primary",
                            },
                          })
                        );
                      } else {
                        setVerticalTab("assets");
                      }
                    }
                  }}
                  className={`${
                    props.mode === "add"
                      ? "disable"
                      : verticalTab === "assets"
                      ? "active"
                      : ""
                  } ${
                    convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("assets")
                      ? "disabled-link"
                      : ""
                  }`}
                >
                  Assets
                </a>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("liabilities") ? (
                <a
                  href="#"
                  onClick={(e) => {
                    const isDisabled = convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("liabilities");
                    if (isDisabled) {
                      e.preventDefault();
                      return;
                    }
                    if (props.mode !== "add") {
                      if (isFormDataUpdated && verticalTab !== "liabilities") {
                        dispatch(
                          setDialogModalOptions({
                            open: true,
                            content_line_1:
                              "We have detected an attempt to move away from the current page",
                            content_line_2:
                              "This would cause all changes made to be lost",
                            cancel: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({
                                    open: false,
                                  })
                                );
                                dispatch(resetFormData());
                                setVerticalTab("liabilities");
                              },
                              label: "Leave the Page",
                            },
                            no: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({ open: false })
                                );
                              },
                              label: "Stay on Page",
                              type: "primary",
                            },
                          })
                        );
                      } else {
                        setVerticalTab("liabilities");
                      }
                    }
                  }}
                  className={`${
                    props.mode === "add"
                      ? "disable"
                      : verticalTab === "liabilities"
                      ? "active"
                      : ""
                  } ${
                    convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("liabilities")
                      ? "disabled-link"
                      : ""
                  }`}
                >
                  Liabilities
                </a>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("policies") ? (
                <a
                  href="#"
                  onClick={(e) => {
                    const isDisabled = convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("policies");
                    if (isDisabled) {
                      e.preventDefault();
                      return;
                    }
                    if (props.mode !== "add") {
                      if (isFormDataUpdated && verticalTab !== "policies") {
                        dispatch(
                          setDialogModalOptions({
                            open: true,
                            content_line_1:
                              "We have detected an attempt to move away from the current page",
                            content_line_2:
                              "This would cause all changes made to be lost",
                            cancel: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({
                                    open: false,
                                  })
                                );
                                dispatch(resetFormData());
                                setVerticalTab("policies");
                              },
                              label: "Leave the Page",
                            },
                            no: {
                              onclick: () => {
                                dispatch(
                                  setDialogModalOptions({ open: false })
                                );
                              },
                              label: "Stay on Page",
                              type: "primary",
                            },
                          })
                        );
                      } else {
                        setVerticalTab("policies");
                      }
                    }
                  }}
                  className={`${
                    props.mode === "add"
                      ? "disable"
                      : verticalTab === "policies"
                      ? "active"
                      : ""
                  } ${
                    convertStringToArray(
                      process.env.REACT_APP_DISABLE_SIDE_TAB
                    ).includes("policies")
                      ? "disabled-link"
                      : ""
                  }`}
                >
                  Policies
                </a>
              ) : (
                ""
              )}

              {props.module !== undefined &&
              props.module == "commission" ? null : (
                <>
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_SIDE_TAB
                  ).includes("fact") ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        const isDisabled = convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("fact");
                        if (isDisabled) {
                          e.preventDefault();
                          return;
                        }
                        if (props.mode !== "add") {
                          if (isFormDataUpdated && verticalTab !== "fact") {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                content_line_1:
                                  "We have detected an attempt to move away from the current page",
                                content_line_2:
                                  "This would cause all changes made to be lost",
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({
                                        open: false,
                                      })
                                    );
                                    dispatch(resetFormData());
                                    setVerticalTab("fact");
                                  },
                                  label: "Leave the Page",
                                },
                                no: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Stay on Page",
                                  type: "primary",
                                },
                              })
                            );
                          } else {
                            setVerticalTab("fact");
                          }
                        }
                      }}
                      className={`${
                        props.mode === "add"
                          ? "disable"
                          : verticalTab === "fact"
                          ? "active"
                          : ""
                      } ${
                        convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("assets")
                          ? "disabled-link"
                          : ""
                      }`}
                    >
                      {" "}
                      Fact-find Note
                    </a>
                  ) : (
                    ""
                  )}

                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_SIDE_TAB
                  ).includes("fees") ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        const isDisabled = convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("fees");
                        if (isDisabled) {
                          e.preventDefault();
                          return;
                        }
                        if (props.mode !== "add") {
                          if (isFormDataUpdated && verticalTab !== "fees") {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                content_line_1:
                                  "We have detected an attempt to move away from the current page",
                                content_line_2:
                                  "This would cause all changes made to be lost",
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({
                                        open: false,
                                      })
                                    );
                                    dispatch(resetFormData());
                                    setVerticalTab("fees");
                                  },
                                  label: "Leave the Page",
                                },
                                no: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Stay on Page",
                                  type: "primary",
                                },
                              })
                            );
                          } else {
                            setVerticalTab("fees");
                          }
                        }
                      }}
                      className={`${
                        props.mode === "add"
                          ? "disable"
                          : verticalTab === "fees"
                          ? "active"
                          : ""
                      } ${
                        convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("fees")
                          ? "disabled-link"
                          : ""
                      }`}
                    >
                      {" "}
                      Fees/Charges
                    </a>
                  ) : (
                    ""
                  )}
                  <hr />
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_SIDE_TAB
                  ).includes("contact") ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        const isDisabled = convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("contact");
                        if (isDisabled) {
                          e.preventDefault();
                          return;
                        }
                        if (props.mode !== "add") {
                          if (isFormDataUpdated && verticalTab !== "contact") {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                content_line_1:
                                  "We have detected an attempt to move away from the current page",
                                content_line_2:
                                  "This would cause all changes made to be lost",
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({
                                        open: false,
                                      })
                                    );
                                    dispatch(resetFormData());
                                    setVerticalTab("contact");
                                  },
                                  label: "Leave the Page",
                                },
                                no: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Stay on Page",
                                  type: "primary",
                                },
                              })
                            );
                          } else {
                            setVerticalTab("contact");
                          }
                        }
                      }}
                      className={`${
                        props.mode === "add"
                          ? "disable"
                          : verticalTab === "contact"
                          ? "active"
                          : ""
                      } ${
                        convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("contact")
                          ? "disabled-link"
                          : ""
                      }`}
                    >
                      Contact
                    </a>
                  ) : (
                    ""
                  )}
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_SIDE_TAB
                  ).includes("documents") ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        const isDisabled = convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("documents");
                        if (isDisabled) {
                          e.preventDefault();
                          return;
                        }
                        if (props.mode !== "add") {
                          if (
                            isFormDataUpdated &&
                            verticalTab !== "documents"
                          ) {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                content_line_1:
                                  "We have detected an attempt to move away from the current page",
                                content_line_2:
                                  "This would cause all changes made to be lost",
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({
                                        open: false,
                                      })
                                    );
                                    // props.setFormUpdated(false);
                                    dispatch(resetFormData());
                                    setVerticalTab("documents");
                                  },
                                  label: "Leave the Page",
                                },
                                no: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Stay on Page",
                                  type: "primary",
                                },
                              })
                            );
                          } else {
                            setVerticalTab("documents");
                          }
                        }
                      }}
                      className={`${
                        props.mode === "add"
                          ? "disable"
                          : verticalTab === "documents"
                          ? "active"
                          : ""
                      } ${
                        convertStringToArray(
                          process.env.REACT_APP_DISABLE_SIDE_TAB
                        ).includes("documents")
                          ? "disabled-link"
                          : ""
                      }`}
                    >
                      Documents
                    </a>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </aside>
        <main className="cl-content">
          <div>
            {" "}
            {verticalTab === "Client" && (
              <>
                <header className="cl-page-header">
                  <Subtitle2>{mapPageHeader(verticalTab)}</Subtitle2>

                  <nav className="cl-breadcrumbs">
                    <ul>
                      <li>
                        <Caption1>{mapPageHeader(verticalTab)}</Caption1>

                        <span className="cl-separator"> / </span>
                      </li>
                      <li>
                        <Caption1Strong>
                          {getTablistLabelByValue(selectedValue)}
                        </Caption1Strong>
                      </li>
                    </ul>
                  </nav>
                </header>
                <div className="form-container">
                  <div className="tab-menubar">
                    {showButtons && (
                      <ChevronDoubleLeftFilled
                        id="slideLeft"
                        onClick={handleSlideLeft}
                      />
                    )}
                    <TabList
                      id="tablist"
                      ref={tabListRef}
                      style={{
                        background: "#fff",
                        zIndex: 99,
                        width: "82vw",
                        overflowX: "auto",
                        overflowY: "hidden",
                        padding: "2px",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        display: "flex",
                        marginInline: showButtons ? "21px" : "0px",
                      }}
                      selectedValue={selectedValue}
                      onTabSelect={onTabSelect}
                    >
                      {tabListItems &&
                        tabListItems.length > 0 &&
                        tabListItems.map((item, i) => (
                          <Tab
                            key={i}
                            value={item.value}
                            style={{
                              padding: "0px 0px 5px",
                              marginInline: "5px",
                            }}
                            disabled={
                              !convertStringToArray(
                                process.env.REACT_APP_DISABLE_CTA_BUTTON
                              ).includes(item.value)
                                ? props.mode === "add"
                                  ? tabMenuIndex.current >= i
                                    ? false
                                    : true
                                  : undefined
                                : true
                            }
                          >
                            {!convertStringToArray(
                              process.env.REACT_APP_HIDE_CTA_BUTTON
                            ).includes(item.value) ? (
                              <>{item.label}</>
                            ) : (
                              ""
                            )}
                          </Tab>
                        ))}
                    </TabList>
                    {showButtons && (
                      <ChevronDoubleRightFilled
                        color="black"
                        id="slideRight"
                        onClick={handleSlideRight}
                      />
                    )}
                  </div>

                  {selectedValue !== "" &&
                    selectedValue === configForm?.context && (
                      <>
                        {[
                          "CLIENT_EMPLOYMENT",
                          "CLIENT_ATTITUDE_TO_RISK",
                          "CLIENT_SERVICE_TYPE",
                          "CLIENT_IDENTITY",
                          "CLIENT_OBJECTIVES",
                          "COMMISSION_SERVICE_TYPE",
                        ].includes(selectedValue) ? (
                          <FormContainerWithList
                            context={selectedValue}
                            initialData={initialData}
                            attitudeToRiskData={attitudeToRiskData}
                            tabMenuIndex={tabMenuIndex}
                            changeTab={changeTab}
                            closePage={props.closePage}
                            activeCustomerId={props.activeCustomerId}
                            setActiveItem={props.setActiveItem}
                            notify={notify}
                            getList={props.getList}
                            config={configForm}
                            reloadData={reloadDetail}
                            module={props.module}
                          />
                        ) : (
                          <FormContainer
                            context={selectedValue}
                            mode={props.mode}
                            changeMode={props.changeMode}
                            initialData={initialData}
                            tabMenuIndex={tabMenuIndex}
                            changeTab={changeTab}
                            closePage={props.closePage}
                            activeCustomerId={props.activeCustomerId}
                            setActiveItem={props.setActiveItem}
                            notify={notify}
                            getList={props.getList}
                            clientFormSubmited={props.clientFormSubmited}
                            resetClientFormSubmited={
                              props.resetClientFormSubmited
                            }
                            config={configForm}
                            reloadDetail={reloadDetail}
                            module={props.module}
                            commissionData={commissionData}
                            getClientDetail={getClientDetail}
                          />
                        )}
                      </>
                    )}
                  {selectedValue === "CLIENT_VULNERABILITY" && (
                    <VulnerabilityForm
                      notify={notify}
                      context={selectedValue}
                      activeCustomerId={props.activeCustomerId}
                      tabMenuIndex={tabMenuIndex}
                      changeTab={changeTab}
                    />
                  )}
                  {selectedValue === "CLIENT_USER_DEFINED" && (
                    <UserDefinedForm
                      activeCustomerId={props.activeCustomerId}
                      mode={props.mode}
                      changeMode={props.changeMode}
                      changeTab={changeTab}
                      closePage={props.closePage}
                      tabMenuIndex={tabMenuIndex}
                    />
                  )}
                </div>
              </>
            )}
            {verticalTab === "outgoings" && (
              <OutgoingsForm
                activeCustomerId={props.activeCustomerId}
                notify={notify}
                setVerticalTab={setVerticalTab}
              />
            )}
            {verticalTab === "partner" && (
              <PartnerForm
                mode={props.mode}
                changeMode={props.changeMode}
                closePage={props.closePage}
                activeCustomerId={props.activeCustomerId}
                setActiveItem={props.setActiveItem}
                getList={props.getList}
                clientFormSubmited={props.clientFormSubmited}
                resetClientFormSubmited={props.resetClientFormSubmited}
                initialData={initialData}
                notify={notify}
                menu="partner"
                getClientDetail={getClientDetail}
                module={props.module}
                setVerticalTab={setVerticalTab}
                reloadDetail={props.reloadDetail}
                activeClient={props.activeClient}
              />
            )}
            {verticalTab === "dependants" && (
              <DependantForm
                activeCustomerId={props.activeCustomerId}
                notify={notify}
                setVerticalTab={setVerticalTab}
              />
            )}
            {verticalTab === "income" && (
              <IncomeForm
                activeCustomerId={props.activeCustomerId}
                activePartnerId={props.activePartnerId}
                setActivePartnerId={props.setActivePartnerId}
                notify={notify}
                setVerticalTab={setVerticalTab}
                reloadDetail={props.reloadDetail}
              />
            )}
            {verticalTab === "assets" && (
              <AssetForm
                activeCustomerId={props.activeCustomerId}
                activePartnerId={props.activePartnerId}
                setActivePartnerId={props.setActivePartnerId}
                notify={notify}
                drawerMode={props.mode}
                reloadDetail={props.reloadDetail}
              />
            )}
            {verticalTab === "liabilities" && (
              <LiabilityForm
                activeCustomerId={props.activeCustomerId}
                activePartnerId={props.activePartnerId}
                setActivePartnerId={props.setActivePartnerId}
                notify={notify}
                drawerMode={props.mode}
                reloadDetail={props.reloadDetail}
              />
            )}
            {verticalTab === "policies" && (
              <PolicyForm
                activeCustomerId={props.activeCustomerId}
                activePartnerId={props.activePartnerId}
                setActivePartnerId={props.setActivePartnerId}
                notify={notify}
                drawerMode={props.mode}
                reloadDetail={props.reloadDetail}
              />
            )}
            {verticalTab === "client_summary" && (
              <ClientSummary
                activeUser={props.activeClient}
                setVerticalTab={setVerticalTab}
              />
            )}
            {verticalTab === "case_summary" && (
              <CaseSummary
                activeUser={props.activeClient}
                setVerticalTab={setVerticalTab}
                activeCustomerId={props.activeCustomerId}
              />
            )}
            {verticalTab === "summary" && (
              <Summary
                activeUser={props.activeClient}
                activeCustomerId={props.activeCustomerId}
              />
            )}
            {verticalTab === "contact_history" && (
              <ContactHistory
                activeUser={props.activeClient}
                activeCustomerId={props.activeCustomerId}
                setActiveItem={props.setActiveItem}
                setVerticalTab={setVerticalTab}
              />
            )}
            {verticalTab === "address" && (
              <AddressBookForm
                changeMode={props.changeMode}
                closePage={props.closePage}
                setActiveItem={props.setActiveItem}
                activeCustomerId={props.activeCustomerId}
                notify={notify}
                setVerticalTab={setVerticalTab}
                activeClient={props.activeClient}
              />
            )}
            {verticalTab === "fees" && (
              <FeesForm
                changeMode={props.changeMode}
                closePage={props.closePage}
                setActiveItem={props.setActiveItem}
                activeCustomerId={props.activeCustomerId}
                notify={notify}
              />
            )}
            {verticalTab === "fact" && (
              <FactFindNoteForm
                changeMode={props.changeMode}
                closePage={props.closePage}
                setActiveItem={props.setActiveItem}
                activeCustomerId={props.activeCustomerId}
                notify={notify}
              />
            )}
            {verticalTab === "contact" && (
              <ContactForm
                mode={props.mode}
                changeMode={props.changeMode}
                closePage={props.closePage}
                activeCustomerId={props.activeCustomerId}
                setActiveItem={props.setActiveItem}
                getList={props.getList}
                clientFormSubmited={props.clientFormSubmited}
                resetClientFormSubmited={props.resetClientFormSubmited}
                initialData={initialData}
                notify={notify}
                menu="contact"
                isContactHistory={props.isContactHistory}
                selectedValue={props.selectedValue}
                tableId={props.tableId}
              />
            )}
            {verticalTab === "documents" && (
              <ClientDocument
                // changeMode={props.changeMode}
                // closePage={props.closePage}
                // setActiveItem={props.setActiveItem}
                activeCustomerId={props.activeCustomerId}
                notify={notify}
              />
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default ClientActionPage;
