import {
  Button,
  Input,
  Label,
  Select,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
} from "@fluentui/react-components";
import { ChangeEvent, useState, useEffect } from "react";
import { Edit24Filled } from "@fluentui/react-icons";
import "./style.css";
import {
  formatedDate,
  formatedDate2,
  formatedTime,
  getClientNoteCategories,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import TimePicker from "react-datepicker";
import TextEditor from "../../../components/TextEditor";
import apiServices from "../../../service";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import CreatableSelectionList from "../../../components/Creatablelist";
import Dropzone from "react-dropzone";
import UploadIcon from "../../../assets/uploadicon.svg";
import close from "../../../assets/close.svg";
import ASCButton from "../../../components/Button";

const ContactHistortForm = (props: any) => {
  const [mode, setMode] = useState("view");
  const [formField, setFormField] = useState(props.data);
  const [startDate, setStartDate] = useState(new Date());
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const noteData = {
    date: formField.date,
    time: formField.time,
    category: formField.category,
    note_text: formField.note_text,
    contact_type: formField.contact_type,
    assigned: formField.assigned,
    status: formField.status,
    file_link: formField.file_link,
  };
  const [noteFormData, setNoteFormData] = useState(noteData);
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const removeFile = (index: number) => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(index, 1);
    setUploadedFiles(newFiles);
  };

  useEffect(() => {
    getSelectionListOptions();
  }, []);

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );

  const dispatch: AppDispatch = useDispatch();

  const selectId = useId();

  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";
      if (props.data.type === "Client") {
        fieldLbl =
          "appointment_type:ModifiableList,appointment_assigned:ModifiableList,appointment_status:ModifiableList";
      } else if (props.data.type === "Notes") {
        fieldLbl = "note_type:ModifiableList,action_category:FixedList";
      } else if (props.data.type === "Case") {
        fieldLbl =
          "business_assigned:ModifiableList,business_status:ModifiableList";
      }
      if (fieldLbl !== "") {
        const res = await apiServices.selectionList.getOptions(fieldLbl);
        if (res.data && res.data.data) {
          setSelectFieldOptions(res.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const newSelectOptionAdded = (
    field: string,
    selectionLabel: string,
    selectionType: string,
    options: any,
    operation: string
  ) => {
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      let newVall: any = {
        type: selectionType,
        options: [],
      };
      newVall["options"].push({
        field: selectionLabel,
        label: options.label,
        value: options.value,
      });

      setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
    }
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {})
        .catch((err) => console.log(err));
    }
  };

  const options = [
    {
      label: "Standard Note ",
      value: 0,
    },
    {
      label: "Letter Produced",
      value: 1,
    },
    {
      label: "Email Sent",
      value: 2,
    },
    {
      label: "Email Saved ",
      value: 3,
    },
    {
      label: "Report Exported",
      value: 4,
    },
    {
      label: "Mail Merge ",
      value: 5,
    },
    {
      label: "Fact-find-Dependants",
      value: 7,
    },
    {
      label: "Fact-find-Incomes",
      value: 8,
    },
    {
      label: "Fact-find-Outgoings",
      value: 9,
    },
    {
      label: "Fact-find-Employments",
      value: 10,
    },
    {
      label: "Fact-find-Assets",
      value: 11,
    },
    {
      label: "Fact-find-Liabilities",
      value: 12,
    },
    {
      label: "Fact-find-Policies",
      value: 13,
    },
    {
      label: "Fact-find-Attitude to Risk",
      value: 14,
    },
    {
      label: "Fact-find-Objectives",
      value: 15,
    },

    {
      label: "Fact-find-Summary",
      value: 16,
    },
    {
      label: "Fact-find-Pensions",
      value: 18,
    },
    {
      label: "Fact-find-Investments",
      value: 19,
    },
    {
      label: "Fact-find-Protection",
      value: 20,
    },
    {
      label: "Fact-find-Mortgages",
      value: 21,
    },
    {
      label: "Document Uploaded",
      value: 22,
    },
    {
      label: "Document Downloaded",
      value: 23,
    },
  ];
  // console.log(formField.case_type,"formField.case_typeformField.case_typeformField.case_type")
  function updateNotes() {
    let newData;
    const formData = new FormData();
    if (props.data.type === "Notes") {
      formData.append("context", "contacts_notes");
      formData.append("note_date", noteFormData.date);
      formData.append("note_time", noteFormData.time);
      formData.append("category", noteFormData.category);
      formData.append("note_text", noteFormData.note_text);
      formData.append("note_type", noteFormData.contact_type);
      formData.append("customer_id", props.customerId);
      formData.append("file", uploadedFiles[0]);
      apiServices.notes
        .updateNotes(props.data.id, formData)
        .then((response) => {
          if (!response.data.isError) {
            notify(response.data.message, "success");
            setMode("view");
            AddSelectOptions();
          } else {
            notify("Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          notify(err.message, "error");
        });
    } else if (props.data.type === "Client") {
      newData = {
        contacts_client_action: {
          customer_id: props.customerId,
          appointment_date: noteFormData.date,
          appointment_time: noteFormData.time,
          appointment_text: noteFormData.note_text,
          appointment_note: noteFormData.contact_type,
          appointment_assigned: noteFormData.assigned,
          appointment_status: noteFormData.status,
          appointment_id: props.data.id,
        },
      };
      apiServices.contactClientAction
        .updateFactFindNote(props.customerId, props.data.id, newData)
        .then((response) => {
          if (!response.data.isError) {
            notify(response.data.message, "success");
            setMode("view");
            AddSelectOptions();
          } else {
            notify("Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          notify(err.message, "error");
        });
    } else if (props.data.type === "Case") {
      if (formField.business_category === 2) {
        newData = {
          asset_investment_actions: {
            customer_id: props.customerId,
            business_date: noteFormData.date,
            business_action: noteFormData.note_text,
            business_category: 2,
            business_status: noteFormData.status,
            business_assigned: noteFormData.assigned,
            case_id: props.data.id,
          },
        };
        apiServices.Actions.updateActions(formField.business_id, newData)
          .then((response) => {
            if (!response.data.isError) {
              notify(response.data.message, "success");
              setMode("view");
              AddSelectOptions();
            } else {
              notify("Something went wrong", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            notify(err.message, "error");
          });
      } else if (formField.business_category === 6) {
        newData = {
          liabilities_mortgages_actions: {
            customer_id: props.customerId,
            business_date: noteFormData.date,
            business_action: noteFormData.note_text,
            business_category: 6,
            business_status: noteFormData.status,
            business_assigned: noteFormData.assigned,
            case_id: props.data.id,
          },
        };
        apiServices.Actions.updateActions(formField.business_id, newData)
          .then((response) => {
            if (!response.data.isError) {
              notify(response.data.message, "success");
              setMode("view");
              AddSelectOptions();
            } else {
              notify("Something went wrong", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            notify(err.message, "error");
          });
      } else if (formField.business_category === 7) {
        newData = {
          liabilities_loan_hire_purchase_actions: {
            customer_id: props.customerId,
            business_date: noteFormData.date,
            business_action: noteFormData.note_text,
            business_category: 7,
            business_status: noteFormData.status,
            business_assigned: noteFormData.assigned,
            case_id: props.data.id,
          },
        };
        apiServices.Actions.updateActions(formField.business_id, newData)
          .then((response) => {
            if (!response.data.isError) {
              notify(response.data.message, "success");
              setMode("view");
              AddSelectOptions();
            } else {
              notify("Something went wrong", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            notify(err.message, "error");
          });
      } else if (formField.business_category === 1) {
        if (formField.case_type === "Life Assurance") {
          newData = {
            policies_life_assurance_actions: {
              customer_id: props.customerId,
              business_date: noteFormData.date,
              business_action: noteFormData.note_text,
              business_category: 1,
              business_status: noteFormData.status,
              business_assigned: noteFormData.assigned,
              case_id: props.data.id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              if (!response.data.isError) {
                notify(response.data.message, "success");
                setMode("view");
                AddSelectOptions();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Pensions") {
          newData = {
            policies_pensions_actions: {
              customer_id: props.customerId,
              business_date: noteFormData.date,
              business_action: noteFormData.note_text,
              business_category: 1,
              business_status: noteFormData.status,
              business_assigned: noteFormData.assigned,
              case_id: props.data.id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              if (!response.data.isError) {
                notify(response.data.message, "success");
                setMode("view");
                AddSelectOptions();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Invesment") {
          newData = {
            policies_investments_actions: {
              customer_id: props.customerId,
              business_date: noteFormData.date,
              business_action: noteFormData.note_text,
              business_category: 1,
              business_status: noteFormData.status,
              business_assigned: noteFormData.assigned,
              case_id: props.data.id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              if (!response.data.isError) {
                notify(response.data.message, "success");
                setMode("view");
                AddSelectOptions();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Savings Plans") {
          newData = {
            policies_savings_plans_actions: {
              customer_id: props.customerId,
              business_date: noteFormData.date,
              business_action: noteFormData.note_text,
              business_category: 1,
              business_status: noteFormData.status,
              business_assigned: noteFormData.assigned,
              case_id: props.data.id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              if (!response.data.isError) {
                notify(response.data.message, "success");
                setMode("view");
                AddSelectOptions();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Income protection") {
          newData = {
            policies_income_protection_actions: {
              customer_id: props.customerId,
              business_date: noteFormData.date,
              business_action: noteFormData.note_text,
              business_category: 1,
              business_status: noteFormData.status,
              business_assigned: noteFormData.assigned,
              case_id: props.data.id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              if (!response.data.isError) {
                notify(response.data.message, "success");
                setMode("view");
                AddSelectOptions();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Heatlh Assuerence") {
          newData = {
            policies_health_assurance_actions: {
              customer_id: props.customerId,
              business_date: noteFormData.date,
              business_action: noteFormData.note_text,
              business_category: 1,
              business_status: noteFormData.status,
              business_assigned: noteFormData.assigned,
              case_id: props.data.id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              if (!response.data.isError) {
                notify(response.data.message, "success");
                setMode("view");
                AddSelectOptions();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "General") {
          newData = {
            policies_general_actions: {
              customer_id: props.customerId,
              business_date: noteFormData.date,
              business_action: noteFormData.note_text,
              business_category: 1,
              business_status: noteFormData.status,
              business_assigned: noteFormData.assigned,
              case_id: props.data.id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              if (!response.data.isError) {
                notify(response.data.message, "success");
                setMode("view");
                AddSelectOptions();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        }
      }
    }
    // // dispatch(setDialogModalOptions({ open: false }));
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setNoteFormData({ ...noteFormData, [e.target.name]: e.target.value });
  }

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };

  return (
    <>
      <Toaster
        toasterId={toasterId}
        position="top-end"
        pauseOnHover
        pauseOnWindowBlur
        timeout={1000}
      />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Label size="large">Contact History</Label>
        </div>
        {mode === "view" && (
          <div className="button-end">
            <ASCButton
              shape="rounded"
              style={{ marginRight: 2, marginBottom: 6 }}
              icon={<Edit24Filled />}
              size="small"
              onClick={() => {
                setMode("edit");
              }}
              label="Edit"
            />
          </div>
        )}

        {props.data.type === "Notes" ? (
          <div className="contact-form-container">
            <div className="">
              <div className="settings-table-content-label">Date</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {formatedDate(noteFormData.date)}
                </div>
              )}
              {mode === "edit" && (
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  onSelectDate={(date) => {
                    if (date) {
                      setNoteFormData({
                        ...noteFormData,
                        date: formatedDate2(date),
                      });
                    }
                  }}
                  // onBlur={formik.handleBlur}
                  // maxDate={
                  //   element.maxDate !== undefined
                  //     ? validateMaxMinDate(element.maxDate)
                  //     : undefined
                  // }
                  // minDate={
                  //   element.minDate !== undefined
                  //     ? validateMaxMinDate(element.minDate)
                  //     : undefined
                  // }
                  name={"date"}
                  value={
                    formField.date !== undefined && formField.date !== ""
                      ? new Date(noteFormData.date)
                      : null
                  }
                  // firstDayOfWeek={DayOfWeek.Monday}
                />
              )}
            </div>
            <div>
              <div className="settings-table-content-label">Time</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {formatedTime(noteFormData.time)}
                </div>
              )}
              {mode === "edit" && (
                <TimePicker
                  selected={startDate}
                  onChange={(date: any) => {
                    if (date && !isNaN(date.getTime())) {
                      setStartDate(date);

                      let formatDate = new Date(date);
                      formatDate.setMinutes(
                        formatDate.getMinutes() - formatDate.getTimezoneOffset()
                      );
                      // formik.setFieldValue(fieldInfo.name, formatDate.toISOString());
                      setFormField({
                        ...formField,
                        time: formatDate.toISOString(),
                      });
                      setNoteFormData({
                        ...noteFormData,
                        time: formatDate.toISOString(),
                      });
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Contact Type</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {noteFormData.contact_type}
                </div>
              )}
              {mode === "edit" && (
                <CreatableSelectionList
                  width="410px"
                  name="note_type"
                  selectionLabel="note_type"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={noteFormData.contact_type}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, contact_type: text });
                    setNoteFormData({ ...noteFormData, contact_type: text });
                  }}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Note</div>
              {mode === "view" && (
                <div>
                  <ReactQuillComponent value={noteFormData.note_text} />
                </div>
              )}
              {mode === "edit" && (
                <TextEditor
                  inputData={noteFormData.note_text}
                  onChange={(text: string) => {
                    // formik.setFieldValue(fieldInfo.name, text);
                    setNoteFormData({ ...noteFormData, note_text: text });
                    // //setNoteFormData({ ...noteFormData, contact_type: text } );
                  }}
                  name={"note_text"}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Category</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {getClientNoteCategories(noteFormData.category)}
                </div>
              )}
              {mode === "edit" && (
                // <Select
                //   id={selectId}
                //   // appearance="filled-lighter"
                //   onChange={(e, value) =>
                //     setNoteFormData({ ...noteFormData, category: value.value })
                //   }
                //   // onBlur={formik.handleBlur}
                //   name={"category"}
                //   // disabled={fieldInfo.readOnly}
                // >
                //   {/* <option value=""></option> */}
                //   {options.map((optn: { label: string; value: number }, i) => (
                //     <option
                //       key={i}
                //       value={optn.value}
                //       label={optn.label}
                //       selected={
                //         formField.category === optn.value ? true : false
                //       }
                //     />
                //   ))}
                // </Select>
                <CreatableSelectionList
                  width="410px"
                  name="action_category"
                  selectionLabel="action_category"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="FixedList"
                  values={noteFormData.contact_type}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, contact_type: text });
                    setNoteFormData({ ...noteFormData, category: text });
                  }}
                />
              )}
            </div>
            <div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {/* {noteFormData.file_link} */}
                  View
                </div>
              )}
              {mode === "edit" && (
                <>
                  <Dropzone
                    onDrop={(acceptedFiles: File[]) => {
                      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
                      // setNoteFormData({...noteFormData, file_link:acceptedFiles[0]});
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="fileupload-container"
                        >
                          <input {...getInputProps()} />
                          <div className="file-upload">
                            <img src={UploadIcon} width={40} />
                          </div>
                          <p style={{ textAlign: "center" }}>
                            Click or Drag the file here to upload
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <div className="list-upload-item" key={index}>
                          <li>
                            {/* Render each file's name and size */}
                            {file.name} - {(file.size / 1024).toFixed(2)} KB
                            <img
                              className="file-upload-close"
                              src={close}
                              onClick={() => removeFile(index)}
                              width={20}
                            />
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : props.data.type === "Client" ? (
          <div className="contact-form-container">
            <div className="">
              <div className="settings-table-content-label">Date</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {formatedDate(noteFormData.date)}
                </div>
              )}
              {mode === "edit" && (
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  onSelectDate={(date) => {
                    if (date) {
                      setNoteFormData({
                        ...noteFormData,
                        date: formatedDate2(date),
                      });
                    }
                  }}
                  name={"note_date"}
                  value={
                    formField.date !== undefined && formField.date !== ""
                      ? new Date(noteFormData.date)
                      : null
                  }
                  // firstDayOfWeek={DayOfWeek.Monday}
                />
              )}
            </div>
            <div>
              <div className="settings-table-content-label">Time</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {formatedTime(props.data.time)}
                </div>
              )}
              {mode === "edit" && (
                <TimePicker
                  selected={startDate}
                  onChange={(date: any) => {
                    if (date && !isNaN(date.getTime())) {
                      setStartDate(date);

                      let formatDate = new Date(date);
                      formatDate.setMinutes(
                        formatDate.getMinutes() - formatDate.getTimezoneOffset()
                      );
                      // formik.setFieldValue(fieldInfo.name, formatDate.toISOString());
                      setFormField({
                        ...formField,
                        time: formatDate.toISOString(),
                      });
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Contact Type</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {noteFormData.contact_type}
                </div>
              )}
              {mode === "edit" && (
                // <Input
                //   // appearance="filled-lighter"
                //   onChange={onChange}
                //   // onBlur={formik.handleBlur}
                //   name={"contact_type"}
                //   // placeholder={fieldInfo.placeholder}
                //   // disabled={fieldInfo.readOnly}
                //   value={formField.contact_type}
                // />
                <CreatableSelectionList
                  width="410px"
                  name="appointment_type"
                  selectionLabel="appointment_type"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={noteFormData.contact_type}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, contact_type: text });
                    setNoteFormData({ ...noteFormData, contact_type: text });
                  }}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Note</div>
              {mode === "view" && (
                <div>
                  <ReactQuillComponent value={noteFormData.note_text} />
                </div>
              )}
              {mode === "edit" && (
                <TextEditor
                  inputData={noteFormData.note_text}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, note_text: text });
                    setNoteFormData({ ...noteFormData, note_text: text });
                  }}
                  name={"note_text"}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Assigned</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {noteFormData.assigned}
                </div>
              )}
              {mode === "edit" && (
                // <Input
                //   // appearance="filled-lighter"
                //   onChange={onChange}
                //   // onBlur={formik.handleBlur}
                //   name={"assigned"}
                //   // placeholder={fieldInfo.placeholder}
                //   // disabled={fieldInfo.readOnly}
                //   value={formField.assigned}
                // />
                <CreatableSelectionList
                  width="410px"
                  name="appointment_assigned"
                  selectionLabel="appointment_assigned"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={noteFormData.assigned}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, contact_type: text });
                    setNoteFormData({ ...noteFormData, assigned: text });
                  }}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Status</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {noteFormData.status}
                </div>
              )}
              {mode === "edit" && (
                // <Input
                //   // appearance="filled-lighter"
                //   onChange={onChange}
                //   // onBlur={formik.handleBlur}
                //   name={"status"}
                //   // placeholder={fieldInfo.placeholder}
                //   // disabled={fieldInfo.readOnly}
                //   value={formField.status}
                // />
                <CreatableSelectionList
                  width="410px"
                  name="appointment_status"
                  selectionLabel="appointment_status"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={noteFormData.status}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, contact_type: text });
                    setNoteFormData({ ...noteFormData, status: text });
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="contact-form-container">
            <div className="">
              <div className="settings-table-content-label">Date</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {formatedDate(noteFormData.date)}
                </div>
              )}
              {mode === "edit" && (
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  onSelectDate={(date) => {
                    if (date) {
                      setNoteFormData({
                        ...noteFormData,
                        date: formatedDate2(date),
                      });
                    }
                  }}
                  // onBlur={formik.handleBlur}
                  // maxDate={
                  //   element.maxDate !== undefined
                  //     ? validateMaxMinDate(element.maxDate)
                  //     : undefined
                  // }
                  // minDate={
                  //   element.minDate !== undefined
                  //     ? validateMaxMinDate(element.minDate)
                  //     : undefined
                  // }
                  name={"date"}
                  value={
                    formField.date !== undefined &&
                    formField.business_date !== ""
                      ? new Date(noteFormData.date)
                      : null
                  }
                  // firstDayOfWeek={DayOfWeek.Monday}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Action Note</div>
              {mode === "view" && (
                <div>
                  <ReactQuillComponent value={noteFormData.note_text} />
                </div>
              )}
              {mode === "edit" && (
                <TextEditor
                  inputData={noteFormData.note_text}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, note_text: text });
                    setNoteFormData({ ...noteFormData, note_text: text });
                  }}
                  name={"note_text"}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Assigned</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {noteFormData.assigned}
                </div>
              )}
              {mode === "edit" && (
                <CreatableSelectionList
                  width="410px"
                  name="business_assigned"
                  selectionLabel="business_assigned"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={noteFormData.assigned}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, business_assigned: text });
                    setNoteFormData({ ...noteFormData, assigned: text });
                  }}
                />
              )}
            </div>

            <div className="">
              <div className="settings-table-content-label">Status</div>
              {mode === "view" && (
                <div className="settings-table-content-value">
                  {noteFormData.status}
                </div>
              )}
              {mode === "edit" && (
                <CreatableSelectionList
                  width="410px"
                  name="business_status"
                  selectionLabel="business_status"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={noteFormData.status}
                  onChange={(text: string) => {
                    // setFormField({ ...formField, business_status: text });
                    setNoteFormData({ ...noteFormData, status: text });
                  }}
                />
              )}
            </div>
          </div>
        )}

        {mode === "edit" && (
          <div className="button-end-popup">
            <ASCButton
              shape="rounded"
              className="asc-button-primary"
              appearance="primary"
              onClick={() => updateNotes()}
              label="Save"
            />

            <ASCButton
              shape="rounded"
              appearance="secondary"
              onClick={() => setMode("view")}
              label="Cancel"
            />
          </div>
        )}

        {mode === "view" && (
          <div className="button-end-popup">
            <ASCButton
              shape="rounded"
              // className="asc-button-primary"
              appearance="secondary"
              onClick={() => dispatch(setDialogModalOptions({ open: false }))}
              label="Close"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ContactHistortForm;
