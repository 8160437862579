import { useEffect, useState } from "react";
import {
  Divider,
  makeStyles,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import "./style.css";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import {
  administration,
  clientDetail,
  partnerDetail,
  compliance,
  review,
  // review,
} from "../../../shared/clientSummary";
import apiServices from "../../../service";
import {
  calculateAge,
  formatedDate,
  formatedDate2,
  formatedTime,
  getYes_NoConversion,
  removeEmptyValues,
} from "../../../utils";

interface PropsType {
  [x: string]: any;
}

const useStyles = makeStyles({
  boxHeading: {
    fontWeight: "bolder",
    marginBottom: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "18px",
  },
  card: {
    width: "100%",
    height: "fit-content",
    marginBottom: "10px",
  },
});
interface ClientData {
  [key: string]: string | number | boolean | any;
}
interface PartnerData {
  [key: string]: string | number | boolean | any;
}

const ClientSummary = (props: PropsType) => {
  let { context } = props;
  // const [initialData, setInitialData] = useState({});
  const [noteData, setNoteData] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [clientData, setClientData] = useState<ClientData>({});
  const [partnerData, setPartnerData] = useState<PartnerData>({});
  const [activePartnerId, setActivePartnerId] = useState("");

  const styles = useStyles();

  const getClientDetails = (id: string) => {
    apiServices.client
      .getDetail(id)
      .then((response: any) => {
        if (!response.data.isError) {
          if (response.data.data) {
            setClientData(response.data.data);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getPartnerDetails = (id: string) => {
    apiServices.client
      .getClientDetail(id)
      .then((response: any) => {
        if (!response.data.isError) {
          if (
            response.data.data &&
            Object.keys(response.data.data).length !== 0
          ) {
            if (response.data.data?.customer_id) {
              setPartnerData(response.data.data);
            }
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getClientNotes = async (id: string) => {
    try {
      const response = await apiServices.notes.getList(id); // Changed from getRecent to getList
      setNoteData(response.data.data);
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const getClientActions = async (id: string) => {
    try {
      const response = await apiServices.contactClientAction.getList(
        id,
        formatedDate2(new Date())
      );
      setActionData(response.data.data);
    } catch (e: any) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (
      Object.keys(clientData).length !== 0 &&
      clientData.NameAndAddress.associated_type === 0 &&
      clientData.NameAndAddress.associated_with !== "" &&
      clientData.NameAndAddress.associated_with !== null
    ) {
      getPartnerDetails(clientData.NameAndAddress.associated_with);
      setActivePartnerId(clientData.NameAndAddress.associated_with);
    }
  }, [clientData]);
  useEffect(() => {
    if (props.clientId) getClientNotes(props.clientId);
    getClientActions(props.clientId);
    getClientDetails(props.clientId);
    getPartnerDetails(props.clientId);
  }, [props.clientId]);

  return (
    <div>
      <div className="client-grid">
        <div className="left_grid">
          {clientData.Administration?.note_text && (
            <div className="health-line-note">
              <ReactQuillComponent
                value={clientData.Administration?.note_text}
              />
            </div>
          )}

          <Accordion
            multiple
            collapsible
            defaultOpenItems="client"
            style={{ height: "calc(100vh - 250px)" }}
          >
            <AccordionItem
              value="client"
              className="detail-component"
              style={{ border: "nome", outline: "none" }}
            >
              <AccordionHeader>
                <h3>Client Details</h3>
              </AccordionHeader>

              <AccordionPanel>
                <div className="detail-grid">
                  {Object.keys(clientData).length !== 0 &&
                    clientDetail.length > 0 &&
                    clientDetail.map((client) => (
                      <div key={client.clientDetail_id}>
                        <p className="detail-label">{client.label}</p>
                        <p className="detail-value">
                          {(() => {
                            switch (client.value) {
                              case "date_of_birth":
                                return (
                                  formatedDate(
                                    clientData.Personal.date_of_birth
                                  ) || "-"
                                );
                              case "state_retirement_date":
                                return (
                                  formatedDate(
                                    clientData.Personal.state_retirement_date
                                  ) || "-"
                                );
                              case "age_next":
                                return calculateAge(
                                  clientData.Personal.date_of_birth
                                );
                              default:
                                return (
                                  clientData.NameAndAddress[client.value] ||
                                  clientData.Contact[client.value] ||
                                  clientData.Nationality[client.value] ||
                                  clientData.Personal[client.value] ||
                                  "-"
                                );
                            }
                          })()}
                        </p>
                      </div>
                    ))}
                </div>
              </AccordionPanel>
            </AccordionItem>
            <Divider />
            {clientData &&
            clientData.NameAndAddress?.associated_with !== null ? (
              <AccordionItem
                value="partner"
                className="detail-component"
                style={{ border: "nome", outline: "none" }}
              >
                <AccordionHeader>
                  <h3>Partner Details</h3>
                </AccordionHeader>

                <AccordionPanel>
                  <div className="detail-grid">
                    {Object.keys(partnerData).length !== 0 &&
                      partnerDetail.length > 0 &&
                      partnerDetail.map((partner) => (
                        <div key={partner.partnerDetail_id}>
                          <p className="detail-label">{partner.label}</p>
                          <p className="detail-value">
                            {(() => {
                              switch (partner.value) {
                                case "date_of_birth":
                                  return (
                                    formatedDate(
                                      partnerData.Personal.date_of_birth
                                    ) || "-"
                                  );
                                case "state_retirement_date":
                                  return (
                                    formatedDate(
                                      partnerData?.Personal
                                        ?.state_retirement_date
                                    ) || "-"
                                  );
                                case "age_next":
                                  return calculateAge(
                                    partnerData.Personal.date_of_birth
                                  );
                                default:
                                  return (
                                    partnerData.NameAndAddress[partner.value] ||
                                    partnerData.Contact[partner.value] ||
                                    partnerData.Nationality[partner.value] ||
                                    partnerData.Personal[partner.value] ||
                                    "-"
                                  );
                              }
                            })()}
                          </p>
                        </div>
                      ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            ) : null}
            <Divider />
            <AccordionItem
              value="admin"
              className="detail-component"
              style={{ border: "nome", outline: "none" }}
            >
              <AccordionHeader>
                <h3>Administration</h3>
              </AccordionHeader>

              <AccordionPanel>
                <div className="detail-grid">
                  {Object.keys(clientData).length !== 0 &&
                    administration.length > 0 &&
                    administration.map((admin) => {
                      if (!admin.value) return null;

                      // Retrieve the corresponding value
                      let value = "-";

                      if (admin.value === "client_since") {
                        value =
                          formatedDate(
                            clientData.Administration?.client_since
                          ) || "-";
                      } else if (admin.value === "pending") {
                        value =
                          getYes_NoConversion(
                            clientData.Administration?.pending
                          ) || "-";
                      } else if (admin.value === "vulnerability") {
                        // Handle separate vulnerabilities for client and partner
                        if (admin.type === "client") {
                          value = clientData.Personal?.vulnerability || "-";
                        } else if (
                          clientData?.NameAndAddress?.associated_with !==
                            null &&
                          admin.type === "partner"
                        ) {
                          Object.keys(partnerData).length !== 0 &&
                            (value =
                              partnerData.Personal?.vulnerability || "-");
                        }
                      } else {
                        // General fallback for other fields
                        value =
                          clientData.Administration?.[admin.value] ||
                          clientData.Personal?.[admin.value] ||
                          clientData.servicetype?.[0]?.[admin.value] ||
                          "-";
                      }

                      return (
                        <div key={admin.admin_id}>
                          <p className="detail-label">{admin.label}</p>
                          <p className="detail-value">{value}</p>
                        </div>
                      );
                    })}
                </div>
              </AccordionPanel>
            </AccordionItem>
            <Divider />
            <AccordionItem
              value="review"
              className="detail-component"
              style={{ border: "nome", outline: "none" }}
            >
              <AccordionHeader>
                <h3>Review</h3>
              </AccordionHeader>

              <AccordionPanel>
                <div className="detail-grid">
                  {Object.keys(clientData).length !== 0 &&
                    review.length > 0 &&
                    review.map((reviews) => (
                      <div key={reviews.review_id}>
                        <p className="detail-label">{reviews.label}</p>
                        <p className="detail-value">
                          {(() => {
                            switch (reviews.value) {
                              case "review_completed":
                                return (
                                  formatedDate(
                                    clientData.Administration.review_completed
                                  ) || "-"
                                );
                              case "review_date":
                                return (
                                  formatedDate(
                                    clientData.Administration.review_date
                                  ) || "-"
                                );
                              case "review_date":
                                return (
                                  formatedDate(
                                    clientData.Administration.review_date
                                  ) || "-"
                                );
                              case "review_note":
                                return (
                                  (
                                    <>
                                      {clientData.Administration.note_text ? (
                                        <ReactQuillComponent
                                          value={
                                            clientData.Administration
                                              .review_note
                                          }
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </>
                                  ) || "-"
                                );

                              default:
                                return (
                                  clientData.Administration[reviews.value] ||
                                  "-"
                                );
                            }
                          })()}
                        </p>
                      </div>
                    ))}
                </div>
              </AccordionPanel>
            </AccordionItem>
            <Divider />
            <AccordionItem
              value="compliance"
              className="detail-component"
              style={{ border: "nome", outline: "none" }}
            >
              <AccordionHeader>
                <h3>Compliance</h3>
              </AccordionHeader>

              <AccordionPanel>
                <div className="detail-grid">
                  {Object.keys(clientData).length !== 0 &&
                    compliance.length > 0 &&
                    compliance.map((complianceDetail) => (
                      <div key={complianceDetail.compliance_id}>
                        <p className="detail-label">{complianceDetail.label}</p>
                        <p className="detail-value">
                          {(() => {
                            switch (complianceDetail.value) {
                              case "business_card":
                                return (
                                  formatedDate(
                                    clientData.compliance.business_card
                                  ) || "-"
                                );
                              case "terms_of_business_sent":
                                return (
                                  formatedDate(
                                    clientData.compliance.terms_of_business_sent
                                  ) || "-"
                                );
                              case "fact_find_completed":
                                return (
                                  formatedDate(
                                    clientData.compliance.fact_find_completed
                                  ) || "-"
                                );
                              case "data_protection_form_sent":
                                return (
                                  formatedDate(
                                    clientData.compliance
                                      .data_protection_form_sent
                                  ) || "-"
                                );
                              case "risk_profile":
                                return (
                                  formatedDate(
                                    clientData.compliance.risk_profile
                                  ) || "-"
                                );
                              case "verification_of_identity":
                                return (
                                  formatedDate(
                                    clientData.compliance
                                      .verification_of_identity
                                  ) || "-"
                                );
                              case "sanctions_check":
                                return (
                                  formatedDate(
                                    clientData.compliance.sanctions_check
                                  ) || "-"
                                );
                              case "created":
                                return (
                                  formatedDate(
                                    clientData.NameAndAddress.created
                                  ) || "-"
                                );
                              case "modified":
                                return (
                                  formatedDate(
                                    clientData.NameAndAddress.modified
                                  ) || "-"
                                );

                              default:
                                return (
                                  clientData.NameAndAddress[
                                    complianceDetail.value
                                  ] ||
                                  clientData.compliance[
                                    complianceDetail.value
                                  ] ||
                                  clientData[complianceDetail.value] ||
                                  "-"
                                );
                            }
                          })()}
                        </p>
                      </div>
                    ))}
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="right_grid">
          <div className="detail-component">
            <h3>Most Recent Note</h3>
            <Divider />
            <div className="recent_data">
              {noteData &&
                noteData.length > 0 &&
                noteData.map((note: any) => (
                  <div className="white-background" key={note.note_id}>
                    <div className="note-actions">
                      <p className="detail-label">
                        {formatedDate(note?.note_date) || "-"}
                      </p>
                      <p className="detail-label">
                        {formatedTime(note?.note_time) || "-"}
                      </p>
                      <p>{note?.note_type || ""}</p>
                    </div>
                    <div className="actions-notes">
                      <p className="notes">
                        <ReactQuillComponent value={note?.note_text || "-"} />
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="detail-component">
            <h3>Outstanding Client Actions</h3>
            <Divider />
            <div className="recent_data">
              {actionData &&
                actionData.length > 0 &&
                actionData.map((note: any) => (
                  <div className="white-background" key={note?.appointment_id}>
                    <div className="note-actions">
                      <p className="detail-red">
                        {formatedDate(note?.appointment_date) || "-"}
                      </p>
                      <p className="detail-label">
                        {formatedTime(note?.appointment_time) || "-"}
                      </p>
                      <p>{note?.appointment_type || ""}</p>
                    </div>
                    <div className="actions-notes">
                      <p className="notes">
                        <ReactQuillComponent
                          value={note?.appointment_note || "-"}
                        />
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSummary;
