import * as DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import ReactShowMoreText from "react-show-more-text";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import "./style.css";

const ReactQuillComponent = ({ value }: any) => {
  const dispatch: AppDispatch = useDispatch();
  //  //const convertedValue = DOMPurify.sanitize(value);
  return (
    <div className="note_lineClamp">
      <ReactShowMoreText
        lines={2}
        more=""
        less=""
        className="content-css"
        anchorClass="show-more-less-clickable"
        onClick={() => {
          dispatch(
            setDialogModalOptions({
              open: true,
              content_line_1: value,
              cancel: {
                onclick: () => {
                  dispatch(setDialogModalOptions({ open: false }));
                },
                label: "Close",
              },
            })
          );
        }}
        expanded={false}
        width={0}
        truncatedEndingComponent={
          <div
            className="underline-on-hover"
            onClick={() => {
              dispatch(
                setDialogModalOptions({
                  open: true,
                  showMore_text: value,
                  cancel: {
                    onclick: () => {
                      dispatch(setDialogModalOptions({ open: false }));
                    },
                    label: "Close",
                  },
                })
              );
            }}
          >
            &nbsp;...View More
          </div>
        }
      >
        <ReactQuill
          value={DOMPurify.sanitize(value)}
          readOnly={true}
          theme={"bubble"}
        />
      </ReactShowMoreText>
    </div>
  );
};

export default ReactQuillComponent;
