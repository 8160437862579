import { Button, Checkbox, Input, Label } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import ASCButton from "../../components/Button";

const SaveSpecification = ({
  BackToTree,
  MoveToConfirmation,
  CancelSaveSpecification,
  onSaveData,
  setValue,
  value,
  title,
  description,
  setTitle,
  setDescription,
  setIsAvailable,
  isAvailable,
}: {
  BackToTree: () => void;
  MoveToConfirmation: () => void;
  CancelSaveSpecification: () => void;
  onSaveData: (data: {
    title: string;
    description: string;
    isAvailable: boolean | string;
  }) => void;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  title: any;
  description: any;
  isAvailable: boolean | string;
  setIsAvailable: React.Dispatch<React.SetStateAction<boolean | string>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const userId = store.getState().authUser.userId;
  const { userProfileInfo } = useSelector((state: any) => state.authUser);

  const handleNextClick = () => {
    onSaveData({ title, description, isAvailable });
    MoveToConfirmation();
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAvailable(event.target.checked ? true : false);
  };

  // useEffect(() => {
  //   setValue(userProfileInfo.user_id);
  // }, [userProfileInfo.user_id]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
      <div>
        <span style={{ fontWeight: 700, fontSize: "16px" }}>
          Save Specifications
        </span>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div
            className="title"
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "1fr 3fr",
            }}
          >
            <Label style={{ fontWeight: "600" }}>Title</Label>
            <Input
              style={{
                border: "1px solid #CDCDCD",
                borderRadius: "0px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)} // Update title state
            />
          </div>

          <div
            className="description"
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "1fr 3fr",
            }}
          >
            <Label style={{ fontWeight: "600" }}>Description</Label>
            <Input
              style={{
                border: "1px solid #CDCDCD",
                borderRadius: "0px",
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)} // Update description state
            />
          </div>
        </div>
        <div>
          <Checkbox
            label="Is this extract available to all Users"
            checked={!!isAvailable} // This will be true if UserId is null
            onChange={handleCheckboxChange}
            value={value || ""}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <ASCButton
            shape="rounded"
            appearance="secondary"
            size="small"
            onClick={BackToTree}
            label="Back"
          />
          <ASCButton
            shape="rounded"
            appearance="primary"
            className="asc-button-primary"
            size="small"
            onClick={handleNextClick}
            label="Next"
          />
          <ASCButton
            shape="rounded"
            appearance="secondary"
            size="small"
            onClick={CancelSaveSpecification}
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default SaveSpecification;
