import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the value
interface SelectedRuleItem {
  partyType?: string;
  CaseType?: string;
  partyName?: string;
  Amount?: string; // Consider changing to number if needed
  Note?: string;
  id?: string;
}

export const commissionSlice = createSlice({
  name: "commission",
  initialState: {
    listItems: [],
    filteredData: {},
    selectedRuleItem: {} as SelectedRuleItem,
  },
  reducers: {
    setlistItems: (state, action) => {
      state.listItems = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setSelectedRuleItem: (state, action) => {
      state.selectedRuleItem = action.payload;
    },
  },
});

export const { setlistItems, setFilteredData, setSelectedRuleItem } =
  commissionSlice.actions;

export default commissionSlice.reducer;
