import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Label,
  makeStyles,
  tokens,
  useToastController,
  useId,
  Toaster,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getAssetDetailById,
  getAssetPaymentDetailById,
  getAssetPaymentsListItems,
} from "../../../../redux/Case/assetSlice";
import { getCaseCategoryByCaseType } from "../../../../utils";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import CaseDetailDrawer from "../CaseDetailDrawer";
import {
  getLiabilityDetailById,
  getLiabilityPaymentDetailById,
  getLiabilityPaymentsListItems,
} from "../../../../redux/Case/liabilitySlice";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

function LiabilityPayments({ tab }: { tab: string }) {
  const navigate = useNavigate();
  const styles = useStyles();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const { clientId, action, caseId, subItemId } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const liabilities = useSelector((state: RootState) => state.liabilities);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);

  const [caseType, setCaseType] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getLiabilityDetailById({ id: caseId, customerId: clientId }));
      dispatch(
        getLiabilityPaymentsListItems({ id: caseId, customerId: clientId })
      );
    }
  }, [caseId, clientId, dispatch]);

  useEffect(() => {
    if (liabilities?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(liabilities.activeItem.case_type));
    }
  }, [liabilities?.activeItem]);

  useEffect(() => {
    if (
      action &&
      action != "add" &&
      liabilities?.payments.listItems.length > 0
    ) {
      const paymentId =
        subItemId || liabilities.payments.listItems[0]?.payment.payment_id;
      navigate(
        `/clients/${clientId}/liabilities/${caseId}/payments/${paymentId}/${tab}/${
          action || "view"
        }`
      );
    }
  }, [
    clientId,
    caseId,
    subItemId,
    tab,
    action,
    liabilities.payments.listItems,
    navigate,
  ]);

  useEffect(() => {
    if (subItemId) {
      dispatch(getLiabilityPaymentDetailById(subItemId));
    }
  }, [subItemId, dispatch]);

  return (
    <div>
      <CaseNavList
        subItem="payments"
        section="liabilities"
        tab={tab}
        caseType={caseType}
        caseDetail={liabilities?.payments.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="liabilities"
          caseType={caseType}
          caseDetail={liabilities?.payments.activeItem}
          caseItemList={liabilities?.payments.listItems}
          tab={tab}
          subItem="payments"
          paymentValue={liabilities?.payments.totalPayment}
        />
        <div>
          {liabilities?.payments.listLoading ? (
            <ContentLoader />
          ) : (
            <>
              {liabilities?.payments.listItems.length > 0 && (
                <CaseList
                  module="payments"
                  data={liabilities?.payments.listItems}
                  activeItem={liabilities?.payments.activeItem}
                  section="liabilities"
                  activeTab={tab}
                />
              )}
              {!action && liabilities?.payments.listItems.length === 0 && (
                <div className={styles.emptyState}>
                  <Label size="large" style={{ color: "red" }}>
                    There are no active records - click Add New to create a new
                    record
                  </Label>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={liabilities?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default LiabilityPayments;
