import * as React from "react";
import {
  makeStyles,
  Tab,
  TabList,
  shorthands,
} from "@fluentui/react-components";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { checkUserPermission } from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { UserPermissionsList } from "../../config";
import { values } from "lodash";
const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    color: "#fff",
    ...shorthands.padding("50px 20px"),
    rowGap: "20px",
    margin: "0px 10px",
  },
});

const Navbar = () => {
  const styles = useStyles();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\/+/, "");
  const isSystemManagerPath = currentPath.startsWith("system-manager");
  const isCommissionPath = currentPath.startsWith("commission");

  const hiddenNavItems = new Set(
    (process.env.REACT_APP_HIDE_NAV_MENU || "").split(",")
  );

  const permissions = {
    clientReports: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_CLIENT_REPORTS
    ),
    addUser: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_ADD_USER
    ),
    updateUser: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_UPDATE_USER
    ),
    viewUser: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_VIEW_USER
    ),
    deleteUser: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_DELETE_USER
    ),
    commissionReceipting: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_COMMISSION_RECEIPTING
    ),
    addClient: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_ADD_CLIENT
    ),
    viewClient: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_VIEW_CLIENT
    ),
    updateClient: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_UPDATE_CLIENT
    ),
    restrictedClient: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_RESTRICTED_CLIENT
    ),
    commissionDistribution: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_COMMISSION_DISTRIBUTION
    ),
    commissionReports: checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_COMMISSION_REPORTS
    ),
  };

  const navItems = React.useMemo(() => {
    const items = [
      { value: "action-list", label: "Action List", link: "/action-list" },
      { value: "clients", label: "Clients", link: "/" },
      { value: "utilities", label: "Utilities", link: "/utilities/email" },
    ];

    if (permissions.clientReports) {
      items.push({ value: "report", label: "Reports", link: "/report" });
    }

    if (
      permissions.addUser ||
      permissions.updateUser ||
      permissions.viewUser ||
      permissions.deleteUser
    ) {
      items.push({
        value: "system-manager",
        label: "System Manager",
        link: "/system-manager/company-details",
      });
    }

    if (
      permissions.commissionReceipting ||
      permissions.addClient ||
      permissions.viewClient ||
      permissions.updateClient ||
      permissions.restrictedClient ||
      permissions.commissionDistribution ||
      permissions.commissionReports
    ) {
      items.push({
        value: "commission",
        label: "Commissions",
        link: "/commission",
      });
    }

    return items.filter((item) => !hiddenNavItems.has(item.value));
  }, [permissions, hiddenNavItems]);

  const handleNavigationLink = (link: string) => {
    navigate(link);
  };

  const isClientsPath = currentPath.includes("clients");
  const selectedTab = isSystemManagerPath
    ? "system-manager"
    : isCommissionPath
    ? "commission"
    : isClientsPath
    ? "clients"
    : currentPath || "clients";

  return (
    <div className={styles.root}>
      <TabList selectedValue={selectedTab}>
        {navItems.map((item, i) => (
          <Tab
            key={i}
            disabled={hiddenNavItems.has(item.value)}
            value={item.value}
            onClick={() => handleNavigationLink(item.link)}
          >
            {item.label}
          </Tab>
        ))}
      </TabList>
    </div>
  );
};

export default Navbar;
