import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StringState {
  value: string;
}

const initialState: StringState = {
  value: "",
};

const stringSlice = createSlice({
  name: "selectionType",
  initialState,
  reducers: {
    setSelectionType: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    clearSelectionType: (state) => {
      state.value = "";
    },
  },
});

export const { setSelectionType, clearSelectionType } = stringSlice.actions;
export default stringSlice.reducer;
