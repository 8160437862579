import { useEffect, useState } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Select,
  Checkbox,
} from "@fluentui/react-components";
import apiServices from "../../service";

import "./style.css";
type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};

export const MasterDataTable = (props: ComponentProps) => {
  const columns =
    props.type === "attitudeToRisk"
      ? [
          { columnKey: "check", label: "" },
          { columnKey: "category", label: "Category" },
          { columnKey: "rating", label: "Rating" },
        ]
      : props.type === "income"
      ? [
          { columnKey: "check", label: "" },
          { columnKey: "sort_order", label: "Sortorder" },
          { columnKey: "description", label: "Description" },
          { columnKey: "frequency", label: "Frequency" },
        ]
      : props.type === "outgoings"
      ? [
          { columnKey: "check", label: "" },
          { columnKey: "sort_order", label: "Sortorder" },
          { columnKey: "description", label: "Description" },
          { columnKey: "discretionary", label: "Type" },
          { columnKey: "frequency", label: "Frequency" },
        ]
      : props.type === "objectives"
      ? [
          { columnKey: "check", label: "" },
          { columnKey: "sort_order", label: "Sortorder" },
          { columnKey: "detail", label: "Detail" },
        ]
      : [];

  const [mainData, setMainData] = useState<any>([]);
  const [secondaryData, setSecondaryData] = useState([]);
  const [tableItem, setTableItem] = useState<
    Array<{ category: any; rating: JSX.Element }>
  >([]);
  const [tableDataItem, setTableDataItem] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectValues, setSelectValues] = useState<any[]>([]);

  useEffect(() => {
    if (props.type === "attitudeToRisk") {
      fetchData();
    } else if (props.type === "income") {
      fetchIncomeData();
    } else if (props.type === "outgoings") {
      fetchExpenseData();
    } else if (props.type === "objectives") {
      fetchOutgoingsData();
    }
  }, []);

  useEffect(() => {
    if (!loader) {
      if (props.type === "attitudeToRisk") {
        loadAttitudeToRiskData();
      } else if (props.type === "income") {
        loadIncomeData();
      } else if (props.type === "outgoings") {
        loadExpenseData();
      } else if (props.type === "objectives") {
        loadObjectiveData();
      }
    }
  }, [mainData, secondaryData, loader]);

  const getExpenceData = async () => {
    try {
      const response =
        await apiServices.systemManager.expenseCategory.getList();

      if (!response.data.isError && response.data.data) {
        setMainData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getIncomeceData = async () => {
    try {
      const response = await apiServices.systemManager.incomeCategory.getList();

      if (!response.data.isError && response.data.data) {
        setMainData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getObjectiveceData = async () => {
    try {
      const response = await apiServices.systemManager.objectives.getList();

      if (!response.data.isError && response.data.data) {
        setMainData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchExpenseData = async () => {
    try {
      await getExpenceData();
      loadExpenseData();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchIncomeData = async () => {
    try {
      await getIncomeceData();
      loadIncomeData();
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOutgoingsData = async () => {
    try {
      await getObjectiveceData();
      loadObjectiveData();
    } catch (err) {
      console.log(err);
    }
  };
  const fetchData = async () => {
    try {
      setLoader(true);

      await getDataAttitudeToRiskCategory();
      await getDataAttitudeToRiskRating();

      loadAttitudeToRiskData();

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };
  const getDataAttitudeToRiskCategory = async () => {
    try {
      const res =
        await apiServices.systemManager.attitudeToRiskcategory.getList();
      if (res.data && res.data.data) {
        setMainData(res.data.data);
        setSelectValues(Array(res.data.data.length).fill(""));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDataAttitudeToRiskRating = async () => {
    try {
      const res =
        await apiServices.systemManager.attitudeToRiskRating.getList();
      if (res.data && res.data.data) {
        setSecondaryData(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onRatingChange = (data: any, index: any, value: any) => {
    setSelectValues((prevSelectValues: any) => {
      // Get the previous value for this index
      const previousValue = prevSelectValues[index];

      // Update selectValues with the new value
      const updatedValues = [...prevSelectValues]; // Clone the array
      updatedValues[index] = value;

      // Update checkedItems if necessary
      if (previousValue !== value) {
        props.setCheckedItems((prevCheckedItems: any) => {
          // Find the existing item with the same category_id
          const existingItemIndex = prevCheckedItems.findIndex(
            (item: any) =>
              item.category_id === data.id && item.rating_id === previousValue
          );

          if (existingItemIndex !== -1) {
            // If the previous value exists, we need to update or remove it
            if (value === "") {
              // If the new value is empty, remove the item
              return prevCheckedItems.filter(
                (item: any) =>
                  item.category_id !== data.id ||
                  item.rating_id !== previousValue
              );
            } else {
              // Update the item with the new rating_id
              const updatedItems = [...prevCheckedItems];
              updatedItems[existingItemIndex] = {
                category_id: data.id,
                rating_id: value,
              };
              return updatedItems;
            }
          } else if (value !== "") {
            // If the previous value was not found and the new value is not empty, add it
            return [
              ...prevCheckedItems,
              { category_id: data.id, rating_id: value },
            ];
          }

          // If nothing changes, return the previous state
          return prevCheckedItems;
        });
      }
      return updatedValues; // Return the updated selectValues
    });
  };

  const loadIncomeData = () => {
    const mappedArray = mainData.map((data: any, index: number) => {
      return {
        sort_order: data.sort_order,
        description: data.description,
        frequency: data.frequency,
        checkBox: (
          <Checkbox
            value={Object.values({
              income_category_id: data.id,
            })}
            onChange={() => handleCheckboxIncome(data.id)}
          />
        ),
      };
    });
    setTableDataItem(mappedArray);
  };

  const loadExpenseData = () => {
    const mappedArray = mainData.map((data: any, index: number) => {
      return {
        sort_order: data.sort_order,
        description: data.description,
        frequency: data.frequency,
        discretionary: data.discretionary,
        checkBox: (
          <Checkbox
            value={Object.values({
              expense_category_id: data.id,
            })}
            onChange={() => handleCheckboxExpense(data.id)}
          />
        ),
      };
    });
    setTableDataItem(mappedArray);
  };
  const loadObjectiveData = () => {
    const mappedArray = mainData.map((data: any, index: number) => {
      return {
        sort_order: data.sort_order,
        detail: data.detail,
        checkBox: (
          <Checkbox
            value={Object.values({
              detail_id: data.id,
            })}
            onChange={() => handleCheckbox(data.id)}
          />
        ),
      };
    });
    setTableDataItem(mappedArray);
  };
  const isCheckboxEnabled = (id: any) => {
    return props.checkedItems.some(
      (item: any) => item.category_id === id && item.rating_id !== ""
    );
  };
  const loadAttitudeToRiskData = () => {
    if (!loader) {
      const mappedArray = mainData.map((data: any, index: number) => {
        return {
          category: data.category,
          rating: (
            <Select
              onChange={(e) => onRatingChange(data, index, e.target.value)}
              // value={selectValues[index]}
              id={data.id}
            >
              <option value={""} label="" />
              {secondaryData.map((option: any) => (
                <option
                  key={option.id}
                  value={option.id}
                  label={option.rating}
                />
              ))}
            </Select>
          ),
          checkBox: (
            <Checkbox
              value={Object.values({
                category_id: data.id,
                rating_id: selectValues,
              })}
              onChange={() =>
                handleCheckboxChange(data.id, selectValues[index])
              }
            />
          ),
        };
      });
      setTableItem(mappedArray);
    }
  };

  const handleCheckboxChange = (dataId: any, ratingId: any) => {
    props.setCheckedItems((prev: []) => {
      const isChecked = prev.some(
        (item: any) =>
          item.category_id === dataId && item.rating_id === ratingId
      );

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter(
          (item: any) =>
            !(item.category_id === dataId && item.rating_id === ratingId)
        );
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { category_id: dataId, rating_id: ratingId }];
      }
    });
  };

  const handleCheckbox = (dataId: any) => {
    props.setCheckedItems((prev: []) => {
      const isChecked = prev.some((item: any) => item.detail_id === dataId);

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter((item: any) => item.detail_id !== dataId);
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { detail_id: dataId }];
      }
    });
  };

  const handleCheckboxIncome = (dataId: any) => {
    props.setCheckedItems((prev: []) => {
      const isChecked = prev.some(
        (item: any) => item.income_category_id === dataId
      );

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter((item: any) => item.income_category_id !== dataId);
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { income_category_id: dataId }];
      }
    });
  };

  const handleCheckboxExpense = (dataId: any) => {
    props.setCheckedItems((prev: []) => {
      const isChecked = prev.some(
        (item: any) => item.expense_category_id === dataId
      );

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter((item: any) => item.expense_category_id !== dataId);
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { expense_category_id: dataId }];
      }
    });
  };

  const tableBody = () => {
    if (props.type === "attitudeToRisk") {
      return tableItem.map((items: any) => (
        <TableRow key={items.category}>
          <TableCellLayout media={items.checkBox}></TableCellLayout>

          <TableCell>
            <TableCellLayout>{items.category}</TableCellLayout>
          </TableCell>

          <TableCellLayout media={items.rating}></TableCellLayout>
        </TableRow>
      ));
    } else if (props.type === "income") {
      return tableDataItem.map((items: any) => (
        <TableRow key={items.id}>
          <TableCellLayout media={items.checkBox}></TableCellLayout>
          <TableCell>
            <TableCellLayout>{items.sort_order}</TableCellLayout>
          </TableCell>
          <TableCell>
            <TableCellLayout>{items.description}</TableCellLayout>
          </TableCell>

          <TableCell>
            <TableCellLayout>{items.frequency}</TableCellLayout>
          </TableCell>
        </TableRow>
      ));
    } else if (props.type === "outgoings") {
      return tableDataItem.map((items: any) => (
        <TableRow key={items.id}>
          <TableCellLayout media={items.checkBox}></TableCellLayout>

          <TableCell>
            <TableCellLayout>{items.sort_order}</TableCellLayout>
          </TableCell>
          <TableCell>
            <TableCellLayout>{items.description}</TableCellLayout>
          </TableCell>

          <TableCell>
            <TableCellLayout>{items.discretionary}</TableCellLayout>
          </TableCell>

          <TableCell>
            <TableCellLayout>{items.frequency}</TableCellLayout>
          </TableCell>
        </TableRow>
      ));
    } else if (props.type === "objectives") {
      return tableDataItem.map((items: any) => (
        <TableRow key={items.id}>
          <TableCellLayout media={items.checkBox}></TableCellLayout>
          <TableCell>
            <TableCellLayout>{items.sort_order}</TableCellLayout>
          </TableCell>
          <TableCell>
            <TableCellLayout>{items.detail}</TableCellLayout>
          </TableCell>
        </TableRow>
      ));
    } else return [];
  };

  return (
    <Table>
      <TableHeader as="thead">
        <TableRow aria-rowindex={1}>
          {columns.map((column) => (
            <TableHeaderCell
              key={column.columnKey}
              style={{ fontWeight: "700" }}
            >
              {column.label}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>{tableBody()}</TableBody>
    </Table>
  );
};
