import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
} from "@fluentui/react-components";
import ASCButton from "../Button";

function ConfirmationDeletePopup(props: any) {
  return (
    <Dialog open={props.dependencyDeletePopup}>
      <DialogSurface>
        <DialogBody>
          <h3>{props.message}</h3>
          <DialogActions>
            <ASCButton
              shape="rounded"
              className="asc-button-primary"
              appearance="primary"
              onClick={() => props.setDependencyDeletePopup(false)}
              label="No"
            />
            <ASCButton
              shape="rounded"
              appearance="secondary"
              onClick={() => {
                props.setDependencyDeletePopup(false);
                props.deleteData(true);
              }}
              label="Yes"
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default ConfirmationDeletePopup;
