import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";

// Define the type for a client row
interface ClientRow {
  customer_id: string;
  first_names: string;
  last_name: string;
  email: string;
}

function ClientSelection(props: any) {
  const [clients, setClients] = useState<ClientRow[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [loading, setLoading] = useState<boolean>(false);

  const columns = useMemo<MRT_ColumnDef<ClientRow>[]>(
    () => [
      {
        accessorKey: "first_names",
        header: "First Name",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
    ],
    []
  );

  useEffect(() => {
    getClientList();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  async function getClientList() {
    setLoading(true);
    try {
      const resp = await apiServices.client.getClientLists(
        pagination.pageIndex + 1,
        pagination.pageSize,
        searchText
      );
      // const response = await apiServices.notification.clientList(
      //   pagination.pageIndex + 1,
      //   pagination.pageSize,
      //   searchText
      // );
      if (
        resp &&
        resp?.data &&
        resp?.data?.data &&
        resp?.data?.data?.customerList &&
        resp?.data?.data?.customerList?.length > 0
      ) {
        const transformedData = resp?.data?.data?.customerList?.map((client: any) => ({
          customer_id: client.customer_id,
          first_names: client?.first_names || "",
          last_name: client?.last_name || "",
        }));

        setClients(transformedData);
        setRowCount(
          resp.data.data && resp.data.data.count && resp.data.data.count
            ? resp.data.data.count
            : 0
        );
        props.setClientList(transformedData);
        setLoading(false);
      } else {
        setLoading(false);
        setClients([]);
        setRowCount(0);
      }

      // if (response && response.data) {

      //   setClients(response.data.data ? response.data.data.res : []);
      //   setRowCount(response.data.data ? response.data.data.count : 0);
      // }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }
  useEffect(() => {
    const selectedIds = Object.keys(rowSelection);
    if (selectedIds != undefined && props.handleClientSelection) {
      props.handleClientSelection(selectedIds);
    }

    // onSelect(selectedIds);
  }, [rowSelection]);
  return (
    <div>
      <OverlayLoader isLoading={loading} />

      <div className="table-in-popup">
        <MaterialReactTable<ClientRow>
          columns={columns}
          data={clients}
          onRowSelectionChange={setRowSelection}
          onPaginationChange={setPagination}
          rowCount={rowCount}
          manualPagination
          state={{ pagination, rowSelection }}
          enableGlobalFilter={true}
          enableColumnActions={false}
          enableColumnFilters={true}
          enableRowSelection
          
          muiTableBodyCellProps={({ column }) => ({
            style: {
              padding: "6px 12px",
              fontSize: "0.875rem",
            },
          })}
          enableFullScreenToggle={false}
          getRowId={(row) => row.customer_id}
          enableMultiRowSelection={true}
          muiTablePaginationProps={{
            showFirstButton: false,
            showLastButton: false,
          }}
          onGlobalFilterChange={(text: string) => {
            if (text) {
              setSearchText(text);
            } else {
              setSearchText("");
            }
          }}
        />
      </div>
    </div>
  );
}

export default ClientSelection;
