import { Field, Input, makeStyles, shorthands } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import React, { ChangeEvent } from "react";

function onFormatDate(date: Date | undefined) {
  return !date ? "" : moment(date).format("DD-MM-YYYY");
}
export const useStyles = makeStyles({
  btn_mode: {
    marginLeft: "3px",
    marginRight: "3px",
  },
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
    marginTop: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
  
});
export const ElementData = ({
  element,
  handleAdd,
  formik,
  selectable,
  setSelectable,
  options,
  newOptionAdded,
  optionSelected,
  addNewOption,
  getOptionsForField,
  addNewFieldOption,
  getFieldValue,
  selectFieldOptions,
  clientDefinedData,
  newItemAdded,
  selectionListNewItems,
  handleOnChange,
  setIsFormError
}: any) => {
  const getOptions = (field: string) => {
    const fieldData = selectFieldOptions.find(
      (item: any) => item.field === field
    );
    if (fieldData) {
      const uniqueOptions = fieldData.options.filter(
        (option: any, index: number, self: any) =>
          index === self.findIndex((t: any) => t.value === option.value)
      );

      return uniqueOptions;
      // return fieldData.options;
    }
    return [];
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  
  switch (element.type) {
   
    case 0: {
      return (
        <div key={`div-${element.id}.${Math.random()}`}>
          <Field
            key={`Field-${element.id}.${Math.random()}`}
            label={element.name}
            className="field-pading"
            validationState={formik.values[element.name] &&formik.values[element.name].length > element.length ? "error" : "none"}
            validationMessage={formik.values[element.name] &&
              formik.values[element.name].length > element.length
                ? `Length of ${
                    element.name
                  } should not be greater than ${element.length}`
                : ""}
          >
            <CreatableSelect
              styles={{
                control: (baseStyles: any, state) => ({
                  ...baseStyles,
                  border: "none",
                  borderColor: state.isFocused ? "grey" : "grey",
                  width: 350,
                }),
              }}
              isClearable
              id={element.id}
              name={formik.values[element.name]}
              value={selectionListNewItems[element.name]}
              onChange={(selectedOption: any) => {
                if (!selectedOption) {
                  if(handleOnChange!= undefined){
                    handleOnChange(element.name, "")
                  }
                  
                  formik.setFieldValue(element.name, "");
                  formik.setFieldError(element.name, ""); 
                  setIsFormError((prevErrors: string[]) =>
                    prevErrors.filter((errorField) => errorField !== element.name)
                  );
                  newItemAdded(
                    { label: "", value: "" },
                    element.name,
                    "remove"
                  );
                } else {
                  formik.setFieldValue(
                    element.name,
                    selectedOption.value || ""
                  );
                  if (selectedOption.value.length > element.length) {
                    formik.setFieldError(
                      element.name,
                      `Length of ${element.name} should not be greater than ${element.length}`
                    );
                    setIsFormError((prevErrors: string[]) =>
                      prevErrors.includes(element.name) ? prevErrors : [...prevErrors, element.name]
                    );
                  } else {
                    formik.setFieldError(element.name, ""); 
                    setIsFormError((prevErrors: string[]) =>
                      prevErrors.filter((errorField) => errorField !== element.name)
                    );
                  }
          
                  if(handleOnChange!= undefined){
                    handleOnChange(element.name, selectedOption.value)
                  }
                  newItemAdded(selectedOption, element.name, "add");
                }
              }}
              getOptionLabel={(option: any) => option.value}
              options={getOptions(element.name)}
            />
           
          </Field>
        
        </div>
      );
    }
    case 3: {
      return (
        <div key={`div-${element.id}.${Math.random()}`}>
          <Field
            key={`Field-${element.id}.${Math.random()}`}
            label={element.name}
            className="field-pading"
          >
            <DatePicker
              key={`Picker-${element.id}.${Math.random()}`}
              as="input"
              appearance="filled-lighter"
              formatDate={onFormatDate}
              name={element.name}
              value={
                formik?.values[element.name]
                  ? new Date(formik.values[element.name])
                  : null
              }
              onSelectDate={(date) => {
                formik.setFieldValue(element.name, date);
                if(handleOnChange!= undefined){
                  handleOnChange(element.name, date)
                }
              }}
            />
          </Field>
          {/* <Link onClick={(e) => handleAdd(element)}>Update</Link> */}
        </div>
      );
    }
    default:
      return (
        <div key={`div-${element.id}.${Math.random()}`}>
          <Field
            key={`Field-${element.id}.${Math.random()}`}
            label={element.name}
            className="field-pading"
            validationState={formik.values[element.name] &&formik.values[element.name] > element.length ? "error" : "none"}
            validationMessage={formik.values[element.name] &&
              formik.values[element.name] > element.length
                ? `Length of ${
                    element.name
                  } should not be greater than ${element.length}`
                : ""}
          >
            <Input
              appearance="filled-lighter"
              name={element.name}
              onBlur={formik.handleBlur}
              value={formik.values[element.name]}
              onChange={(event:any)=>{
                const value = event.target.value;
                formik.setFieldValue(element.name, event.target.value); 
                if (value > element.length) {
        formik.setFieldError(
          element.name,
          `Length of ${element.name} should not be greater than ${element.length}`
        );
        setIsFormError((prevErrors: string[]) =>
          prevErrors.includes(element.name) ? prevErrors : [...prevErrors, element.name]
        );
      } else {
        setIsFormError((prevErrors: string[]) =>
          prevErrors.filter((errorField) => errorField !== element.name)
        );
        formik.setFieldError(element.name, ""); // Clear error if valid
      }
                if(handleOnChange!= undefined){
                  handleOnChange(element.name, event.target.value)
                }
              }
               }
              type="number"
              
             
            />
           
          </Field>
        </div>
      );
  }
};
