import { Tree, TreeItem, TreeItemLayout } from "@fluentui/react-components";
import React, { useState } from "react";
import TableComponent from "./DistributionTable";

const DistributionDetailsTree = () => {
  const [isReceiptExpanded, setIsReceiptExpanded] = useState(false);
  const [isCommissionExpanded, setIsCommissionExpanded] = useState(false);
  const [isPaymentExpanded, setIsPaymentExpanded] = useState(false);
  const [isCaseExpanded, setIsCaseExpanded] = useState(false);
  const [isClientExpanded, setIsClientExpanded] = useState(false);

  const columns = [
    { columnKey: "distibution_amont", label: "Distribution Amount" },
    { columnKey: "rue_amount", label: "Rule Amount" },
    { columnKey: "party_type", label: "Party Type" },
    { columnKey: "case_type", label: "Case Type" },
    { columnKey: "partyName", label: "Party Name" },
    { columnKey: "Note", label: "Note" },
  ];

  const items = [
    {
      distibution_amont: { label: "50.00%" },
      rue_amount: { label: "50.00%" },
      party_type: { label: "Consultant" },
      case_type: { label: "" },
      partyName: { label: "Brain King" },
      Note: { label: "" },
    },
  ];

  const distributioncolumns = [
    { columnKey: "commission_rule", label: "Commission Rule" },
  ];

  const distributionitem = [
    {
      commission_rule: { label: "Custom" },
    },
  ];

  const receiptcolumns = [
    { columnKey: "recived_amount", label: "Received Amount" },
    { columnKey: "due_date", label: "Due Date" },
    { columnKey: "received_date", label: "Received Date" },
  ];
  const receiptitems = [
    {
      recived_amount: { label: "15000.00" },
      due_date: { label: "17/10/2024" },
      received_date: { label: "16/11/2024" },
    },
  ];
  const case_relatedcolumns = [
    { columnKey: "commission_type", label: "Commission Type" },
    { columnKey: "due_date", label: "Due Date" },
  ];
  const case_relateditems = [
    {
      commission_type: { label: "15000.00" },
      due_date: { label: "17/10/2024" },
    },
  ];
  const casecolumns = [
    { columnKey: "provider", label: "Provider" },
    { columnKey: "type", label: "Type/Subtype" },
    { columnKey: "reference", label: "Reference " },
    { columnKey: "benift", label: "Benefit Periodic" },
    { columnKey: "benfreq", label: "Ben Freq " },
  ];
  const caseitems = [
    {
      provider: { label: "" },
      type: { label: "" },
      reference: { label: "222" },
      benift: { label: "" },
      benfreq: { label: "" },
    },
  ];
  const paymentcolumns = [
    { columnKey: "App_submitted", label: "App.submitted" },
    { columnKey: "Start", label: "End" },
    { columnKey: "Amount", label: "Amount" },
    { columnKey: "Frequency", label: "Frequency" },
    { columnKey: "Consultant", label: "Consultant" },
  ];
  const paymentitems = [
    {
      App_submitted: { label: "" },
      Start: { label: "07/02/2024" },
      End: { label: "22/11/2024" },
      Amount: { Label: "5000.00" },
      Frequency: { label: "monthly" },
      Consultant: { label: "Alona" },
    },
  ];
  const clientcolumns = [
    { columnKey: "Client", label: "Client" },
    { columnKey: "Consultant", label: "Consultant" },
    { columnKey: "introduce", label: "introduce" },
  ];
  const clientitems = [
    {
      Client: { label: "Louise,octavia" },
      Consultant: { label: "Alona" },
      Zera: { label: "zera" },
    },
  ];

  return (
    <Tree aria-label="Distribution Details Tree">
      <TreeItem itemType="branch">
        <div style={{ display: "flex", gap: "10px" }}>
          <TableComponent
            columns={distributioncolumns}
            items={distributionitem}
            maxWidth="250px"
            level={1}
          />
        <div style={{ minWidth: "550px" }}>
  <TableComponent
    columns={columns}
    items={items}
    level={2}
  />
</div>
        </div>
      </TreeItem>

      <TreeItem itemType="branch">
        <TreeItemLayout
          onClick={() => setIsReceiptExpanded(!isReceiptExpanded)}
        >
          Receipt
        </TreeItemLayout>
        {isReceiptExpanded && (
          <TableComponent
            columns={receiptcolumns}
            items={receiptitems}
            maxWidth="500px"
            level={3}
          />
        )}

        <Tree>
          <TreeItem itemType="branch">
            <TreeItemLayout
              onClick={() => setIsCommissionExpanded(!isCommissionExpanded)}
            >
              Commission (Case-related)
            </TreeItemLayout>
            {isCommissionExpanded && (
              <TableComponent
                columns={case_relatedcolumns}
                items={case_relateditems}
                maxWidth="500px"
                level={4}
              />
            )}

            <Tree>
              <TreeItem itemType="branch">
                <TreeItemLayout
                  onClick={() => setIsPaymentExpanded(!isPaymentExpanded)}
                >
                  Payment
                </TreeItemLayout>
                {isPaymentExpanded && (
                  <TableComponent
                    columns={paymentcolumns}
                    items={paymentitems}
                    maxWidth="500px"
                    level={5}
                  />
                )}

                <Tree>
                  <TreeItem itemType="branch">
                    <TreeItemLayout
                      onClick={() => setIsCaseExpanded(!isCaseExpanded)}
                    >
                      Case
                    </TreeItemLayout>
                    {isCaseExpanded && (
                      <TableComponent
                        columns={casecolumns}
                        items={caseitems}
                        maxWidth="500px"
                        level={6}
                      />
                    )}

                    <Tree>
                      <TreeItem itemType="branch">
                        <TreeItemLayout
                          onClick={() => setIsClientExpanded(!isClientExpanded)}
                        >
                          Client
                        </TreeItemLayout>
                        {isClientExpanded && (
                          <TableComponent
                            columns={clientcolumns}
                            items={clientitems}
                            maxWidth="500px"
                            level={7}
                          />
                        )}
                      </TreeItem>
                    </Tree>
                  </TreeItem>
                </Tree>
              </TreeItem>
            </Tree>
          </TreeItem>
        </Tree>
      </TreeItem>
    </Tree>
  );
};

export default DistributionDetailsTree;
