import {
  Button,
  Caption1Strong,
  Input,
  Label,
  Toaster,
  useId,
} from "@fluentui/react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import ASCButton from "../../components/Button";
import { Edit24Filled, EyeOffFilled, EyeFilled } from "@fluentui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import apiServices from "../../service";
import { AppDispatch } from "../../redux/store";

function CommunicationDetail(props: any) {
  const toasterId = useId("toaster");
  const dispatch: AppDispatch = useDispatch();

  const userProfileInfo = useSelector((state: any) => state.authUser);
  const [userInfo, setUserInfo] = useState<any>({});
  const [formField, setFormField] = useState({
    user_email: "",
    appPassword: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const formik = useFormik({
    initialValues: {
      user_email: formField?.user_email ? formField.user_email : "",
      appPassword: formField?.appPassword ? formField.appPassword : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // Handle form submission logic
      updateCredentials(values);
    },
  });
  const getUserDetail = (userId: any) => {
    apiServices.systemManager.manageUsers
      .getDetail(userId)
      .then((response: any) => {
        setUserInfo(response.data.user.UserId);
      })
      .catch((err: any) => console.log(err));
  };

  const updateCredentials = (values: any) => {
    const { user_email, appPassword } = values;
    let reqBody = {
      // user_email: emailPrefix + "@" + formField.domain,
      user_email: userProfileInfo.userProfileInfo.user_email,
      appPassword: appPassword,
    };

    apiServices.emailCredentials
      .add(reqBody)
      .then((response) => {
        if (response.data.isError === false) {
          props.notify(response.data.message, "", "success");
          props.setMode("view");
          getUserDetail(userProfileInfo.userProfileInfo.user_id);
        } else {
          props.notify(response.data.error.message, "", "error");
          getUserDetail(userProfileInfo.userProfileInfo.user_id);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDetail(userProfileInfo.userProfileInfo.user_id);
  }, []);

  return (
    <>
      <Toaster toasterId={toasterId} />
      <div className="profile_content">
        <div>
          <h2>Email Settings</h2>
          <div className="profile_view">
            <Label>Email</Label>
            <>
              {props.mode === "view" && (
                <Label>{userProfileInfo.userProfileInfo.user_email}</Label>
              )}
              {props.mode === "edit" && (
                <>
                  <Input
                    name={"user_email"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={userProfileInfo.userProfileInfo.user_email}
                    disabled
                  />
                  {formik.errors.user_email && (
                    <Caption1Strong className="form_error_msg">
                      {formik.errors.user_email}
                    </Caption1Strong>
                  )}
                </>
              )}
            </>
            <Label>Password</Label>

            <>
              {props.mode === "view" && <Label>{"********"}</Label>}
              {props.mode === "edit" && (
                <>
                  <Input
                    name={"appPassword"}
                    type={isPasswordVisible ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      formik?.values.appPassword !== undefined
                        ? formik.values.appPassword
                        : ""
                    }
                    contentAfter={
                      <span
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {isPasswordVisible ? <EyeOffFilled /> : <EyeFilled />}
                      </span>
                    }
                  />
                </>
              )}
            </>
          </div>
          {props.mode === "edit" && (
            <div className="action_buttons">
              <ASCButton
                shape="rounded"
                onClick={() => {
                  props.setMode("view");
                }}
                label="Cancel"
              />
              <ASCButton
                shape="rounded"
                label={"Save"}
                className="asc-button-primary"
                appearance="primary"
                onClick={() => formik.handleSubmit()}
                size="medium"
              />
            </div>
          )}
        </div>
        <div>
          {props.mode === "view" && (
            <ASCButton
              shape="rounded"
              onClick={() => {
                props.setMode("edit");
              }}
              icon={<Edit24Filled />}
              label="Edit"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CommunicationDetail;
