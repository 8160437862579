import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
import {
  setRuleCategory,
  setSelectedRule,
} from "../../../redux/distributionRuleSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { getAmountType } from "../../../utils";

const DistributionRule = (props: any) => {
  // const [provderData, setProviderData] = useState([]);
  //   const [ruleCategory, setRuleCategory] = useState([]);
  const dispatch: AppDispatch = useDispatch();
  const selectedItem = useSelector((state: any) => state.ruleid.selectedRule);
  const ruleCategory = useSelector((state: any) => state.ruleid.storedValue);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [loading, setLoading] = useState(false);
  //   const [selectedItem, setSelectedItem] = useState<any | {}>(props.data);
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "party_type",
        header: "Party Type",
      },
      {
        accessorKey: "provider_name",
        header: "Case Type",
      },
      {
        accessorKey: "party_name",
        header: "Party Name",
      },

      {
        accessorKey: "amount",
        header: "Amount",
      },
      {
        accessorKey: "amount_flag",
        header: "Amount Type",
        Cell: ({ row }: any) => <>{getAmountType(row.original.amount_flag)}</>,
      },
      {
        accessorKey: "note_text",
        header: "Note",
      },
    ],
    []
  );
  const getDataList = () => {
    setLoading(true);
    apiServices.systemManager.commissionRule
      .getList()
      .then((res) => {
        setLoading(false);
        if (res?.data?.data) dispatch(setRuleCategory(res.data.data));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (ruleCategory.length && Object.keys(rowSelection)[0]) {
      let index = 0;
      index = ruleCategory.findIndex(
        (x: any) => x.id === Object.keys(rowSelection)[0]
      );
      if (ruleCategory[index] !== undefined) {
        dispatch(setSelectedRule(ruleCategory[index]));
        props.ruleId(ruleCategory[index]);
      }
    }
  }, [rowSelection]);

  useEffect(() => {
    if (
      props.options !== undefined &&
      Object.keys(props.options).length !== 0 &&
      props.options.getRuleId !== undefined &&
      ruleCategory.length > 0
    ) {
      let index = 0;

      if (props.options.getRuleId["commission_rule_id"]) {
        index = ruleCategory.findIndex(
          (x: any) => x.id == props.options.getRuleId["commission_rule_id"]
        );
      } else {
        index = ruleCategory.findIndex(
          (x: any) => x.id == props.options.getRuleId["id"]
        );
      }

      if (ruleCategory[index] !== undefined) {
        setRowSelection({ [ruleCategory[index]["id"]]: true });
        dispatch(setSelectedRule(ruleCategory[index]));
        props.ruleId(ruleCategory[index]);
      }
    }
  }, [props.options, ruleCategory]);
  // useEffect(() => {
  //   if (
  //     props.options !== undefined &&
  //     Object.keys(props.options).length !== 0 &&
  //     props.options.getRuleId !== undefined &&
  //     ruleCategory.length > 0
  //   ) {
  //     let index = 0;

  //     index = ruleCategory.findIndex(
  //       (x: any) => x.id == props.options.getRuleId["id"]
  //     );

  //     if (ruleCategory[index] !== undefined) {
  //       setRowSelection({ [ruleCategory[index]["id"]]: true });
  //       dispatch(setSelectedRule(ruleCategory[index]));
  //       props.ruleId(ruleCategory[index]);
  //     }
  //   }
  // }, [props.options, ruleCategory]);

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <>
      <OverlayLoader isLoading={loading} />
      <div className="client-list-wrapper">
        <MaterialReactTable
          columns={columns}
          data={ruleCategory}
          onRowSelectionChange={setRowSelection}
          getRowId={(row) => row.id}
          enableColumnFilters={false}
          enableGlobalFilter={false}
          enableDensityToggle={false}
          enableRowSelection={true}
          enableSelectAll={false}
          enableColumnActions={false}
          enableMultiRowSelection={false}
          enableRowActions={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          state={{ rowSelection }}
          enablePagination={false}
        />
      </div>
    </>
  );
};

export default DistributionRule;
