export const clientDetail = [
  {
    clientDetail_id: 0,
    label: "Title",
    value: "title",
  },
  {
    clientDetail_id: 1,
    label: "First Name(s)",
    value: "first_names",
  },
  {
    clientDetail_id: 2,
    label: "Last Name",
    value: "last_name",
  },
  {
    clientDetail_id: 3,
    label: "Known as",
    value: "known_as",
  },
  {
    clientDetail_id: 4,
    label: "Street Address",
    value: "address_1",
  },
  {
    clientDetail_id: 5,
    label: "Town",
    value: "town",
  },
  {
    clientDetail_id: 6,
    label: "County",
    value: "county",
  },
  {
    clientDetail_id: 7,
    label: "Country",
    value: "country",
  },
  {
    clientDetail_id: 8,
    label: "Postcode",
    value: "postcode",
  },
  {
    clientDetail_id: 9,
    label: "Home Phone",
    value: "home_phone",
  },
  {
    clientDetail_id: 10,
    label: "Work Phone",
    value: "work_phone",
  },
  {
    clientDetail_id: 11,
    label: "Mobile",
    value: "mobile",
  },
  {
    clientDetail_id: 12,
    label: "Email",
    value: "email",
  },
  {
    clientDetail_id: 13,
    label: "Work Email",
    value: "work_email",
  },
  {
    clientDetail_id: 14,
    label: "Date of Birth",
    value: "date_of_birth",
  },
  {
    clientDetail_id: 15,
    label: "Age Next",
    value: "age_next",
  },
  {
    clientDetail_id: 16,
    label: "State Retirement",
    value: "state_retirement_date",
  },
  {
    clientDetail_id: 17,
    label: "NI Number",
    value: "ni_number",
  },
];
export const partnerDetail = [
  {
    partnerDetail_id: 0,
    label: "Title",
    value: "title",
  },
  {
    partnerDetail_id: 1,
    label: "First Name(s)",
    value: "first_names",
  },
  {
    partnerDetail_id: 2,
    label: "Last Name",
    value: "last_name",
  },
  {
    partnerDetail_id: 3,
    label: "Known as",
    value: "known_as",
  },
  {
    partnerDetail_id: 4,
    label: "Street Address",
    value: "address_1",
  },
  {
    partnerDetail_id: 5,
    label: "Town",
    value: "town",
  },
  {
    partnerDetail_id: 6,
    label: "County",
    value: "county",
  },
  {
    partnerDetail_id: 7,
    label: "Country",
    value: "country",
  },
  {
    partnerDetail_id: 8,
    label: "Postcode",
    value: "postcode",
  },
  {
    partnerDetail_id: 9,
    label: "Home Phone",
    value: "home_phone",
  },
  {
    partnerDetail_id: 10,
    label: "Work Phone",
    value: "work_phone",
  },
  {
    partnerDetail_id: 11,
    label: "Mobile",
    value: "mobile",
  },
  {
    partnerDetail_id: 12,
    label: "Email",
    value: "email",
  },
  {
    partnerDetail_id: 13,
    label: "Work Email",
    value: "work_email",
  },
  {
    partnerDetail_id: 14,
    label: "Date of Birth",
    value: "date_of_birth",
  },
  {
    partnerDetail_id: 15,
    label: "Age Next",
    value: "age_next",
  },
  {
    partnerDetail_id: 16,
    label: "State Retirement",
    value: "state_retirement_date",
  },
  {
    partnerDetail_id: 17,
    label: "NI Number",
    value: "ni_number",
  },
];
export const administration = [
  {
    admin_id: 0,
    label: "Client Type",
    value: "client_type",
  },
  {
    admin_id: 1,
    label: "Other Client Type",
    value: "fsa_client_type",
  },
  {
    admin_id: 2,
    label: "Service Type",
    value: "description",
  },
  {
    admin_id: 3,
    label: "Client Reference",
    value: "client_reference",
  },
  {
    admin_id: 4,
    label: "Client Since",
    value: "client_since",
  },
  {
    admin_id: 5,
    label: "Restricted Access?",
    value: "pending",
  },
  {
    admin_id: 6,
    label: "Consultant",
    value: "consultant",
  },
  {
    admin_id: 7,
    label: "Administrator",
    value: "",
  },
  {
    admin_id: 8,
    label: "Introduced by",
    value: "introduced_by",
  },
  {
    admin_id: 9,
    label: "Headline Note",
    value: "note_text",
  },
  {
    admin_id: 10,
    label: "Client Vulnerability ",
    value: "vulnerability",
    type: "client",
  },
  {
    admin_id: 11,
    label: "Partner Vulnerability",
    value: "vulnerability",
    type: "partner",
  },
];
export const compliance = [
  {
    compliance_id: 0,
    label: "Business Card",
    value: "business_card",
  },
  {
    compliance_id: 1,
    label: "Terms of Engagement",
    value: "terms_of_business_sent",
  },
  {
    compliance_id: 2,
    label: "Fact-find",
    value: "fact_find_completed",
  },
  {
    compliance_id: 3,
    label: "Data Protection",
    value: "data_protection_form_sent",
  },
  {
    compliance_id: 4,
    label: "Risk Profiling",
    value: "risk_profile",
  },
  {
    compliance_id: 5,
    label: "Identity Verified",
    value: "verification_of_identity",
  },
  {
    compliance_id: 6,
    label: "Sanctions Check",
    value: "sanctions_check",
  },
  {
    compliance_id: 7,
    label: "Last Updated",
    value: "modified",
  },
  {
    compliance_id: 8,
    label: "Updated by",
    value: "modified_by",
  },
  {
    compliance_id: 9,
    label: "Created ",
    value: "created",
  },
  {
    compliance_id: 10,
    label: "Created by",
    value: "created_by",
  },
  {
    compliance_id: 11,
    label: "ClientId",
    value: "customer_id",
  },
  {
    compliance_id: 12,
    label: "PartnerId",
    value: "associated_with",
  },
];
export const review = [
  {
    review_id: 0,
    label: "Last Review Completed",
    value: "review_completed",
  },
  {
    review_id: 1,
    label: "Next Review Due",
    value: "review_date",
  },
  {
    review_id: 2,
    label: "Review Interval (mnths)",
    value: "review_interval",
  },
  {
    review_id: 3,
    label: "Next Review Note",
    value: "review_note",
  },
];
