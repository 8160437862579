import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  Input,
  Label,
  Option,
  Select,
} from "@fluentui/react-components";
import React, { useState } from "react";
import { Edit24Filled, DismissFilled } from "@fluentui/react-icons";
import TextEditor from "../../../components/TextEditor";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import AssignClient from "./AssignClient";
import AssignCase from "./AssinCase";
import DistibutionEditForm from "../CommissionDistributon/DistibutionEditForm";
import StopcommissionPopup from "../CommissionReceipts/StopcommissionPopup";
import ASCButton from "../../../components/Button";
const FeesChargesEditForm = (props: any) => {
  const [isclient, setisClient] = useState(false);
  const [iscase, setisCase] = useState(false);
  const [editCommissionpopup, setEditCommissionpopup] = useState(false);
  const [stopReceiptpopup, setStopReceiptpopup] = useState(false);

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };
  return (
    <div>
      <Dialog open={props.editpopup}>
        <DialogSurface>
          <DialogBody className="dialog_content">
            <DialogTitle className="actionlist_title">
              Commission
              <DismissFilled
                style={{ fontSize: "20px" }}
                onClick={() => props.setEditpopup(false)}
              />
            </DialogTitle>
            {/* <DialogContent> */}
            <div className="commission_action_main">
              <div className="settings-table-content-label">Status</div>
              <span>abc</span>
              <div className="settings-table-content-label">
                Commission type
              </div>
              <Select
                //   onChange={onChange}
                name={"commission-type"}
                //   value={formField.review_interval}
              >
                {/* <option>Renewal-General</option> */}
              </Select>
              <div className="settings-table-content-label">
                Source of Payment
              </div>
              <Select />
              <div className="settings-table-content-label">
                Amount to Company
              </div>
              <Input />

              <div className="settings-table-content-label">Frequency</div>
              <Select>{/* <option>Monthly</option> */}</Select>
              <div className="settings-table-content-label">
                Commission Start Date
              </div>
              <DatePicker
                placeholder="mm/dd/yyyy"
                formatDate={onFormatDate}
                as="input"
                className=""
                allowTextInput
                name={"review_completed"}
              />
              <div className="settings-table-content-label">End Date</div>
              <DatePicker
                placeholder="mm/dd/yyyy"
                formatDate={onFormatDate}
                as="input"
                className=""
                allowTextInput
                name={"review_completed"}
              />
              <div className="settings-table-content-label">
                Number of Payment
              </div>
              <Input />

              <div className="settings-table-content-label">Note</div>
              <TextEditor
                // inputData={formField.review_reason}
                // onChange={(text: string) => {
                //   dispatch(setFormData());
                //   setFormField({ ...formField, review_reason: text });
                // }}
                name={"review_reason"}
              />
              <div className="settings-table-content-label"></div>
              <ASCButton
                shape="rounded"
                onClick={() => {
                  setStopReceiptpopup(true);
                }}
                label="Stop Receipts"
              />
              <div className="settings-table-content-label">Provider</div>
              <Input />
              <div className="settings-table-content-label">
                Associated Client
              </div>
              <span>Not Assigned</span>
              <div className="settings-table-content-label"></div>
              <ASCButton
                shape="rounded"
                onClick={() => {
                  setisClient(true);
                }}
                label="Assign to Client"
              />

              <div className="settings-table-content-label">
                Associated Case
              </div>
              <span>Not Assigned</span>
              <div className="settings-table-content-label"></div>
              <ASCButton
                shape="rounded"
                onClick={() => {
                  setisCase(true);
                }}
                label="Assign to Case"
              />
              <div className="settings-table-content-label">Consultant</div>
              <Input />
              <div className="settings-table-content-label">Introduced By</div>
              <Input />
            </div>
            {/* </DialogContent> */}

            <DialogActions>
              <div className="button-end-popup">
                <div className="settings-table-content-label"></div>
                <ASCButton
                  shape="rounded"
                  appearance="secondary"
                  onClick={() => {
                    setEditCommissionpopup(true);
                  }}
                  label="Commission Distribution"
                />
                <Button
                  shape="rounded"
                  className="asc-button-primary"
                  appearance="primary"
                  type="submit"
                  size="small"
                  style={{ marginRight: 6 }}
                >
                  Save
                </Button>
                <Button
                  shape="rounded"
                  appearance="secondary"
                  type="submit"
                  size="small"
                  onClick={() => props.setEditpopup(false)}
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <AssignClient isclient={isclient} setisClient={setisClient} />
      <AssignCase iscase={iscase} setisCase={setisCase} />
      <DistibutionEditForm
        setEditCommissionpopup={setEditCommissionpopup}
        editCommissionpopup={editCommissionpopup}
      />
      <StopcommissionPopup
        setStopReceiptpopup={setStopReceiptpopup}
        stopReceiptpopup={stopReceiptpopup}
      />
    </div>
  );
};

export default FeesChargesEditForm;
