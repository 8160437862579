import { useEffect, useMemo, useState } from "react";
import apiServices from "../../service";
import { ClientDataType } from "../../types";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import Search from "../../components/Search";
import { Button } from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import ASCButton from "../../components/Button";

type ComponentProps = {
  [x: string]: any;
};

type selectedValue = {
  customer_id: String;
  first_names: String;
  last_name: String;
};

const CustomerSelection = (props: ComponentProps) => {
  const [client, setClient] = useState<ClientDataType[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [search, setSearch] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [value, setValue] = useState<selectedValue | {}>({});

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const keyForTrue: string | undefined = Object.keys(rowSelection).find(
      (key) => rowSelection[key] === true
    );
    if (keyForTrue !== undefined) {
      let selectedValue = client[Number(keyForTrue)];
      setValue({
        customer_id: selectedValue.customer_id,
        first_names: selectedValue.NameAndAddress.first_names,
        last_name: selectedValue.NameAndAddress.last_name,
      });
    }
  }, [rowSelection]);

  const columns = useMemo<MRT_ColumnDef<ClientDataType>[]>(
    () => [
      {
        accessorKey: "NameAndAddress.first_names",
        header: "First Name",
      },
      {
        accessorKey: "NameAndAddress.last_name",
        header: "Last Name",
      },
    ],
    []
  );

  useEffect(() => {
    fetchUsersData();
  }, [pagination.pageIndex, pagination.pageSize, search]);

  async function fetchUsersData() {
    apiServices.client
      .getClients(pagination.pageIndex + 1, pagination.pageSize, search)
      .then(async (response: any) => {
        if (response) {
          await setClient(
            response.data && response.data.data ? response.data.data : []
          );
          await setRowCount(
            response.data && response.data.pagination
              ? response.data.pagination.count
              : 0
          );
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      <Search setSearch={setSearch} search={search} />
      <div className="table-in-popup">
        <MaterialReactTable
          columns={columns}
          data={client}
          rowCount={rowCount}
          manualPagination
          onPaginationChange={setPagination}
          onRowSelectionChange={setRowSelection}
          state={{ pagination, rowSelection }}
          enableGlobalFilter={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableRowSelection
          enableMultiRowSelection={false}
          positionActionsColumn="last"
          positionToolbarAlertBanner="none"
          muiTablePaginationProps={{
            rowsPerPageOptions: [10, 20, 50, 100],
            showFirstButton: false,
            showLastButton: false,
          }}
        />
      </div>
      <div className="button-end">
        <ASCButton
          shape="rounded"
          className="asc-button-primary"
          appearance="primary"
          onClick={() => {
            props.customerSelection(value);
            dispatch(setDialogModalOptions({ open: false }));
          }}
          label="Select"
        />
      </div>
    </div>
  );
};

export default CustomerSelection;
