import React, { ChangeEvent, useEffect, useState } from "react";
import "./form.css";
import RenderFormElements from "./RenderFormElements";
import { SwitchButton } from "../SwitchButton";
import { Label } from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { checkUserPermission } from "../../utils";
import { UserPermissionsList } from "../../config";

type FormContentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};
const FormContent = (props: FormContentProps) => {
  let {
    formik,
    mode,
    optionList,
    customHandleChange,
    selectFieldOptions,
    newSelectOptionAdded,
    section,
  } = props;
  const [inputFields, setInputFields] = useState([]);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  // useEffect(() => {
  //   if (props.formFieldData && props.formFieldData.inputs) {
  //     setInputFields(props.formFieldData.inputs);
  //   }
  // }, [props.formFieldData]);
  useEffect(() => {
    if (
      ["edit", "view"].includes(props.mode) &&
      props.context === "SYSTEM_MANAGER_USERLIST"
    ) {
      if (props.formFieldData && props.formFieldData.inputs) {
        const filteredInput = props.formFieldData.inputs.filter(
          (item: any) => item.field !== "password"
        );
        setInputFields(filteredInput);
      }
    } else if (
      ["edit", "view"].includes(props.mode) &&
      props.context === "ADMINISTRATION" &&
      !checkUserPermission(
        loggedUser,
        UserPermissionsList.ALLOW_RESTRICTED_CLIENT
      )
    ) {
      if (props.formFieldData && props.formFieldData.inputs) {
        const filteredInput = props.formFieldData.inputs.filter(
          (item: any) => item.field !== "pending"
        );
        setInputFields(filteredInput);
      }
    } else {
      if (props.formFieldData && props.formFieldData.inputs) {
        setInputFields(props.formFieldData.inputs);
      }
    }
  }, [props.mode, props.formFieldData]);

  return (
    <div
      style={{
        backgroundColor: "#f9f9f9",
        padding: 10,
        overflowY: "visible",
      }}
    >
      {formik && inputFields && inputFields.length > 0 && (
        <>
          <div>{props.title}</div>
          <form onSubmit={formik.handleSubmit} onChange={props.handleOnChange}>
            <div
              className={`${
                props.isAdminForm ? "admin-container" : "grid-container"
              }`}
            >
              {inputFields.map((item: any, index: number) => {
                let formItem = {};
                if (
                  props.mode === "edit" &&
                  props.context === "SYSTEM_MANAGER_USERLIST" &&
                  item.label === "Password"
                ) {
                  formItem = { ...item, label: "Change Password" };
                  item = formItem;
                }

                let label: string = item.field;
                let listOptions: any = [];
                let prefixlistOptions: any = [];
                if (
                  item.listType !== undefined &&
                  [
                    "ModifiableList",
                    "FixedList",
                    "MasterDatawithNewEntry",
                    "MasterDatawithoutNewEntry",
                  ].includes(item.listType) &&
                  item.fieldOptionLabel !== undefined &&
                  selectFieldOptions
                ) {
                  let fieldOpt = selectFieldOptions.find(
                    (c: any) => c.field === item.fieldOptionLabel
                  );
                  selectFieldOptions && fieldOpt
                    ? (listOptions = fieldOpt.options)
                    : (listOptions = []);
                } else {
                  if (item.type == "select" && item.options !== undefined) {
                    listOptions = item.options;
                  }
                }
                if (
                  item.blank !== undefined &&
                  listOptions.length > 0 &&
                  item.blank
                ) {
                  let emptyOption = { value: "", label: "" };
                  let existingObj = listOptions.find(
                    (obj: any) => obj.value === emptyOption.value
                  );
                  if (!existingObj) {
                    listOptions = [emptyOption, ...listOptions];
                  }
                }
                if (
                  item.prefix !== undefined &&
                  item.prefix.listType !== undefined &&
                  [
                    "ModifiableList",
                    "FixedList",
                    "MasterDatawithNewEntry",
                    "MasterDatawithoutNewEntry",
                  ].includes(item.prefix.listType) &&
                  item.prefix.fieldOptionLabel !== undefined &&
                  selectFieldOptions
                ) {
                  let prefixfieldOpt = selectFieldOptions.find(
                    (c: any) => c.field === item.prefix.fieldOptionLabel
                  );
                  selectFieldOptions && prefixfieldOpt
                    ? (prefixlistOptions = prefixfieldOpt.options)
                    : (prefixlistOptions = []);
                }
                // optionList
                //   ? (listOptions = optionList[label])
                //   : (listOptions = []);

                return (
                  <RenderFormElements
                    {...{
                      formik,
                      item,
                      options: listOptions,
                      prefixOptions: prefixlistOptions,
                      isAdminForm: props.isAdminForm,
                      mode,
                      primaryContact: props.primaryContact
                        ? props.primaryContact
                        : undefined,
                      setFieldAsPrimary: props.setFieldAsPrimary
                        ? props.setFieldAsPrimary
                        : undefined,
                      primaryContactFields: props.primaryContactFields
                        ? props.primaryContactFields
                        : undefined,
                      customHandleChange,
                      newSelectOptionAdded,
                      caseDetails: props.caseDetails
                        ? props.caseDetails
                        : undefined,
                      updateConsultantName: props.updateConsultantName
                        ? props.updateConsultantName
                        : undefined,
                      updateCommissionType: props.updateCommissionType
                        ? props.updateCommissionType
                        : undefined,
                      context: props.context,
                      customer_id: props.customer_id,
                      uploadedFiles: props.uploadedFiles,
                      setUploadedFiles: props.setUploadedFiles,
                      removeFile: props.removeFile,
                      index,
                    }}
                  />
                );
              })}
            </div>

            {props.context === "SYSTEM_MANAGER_USERLIST" &&
              props.permissionList !== undefined &&
              props.permissionList.length > 0 && (
                <div>
                  {props.mode !== "view" ? (
                    <>
                      {" "}
                      <p style={{ marginLeft: 8 }}>Permissions</p>
                      <div className="admin-container">
                        {props.permissionList.map((item: any) => {
                          let permItem = item;

                          let findindex = props.selectedPermissions.findIndex(
                            (obj: any) => obj["id"] === permItem["id"]
                          );

                          return (
                            <div>
                              <SwitchButton
                                label={item.description}
                                labelPosition="after"
                                itemChecked={findindex !== -1 ? true : false}
                                input={item.id}
                                disabled={mode == "view" ? true : false}
                                onChange={(
                                  ev: ChangeEvent<HTMLInputElement>,
                                  item: any
                                ) => {
                                  props.permissionAdded(permItem);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: 12,
                        }}
                      >
                        <div>
                          <Label weight="semibold" size="medium">
                            Permissions
                          </Label>{" "}
                        </div>
                        <div>
                          {props.selectedPermissions.length > 0 && (
                            <>
                              <Label>
                                {props.selectedPermissions
                                  .map((obj: any) => obj["description"])
                                  .join(", ")}
                              </Label>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
          </form>
        </>
      )}
    </div>
  );
};

export default FormContent;
