import Axios from "axios";
import { seesionExpired, accessDenied } from "../redux/appSettingsSlice";
import store from "../redux/store";
import { setCurrentUser } from "../redux/authSlice";

// Cache to store API responses
const apiCache: Record<string, any> = {};
const CACHE_TTL = 5 * 60 * 1000; // Cache TTL of 5 minutes

// Axios instance configuration
const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Function to create cache key from request URL and parameters
const createCacheKey = (config: any) => {
  const { url, params } = config;
  const queryString = params ? new URLSearchParams(params).toString() : "";
  const cacheKey = queryString ? `${url}?${queryString}` : url;
  return cacheKey;
};

// Nonce generation function
let timestamp: any = new Date();
timestamp = timestamp.toISOString();
const generateNonce = () => {
  const CryptoJS = require("crypto-js");
  const requestData = {};
  const hash = CryptoJS.SHA256(`${requestData}${timestamp}`);
  return hash.toString(CryptoJS.enc.Hex);
};

const nonce = generateNonce();

const cacheInvalidationMapping = {
  "/client/*/attituderisk/*": ["/client/attituderisk/customer/*"],
  "/client/*": [
    "/client",
    "/case/new/actionlist/user/*",
    "/client/client-details",
    "/client/client-details/*/administration",
  ],
  "/client": [
    "/client",
    "/client/addressbook/customer/*",
    "/client/depentant/*",
    "/client/commissionbycustomer/customer/*",
    "/client/appointmentbycustomer/*",
    "/client/timeallocationbycustomer/*",
    "/client/client-details/*",
    "/client/attituderisk/customer/*",
    "/client/client-details/*/service_type",
    "/client/client-details/*/objectives",
    "/client/client-details/*/employment",
    "/client/client-details/*/administration",
  ],
  "/client/search": ["/client/search", "client/export-search/export"],
  "/client/search/*": ["/client/search"],
  "/client/export-search/export/*": ["client/export-search/export"],
  "/client/*/servicetype/*": ["/client/client-details/*/service_type"],
  "/client/*/objectives/*": ["/client/client-details/*/objectives"],
  "/client/*/employment/*": ["/client/client-details/*/employment"],
  "/client/delete/address/*": ["/client/addressbook/customer/*"],
  "/assystcashflow/delete/income/*": ["/assystcashflow/income/customer/*"],
  "/assystcashflow/income/default": ["/assystcashflow/income/customer/*"],
  "/assystcashflow/income": ["/assystcashflow/income/customer/*"],
  "/assystcashflow/expense/default": ["/assystcashflow/expense/customer/*"],
  "/assystcashflow/expense": ["/assystcashflow/expense/customer/*"],
  "/assystcashflow/delete/expense/*": ["/assystcashflow/expense/customer/*"],
  "/client/delete/dependant/*": ["/client/depentant/*"],
  "/client/notes": ["/client/notes/*"],
  "/client/delete/note/*": ["/client/notes/*"],
  "/client/delete/appointment/*": ["/client/appointmentbycustomer/*"],
  "/client/create-folder": ["/client/list-of-folders"],
  "/client/upload-file": ["/client/list-of-folders"],
  "/client/userdefined/bulkinsert/value": ["/client/userdefined/value/*"],
  "/client/userdefined/bulkupdate/value/*": ["/client/userdefined/value/*"],
  "/client/vulnerability": ["/client/vulnerability/customer/*"],
  "/client/delete/vulnerability/*": ["/client/vulnerability/customer/*"],
  "/case/asset": ["/case/asset/customer/*", "/case/asset/*/customer/*"],
  "/case/asset/*": ["/case/asset/customer/*", "/case/asset/*/customer/*"],
  "/case/fund": ["/case/fund/customer/*/case/*", "/case/asset/customer/*"],
  "/case/fund/*": ["/case/fund/customer/*/case/*", "/case/asset/customer/*"],
  "/case/delete/fund/*": ["/case/fund/customer/*/case/*"],
  "/case/payments": ["/case/payments/case/*/customer/*"],
  "/case/payments/*": ["/case/payments/case/*/customer/*"],
  "/case/commission": ["/case/commission/*/customer/*/case/*"],
  "/case/commission/*": ["/case/commission/*/customer/*/case/*"],
  "/case/commission-allocation": ["/case/commission-allocation/commission/*"],
  "/case/commission-allocation/*": ["/case/commission-allocation/commission/*"],
  "/case/delete/commission/*": ["/case/commission/*/customer/*/case/*"],
  "/case/withdrawal": ["/case/withdrawal/case/*/customer/*"],
  "/case/withdrawal/*": ["/case/withdrawal/case/*/customer/*"],
  "/case/delete/withdrawal/*": ["/case/withdrawal/case/*/customer/*"],

  "/case/business": ["/case/business/customer/*/case/*"],
  "/case/business/*": [
    "/case/business/customer/*/case/*",
    "/case/new/actionlist/user/*",
  ],
  "/case/valuation": ["/case/valuation/case/*/customer/*"],
  "/case/valuation/*": ["/case/valuation/case/*/customer/*"],
  "/case/delete/valuation/*": ["/case/valuation/case/*/customer/*"],
  "/client/appointment/*": [
    "/case/new/actionlist/user/*",
    "/client/appointment/*/customer/*",
  ],
  "/case/liability": [
    "/case/liability/customer/*",
    "/case/liability/*/customer/*",
  ],
  "/case/liability/*": [
    "/case/liability/customer/*",
    "/case/liability/*/customer/*",
  ],
  "/case/policy": ["/case/policy/customer/*", "/case/policy/*/customer/*"],
  "/case/policy/*": ["/case/policy/customer/*", "/case/policy/*/customer/*"],
  "/user/*": ["/user"],
  "/user": ["/user"],
  "/masterdata/transactions/incomecategories/*": [
    "/masterdata/transactions/incomecategories",
  ],
  "/masterdata/transactions/incomecategories": [
    "/masterdata/transactions/incomecategories",
  ],
  "/masterdata/delete/income-category/*": [
    "/masterdata/transactions/incomecategories",
  ],
  "/masterdata/transactions/expensecategories/*": [
    "/masterdata/transactions/expensecategories",
  ],
  "/masterdata/transactions/expensecategories": [
    "/masterdata/transactions/expensecategories",
  ],
  "/masterdata/delete/expense-category/*": [
    "/masterdata/transactions/expensecategories",
  ],
  "/masterdata/attituderisk/categories": [
    "/masterdata/attituderisk/categories",
  ],
  "/masterdata/attituderisk/categories/*": [
    "/masterdata/attituderisk/categories",
  ],
  "/masterdata/delete/attitude-category/*": [
    "/masterdata/attituderisk/categories",
  ],
  "/masterdata/attituderisk/ratings": ["/masterdata/attituderisk/ratings"],
  "/masterdata/attituderisk/ratings/*": ["/masterdata/attituderisk/ratings"],
  "/masterdata/delete/attitude-rating/*": ["/masterdata/attituderisk/ratings"],
  "/masterdata/objectives": ["/masterdata/objectives"],
  "/masterdata/objectives/*": ["/masterdata/objectives"],
  "/masterdata/delete/objective/*": ["/masterdata/objectives"],
  "/masterdata/standardtracking/case": ["/masterdata/standardtracking/case"],
  "/masterdata/standardtracking/case/*": ["/masterdata/standardtracking/case"],
  "/masterdata/delete/tracking/*": ["/masterdata/standardtracking/case"],
  "/masterdata/standardtracking/client": [
    "/masterdata/standardtracking/client",
  ],
  "/masterdata/standardtracking/client/*": [
    "/masterdata/standardtracking/client",
  ],
  "/masterdata/userdefined/field": ["/masterdata/userdefined/field"],
  "/masterdata/userdefined/field/*": ["/masterdata/userdefined/field"],
  "/masterdata/userdefined/field/delete/*": ["/masterdata/userdefined/field"],
  "/masterdata/providers": ["/masterdata/providers"],
  "/masterdata/providers/*": ["/masterdata/providers"],
  "/masterdata/delete/provider/*": ["/masterdata/providers"],

  "/masterdata/document/*": [
    "/report/Email/getTemplatesByType",
    "/report/Letter/getTemplatesByType",
  ],
  "/masterdata/update/*": [
    "/report/Email/getTemplatesByType",
    "/report/Letter/getTemplatesByType",
  ],

  "/masterdata/delete/template/Letter/*": ["/report/Letter/getTemplatesByType"],
  "/masterdata/delete/template/Email/*": ["/report/Email/getTemplatesByType"],
  "/masterdata/commission/commissiontypes": [
    "/masterdata/commission/commissiontypes",
  ],
  "/masterdata/commission/commissiontypes/*": [
    "/masterdata/commission/commissiontypes",
  ],
  "/masterdata/delete/commission-type/*": [
    "/masterdata/commission/commissiontypes",
  ],
  "/masterdata/commission/commissionrule": [
    "/masterdata/commission/commissionrule",
  ],
  "/masterdata/commission/commissionrule/*": [
    "/masterdata/commission/commissionrule",
  ],
  "/masterdata/delete/commission-rule/*": [
    "/masterdata/commission/commissionrule",
  ],
  "/masterdata/standardtracking": [
    "/masterdata/standardtracking/case",
    "/masterdata/standardtracking/client",
  ],
  "/masterdata/standardtracking/*": [
    "/masterdata/standardtracking/case",
    "/masterdata/standardtracking/client",
  ],
  "/case/delete/payment/*": ["/case/payments/case/*/customer/*"],
  "/case/business/default": ["/case/business/customer/*/case/*"],
  "/masterdata/option": ["/masterdata/option"],
};
const convertPatternToRegex = (pattern: string, allowOptionalSlash = false) => {
  // Remove the leading slash for pattern if it should be optional
  const adjustedPattern = pattern.replace(/^\//, "");

  // Build the regex pattern
  const regexPattern = allowOptionalSlash
    ? `^/?${adjustedPattern.replace(/\*/g, ".*")}$` // Optional slash
    : `^/${adjustedPattern.replace(/\*/g, ".*")}$`; // Required slash

  return new RegExp(regexPattern, "i");
};
// Request interceptor
axiosInstance.interceptors.request.use(
  async (config: any) => {
    const cacheKey = createCacheKey(config);
    if (config.method === "get") {
      const cachedResponse = apiCache[cacheKey];
      if (cachedResponse) {
        const { data, expiration } = cachedResponse;
        if (Date.now() < expiration) {
          // Cache hit, return cached data
          return Promise.reject({
            config,
            data,
            status: 200,
            statusText: "OK",
            headers: {},
            request: {},
            fromCache: true,
          });
        } else {
          delete apiCache[cacheKey]; // Cache expired, delete it
        }
      }
    }

    // Handle cache invalidation for specific methods
    const cacheInvalidatingMethods = ["patch", "post", "delete"];
    if (cacheInvalidatingMethods.includes(config.method.toLowerCase())) {
      const { url } = config;

      Object.entries(cacheInvalidationMapping).forEach(
        ([pattern, keysToInvalidate]) => {
          const allowOptionalSlash = pattern.startsWith("/");
          // Convert pattern to a regular expression
          const regex = convertPatternToRegex(pattern, allowOptionalSlash);
          if (regex.test(url) || url.includes(pattern)) {
            keysToInvalidate.forEach((keyPattern) => {
              const keyRegex = convertPatternToRegex(
                keyPattern,
                allowOptionalSlash
              );
              Object.keys(apiCache).forEach((cachedKey) => {
                const baseUrl = cachedKey.split("?")[0];
                if (keyRegex.test(cachedKey) || keyRegex.test(baseUrl)) {
                  delete apiCache[cachedKey];
                }
              });
            });
          }

          // if (regex.test(url)) {
          //   keysToInvalidate.forEach((keyPattern) => {
          //     const keyRegex = convertPatternToRegex(
          //       keyPattern,
          //       allowOptionalSlash
          //     );
          //     Object.keys(apiCache).forEach((cacheKey) => {
          //       if (
          //         keyRegex.test(cacheKey) ||
          //         cacheKey.startsWith(keyPattern)
          //       ) {
          //         delete apiCache[cacheKey];
          //       }
          //     });
          //     // const cacheKey = Object.keys(apiCache).find((key) =>
          //     //   keyRegex.test(key)
          //     // );

          //     // if (cacheKey) {
          //     //   delete apiCache[cacheKey];
          //     // }
          //   });
          // } else if (url.includes(pattern)) {
          //   keysToInvalidate.forEach((keyPattern) => {
          //     const keyRegex = convertPatternToRegex(
          //       keyPattern,
          //       allowOptionalSlash
          //     );

          //     // Look for the cache key using includes
          //     const cacheKey = Object.keys(apiCache).find(
          //       (key) => keyRegex.test(key) || key.includes(pattern) // Use includes if regex fails
          //     );

          //     if (cacheKey) {
          //       delete apiCache[cacheKey]; // Invalidate cache if the key exists
          //     }
          //   });
          // }
        }
      );
    }

    // Add authorization and headers
    const token = store.getState().authUser.userToken;
    const userId = store.getState().authUser.userId;
    const refreshToken = store.getState().authUser.refreshToken;

    if (!(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["X-Nonce"] = nonce;
    config.headers["X-Timestamp"] = timestamp;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (userId) {
      config.headers.UserId = userId;
    }
    if (refreshToken) {
      config.headers.RefreshToken = refreshToken;
    }
    return config; // Don't forget to return the config object
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.method === "get") {
      const cacheKey = createCacheKey(response.config);
      const expiration = Date.now() + CACHE_TTL;
      apiCache[cacheKey] = {
        data: response.data,
        expiration,
      };
    }

    // Handle authorization header and store the token
    let authorizationHeader =
      response.headers["authorization"] ||
      response.headers["x-amzn-remapped-authorization"];
    if (authorizationHeader?.startsWith("Bearer ")) {
      store.dispatch(
        setCurrentUser(authorizationHeader.replace("Bearer ", ""))
      );
    }
    return response;
  },
  (error) => {
    if (error.fromCache) {
      return Promise.resolve({
        data: error.data,
        status: error.status,
        statusText: error.statusText,
        config: error.config,
        headers: error.headers,
        request: error.request,
      });
    }
    if (error.response?.status === 401) store.dispatch(seesionExpired(true));
    else if (error.response?.status === 403) store.dispatch(accessDenied(true));
    return Promise.reject(error);
  }
);

export default axiosInstance;

// Public instance for unauthenticated requests
export const axiosPublicInstance = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_URL,
});
