import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import ContactHistory from "./ContactHistory/ContactHistory";
import CaseSummary from "./CaseSummary/CaseSummary";
import ClientSummary from "./ClientSummary/ClientSummary";
import ClientPageActions from "./ClientPageActions";
import DependantForm from "./Dependant/DependantForm";
import IncomeForm from "./Income/IncomeForm";
import OutgoingsForm from "./Outgoings/OutgoingsForm";
import VulnerabilityForm from "./Vulnerability/VulnerabilityForm";

import apiServices from "../../service";
import { removeEmptyValues } from "../../utils";
import EmploymentForm from "./Employment/EmploymentForm";
import AttitudeToRiskForm from "./AttitudeToRisk/AttitudeToRiskForm";
import IdentityForm from "./Identity/IdentityForm";
import ObjectiveForm from "./objectives/ObjectiveForm";
import ServiceTypeForm from "./ServiceType/ServiceTypeForm";
import ContentLoader from "../../components/Loader/ContentLoader";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
function PartnerSectionHandler() {
  const { clientId, section, action, assetId, partnerId, sectionId } =
    useParams();
  const navigate = useNavigate();
  const [partnerInfo, setPartnerInfo] = useState<any>({});
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);
  const { clientInfo, notify } = useOutletContext<any>();
  const activeClient = useSelector((state: RootState) => state.activeClient);
  useEffect(() => {
    if (!section) {
      if (
        activeClient &&
        Object.keys(activeClient.basicDetails).length !== 0 &&
        activeClient.basicDetails.associated_type == 0 &&
        activeClient.basicDetails.associated_with !== null &&
        activeClient.basicDetails.associated_with != ""
      ) {
        navigate(`/clients/${clientId}/partner/name-and-address/view`, {
          replace: true,
        });
      } else {
        navigate(`/clients/${clientId}/partner/name-and-address/add`, {
          replace: true,
        });
      }
    } else if (!action) {
      if (
        activeClient &&
        Object.keys(activeClient.basicDetails).length !== 0 &&
        activeClient.basicDetails.associated_type == 0 &&
        activeClient.basicDetails.associated_with !== null &&
        activeClient.basicDetails.associated_with != ""
      ) {
        navigate(`/clients/${clientId}/partner/${section}/view`, {
          replace: true,
        });
      } else {
        navigate(`/clients/${clientId}/partner/${section}/add`, {
          replace: true,
        });
      }
    }
  }, [clientId, section, action, navigate, activeClient]);
  useEffect(() => {
    if (
      activeClient &&
      Object.keys(activeClient.basicDetails).length !== 0 &&
      activeClient.basicDetails.associated_type == 0 &&
      activeClient.basicDetails.associated_with !== null &&
      section &&
      [
        "name-and-address",
        "contact",
        "nationality",
        "personal",
        "health-note",
      ].includes(section)
    ) {
      getPartnerDetails(activeClient.basicDetails.associated_with, section);
    }
  }, [activeClient, section]);

  const getPartnerDetails = (clientId: string, section: string) => {
    setIsContentLoading(true);
    const formattedSection = (() => {
      switch (section) {
        case "name-and-address":
          return "name_and_address";
        case "health-note":
          return "health_note";
        default:
          return section;
      }
    })();
    let singleData = {};
    apiServices.client
      .getDetailByTab(clientId, formattedSection)
      .then((response: any) => {
        if (!response.data.isError && response.data.data.length > 0) {
          singleData = {
            ...{
              customer_id: clientId,
              title: response.data.data[0].Title,
              first_names: response.data.data[0].FirstNames,
              initials: response.data.data[0].Initials,
              last_name: response.data.data[0].LastName,
              former_last_name: response.data.data[0].FormerLastName,
              honours: response.data.data[0].Honours,
              known_as: response.data.data[0].KnownAs || "",
              address_name: response.data.data[0].AddressName,
              address_1: response.data.data[0].Address1,
              address_2: response.data.data[0].Address2,
              address_3: response.data.data[0].Address3,
              town: response.data.data[0].Town,
              county: response.data.data[0].County,
              country: response.data.data[0].Country,
              postcode: response.data.data[0].Postcode,
              owner: response.data.data[0].Owner || "",
              date_moved_in: response.data.data[0].DateMovedIn || "",
              salutation: response.data.data[0].Salutation,
              joint_salutation: response.data.data[0].JointSalutation,
              joint_address_label: response.data.data[0].JointAddressLabel,

              home_phone: response.data.data[0].HomePhone,
              work_phone: response.data.data[0].WorkPhone,
              fax: response.data.data[0].Fax,
              work_fax: response.data.data[0].WorkFax,
              mobile: response.data.data[0].Mobile,
              email: response.data.data[0].Email,
              work_email: response.data.data[0].WorkEmail,
              preferred_communication_type:
                response.data.data[0].PreferredCommunicationType,
              preferred_contact_time:
                response.data.data[0].PreferredContactTime,
              postal_marketing: response.data.data[0].PostalMarketing,
              email_marketing: response.data.data[0].EmailMarketing,
              nationality: response.data.data[0].Nationality,
              place_of_birth: response.data.data[0].PlaceOfBirth,
              uk_residence: response.data.data[0].UKResidence,
              country_of_residence: response.data.data[0].CountryOfResidence,
              country_of_domicile: response.data.data[0].CountryOfDomicile,

              sex: response.data.data[0].Sex,
              date_of_birth: response.data.data[0].DateOfBirth,
              preferred_retirement_age:
                response.data.data[0].PreferredRetirementAge,
              state_retirement_date: response.data.data[0].StateRetirementDate,
              pension_life_allowance:
                response.data.data[0].PensionLifeAllowance,
              marital_status: response.data.data[0].MaritalStatus,
              date_of_marriage: response.data.data[0].DateOfMarriage,
              vulnerability: response.data.data[0].Vulnerability,
              vulnerability_note: response.data.data[0].VulnerabilityNote,
              esg_attitude: response.data.data[0].ESGAttitude,
              esg_attitude_note: response.data.data[0].ESGAttitudeNote,
              will_date: response.data.data[0].WillDate,
              will_note: response.data.data[0].WillNote,
              power_of_attorney: response.data.data[0].PowerOfAttorney,
              deceased: response.data.data[0].Deceased,
              death_date: response.data.data[0].DeathDate,
              education_profession_experience:
                response.data.data[0].EducationProfessionExperience,
              ni_number: response.data.data[0].NINumber,
              unique_tax_ref: response.data.data[0].UniqueTaxRef,
              tax_ref: response.data.data[0].TaxRef,
              tax_office: response.data.data[0].TaxOffice,
              tax_rate: response.data.data[0].TaxRate,
              marriage_allowance: response.data.data[0].MarriageAllowance,

              height: response.data.data[0].Height,
              weight: response.data.data[0].Weight,
              state_of_health: response.data.data[0].StateOfHealth,
              health_note: response.data.data[0].HealthNote,
              long_term_care_needed: response.data.data[0].LongTermCareNeeded,
              smoker: response.data.data[0].Smoker,
              smoker_note: response.data.data[0].SmokerNote,
              alcohol_note: response.data.data[0].AlcoholNote,
              hazardous_pursuit_note:
                response.data.data[0].HazardousPursuitNote,
              client_type: response.data.data[0].ClientType,
              fsa_client_type: response.data.data[0].FSAClientType,
              client_reference: response.data.data[0].ClientReference,
              client_since: response.data.data[0].ClientSince,
              pending: response.data.data[0].Pending,
              consultant_id: response.data.data[0].ConsultantId,
              introduced_by: response.data.data[0].IntroducedBy,
              review_interval: response.data.data[0].ReviewInterval,
              review_completed: response.data.data[0].ReviewCompleted,
              review_date: response.data.data[0].ReviewDate,
              review_assigned: response.data.data[0].ReviewAssigned,
              review_note: response.data.data[0].ReviewNote,
              note_text: response.data.data[0].NoteText,
              business_card: response.data.data[0].BusinessCard,
              client_agreement: response.data.data[0].ClientAgreement,
              terms_of_business_sent: response.data.data[0].TermsOfBusinessSent,
              idd: response.data.data[0].Idd,
              menu: response.data.data[0].Menu,
              fact_find_completed: response.data.data[0].FactfindCompleted,
              data_protection_form_sent:
                response.data.data[0].DataProtectionFormSent,
              risk_profile: response.data.data[0].RiskProfile,
              risk_profile_note: response.data.data[0].RiskProfileNote,
              verification_of_identity:
                response.data.data[0].VerificationOfIdentity,
              sanctions_check: response.data.data[0].SanctionsCheck,
            },
          };

          setPartnerInfo(removeEmptyValues(singleData));
        }
        setIsContentLoading(false);
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(false);
        setIsContentLoading(false);
        console.log(err);
      });
  };
  const renderSectionComponent = () => {
    switch (section) {
      case "name-and-address":
      case "contact":
      case "nationality":
      case "personal":
      case "health-note":
        return (
          <ClientPageActions
            clientId={clientId}
            action={action}
            section={section}
            clientInfo={partnerInfo}
            notify={notify}
            module="partner"
            partnerId={partnerInfo.customer_id}
          />
        );
      // case "vulnerability":
      //   return (
      //     <VulnerabilityForm
      //       activeCustomerId={partnerInfo.customer_id}
      //       action={action}
      //       notify={notify}
      //       sectionId={sectionId}
      //       section="vulnerability"
      //       module="partner"
      //       partnerId={partnerInfo.customer_id}
      //     />
      //   );

      default:
        return "";
    }
  };
  return isContentLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 200px)",
      }}
    >
      <ContentLoader />
    </div>
  ) : (
    <div>{renderSectionComponent()}</div>
  );
}

export default PartnerSectionHandler;
