import { useEffect, useState } from "react";
import "../../style.css";

import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { configFm } from "../../../../types";
import apiServices from "../../../../service";
import SystemManagerFormContainer from "../../SystemManagerFormContainer";
import OverlayLoader from "../../../../components/OverlayLoader";
import ContentLoader from "../../../../components/Loader/ContentLoader";

type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const AttitudeToRiskCategories = (props: ComponentProps) => {
  const [formContext, setFormContext] = useState(
    "MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY"
  );
  const [isLoading, setIsLoading] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const [listDataItems, setListDataItems] = useState([]);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.systemAdmin &&
      formSettingsObj.systemAdmin.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.systemAdmin.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    getDataList();
  }, []);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = async () => {
    setIsLoading(true);
    try {
      const res =
        await apiServices.systemManager.attitudeToRiskcategory.getList();
      if (res.data !== undefined) {
        if (res.data.data) {
          setListDataItems(res.data.data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  return isLoading ? (
    <ContentLoader />
  ) : (
    <div className="admin-form-container">
      <SystemManagerFormContainer
        context={formContext}
        config={configForm}
        notify={props.notify}
        title=""
        isFormAdmin={true}
        dataList={listDataItems}
        reloadData={loadListData}
      />
    </div>
  );
};

export default AttitudeToRiskCategories;
