export const ModuleLabel: any = {
  assets: "Asset",
  liabilities: "Liability",
  policies: "Policy",
};
export const radioOptions: any = {
  assets: [
    { value: "investment", label: "Investment" },
    { value: "shares", label: "Share Holdings" },
    { value: "home-personal", label: "Home and Personal" },
    { value: "bank-societies", label: "Bank/Building Societies" },
  ],
  liabilities: [
    { value: "morgages", label: "Mortgages" },
    { value: "loans-lease-hp", label: "Loan and Hire Purchase" },
    { value: "credit-cards", label: "Credit Cards" },
  ],
  policies: [
    { value: "Life Assurance", label: "Life Assurance" },
    { value: "Pensions", label: "Pensions" },
    { value: "Investments", label: "Investments" },
    { value: "Savings Plans", label: "Savings Plans" },
    { value: "Income Protection", label: "Income Protection" },
    { value: "Health Assurance", label: "Health Assurance" },
    { value: "General", label: "General" },
  ],
};
