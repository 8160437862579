import { createSlice } from "@reduxjs/toolkit";

export const opentimerSlice=createSlice({
name:"opentimer",
initialState:{
    storedValue:false,
    activeCustomer:""
},
reducers:{
    setIsFloatingMenuOpen:(state,action)=>{
        state.storedValue=action.payload;
    },
    setActiveCustmer:(state,action)=>{
        state.activeCustomer=action.payload;
    }
}
})
export const {setIsFloatingMenuOpen,setActiveCustmer}= opentimerSlice.actions;
export default opentimerSlice.reducer;