import { createSlice } from "@reduxjs/toolkit";

export const clientSlice=createSlice({
    name:'clientid',
    initialState:{
        storedValue:"",
    },
    reducers:{
        setClientValue:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})

export const tableValue=createSlice({
    name:'tableData',
    initialState:{
        storedValue:[],
    },
    reducers:{
        setTableData:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})

export const partnerIDs=createSlice({
    name:"partnerId",
    initialState:{
        storedValue:"",
    },
    reducers:{
        setPartnerIDs:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})
export const selectionList= createSlice({
    name:'list',
    initialState:{
        storedValue:[],
    },
    reducers:{
        setLists:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})
export const clientOrPartner=createSlice({
    name:'clientpartner',
    initialState:{
        storedValue:''
    },
    reducers:{
        setClientOrPartner:(state,action)=>{
            state.storedValue=action.payload
        }
    }
})
export const conditionclientOrPartner=createSlice({
    name:'conditionalclientpartner',
    initialState:{
        storedValue:''
    },
    reducers:{
        setconditionalClientOrPartner:(state,action)=>{
            state.storedValue=action.payload
        }
    }
})
export const filterSelectionData=createSlice({
    name:'selectionFilter',
    initialState:{
        storedValue:[]
    },
    reducers:{
        setFilterSelectionData:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})
export const loaderForFiltering = createSlice({
    name:'filter',
    initialState:{
        isLoaderFiler:false
    },
    reducers:{
        startLoading:(state)=>{
            state.isLoaderFiler=true;
        },
        stopLoading:(state)=>{
            state.isLoaderFiler=false;
        }
    }
})

export const clientArray=createSlice({
    name:'clients',
    initialState:{
        storedValue:[]
    },
    reducers:{
        setClients:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})
export const partnerArray=createSlice({
    name:"partner",
    initialState:{
        storedValue:[]
    },
    reducers:{
        setPartners:(state,action)=>{
            state.storedValue=action.payload;
        }
    }
})
const clientSplit = createSlice({
    name: "client",
    initialState: {
      combinedData: [],
      selectedData: [],
    },
    reducers: {
      setCombinedData: (state, action) => {
        state.combinedData = action.payload;
      },
      setSelectedData: (state, action) => {
        state.selectedData = action.payload;
      },
    },
  });

export const {setCombinedData,setSelectedData}=clientSplit.actions;
export const {setClientValue}=clientSlice.actions;
export const {setTableData}=tableValue.actions;
export const {setPartnerIDs}=partnerIDs.actions;
export const {setClients}=clientArray.actions;
export const {setPartners}=partnerArray.actions;
export const {setLists}=selectionList.actions;
export const {setClientOrPartner}=clientOrPartner.actions;
export const {setconditionalClientOrPartner}=conditionclientOrPartner.actions;
export const {setFilterSelectionData}=filterSelectionData.actions;
export const {startLoading,stopLoading}=loaderForFiltering.actions;

export const clientReducer = clientSlice.reducer;
export const tableReducer = tableValue.reducer;
export const partnerIdsReducer =partnerIDs.reducer;
export const clientsArray = clientArray.reducer;
export const partnersArray = partnerArray.reducer;
export const splits = clientSplit.reducer;
export const list=selectionList.reducer;
export const clientpartner=clientOrPartner.reducer;
export const conditionalclientpartner=conditionclientOrPartner.reducer;
export const selectionFilter=filterSelectionData.reducer;
export const filter=loaderForFiltering.reducer;