import React, { useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import ASCButton from "../../../components/Button";

interface Item {
  client: string;
  fname: string;
  lname: string;
  title: string;
}

const initialItems: Item[] = [
  {
    client: "Paterson, Matt",
    fname: "Paterson",
    lname: "Matt",
    title: "Mis",
  },
  {
    client: "Jone, Doe",
    fname: "Jone",
    lname: "Done",
    title: "",
  },
  {
    client: "Patrick, Stewart",
    fname: "Patrick",
    lname: "Stewart",
    title: "Mr",
  },
];

const AssignClient = (props: any) => {
  const [items, setItems] = useState<Item[]>(initialItems);
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});

  const handleCellChange = (
    rowIndex: number,
    columnKey: keyof Item,
    value: string
  ) => {
    const updatedItems = [...items];
    updatedItems[rowIndex] = { ...updatedItems[rowIndex], [columnKey]: value };
    setItems(updatedItems);
  };

  const columns: MRT_ColumnDef<Item>[] = [
    {
      accessorKey: "client",
      header: "Client",
      enableEditing: true,
    },
    {
      accessorKey: "lname",
      header: "Last Name",
      enableEditing: true,
    },
    {
      accessorKey: "fname",
      header: "First Name",
      enableEditing: true,
    },
    {
      accessorKey: "title",
      header: "Title",
      enableEditing: true,
    },
  ];

  return (
    <Dialog open={props.isclient}>
      <div>
        {/* <DialogTrigger disableButtonEnhancement>
          <Button>Commission Rule</Button>
        </DialogTrigger> */}
        <DialogSurface
          style={{
            height: "400px",
            width: "500px",
            maxHeight: "90vh",
            maxWidth: "90vw",
            overflow: "auto", // Ensure scrolling if content exceeds height/width
          }}
        >
          <DialogBody>
            <DialogTitle>Assign Client</DialogTitle>
            <DialogContent>
              <MaterialReactTable
                columns={columns}
                data={items}
                enablePagination={false}
                enableStickyHeader
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableDensityToggle={false}
                enableRowSelection={true}
                enableSelectAll={false}
                enableColumnActions={false}
                enableMultiRowSelection={false}
                enableRowActions={false}
                enableFullScreenToggle
                positionActionsColumn="last"
                positionToolbarAlertBanner="none"
                muiTableBodyRowProps={({ row }: any) => ({
                  onClick: () =>
                    setSelectedRow((prev: any) => ({
                      [row.id]: !prev[row.id],
                    })),
                  selected: selectedRow[row.id],
                  sx: {
                    cursor: "pointer",
                    backgroundColor: selectedRow[row.id]
                      ? "#f1ebea"
                      : "transparent",
                    "& > *": {
                      backgroundColor: selectedRow[row.id]
                        ? "#f1ebea"
                        : "transparent", // apply to all cells in the row
                    },
                  },
                })}
              />
            </DialogContent>
            <DialogActions>
              <ASCButton
                shape="rounded"
                appearance="secondary"
                onClick={() => {
                  props.setisClient(false);
                }}
                label="Clear"
              />
              <ASCButton
                shape="rounded"
                appearance="primary"
                className="asc-button-primary"
                label="Assign"
              />
              <ASCButton
                shape="rounded"
                appearance="secondary"
                onClick={() => {
                  props.setisClient(false);
                }}
                label="Close"
              />
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </div>
    </Dialog>
  );
};

export default AssignClient;
