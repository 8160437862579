import React, { useEffect, useState } from "react";
import "../style.css";
import {
  Subtitle2,
  Caption1,
  Caption1Strong,
} from "@fluentui/react-components";

import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import FormContainerWithList from "../../../components/FormContainerWithList";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
import ContentLoader from "../../../components/Loader/ContentLoader";

type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const AddressBookForm = (props: ComponentProps) => {
  const [formContext, setFormContext] = useState("ADDRESS_BOOK");
  const [initialData, setInitialData] = useState({});
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.data &&
      formSettingsObj.data.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    if (props.activeCustomerId !== null) {
      getDataList();
    }
  }, [props.activeCustomerId]);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = () => {
    setLoading(true);
    apiServices.addressBook
      .getList(props.activeCustomerId)

      .then((res) => {
        if (res.data !== undefined) {
          if (res.data.data) {
            let singleData = {};
            singleData = {
              ...singleData,
              addressBook: res.data.data,
            };
            setInitialData(singleData);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return loading ? (
    <ContentLoader />
  ) : (
    <div className="form-container-wrapper">
      <FormContainerWithList
        context={formContext}
        config={configForm}
        activeCustomerId={props.activeCustomerId}
        notify={props.notify}
        initialData={initialData}
        reloadData={loadListData}
        activeClient={props.activeClient}
        section="address-book"
      />
    </div>
  );
};

export default AddressBookForm;
