import React, { useEffect, useState, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import CaseList from "../CaseList";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import { getCaseCategoryByCaseType } from "../../../../utils";
import {
  getAssetDetailById,
  getAssetListItems,
} from "../../../../redux/Case/assetSlice";
import { TableListLoader } from "../../../../components/Loader/TableListLoader";
import { FormLoader } from "../../../../components/Loader/FormLoader";

interface AssetDetailPageProps {
  tab?: string;
}

const AssetDetailPage: React.FC<AssetDetailPageProps> = ({ tab }) => {
  const { clientId, action, caseId } = useParams();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const { activeItem, listItems, loading, activeItemloading } = useSelector(
    (state: RootState) => state.assets
  );

  const [caseType, setCaseType] = useState<string>("");

  useEffect(() => {
    if (location.state?.caseType) {
      setCaseType(location.state.caseType);
    }
  }, [location.state?.caseType]);

  useEffect(() => {
    if (clientId) {
      dispatch(getAssetListItems(clientId));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getAssetDetailById({ id: caseId, customerId: clientId }));
    }
  }, [caseId, clientId, dispatch]);

  useEffect(() => {
    if (activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(activeItem.case_type));
    }
  }, [activeItem]);

  const hasListItems = useMemo(() => listItems.length > 0, [listItems]);

  return (
    <>
      {action && (
        <CaseNavList
          menuItem={undefined}
          section="assets"
          tab={tab}
          caseType={caseType}
          caseDetail={activeItem}
          mode={action || ""}
        />
      )}
      <div className="case_container_flex">
        {action &&
          (activeItemloading ? (
            <FormLoader />
          ) : (
            <CaseForm
              mode={action || ""}
              section="assets"
              caseType={caseType}
              caseDetail={activeItem}
              caseItemList={listItems}
              tab={tab}
            />
          ))}
        <div>
          {loading ? (
            <TableListLoader rows={8} columns={6} />
          ) : (
            hasListItems &&
            action &&
            listItems.length > 0 && (
              <CaseList
                module="assets"
                data={listItems}
                activeItem={activeItem}
                section="assets"
                tab={tab}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export default AssetDetailPage;
