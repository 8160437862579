import { Button, Label } from "@fluentui/react-components";
import React, { useEffect } from "react";
import apiServices from "../../service";
import ASCButton from "../../components/Button";

const Confirmation = ({
  MoveToExport,
  cancelConfirmation,
  savedData,
}: {
  MoveToExport: () => void;
  cancelConfirmation: () => void;
  savedData: {
    title: string;
    description: string;
    isAvailable: boolean | string;
  } | null;
}) => {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div>
          <Label style={{ fontWeight: "700", fontSize: "16px" }}>
            Are you sure you want to save the specification?
          </Label>
        </div>
        <div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
          <ASCButton
            shape="rounded"
            appearance="primary"
            className="asc-button-primary"
            size="small"
            onClick={MoveToExport}
            label="Yes"
          />
          <ASCButton
            shape="rounded"
            appearance="secondary"
            size="small"
            onClick={cancelConfirmation}
            label="No"
          />
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
