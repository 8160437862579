import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { Add16Filled } from "@fluentui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { checkUserPermission } from "../../../../utils";
import { UserPermissionsList } from "../../../../config";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ASCButton from "../../../../components/Button";
import { ModuleLabel, radioOptions } from "../caseHelpers";
import { getLiabilityListItems } from "../../../../redux/Case/liabilitySlice";

const useStyles = makeStyles({
  field: {
    display: "grid",
    gap: tokens.spacingVerticalS,
    marginBottom: "20px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 10px 0",
  },
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

const LiabilityHomePage: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCaseType, setSelectedCaseType] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { clientId, action } = useParams<{
    clientId: string;
    action?: string;
  }>();

  const { authUser, liabilities } = useSelector((state: RootState) => ({
    authUser: state.authUser,
    liabilities: state.liabilities,
  }));

  const styles = useStyles();

  useEffect(() => {
    if (clientId) dispatch(getLiabilityListItems(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (liabilities.listItems.length > 0) {
      navigate(
        `/clients/${clientId}/liabilities/${
          liabilities.listItems[0]?.liability_id
        }/liability/${action || "view"}`
      );
    }
  }, [clientId, action, navigate, liabilities.listItems]);

  const handleAddCase = useCallback(() => {
    if (!selectedCaseType) return;
    setIsDialogOpen(false);
    navigate(`/clients/${clientId}/liabilities/add`, {
      state: { caseType: selectedCaseType },
    });
  }, [selectedCaseType, clientId, navigate]);

  return (
    <div>
      {liabilities.loading ? (
        <ContentLoader />
      ) : liabilities.listItems.length === 0 ? (
        <div>
          <div className={styles.wrapper}>
            {checkUserPermission(
              authUser,
              UserPermissionsList.ALLOW_UPDATE_CLIENT
            ) && (
              <ASCButton
                shape="rounded"
                appearance="primary"
                className="asc-button-primary"
                icon={<Add16Filled />}
                size="medium"
                onClick={() => setIsDialogOpen(true)}
                label="Add New"
              />
            )}
          </div>
          <div className={styles.emptyState}>
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click Add New to create a new record
            </Label>
          </div>
        </div>
      ) : null}

      <Dialog open={isDialogOpen} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <div className={styles.field}>
                <Label weight="semibold">
                  Select {ModuleLabel["liabilities"]} Category
                </Label>
                <RadioGroup
                  value={selectedCaseType}
                  onChange={(_, data) => setSelectedCaseType(data.value)}
                >
                  {radioOptions["liabilities"]?.map((radio: any) => (
                    <Radio
                      key={radio.value}
                      value={radio.value}
                      label={radio.label}
                    />
                  ))}
                </RadioGroup>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <ASCButton
                  shape="rounded"
                  appearance="secondary"
                  size="small"
                  onClick={() => setIsDialogOpen(false)}
                  label="Close"
                />
              </DialogTrigger>
              <ASCButton
                shape="rounded"
                appearance="primary"
                size="small"
                className="asc-button-primary"
                onClick={handleAddCase}
                label="Submit"
              />
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default LiabilityHomePage;
