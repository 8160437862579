import * as React from "react";
import {
  Combobox,
  makeStyles,
  Option,
  useId,
  Spinner,
} from "@fluentui/react-components";
import type {
  ComboboxProps,
  OptionOnSelectData,
  ComboboxOpenChangeData,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    gap: "2px",
    maxWidth: "400px",
    minWidth: "180px",
  },
  combobox: {
    minWidth: "200px",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px", // Height similar to the dropdown
  },
});
interface OptionType {
  label: string;
  value: string;
}
interface CustomDropDownProps extends Partial<ComboboxProps> {
  label?: string;
  optionsFetcher?: () => Promise<OptionType[]>;
  values?: OptionType[];
  onSelectionChange?: (selectedValue: string) => void;
  className?: string; // Optional custom class
  style?: React.CSSProperties; // Optional inline styles
}
const ASCDropDown: React.FC<CustomDropDownProps> = ({
  label,
  optionsFetcher,
  values,
  onSelectionChange,
  className,
  style,
  ...rest
}) => {
  const comboId = useId("combo-default");

  const styles = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<OptionType[]>([]);
  const handleOptionSelect = (
    event: React.SyntheticEvent<HTMLElement>,
    data: OptionOnSelectData
  ) => {
    if (onSelectionChange) {
      const selectedOption = options.find(
        (option) => option.label === data.optionValue
      );
      onSelectionChange(selectedOption?.value || "");
    }
  };
  const handleOpenChange = async (
    event: React.SyntheticEvent<HTMLElement>,
    data: ComboboxOpenChangeData
  ) => {
    if (data.open && options.length === 0) {
      setLoading(true);
      try {
        if (optionsFetcher) {
          const fetchedOptions = await optionsFetcher();
          setOptions(fetchedOptions);
        } else if (values) {
          setOptions(values); // Use provided values if no fetcher
        }
      } catch (error) {
        console.error("Failed to fetch options:", error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div className={`${styles.root} ${className || ""}`} style={style}>
      {label && <label id={comboId}>{label}</label>}

      <Combobox
        aria-labelledby={comboId}
        onOptionSelect={handleOptionSelect}
        onOpenChange={handleOpenChange}
        {...rest}
        className={styles.combobox}
      >
        {loading ? (
          <div className={styles.loaderContainer}>
            <Spinner size="medium" />
          </div>
        ) : (
          options.map((option) => (
            <Option key={option.value}>{option.label}</Option>
          ))
        )}
      </Combobox>
    </div>
  );
};
export default ASCDropDown;
