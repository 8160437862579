import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Select,
  useId,
  makeStyles,
  Button,
  Divider,
  tokens,
  CompoundButton,
  ButtonProps,
  Tab,
  TabList,
  shorthands,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Radio,
  RadioOnChangeData,
  RadioGroup,
} from "@fluentui/react-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbProps,
  BreadcrumbButton,
  BreadcrumbItemProps,
} from "@fluentui/react-breadcrumb-preview";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  DrawerProps,
} from "@fluentui/react-components";
import {
  Add20Filled,
  Add16Filled,
  ChevronLeft20Regular,
  Add20Regular,
  DocumentBulletList20Regular,
  DocumentFolder20Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import FormContainer from "./FormContainer";
import { TableSmall } from "../../../components/TableSmall";
import AssetDetails from "./AssetDetails";
import {
  camalize,
  checkIfKeyExist,
  checkUserPermission,
  convertStringToArray,
  formatedDate,
  formatedDate2,
  getAssetCategoryByCaseType,
  getAssetFundCodeSource,
  getAssetTypeByCaseTypeASReq,
  getAssetTypeByCaseTypeId,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import AssetListItems from "./AssetListItems";
import apiServices from "../../../service";
import { addBreadcrumbs } from "../../../redux/appSettingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import "../style.css";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import FormContainerDetailPage from "./FormContainerDetailPage";
import LoadStandardAction from "../../../components/LoadStandardAction";
import { UserPermissionsList } from "../../../config";
import OverlayLoader from "../../../components/OverlayLoader";
import {
  banksBSocieties,
  homePersonal,
  shares,
  unitLinked,
} from "../../../shared/AssetDetails";

const renderDate = (date: string) => (date ? formatedDate(date) : "-");
const renderRichText = (text: string) =>
  text ? <ReactQuillComponent value={text} /> : "-";
const renderYesorNo = (value: number) =>
  value ? getYes_NoConversion(value) : "-";
const CaseDetails = (activeItem: any) => {
  if (Object.keys(activeItem).length !== 0) {
    if (activeItem.case_type === "Unit-linked") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            unitLinked.length > 0 &&
            unitLinked.map((AssetInvestment: any) => (
              <div key={AssetInvestment.Unit_linked_id} className="details">
                <p className="details_label">{AssetInvestment.label} </p>
                <p>
                  {(() => {
                    switch (AssetInvestment.value) {
                      case "joint_indicator":
                        return getClientType(activeItem.joint_indicator) || "-";
                      case "investment_start_date":
                        return renderDate(activeItem.investment_start_date);
                      case "investment_end_date":
                        return renderDate(activeItem.investment_end_date);
                      case "value_date":
                        return renderDate(activeItem.value_date);
                      case "status_date":
                        return renderDate(activeItem.status_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "note_text":
                        return renderRichText(activeItem.note_text);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[AssetInvestment.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Shares") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            shares.length > 0 &&
            shares.map((Assetshares: any) => (
              <div key={Assetshares.shares_id} className="details">
                <p className="details_label">{Assetshares.label} </p>
                <p>
                  {(() => {
                    switch (Assetshares.value) {
                      case "joint_indicator":
                        return getClientType(activeItem.joint_indicator) || "-";
                      case "share_date_acquired":
                        return renderDate(activeItem.share_date_acquired);
                      case "value_date":
                        return renderDate(activeItem.amount_outstanding_date);
                      case "status_date":
                        return renderDate(activeItem.status_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "note_text":
                        return renderRichText(activeItem.note_text);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[Assetshares.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Home/Personal") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            homePersonal.length > 0 &&
            homePersonal.map((AssetHP: any) => (
              <div key={AssetHP.Home_Personal_id} className="details">
                <p className="details_label">{AssetHP.label} </p>
                <p>
                  {(() => {
                    switch (AssetHP.value) {
                      case "joint_indicator":
                        return getClientType(activeItem.joint_indicator) || "-";
                      case "home_date_acquired":
                        return renderDate(activeItem.home_date_acquired);

                      case "value_date":
                        return renderDate(activeItem.amount_outstanding_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "note_text":
                        return renderRichText(activeItem.note_text);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[AssetHP.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Banks/B Societies") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            banksBSocieties.length > 0 &&
            banksBSocieties.map((AssetBank: any) => (
              <div key={AssetBank.Banks_B_Societies_id} className="details">
                <p className="details_label">{AssetBank.label} </p>
                <p>
                  {(() => {
                    switch (AssetBank.value) {
                      case "joint_indicator":
                        return getClientType(activeItem.joint_indicator) || "-";

                      case "balance_date":
                        return renderDate(activeItem.balance_date);

                      case "bank_date_opened":
                        return renderDate(activeItem.bank_date_opened);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "note_text":
                        return renderRichText(activeItem.note_text);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[AssetBank.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    }
  } else {
    return <p>No details available</p>;
  }
};

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "0px"),
    rowGap: "20px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
    marginBottom: "20px",
  },
});

type componentProps = {
  [x: string]: any;
};
const AssetForm = (props: componentProps) => {
  const selectId = useId();
  const styles = useStyles();

  const [assetType, setAssetType] = useState("");
  const [assetList, setAssetList] = useState([]);
  const [defaultAction, setDefaultAction] = useState(false);
  const [assetActiveTabLevel1, setAssetActiveTabLevel1] = useState("asset");
  const [assetActiveTabLevel2, setAssetActiveTabLevel2] = useState("");
  const [currentPage, setCurrentPage] = useState("listing");
  const [formContext, setFormContext] = useState("");
  const [detailFormContext, setDetailFormContext] = useState("");
  const [mode, setMode] = useState("");
  const [detailPageMode, setDetailPageMode] = useState("");
  const [initialFormData, setInitialFormData] = useState({});
  const [assetRowSelection, setAssetRowSelection] = React.useState({});

  const [activeItem, setActiveItem] = useState<any | {}>({});
  const [activeAssetTab, setActiveAssetTab] = useState("asset");
  const dispatch: AppDispatch = useDispatch();
  // asset subitems
  const [assetSubitemsList, setAssetSubitemsList] = useState<any>([]);
  const [assetSubActiveItem, setAssetSubActiveItem] = useState<any>({});
  const [detailPageSelectedRowIndex, setDetailPageSelectedRowIndex] =
    useState(0);
  const [initialDetailPageFormData, setInitialDetailPageFormData] = useState(
    {}
  );
  const [fundValue, setFundValue] = useState<any>();
  const [paymentValue, setPaymentValue] = useState<any>();
  const [withdrawalValue, setWithdrawalValue] = useState<any>();
  const [currentRecord, setCurrentRecord] = useState(1);
  const [currentSubRecord, setCurrentSubRecord] = useState(1);
  const [isOpen, setIsOpen] = React.useState(false);
  const [assetCatPopup, setAssetCatPopup] = React.useState(false);
  const [selectedAssetCatPopup, setSelectedAssetCatPopup] = React.useState("");
  const [assetActiveTabLevel2Subtab, setAssetActiveTabLevel2Subtab] =
    useState("");
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const radioName = useId("radio");
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [saveTrigger, setSaveTrigger] = useState<boolean>(false);

  const labelId = useId("label");
  const [loadStandardActionPopup, setLoadStandardActionPopup] = useState(false);
  const [loadStandardActionDate, setLoadStandardActionDate] = useState(
    new Date()
  );
  const [selectedLoadStandardActions, setSelectedLoadStandardActions] =
    useState<any>([]);
  const [activeProvider, setActiveProvider] = useState<any>({});
  const [activeAssetDetail, setActiveAssetDetail] = useState<any>({});
  const [activePaymentItem, setActivePaymentItem] = useState<any>({});
  const [activeCommissionItem, setActiveCommissionItem] = useState<any>({});

  const [loading, setLoading] = useState(false);
  const contextList: any = {
    "Unit-linked": {
      asset: "ASSET_INVESTMENT_ASSET",
      fund: "ASSET_INVESTMENT_FUND",
      payment: "ASSET_INVESTMENT_PAYMENTS",
      paymentcomplaince: "ASSET_INVESTMENT_PAYMENTS_COMPLIANCE",
      paymentCommission: "ASSET_INVESTMENT_PAYMENTS_COMMISSION",
      commissionDistribution: "ASSET_INVESTMENT_COMMISSION_DISTRIBUTION",
      withdrawals: "ASSET_INVESTMENT_WITHDRAWALS",
      actions: "ASSET_INVESTMENT_ACTIONS",
      valuations: "ASSET_INVESTMENT_VALUATION",
      review: "ASSET_INVESTMENT_REVIEW",
    },
    Shares: {
      asset: "ASSET_SHAREHOLDINGS_ASSET",
      fund: "ASSET_SHARE_HOLDINGS_FUND",
      payment: "ASSET_SHARE_HOLDINGS_PAYMENT",
      paymentcomplaince: "ASSET_SHAREHOLDINGS_PAYMENT_COMPLIANCE",
      paymentCommission: "ASSET_SHAREHOLDINGS_PAYMENT_COMMISSION",
      withdrawals: "ASSET_SHARE_HOLDING_WITHDRAWALS",
      review: "ASSET_SHARE_HOLDINGS_REVIEW",
      valuations: "ASSET_SHAREHOLDING_VALUATION",
    },
    "Home/Personal": {
      asset: "ASSET_HOME_AND_PERSONAL_ASSET",
      review: "ASSET_HOME_AND_PERSONAL_REVIEW",
      valuations: "ASSET_HOME_AND_PERSONAL_VALUATION",
    },
    "Banks/B Societies": {
      asset: "ASSET_BANK_OR_BUILDING_SOCIETIES_ASSET",
      fund: "ASSET_BANK_OR_BUILDING_SOCIETIES_FUND",
      payment: "ASSET_BANK_OR_BUILDING_SOCIETIES_PAYMENT",
      paymentcomplaince: "ASSET_BANK_OR_BUILDING_SOCIETIES_COMPLIANCE",
      paymentCommission: "ASSET_BANK_OR_BUILDING_SOCIETIES_PAYMENT_COMMISSION",
      withdrawals: "ASSET_BANK_OR_BUILDING_SOCIETIES_WITHDRAWALS",
      review: "ASSET_BANK_OR_BUILDING_SOCIETIES_REVIEW",
      valuations: "ASSET_BANK_OR_BUILDING_SOCIETIES_VALUATION",
    },
  };
  const handleBreadcrumbs = () => {
    let breadcrumbsItems: { key: number; value: string; link?: string }[] = [
      {
        key: 0,
        value: "Home",
        link: "/",
      },
      {
        key: 1,
        value: "Clients",
        link: "/clients",
      },
      {
        key: 2,
        value: "Client",
        link: "/client",
      },
      {
        key: 3,
        value: "Assets",
        link: "/client",
      },
    ];
    if (assetType !== "" && assetList.length > 0) {
      breadcrumbsItems.push({
        key: 4,
        value: camalize(getAssetCategoryByCaseType(assetType)),
      });
    }
    dispatch(addBreadcrumbs(breadcrumbsItems));
    // }
  };
  const getContextDetails = (assetType: string, context: string) => {
    if (contextList[assetType] && contextList[assetType][context]) {
      setFormContext(contextList[assetType][context]);
    } else {
      setFormContext("");
    }
  };

  useEffect(() => {
    setAssetSubitemsList([]);
  }, [assetActiveTabLevel2Subtab]);

  useEffect(() => {
    fetchAssetLists();
    // handleBreadcrumbs();
  }, []);

  useEffect(() => {
    if (assetList.length > 0) {
      selectedIndex(0);
    }
  }, [assetList, saveTrigger]);

  useEffect(() => {
    if (assetSubitemsList.length > 0) {
      selectedSubIndex(0);
    } else {
      setDetailFormContext("");
    }
  }, [assetSubitemsList]);

  // useEffect(() => {
  //   getAssetSubItems();
  //   setDetailFormContext("");
  //   handleBreadcrumbs();
  // }, [assetActiveTabLevel2]);

  // useEffect(()=>{
  //   if (assetActiveTabLevel2 === "payment") {
  //       setPaymentData((assetSubActiveItem as { [key: string]: any }).payment.payment_id)
  //   }

  // },[assetActiveTabLevel2])

  useEffect(() => {
    if (assetActiveTabLevel2 !== "") {
      if (assetActiveTabLevel2 === "payment") {
        setAssetActiveTabLevel2Subtab("payment");
      } else {
        setAssetActiveTabLevel2Subtab("");
      }
      // else {
      //   getAssetSubItems();
      // }
    }
    getAssetSubItems();
    setDetailFormContext("");
    handleBreadcrumbs();
  }, [assetActiveTabLevel2]);
  useEffect(() => {
    if (assetActiveTabLevel2Subtab !== "") {
      getAssetSubItems();
    }
  }, [assetActiveTabLevel2Subtab]);
  useEffect(() => {
    if (assetList.length > 0) {
      let index = 0;

      index = assetList.findIndex(
        (x: any) => x.asset_id === activeItem.asset_id
      );
      if (activeAssetTab === "asset") {
        setInitialFormData(setAssetFieldValues(assetList[index]));
      } else if (activeAssetTab === "review") {
        setInitialFormData(setAssetReviewFieldValues(assetList[index]));
      } else {
      }
    }
  }, [mode]);

  useEffect(() => {
    // setMode(detailPageMode);
    if (assetSubitemsList.length > 0) {
      let index = 0;

      if (assetActiveTabLevel2 === "payment") {
        if (assetActiveTabLevel2Subtab === "paymentCommission") {
          index = assetSubitemsList.findIndex(
            (x: any) => x.commission_id === assetSubActiveItem.commission_id
          );
        } else {
          index = assetSubitemsList.findIndex(
            (x: any) =>
              x.payment.payment_id === assetSubActiveItem.payment.payment_id
          );

          if (assetActiveTabLevel2Subtab === "payment") {
            setInitialDetailPageFormData(
              setPaymentFieldValues(assetSubitemsList[index])
            );
          } else if (assetActiveTabLevel2Subtab === "paymentcomplaince") {
            setInitialDetailPageFormData(
              setPaymentComplainceFieldValues(assetSubitemsList[index])
            );
          } else {
          }
        }
      } else if (assetActiveTabLevel2 === "withdrawals") {
        index = assetSubitemsList.findIndex(
          (x: any) => x.withdrawal_id === assetSubActiveItem.withdrawal_id
        );
        setInitialDetailPageFormData(
          setWithdrawalsFieldValues(assetSubitemsList[index])
        );
      } else if (assetActiveTabLevel2 === "valuations") {
        index = assetSubitemsList.findIndex(
          (x: any) => x.valuation_id === assetSubActiveItem.valuation_id
        );
        setInitialDetailPageFormData(
          setValuationsFieldValues(assetSubitemsList[index])
        );
      } else if (assetActiveTabLevel2 === "actions") {
        index = assetSubitemsList.findIndex(
          (x: any) => x.business_id === assetSubActiveItem.business_id
        );
        setInitialDetailPageFormData(
          setActionsFieldValues(assetSubitemsList[index])
        );
      } else if (assetActiveTabLevel2 === "fund") {
        index = assetSubitemsList.findIndex(
          (x: any) => x.fund_id === assetSubActiveItem.fund_id
        );
        setInitialDetailPageFormData(
          setFundFieldValues(assetSubitemsList[index])
        );
      } else {
      }
    }
  }, [detailPageMode]);

  useEffect(() => {
    // alert("activeAssetTab==" + currentRecord);
    if (activeAssetTab === "review" && currentRecord) {
      setInitialFormData(
        setAssetReviewFieldValues(assetList[currentRecord - 1])
      );
    } else if (activeAssetTab === "asset" && currentRecord) {
      setInitialFormData(setAssetFieldValues(assetList[currentRecord - 1]));
    } else {
    }
    getContextDetails(assetType, activeAssetTab);
  }, [activeAssetTab]);
  const showReviewForm = () => {
    // getContextDetails(assetType, "review");
    // alert(assetType);
  };
  useEffect(() => {
    // if (assetList.length === 0 && assetType !== "") {
    //   setDefaultAction(true);
    //   getContextDetails(assetType, assetActiveTabLevel1);
    // } else {
    //   setDefaultAction(false);
    // }
    handleBreadcrumbs();
  }, [assetType]);

  // useEffect(() => {
  //   if (Object.keys(activeItem).length !== 0) {
  //     getAssetDetails(activeItem.customer_id, activeItem.asset_id);
  //   }
  // }, [activeItem]);

  useEffect(() => {
    if (Object.keys(activeAssetDetail).length !== 0) {
      if (activeAssetTab === "asset") {
        setInitialFormData(setAssetFieldValues(activeAssetDetail));
      } else if (activeAssetTab === "review") {
        setInitialFormData(setAssetReviewFieldValues(activeAssetDetail));
      } else {
      }
      // setInitialFormData(setAssetFieldValues(activeAssetDetail));
    }
  }, [activeAssetDetail]);

  const getAssetDetails = (customerId: string, assetId: string) => {
    setLoading(true);
    apiServices.assets
      .getDetail(assetId, customerId)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          if (response.data.data) {
            setActiveAssetDetail(response.data.data);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err");
      });
  };

  const fetchAssetLists = () => {
    setLoading(true);
    apiServices.assets
      .getAssets(props.activeCustomerId)
      .then((res) => {
        setLoading(false);
        if (res.data !== undefined) {
          if (res.data.data) {
            setAssetList(res.data.data);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const selectedIndex = (index: number) => {
    if (assetList.length > 0) {
      setActiveItem(assetList[index]);
      setActiveAssetTab(activeAssetTab);
      setMode("view");
      if (activeAssetTab === "asset") {
        setInitialFormData(setAssetFieldValues(assetList[index]));
      } else if (activeAssetTab === "review") {
        setInitialFormData(setAssetReviewFieldValues(assetList[index]));
      } else {
      }

      if ((assetList as { [key: string]: any })[index].case_type) {
        let case_type_value = (assetList as { [key: string]: any })[index]
          .case_type;
        setFormContext(
          (contextList as { [key: string]: any })[case_type_value]["asset"]
        );

        setAssetType(case_type_value);
        getContextDetails(case_type_value, assetActiveTabLevel1);
      }
      // setActiveProvider({});
      setLoading(true);
      apiServices.assets
        .getDetail(
          (assetList as { [key: string]: any })[index].asset_id,
          props.activeCustomerId
        )
        .then((response) => {
          setLoading(true);
          if (response.data) {
            if (response.data.data) {
              setActiveProvider(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    }
  };
  const selectedSubIndex = (index: number) => {
    if (index !== undefined) {
      if (assetActiveTabLevel2 === "payment") {
        if (assetActiveTabLevel2Subtab === "payment") {
          setInitialDetailPageFormData(
            setPaymentFieldValues(assetSubitemsList[index])
          );
          setActivePaymentItem(assetSubitemsList[index]);
        } else if (assetActiveTabLevel2Subtab === "paymentcomplaince") {
          setInitialDetailPageFormData(
            setPaymentComplainceFieldValues(assetSubitemsList[index])
          );
        } else if (assetActiveTabLevel2Subtab === "paymentCommission") {
          setActiveCommissionItem(assetSubitemsList[index]);
          setInitialDetailPageFormData(
            setcommissionFieldValues(assetSubitemsList[index])
          );
        } else if (assetActiveTabLevel2Subtab === "commissionDistribution") {
          setInitialDetailPageFormData(
            setDistributionFieldValues(assetSubitemsList[index])
          );
        } else {
        }
      } else if (assetActiveTabLevel2 === "withdrawals") {
        setActivePaymentItem({});
        setActiveCommissionItem({});
        setInitialDetailPageFormData(
          setWithdrawalsFieldValues(assetSubitemsList[index])
        );
      } else if (assetActiveTabLevel2 === "valuations") {
        setActivePaymentItem({});
        setActiveCommissionItem({});
        setInitialDetailPageFormData(
          setValuationsFieldValues(assetSubitemsList[index])
        );
      } else if (assetActiveTabLevel2 === "actions") {
        setActivePaymentItem({});
        setActiveCommissionItem({});
        setInitialDetailPageFormData(
          setActionsFieldValues(assetSubitemsList[index])
        );
      } else if (assetActiveTabLevel2 === "fund") {
        setActivePaymentItem({});
        setActiveCommissionItem({});
        setInitialDetailPageFormData(
          setFundFieldValues(assetSubitemsList[index])
        );
      } else {
      }

      setDetailPageMode("view");
      if (assetActiveTabLevel2 === "payment") {
        setDetailFormContext(
          contextList[assetType][assetActiveTabLevel2Subtab]
        );
      } else {
        setDetailFormContext(contextList[assetType][assetActiveTabLevel2]);
      }
      setAssetSubActiveItem(assetSubitemsList[index]);
      handleBreadcrumbs();
    }
  };
  const selectActiveAssetItem = (item: string) => {
    setCurrentPage("detail");
    setAssetActiveTabLevel2(item);
  };
  const getAssetSubItems = () => {
    // alert("hiii")

    if (assetActiveTabLevel2 != "") {
      // alert(assetActiveTabLevel2);
      // api call
      fetchSubItemList();
      // setAssetSubitemsList(assetSubitems);
    }
  };
  const handleRowAction = (mode: string, row: any) => {
    setActiveItem(row.orginal);
    setMode(mode);
  };
  const handleRowSelection = (assetId: any, mode: string) => {
    let assetObj = assetList.find((item: any) => item.asset_id === assetId);
    if (assetObj) {
      setActiveItem(assetObj);
      setMode("view");
      setActiveAssetTab(activeAssetTab);
      if (activeAssetTab === "asset") {
        setInitialFormData(setAssetFieldValues(assetObj));
      } else if (activeAssetTab === "review") {
        setInitialFormData(setAssetReviewFieldValues(assetObj));
      } else {
      }
      // setInitialFormData(setAssetFieldValues(assetObj));
      let case_type_value = (assetObj as { [key: string]: any }).case_type;

      setFormContext(case_type_value[activeAssetTab]);
      setAssetType(case_type_value);
      getContextDetails(case_type_value, activeAssetTab);
      // getAssetDetails(
      //   (assetObj as { [key: string]: any }).customer_id,
      //   (assetObj as { [key: string]: any }).asset_id
      // );
    }
  };
  const handleDetailPageRowSelection = (index: number) => {
    // setDetailPageSelectedRowIndex(index);
    selectedSubIndex(index);
    // alert(index);
    //  let assetObj = assetList.find((item: any) => item.AssetId === assetId);
    //  if (assetObj) {
    //    setActiveItem(assetObj);
    //    setMode("view");
    //    setInitialFormData(setAssetFieldValues(assetObj));
    //    let case_type_value = (assetObj as { [key: string]: any }).CaseType;

    //    setFormContext(case_type_value["asset"]);
    //    setAssetType(case_type_value);
    //    getContextDetails(case_type_value, assetActiveTabLevel1);
    //  }
  };
  const handleDetailFormContext = (module: string) => {
    setDetailFormContext(contextList[assetType][module]);
  };
  const handleReloadList = () => {
    fetchAssetLists();
  };
  const handleReloadSublist = () => {
    fetchSubItemList();
  };
  const fetchSubItemList = () => {
    setLoading(true);
    // setAssetSubitemsList([]);
    if (assetActiveTabLevel2 === "payment") {
      if (assetActiveTabLevel2Subtab === "paymentCommission") {
        if (Object.keys(activePaymentItem).length !== 0) {
          apiServices.commission
            .getCommission(
              (activePaymentItem as { [key: string]: any }).payment?.payment_id,
              props.activeCustomerId,
              (activeItem as { [key: string]: any }).asset_id
            )
            .then((response) => {
              setLoading(false);
              if (response.data) {
                if (response.data.data) {
                  setAssetSubitemsList(response.data.data);
                }
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      } else if (assetActiveTabLevel2Subtab === "commissionDistribution") {
        if (Object.keys(activeCommissionItem).length !== 0) {
          // apiServices.commission
          //   .getCommission(
          //     (activePaymentItem as { [key: string]: any }).payment?.payment_id,
          //     props.activeCustomerId,
          //     (activeItem as { [key: string]: any }).asset_id
          //   )
          //   .then((response) => {
          //     setLoading(false);
          //     if (response.data) {
          //       if (response.data.data) {
          //         setAssetSubitemsList(response.data.data);
          //       }
          //     }
          //   })
          //   .catch((err) => {
          //     setLoading(false);
          //     console.log(err);
          //   });
        }
      } else {
        apiServices.Payments.getPayments(
          (activeItem as { [key: string]: any }).asset_id,
          props.activeCustomerId
        )
          .then((response) => {
            setLoading(false);
            if (response.data) {
              if (response.data.data && response.data.data.result) {
                setAssetSubitemsList(response.data.data?.result || []);
                setPaymentValue(response.data.data?.total);
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } else if (assetActiveTabLevel2 === "withdrawals") {
      apiServices.Withdrawals.getList(
        (activeItem as { [key: string]: any }).asset_id,
        props.activeCustomerId
      )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data && response.data.data.result) {
              setAssetSubitemsList(response.data.data?.result || []);
              setWithdrawalValue(response.data.data?.total);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (assetActiveTabLevel2 === "actions") {
      apiServices.Actions.getActions(
        (activeItem as { [key: string]: any }).asset_id,
        props.activeCustomerId
      )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data) {
              setAssetSubitemsList(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (assetActiveTabLevel2 === "fund") {
      apiServices.fund
        .getList(
          props.activeCustomerId,
          (activeItem as { [key: string]: any }).asset_id
        )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data && response.data.data.result) {
              setAssetSubitemsList(response.data.data?.result || []);
              setFundValue(response.data.data?.total_fund);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (assetActiveTabLevel2 === "valuations") {
      // let itemArr=[{
      //   id: "f47ac10b-58cc-4372-a567-0e02b2c3d479",
      //   effective_date:"05/04/2024",
      //   valuation_amount:"3500",
      //   note_text:"<p>string</p>"
      // }]
      // setAssetSubitemsList(itemArr);
      apiServices.valuation
        .getvaluation(
          (activeItem as { [key: string]: any }).asset_id,
          props.activeCustomerId
        )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data) {
              setAssetSubitemsList(response.data.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };
  const setAssetFieldValues = (params: any) => {
    let paramVal = {};
    if (params?.case_type === "Unit-linked") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        investment_type: params.investment_type ? params.investment_type : "",
        investment_subtype: params.investment_subtype
          ? params.investment_subtype
          : "",
        provider_id: params.provider_id ? params.provider_id : "",
        investment_provider: params.investment_provider
          ? params.investment_provider
          : "",
        investment_account_number: params.investment_account_number
          ? params.investment_account_number
          : "",
        current_value: params.current_value ? params.current_value : "",
        value_date: params.value_date ? params.value_date : "",
        purpose: params.purpose ? params.purpose : "",
        investment_start_date: params.investment_start_date
          ? params.investment_start_date
          : "",
        investment_end_date: params.investment_end_date
          ? params.investment_end_date
          : "",
        investment_status: params.investment_status
          ? params.investment_status
          : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_correspondence: params.provider_correspondence
          ? activeAssetDetail?.provider_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Shares") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        share_type: params.share_type ? params.share_type : "",
        share_subtype: params.share_subtype ? params.share_subtype : "",
        provider_id: params.provider_id ? params.provider_id : "",
        share_company: params.share_company ? params.share_company : "",
        share_current_number: params.share_current_number
          ? params.share_current_number
          : "",
        share_current_price: params.share_current_price
          ? params.share_current_price
          : "",
        current_value: params.current_value ? params.current_value : "",

        value_date: params.value_date ? params.value_date : "",
        purpose: params.purpose ? params.purpose : "",
        share_date_acquired: params.share_date_acquired
          ? params.share_date_acquired
          : "",
        share_original_value: params.share_original_value
          ? params.share_original_value
          : "",
        share_status: params.share_status ? params.share_status : "",

        service_status: params.service_status ? params.service_status : "",
        status_date: params.status_date ? params.status_date : "",

        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_correspondence: params.provider_correspondence
          ? activeAssetDetail?.provider_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Home/Personal") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        home_description: params.home_description
          ? params.home_description
          : "",
        home_date_acquired: params.home_date_acquired
          ? params.home_date_acquired
          : "",
        home_original_value: params.home_original_value
          ? params.home_original_value
          : "",
        current_value: params.current_value ? params.current_value : "",
        value_date: params.value_date ? params.value_date : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        purpose: params.purpose ? params.purpose : "",
        service_status: params.service_status ? params.service_status : "",
        status_date: params.status_date ? params.status_date : "",
      };
    } else if (params?.case_type === "Banks/B Societies") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          mode == "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        owner: params.owner ? params.owner : "",
        owner_name1: params.owner_name1 ? params.owner_name1 : "",
        owner_name2: params.owner_name2 ? params.owner_name2 : "",
        bank_account_type: params.bank_account_type
          ? params.bank_account_type
          : "",
        bank_name: params.bank_name ? params.bank_name : "",
        bank_sort_code: params.bank_sort_code ? params.bank_sort_code : "",

        bank_account_name: params.bank_account_name
          ? params.bank_account_name
          : "",
        bank_account_number: params.bank_account_number
          ? params.bank_account_number
          : "",

        current_value: params.current_value ? params.current_value : "",

        value_date: params.value_date ? params.value_date : "",
        purpose: params.purpose ? params.purpose : "",

        bank_interest_rate: params.bank_interest_rate
          ? params.bank_interest_rate
          : "",

        bank_date_opened: params.bank_date_opened
          ? params.bank_date_opened
          : "",
        bank_status: params.bank_status ? params.bank_status : "",

        service_status: params.service_status ? params.service_status : "",
        status_date: params.status_date ? params.status_date : "",

        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    }
    return paramVal;
  };
  const setAssetReviewFieldValues = (params: any) => {
    let paramVal = {};
    paramVal = {
      ...paramVal,
      review_assigned: params?.review_assigned || "",
      review_completed: params?.review_completed || "",
      review_date: params?.review_date || "",
      review_interval: params?.review_interval || "",
      review_reason: params?.review_reason || "",
    };
    return paramVal;
  };
  const setPaymentFieldValues = (params: any) => {
    let paymentParamVal = {};
    if (params?.payment !== undefined) {
      paymentParamVal = {
        ...paymentParamVal,
        start_date: params.payment.start_date ? params.payment.start_date : "",
        end_date: params.payment.end_date ? params.payment.end_date : "",
        amount: params.payment.amount ? params.payment.amount : "",
        frequency: params.payment.frequency ? params.payment.frequency : "",
        new_business_register: params.payment.new_business_register
          ? params.payment.new_business_register
          : "",
        reinvestment: params.payment.reinvestment
          ? params.payment.reinvestment
          : "",
        note_text: params.payment.note_text ? params.payment.note_text : "",
        payment_type: params.payment.payment_type
          ? params.payment.payment_type
          : "",
      };
    }
    return paymentParamVal;
  };
  const setWithdrawalsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        amount: params.amount ? params.amount : "",
        frequency: params.frequency ? params.frequency : "",
        high_risk: params.high_risk ? params.high_risk : "",
        high_risk_note: params.high_risk_note ? params.high_risk_note : "",
        reinvestment: params.reinvestment ? params.reinvestment : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setValuationsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,

        effective_date: params.effective_date ? params.effective_date : "",
        valuation_amount: params.valuation_amount
          ? params.valuation_amount
          : "",

        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setPaymentComplainceFieldValues = (params: any) => {
    let paymentComplainceParamVal = {};
    if (params.payment_complience !== undefined) {
      paymentComplainceParamVal = {
        ...params.payment_complience,
        consultant: params.payment_complience.consultant
          ? params.payment_complience.consultant
          : "",
      };
    }
    return paymentComplainceParamVal;
  };
  const setcommissionFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        status: params.status ? params.status : "",
        commission_type: params.commission_type ? params.commission_type : "",
        payment_source: params.payment_source ? params.payment_source : "",
        frequency: params.frequency ? params.frequency : "",
        amount_to_company: params.amount_to_company
          ? params.amount_to_company
          : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        // nb_register: params.nb_register ? params.nb_register : "",
        nb_register:
          mode == "view"
            ? getYes_NoConversion(params.nb_register)
            : params.nb_register,
        payment_count: params.payment_count ? params.payment_count : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setDistributionFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        status: params.status ? params.status : "",
        party_type: params.party_type ? params.party_type : "",
        party_name: params.party_name ? params.party_name : "",
        case_type: params.case_type ? params.case_type : "",
        current_rule: params.current_rule ? params.current_rule : "",
        commission_rule_id: params.commission_rule_id
          ? params.commission_rule_id
          : "",
        amount: params.amount ? params.amount : "",

        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setActionsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        business_date: params.business_date ? params.business_date : "",
        business_action: params.business_action ? params.business_action : "",
        business_assigned: params.business_assigned
          ? params.business_assigned
          : "",
        business_status: params.business_status ? params.business_status : "",
      };
    }
    return ParamVal;
  };
  const setFundFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        fund_code: params.fund_code ? params.fund_code : "",
        fund_code_source:
          detailPageMode === "view"
            ? getAssetFundCodeSource(params.fund_code_source)
            : params?.fund_code_source?.toString(),
        fund_name: params.fund_name ? params.fund_name : "",
        fund_units: params.fund_units ? parseFloat(params.fund_units) : "",
        unit_price: params.unit_price ? parseFloat(params.unit_price) : 0.0,
        currency_code: params.currency_code ? params.currency_code : "",
        price_date: params.price_date ? params.price_date : "",
        fund_value: params.fund_value ? parseFloat(params.fund_value) : "",
        sector: params.sector ? params.sector : "",
        total_expense_ratio: params.total_expense_ratio
          ? parseFloat(params.total_expense_ratio)
          : "",
        risk_rating: params.risk_rating ? params.risk_rating : "",
      };
    }

    return ParamVal;
  };
  const addLoadStandardActions = () => {
    if (selectedLoadStandardActions.length > 0) {
      let case_type_value = (activeItem as { [key: string]: any }).case_type;
      let contextVal = contextList[case_type_value]["actions"];

      if (formSettingsObj && formSettingsObj.data) {
        let configObj: any = checkIfKeyExist(
          formSettingsObj.data.formConfig,
          contextVal
        );
        if (
          configObj &&
          configObj.renderForm &&
          configObj.renderForm?.reference_id
        ) {
          let actReq: any = {};
          actReq[configObj.renderForm?.reference_id] = {
            customer_id: props.activeCustomerId,
            case_id: activeItem.asset_id,
            effective_date: formatedDate2(loadStandardActionDate),
            tracking_case_actions: [],
          };
          let selAct: any = [];
          selectedLoadStandardActions.map((v: any) => {
            selAct.push({
              tracking_id: v,
            });
          });
          actReq[configObj.renderForm?.reference_id]["tracking_case_actions"] =
            selAct;
          setLoading(true);
          apiServices.tackingActions
            .addStandardActions(actReq)
            .then((response) => {
              setLoading(false);
              if (response.data && response.data.data) {
                setSelectedLoadStandardActions([]);
                getAssetSubItems();
                setLoadStandardActionPopup(false);
                setLoadStandardActionDate(new Date());
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      }
    }
  };

  const loadActionSelected = (action: any) => {
    let act: any = [...selectedLoadStandardActions];

    const index = act.indexOf(action);
    if (index !== -1) {
      act.splice(index, 1);
    } else {
      act.push(action);
    }

    // setSelectedActions(act);
    setSelectedLoadStandardActions(act);
  };

  return (
    <div className="form-container">
      {/* <OverlayLoader isLoading={loading} /> */}
      {/* <Divider style={{ marginTop: "6px" }} /> */}

      {assetList.length === 0 && (formContext === "" || mode === "") && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              marginBottom: "10px",
              marginRight: "10px",
            }}
          >
            {checkUserPermission(
              loggedUser,
              UserPermissionsList.ALLOW_UPDATE_CLIENT
            ) && (
              <Button
                appearance="primary"
                className="asc-button-primary"
                icon={<Add16Filled />}
                size="small"
                onClick={() => {
                  setAssetCatPopup(true);
                }}
              >
                Add Asset
              </Button>
            )}
          </div>
        </>
      )}
      {formContext !== "" && mode !== "" && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className={styles.btnWrapper}>
                {activeItem &&
                  Object.keys(activeItem).length !== 0 &&
                  currentPage !== "listing" && (
                    <>
                      <Button
                        size="small"
                        icon={<ChevronLeft20Regular />}
                        onClick={() => {
                          setAssetActiveTabLevel2("");
                          setDetailFormContext("");
                          setAssetSubitemsList([]);
                          setCurrentPage("listing");
                          setDetailPageSelectedRowIndex(0);
                        }}
                      >
                        <span style={{ color: "red" }}>Back to Asset</span>
                      </Button>
                    </>
                  )}

                {["Unit-linked"].includes(assetType) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_hide_fund_button") ? (
                  <Button
                    size="small"
                    style={{
                      background:
                        assetActiveTabLevel2 === "fund" ? "black" : undefined,
                      color:
                        assetActiveTabLevel2 === "fund" ? "white" : undefined,
                    }}
                    onClick={() => {
                      selectActiveAssetItem("fund");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_disable_fund_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Fund
                  </Button>
                ) : (
                  ""
                )}
                {["Unit-linked", "Shares", "Banks/B Societies"].includes(
                  assetType
                ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_hide_payment_button") ? (
                  <Button
                    size="small"
                    style={{
                      background:
                        assetActiveTabLevel2 === "payment"
                          ? "black"
                          : undefined,
                      color:
                        assetActiveTabLevel2 === "payment"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActiveAssetItem("payment");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_disable_payment_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Payments
                  </Button>
                ) : (
                  ""
                )}
                {["Unit-linked", "Shares", "Banks/B Societies"].includes(
                  assetType
                ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_hide_withdrawal_button") ? (
                  <Button
                    size="small"
                    style={{
                      background:
                        assetActiveTabLevel2 === "withdrawals"
                          ? "black"
                          : undefined,
                      color:
                        assetActiveTabLevel2 === "withdrawals"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActiveAssetItem("withdrawals");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_disable_withdrawal_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Withdrawals
                  </Button>
                ) : (
                  ""
                )}
                {["Unit-linked"].includes(assetType) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_hide_action_button") ? (
                  <Button
                    size="small"
                    style={{
                      background:
                        assetActiveTabLevel2 === "actions"
                          ? "black"
                          : undefined,
                      color:
                        assetActiveTabLevel2 === "actions"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActiveAssetItem("actions");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_disable_action_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Actions
                  </Button>
                ) : (
                  ""
                )}
                {[
                  "Unit-linked",
                  "Shares",
                  "Banks/B Societies",
                  "Home/Personal",
                ].includes(assetType) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_hide_valuation_button") ? (
                  <Button
                    size="small"
                    style={{
                      background:
                        assetActiveTabLevel2 === "valuations"
                          ? "black"
                          : undefined,
                      color:
                        assetActiveTabLevel2 === "valuations"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActiveAssetItem("valuations");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_disable_valuation_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Valuations
                  </Button>
                ) : (
                  ""
                )}
              </div>
              {currentPage && currentPage === "listing" && mode === "view" && (
                <div className={styles.btnWrapper}>
                  {checkUserPermission(
                    loggedUser,
                    UserPermissionsList.ALLOW_UPDATE_CLIENT
                  ) && (
                    <Button
                      appearance="primary"
                      className="asc-button-primary"
                      icon={<Add16Filled />}
                      size="small"
                      onClick={() => {
                        setAssetCatPopup(true);
                      }}
                      disabled={
                        activeAssetTab === "review"
                          ? true
                            ? assetType !== "" &&
                              !["edit", "view"].includes(mode)
                              ? false
                              : true
                            : false
                          : false
                      }
                    >
                      Add Asset
                    </Button>
                  )}
                </div>
              )}
              {currentPage && currentPage === "detail" && (
                <div className={styles.btnWrapper}>
                  {/* <Button
                    size="small"
                    appearance="primary"
                    icon={<Add20Regular />}
                    onClick={() => {
                      setDetailFormContext(
                        contextList[assetType][assetActiveTabLevel2]
                      );
                      // getContextDetails(assetType, assetActiveTabLevel2);
                    }}
                  >
                    {camalize(`add ${assetActiveTabLevel2}`)}
                  </Button> */}
                  {
                    //   assetActiveTabLevel2 === "fund" && (
                    //   <Button
                    //     size="small"
                    //     appearance="outline"
                    //     icon={<DocumentFolder20Regular />}
                    //   >
                    //     Locate Fund
                    //   </Button>
                    // )
                  }
                  {assetActiveTabLevel2 === "actions" && (
                    <>
                      {checkUserPermission(
                        loggedUser,
                        UserPermissionsList.ALLOW_UPDATE_CLIENT
                      ) &&
                        !convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes("asset_load_standard_action_hide") && (
                          <Button
                            size="small"
                            appearance="outline"
                            disabled={convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("asset_load_standard_action_disable")}
                            onClick={() => {
                              setLoadStandardActionPopup(true);
                            }}
                          >
                            Load Standard Actions
                          </Button>
                        )}
                    </>
                  )}

                  {assetActiveTabLevel2 === "valuations" &&
                    !convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("asset_use_case_fund_value") && (
                      <Button
                        size="small"
                        disabled={convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("asset_use_case_fund_value")}
                        appearance="outline"
                      >
                        Use Case Fund Value
                      </Button>
                    )}
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("asset_case_details_hide") ? (
                    <Button
                      size="small"
                      appearance="outline"
                      disabled={convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_case_details_disable")}
                      icon={<DocumentBulletList20Regular />}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      Case Details
                    </Button>
                  ) : (
                    ""
                  )}

                  {/* <Button
                    size="small"
                    appearance="primary"
                    className="asc-button-primary"
                    icon={<Add20Regular />}
                    disabled={
                      assetActiveTabLevel2Subtab === "paymentcomplaince"
                        ? true
                        : false
                    }
                    onClick={() =>{
                      handleDetailFormContext(assetActiveTabLevel2);}
                      setDetailPageMode("add");
                    }}
                  >
                    {camalize(`add ${assetActiveTabLevel2}`)}
                  </Button> */}
                  {checkUserPermission(
                    loggedUser,
                    UserPermissionsList.ALLOW_UPDATE_CLIENT
                  ) && (
                    <Button
                      size="small"
                      appearance="primary"
                      className="asc-button-primary"
                      icon={<Add20Regular />}
                      disabled={
                        assetActiveTabLevel2Subtab === "paymentcomplaince"
                          ? true
                          : false
                      }
                      onClick={() => {
                        assetActiveTabLevel2Subtab === "paymentCommission" ||
                        assetActiveTabLevel2Subtab === "commissionDistribution"
                          ? handleDetailFormContext(assetActiveTabLevel2Subtab)
                          : handleDetailFormContext(assetActiveTabLevel2);
                        setDetailPageMode("add");
                      }}
                    >
                      {assetActiveTabLevel2Subtab === "paymentCommission"
                        ? "Add Commission"
                        : assetActiveTabLevel2Subtab ===
                          "commissionDistribution"
                        ? "Add Distribution"
                        : camalize(`Add ${assetActiveTabLevel2}`)}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.tabList}>
            {currentPage === "listing" && (
              <TabList selectedValue={activeAssetTab}>
                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_asset_hide_button") ? (
                  <Tab
                    value="asset"
                    onClick={() => {
                      setActiveAssetTab("asset");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_asset_disable_button")
                        ? false
                        : true
                    }
                  >
                    Asset
                  </Tab>
                ) : (
                  ""
                )}
                {[
                  "Unit-linked",
                  "Shares",
                  "Banks/B Societies",
                  "Home/Personal",
                ].includes(assetType) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_review_hide_button") ? (
                  <Tab
                    value="review"
                    onClick={() => {
                      setActiveAssetTab("review");
                      // showReviewForm();
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_review_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Review
                  </Tab>
                ) : (
                  ""
                )}
                {["Unit-linked"].includes(assetType) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("asset_contract_enquiry_hide_button") ? (
                  <Tab
                    value="contract-enquiry"
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_contract_enquiry_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? true
                        : true
                    }
                    onClick={() => {
                      setActiveAssetTab("contract-enquiry");
                    }}
                  >
                    Contract Enquiry
                  </Tab>
                ) : (
                  ""
                )}
              </TabList>
            )}
            {currentPage === "detail" && (
              <>
                {assetActiveTabLevel2 === "payment" && (
                  <TabList selectedValue={assetActiveTabLevel2Subtab}>
                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("asset_payment_payment_hide_button") ? (
                      <Tab
                        value="payment"
                        onClick={() => {
                          setAssetActiveTabLevel2Subtab("payment");
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes("asset_payment_payment_disable_button")
                            ? false
                            : true
                        }
                      >
                        Payment
                      </Tab>
                    ) : (
                      ""
                    )}
                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("asset_payment_compliance_hide_button") ? (
                      <Tab
                        value="paymentcomplaince"
                        onClick={() => {
                          setAssetActiveTabLevel2Subtab("paymentcomplaince");
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes(
                            "asset_payment_compliance_disable_button"
                          ) &&
                          assetSubActiveItem &&
                          Object.keys(assetSubActiveItem).length !== 0
                            ? false
                            : true
                        }
                      >
                        Compliance
                      </Tab>
                    ) : (
                      ""
                    )}

                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("asset_payment_commission_hide_button") ? (
                      <Tab
                        value="paymentCommission"
                        onClick={() => {
                          setAssetActiveTabLevel2Subtab("paymentCommission");
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes(
                            "asset_payment_commission_disable_button"
                          ) &&
                          assetSubActiveItem &&
                          Object.keys(assetSubActiveItem).length !== 0
                            ? false
                            : true
                        }
                      >
                        Commission
                      </Tab>
                    ) : (
                      ""
                    )}
                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("asset_commission_distribution_hide_button") ? (
                      <Tab
                        value="commissionDistribution"
                        onClick={() => {
                          setAssetActiveTabLevel2Subtab(
                            "commissionDistribution"
                          );
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes(
                            "asset_commission_distribution_disable_button"
                          ) &&
                          activeCommissionItem &&
                          Object.keys(activeCommissionItem).length !== 0
                            ? false
                            : true
                        }
                      >
                        Distribution
                      </Tab>
                    ) : (
                      ""
                    )}
                  </TabList>
                )}
                {assetActiveTabLevel2 === "withdrawals" && (
                  <TabList defaultSelectedValue="tab1">
                    <Tab value="tab1">Withdrawals</Tab>
                  </TabList>
                )}
                {assetActiveTabLevel2 === "valuations" && (
                  <TabList defaultSelectedValue="tab1">
                    <Tab value="tab1">Valuations</Tab>
                  </TabList>
                )}
              </>
            )}
          </div>
          <div className="form-container">
            {currentPage === "listing" && mode !== "" ? (
              <FormContainer
                {...{
                  context: formContext,
                  defaultAction,
                  setFormContext,
                  setAssetType,
                  currentPage,
                  setAssetList,
                  mode,
                  setMode,
                  initialFormData,
                  handleReloadList,
                  activeItem,
                  activeCustomerId: props.activeCustomerId,
                  assetType,
                  notify: props.notify,
                  handleRowSelection,
                  assetList,
                  currentRecord,
                  caseDetails: activeAssetDetail,
                  activePartnerId: props.activePartnerId,
                  setActivePartnerId: props.setActivePartnerId,
                }}
              />
            ) : (
              <>
                {assetActiveTabLevel2Subtab === "paymentCommission" ? (
                  <>
                    {assetSubitemsList.length === 0 &&
                      detailFormContext === "" &&
                      detailPageMode === "" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                          }}
                        >
                          <Label size="large" style={{ color: "red" }}>
                            There are no active records - click{" "}
                            {camalize(`add commission`)} to create a new record
                          </Label>
                        </div>
                      )}

                    <FormContainerDetailPage
                      {...{
                        context: detailFormContext,
                        setDetailFormContext,
                        activeItem,
                        mode: detailPageMode,
                        setMode: setDetailPageMode,
                        initialFormData: initialDetailPageFormData,
                        activeCustomerId: props.activeCustomerId,
                        assetType,
                        detailPageMode,
                        setDetailPageMode,
                        assetActiveTabLevel2,
                        handleReloadSublist,
                        notify: props.notify,
                        detailPageSelectedRowIndex,
                        assetSubitemsList,
                        assetSubActiveItem,
                        currentSubRecord,
                        assetActiveTabLevel2Subtab,
                        activePaymentItem,
                        activeCommissionItem,
                        fundValue,
                        paymentValue,
                        withdrawalValue,
                        setSaveTrigger,
                        activePartnerId: props.activePartnerId,
                        setActivePartnerId: props.setActivePartnerId,
                      }}
                    />
                  </>
                ) : (
                  currentPage === "detail" &&
                  detailPageMode !== "" && (
                    <FormContainerDetailPage
                      {...{
                        context: detailFormContext,
                        setDetailFormContext,
                        activeItem,
                        mode: detailPageMode,
                        setMode: setDetailPageMode,
                        initialFormData: initialDetailPageFormData,
                        activeCustomerId: props.activeCustomerId,
                        assetType,
                        detailPageMode,
                        setDetailPageMode,
                        assetActiveTabLevel2,
                        handleReloadSublist,
                        notify: props.notify,
                        detailPageSelectedRowIndex,
                        assetSubitemsList,
                        assetSubActiveItem,
                        currentSubRecord,
                        assetActiveTabLevel2Subtab,
                        activePaymentItem,
                        activeCommissionItem,
                        fundValue,
                        paymentValue,
                        withdrawalValue,
                        setSaveTrigger,
                        activePartnerId: props.activePartnerId,
                        setActivePartnerId: props.setActivePartnerId,
                      }}
                    />
                  )
                )}
              </>
            )}
          </div>
        </>
      )}
      {currentPage === "listing" && assetList.length > 0 ? (
        <>
          <div style={{ margin: 6 }}>
            <AssetListItems
              {...{
                context: formContext,
                data: assetList,
                activeItem: activeItem,
                handleRowAction,
                mode,
                assetRowSelection,
                handleRowSelection,
                setCurrentRecord,
              }}
            />
          </div>
        </>
      ) : (
        mode === "" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click Add Asset to create a new
              record
            </Label>
          </div>
        )
      )}
      {currentPage === "detail" && assetSubitemsList.length > 0 ? (
        <AssetDetails
          {...{
            context: detailFormContext,
            data: assetSubitemsList,
            assetActiveTabLevel2,
            assetActiveTabLevel2Subtab,
            handleDetailFormContext,
            detailPageMode,
            setDetailPageMode,
            handleRowAction,
            assetSubActiveItem,
            handleDetailPageRowSelection,
            detailPageSelectedRowIndex,
            setCurrentSubRecord,
          }}
        />
      ) : (
        currentPage === "detail" &&
        assetSubitemsList.length === 0 &&
        detailPageMode === "" &&
        assetActiveTabLevel2Subtab !== "paymentCommission" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click{" "}
              {camalize(`add ${assetActiveTabLevel2}`)} to create a new record
            </Label>
          </div>
        )
      )}

      <OverlayDrawer
        position={"end"}
        open={isOpen}
        onOpenChange={(_, { open }) => setIsOpen(open)}
        className={"common-drawer"}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Case Details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>{CaseDetails(activeItem)}</DrawerBody>
      </OverlayDrawer>
      <Dialog open={assetCatPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <div className={styles.field}>
                <Label id={labelId} weight="semibold">
                  Select Asset Category
                </Label>
                <RadioGroup
                  // role="radiogroup"
                  value={selectedAssetCatPopup}
                  aria-labelledby={labelId}
                  className="radioItems"
                  onChange={(_, data) => {
                    setSelectedAssetCatPopup(data.value);
                  }}
                >
                  <Radio
                    name={radioName}
                    value="Unit-linked"
                    label="Investment"
                  />
                  <Radio
                    name={radioName}
                    value="Shares"
                    label="Share Holdings"
                  />
                  <Radio
                    name={radioName}
                    value="Home/Personal"
                    label="Home and Personal"
                  />
                  <Radio
                    name={radioName}
                    value="Banks/B Societies"
                    label="Bank/Building Societies"
                  />
                </RadioGroup>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedAssetCatPopup("");
                    setAssetCatPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={() => {
                  if (selectedAssetCatPopup !== "") {
                    setMode("add");
                    setAssetType(selectedAssetCatPopup);
                    setAssetCatPopup(false);

                    getContextDetails(
                      selectedAssetCatPopup,
                      assetActiveTabLevel1
                    );
                    setSelectedAssetCatPopup("");
                  }
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Dialog open={loadStandardActionPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <LoadStandardAction
                loadStandardActionDate={loadStandardActionDate}
                setLoadStandardActionDate={setLoadStandardActionDate}
                selectedLoadStandardActions={selectedLoadStandardActions}
                loadActionSelected={loadActionSelected}
                type="case"
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedLoadStandardActions([]);
                    setLoadStandardActionPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={addLoadStandardActions}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default AssetForm;
