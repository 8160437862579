import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import TimePicker from "react-datepicker";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
  Link,
  makeStyles,
  Spinner,
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  ToastTrigger,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { DialogContent } from "@fluentui/react";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  convertLocalTimeToUtcTimestamp,
  formatedDate2,
  stripHtml,
} from "../../../utils";
import apiServices from "../../../service";
import { setIsFloatingMenuOpen } from "../../../redux/timerSlice";
import { PauseRegular, PlayRegular, StopRegular } from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import TextEditor from "../../../components/TextEditor";
import CreatableSelectionList from "../../../components/Creatablelist";
import "./style.css";
import { getTimeAllocationList } from "../../../redux/timeAllocationSlice";
import ASCButton from "../../../components/Button";

interface TimerState {
  isRunning: boolean;
  isPaused: boolean;
  elapsedTime: number;
}
interface SelectionOption {
  label: string;
  value: string;
}
const useStyles = makeStyles({
  customDialog: {
    "& .inner-100": {
      padding: 0, // or other styling adjustments
    },
  },
  timeField: {
    border: "1px solid #cdcdcd",
  },
});
const useTimer = () => {
  const [state, setState] = useState<TimerState>({
    isRunning: false,
    isPaused: false,
    elapsedTime: 0,
  });
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const formatDuration = useCallback((seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  }, []);

  // Convert hh:mm:ss to total seconds

  const start = () => {
    if (!state.isRunning) {
      const id = setInterval(() => {
        setState((prev) => ({ ...prev, elapsedTime: prev.elapsedTime + 1 }));
      }, 1000);
      setIntervalId(id);
      setState({ isRunning: true, isPaused: false, elapsedTime: 0 });
    }
  };

  const resume = () => {
    if (!state.isRunning && state.isPaused) {
      const id = setInterval(() => {
        setState((prev) => ({ ...prev, elapsedTime: prev.elapsedTime + 1 }));
      }, 1000);
      setIntervalId(id);
      setState((prev) => ({ ...prev, isRunning: true, isPaused: false }));
    }
  };

  const pause = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      setState((prev) => ({ ...prev, isRunning: false, isPaused: true }));
    }
  };

  const stop = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      const finalTime = state.elapsedTime;
      setState({ isRunning: false, isPaused: false, elapsedTime: 0 });
      return finalTime;
    }
    return 0;
  };

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  return { ...state, formatDuration, start, resume, pause, stop };
};

const TimerDialog = ({ message, onConfirm, onCancel }: any) => {
  const styles = useStyles();

  return (
    <Dialog open>
      <DialogSurface>
        <DialogTitle>{message}</DialogTitle>
        <DialogContent className={styles.customDialog}>
          <DialogActions>
            <ASCButton
              shape="rounded"
              appearance="primary"
              className="asc-button-primary"
              onClick={onConfirm}
              label="Yes"
            />
            <ASCButton
              shape="rounded"
              appearance="secondary"
              onClick={onCancel}
              label="No"
            />
          </DialogActions>
        </DialogContent>
      </DialogSurface>
    </Dialog>
  );
};

const Timer = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const styles = useStyles();
  const {
    isRunning,
    isPaused,
    elapsedTime,
    formatDuration,
    start,
    resume,
    pause,
    stop,
  } = useTimer();
  const [dialogState, setDialogState] = useState<{
    type: "start" | "resume" | "stop" | "stopConfirm" | null;
    duration: any;
  }>({ type: null, duration: 0 });

  const [loading, setLoading] = useState(false);
  const [apiProcessing, setApiProcessing] = useState(false);
  const { dispatchToast } = useToastController(useId("toaster"));
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [displayDuration, setDisplayDuration] = useState("00:00:00");
  const [selectFieldOptions, setSelectFieldOptions] = useState<
    SelectionOption[]
  >([]);
  const activeClient: any = useSelector(
    (state: RootState) => state.activeClient
  );
  const isFloatingMenuOpen = useSelector(
    (state: any) => state.opentimer.storedValue
  );

  useEffect(() => {
    setDisplayDuration(formatDuration(dialogState.duration));
  }, [dialogState.duration, formatDuration]);

  const newSelectOptionAdded = (
    field: string,
    selectionLabel: string,
    selectionType: string,
    options: any,
    operation: string
  ) => {
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );

      setNewlyAddedSelectOptions(filteredRes);
    } else {
      let newVall: any = {
        type: selectionType,
        options: [],
      };
      newVall["options"].push({
        field: selectionLabel,
        label: options.label,
        value: options.value,
      });

      setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
    }
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {})
        .catch((err) => console.log(err));
    }
  };
  const formik = useFormik({
    initialValues: {
      allocated_date: new Date(),
      duration: "00:00:00",
      note_text: "",
      resource: "",
    },
    validate: (values) => {
      const cleanedNoteText = stripHtml(values.note_text || "");
      const errors: any = {};
      if (!values.allocated_date) errors.allocated_date = "Date is required";
      if (!cleanedNoteText) {
        errors.note_text = "Note is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setApiProcessing(true);
      try {
        const payload = {
          contacts_time_allocation: {
            customer_id: activeClient?.customer_id,
            allocated_date: formatedDate2(values.allocated_date),
            duration: convertLocalTimeToUtcTimestamp(displayDuration),
            note_text: values.note_text,
            resource: values.resource,
          },
        };

        const response: any = await apiServices.timeAllocation.create(payload);
        if (!response.data.isError) {
          notify(response.data.message, "", "success");
          setLoading(true);

          setTimeout(() => {
            setApiProcessing(false);
            AddSelectOptions();
            dispatch(setIsFloatingMenuOpen(false));
          }, 1000);
          setLoading(false);

          props.getTimerData(response?.data?.data?.timeallocation?.customer_id);
        }
      } catch (error) {
        notify("Failed to save time allocation", "error");
      } finally {
        setApiProcessing(false);
        setDialogState({ type: null, duration: 0 });
        formik.resetForm();
      }
    },
  });

  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>
    );

  const handleStart = () => {
    setDialogState({ type: "start", duration: 0 });
  };

  const handleStopConfirm = () => {
    setDialogState({ type: "stopConfirm", duration: 0 });
  };

  const handleStopDialogConfirm = () => {
    const duration = stop();
    setDialogState({ type: "stop", duration });
    formik.setFieldValue("duration", formatDuration(duration));
  };

  const handleStopDialogCancel = () => {
    setDialogState({ type: null, duration: 0 });
    if (isPaused) {
      resume();
    }
  };

  const handleConfirm = () => {
    if (dialogState.type === "start") start();
    if (dialogState.type === "resume") resume();
    setDialogState({ type: null, duration: 0 });
  };

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };

  useEffect(() => {
    getSelectionListOptions();
    formik.setFieldValue("allocated_date", new Date());
  }, []);

  useEffect(() => {
    dispatch(getTimeAllocationList(activeClient?.customer_id)); // Fetch data when context is CONTACTS_TIME_ALLOCATION
  }, [dispatch, activeClient?.customer_id]);

  const getSelectionListOptions = async () => {
    try {
      const res = await apiServices.selectionList.getOptions(
        "resource:ModifiableList"
      );
      if (res.data && res.data.data) {
        setSelectFieldOptions(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const parseTime = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
  };

  const parseDuration = (timeStr: string | Date) => {
    if (timeStr instanceof Date) {
      return (
        timeStr.getHours() * 3600 +
        timeStr.getMinutes() * 60 +
        timeStr.getSeconds()
      );
    }

    // Handle if timeStr is already in HH:mm:ss format
    if (typeof timeStr === "string" && timeStr.includes(":")) {
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    }

    return 0; // Return 0 for invalid inputs
  };
  const handleManualInput = (inputValue: string) => {
    // Allow intermediate formats during typing
    const partialTimeRegex = /^(\d{0,2}):?(\d{0,2}):?(\d{0,2})$/;

    if (partialTimeRegex.test(inputValue)) {
      setDisplayDuration(inputValue);

      // Only update final state when valid
      const fullTimeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
      if (fullTimeRegex.test(inputValue)) {
        setDialogState({
          ...dialogState,
          duration: parseDuration(inputValue),
        });
      }
    }
  };

  return (
    <div className="timer">
      <Toaster position="top-end" timeout={3000} />

      {isFloatingMenuOpen && (
        <div className="floating-timer">
          <div className="timer-controls">
            {isRunning ? (
              <>
                <ASCButton
                  shape="rounded"
                  onClick={pause}
                  icon={<PauseRegular />}
                />
                <ASCButton
                  shape="rounded"
                  onClick={handleStopConfirm}
                  icon={<StopRegular />}
                />
              </>
            ) : (
              <>
                {" "}
                <ASCButton
                  shape="rounded"
                  onClick={
                    isPaused
                      ? () => setDialogState({ type: "resume", duration: 0 })
                      : handleStart
                  }
                  icon={<PlayRegular />}
                />
              </>
            )}
            <span className="timer-display">{formatDuration(elapsedTime)}</span>
          </div>
        </div>
      )}

      {["start", "resume"].includes(dialogState.type!) && (
        <TimerDialog
          message={`Are you sure you want to ${dialogState.type} the timer?`}
          onConfirm={handleConfirm}
          onCancel={() => {
            if (!isRunning && !isPaused) {
              dispatch(setIsFloatingMenuOpen(false));
            }
            setDialogState({ type: null, duration: 0 });
          }}
        />
      )}
      {dialogState.type === "stopConfirm" && (
        <TimerDialog
          message="Do you want to stop the timer?"
          onConfirm={handleStopDialogConfirm}
          onCancel={handleStopDialogCancel}
        />
      )}

      {dialogState.type === "stop" && (
        <Dialog open>
          <DialogSurface>
            <DialogTitle>Time Allocation</DialogTitle>
            <DialogContent className={styles.customDialog}>
              <form onSubmit={formik.handleSubmit}>
                <Field
                  label={
                    <span>
                      Date<span className="required-item">*</span>
                    </span>
                  }
                  validationState={
                    formik.errors.allocated_date ? "error" : "none"
                  }
                  validationMessage={`${formik?.errors?.allocated_date || ""}`}
                >
                  <DatePicker
                    name="allocated_date"
                    value={
                      formik.values.allocated_date
                        ? new Date(formik.values.allocated_date)
                        : null
                    }
                    onSelectDate={(date) =>
                      formik.setFieldValue("allocated_date", date || new Date())
                    }
                    allowTextInput
                    formatDate={onFormatDate}
                  />
                </Field>
                <Field label="Duration">
                  <TimePicker
                    className={styles.timeField}
                    name="duration"
                    selected={
                      displayDuration ? parseTime(displayDuration) : new Date()
                    }
                    onChange={(date: Date | null) => {
                      if (!date) return;
                      const formatted = [
                        String(date.getHours()).padStart(2, "0"),
                        String(date.getMinutes()).padStart(2, "0"),
                        String(date.getSeconds()).padStart(2, "0"),
                      ].join(":");

                      setDisplayDuration(formatted);
                      setDialogState({
                        ...dialogState,
                        duration: parseDuration(formatted),
                      });
                    }}
                    onChangeRaw={(e) => handleManualInput(e.target.value)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm:ss"
                    timeFormat="HH:mm:ss"
                    customInput={<input className="time_picker" />}
                    // isClearable
                    placeholderText="HH:MM:SS"
                  />
                </Field>

                {/* Display formatted time separately */}
                <Field
                  label={
                    <span>
                      Note<span className="required-item">*</span>
                    </span>
                  }
                  validationState={formik.errors.note_text ? "error" : "none"}
                  validationMessage={`${formik?.errors?.note_text || ""}`}
                >
                  <TextEditor
                    label="Note"
                    inputData={formik.values.note_text}
                    onChange={(text: any) =>
                      formik.setFieldValue("note_text", text)
                    }
                    error={formik.errors.note_text}
                  />
                </Field>
                <Field label={"Resource"}>
                  <CreatableSelectionList
                    width="fullwidth"
                    name="resource"
                    selectionLabel="resource"
                    selectFieldOptions={selectFieldOptions}
                    newSelectOptionAdded={newSelectOptionAdded}
                    selectionType="ModifiableList"
                    values={formik.values.resource}
                    onChange={(value: any) =>
                      formik.setFieldValue("resource", value)
                    }
                  />
                </Field>
                <DialogActions style={{ margin: "10px 0" }}>
                  <ASCButton
                    shape="rounded"
                    onClick={() => setDialogState({ type: null, duration: 0 })}
                    label="Cancel"
                  />
                  <Button
                    shape="rounded"
                    type="submit"
                    appearance="primary"
                    className="asc-button-primary"
                    disabled={apiProcessing}
                  >
                    {apiProcessing ? <Spinner size="tiny" /> : "Save"}
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </DialogSurface>
        </Dialog>
      )}
    </div>
  );
};

export default Timer;
