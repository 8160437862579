import {
  Button,
  Checkbox,
  Input,
  Label,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import FeeschargesList from "./FeeschargesList";

const ListDetils = () => {
  const [ListItems, setListItems] = useState<any>([]);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Filters");
  const [filterPopup, setFilterPopup] = useState(false);
  const [amountData, setAmountData] = useState("");

  useEffect(() => {
    setListItems([]);
  }, []);

  return (
    <div className="page-content-area">
      <div className="acionlist_filter">
        {/* <FilterTab
          setFilterPopup={setFilterPopup}
          filterPopup={filterPopup}
          selectedMenuItem={selectedMenuItem}
          selectedProviders={selectedProviders}
        /> */}
      </div>

      <FeeschargesList
        setFilterPopup={setFilterPopup}
        filterPopup={filterPopup}
        selectedMenuItem={selectedMenuItem}
        selectedProviders={selectedProviders}
      />
    </div>
  );
};

export default ListDetils;
