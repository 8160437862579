import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";

import InitialPage from "./InitialPage";
import TitleDescription from "./TitleDescription";
import FieldSelectionPage from "./FieldSelectionPage";
import FinishPage from "./FinishPage";
import apiServices from "../../../service";
import store, { AppDispatch } from "../../../redux/store";
import OverlayLoader from "../../../components/OverlayLoader";
import { useDispatch } from "react-redux";
import { setFilterSelectionData, setLists } from "../../../redux/clientSlice";
import { setString } from "../../../redux/SavedSearch/searchId";
import { setSelectionType } from "../../../redux/SavedSearch/extractTypes";
enum TabValues {
  ExportByCondition = "tab1",
  ExportBySelection = "tab2",
}
interface MainProps {
  isOpen: boolean;
  onClose: () => void;
  setTabOpen: (open: boolean) => void;
  setSelectedTab: (tab: TabValues) => void;
}
interface Client {
  id: string;
  fname: string;
}

const MainPage: React.FC<MainProps> = ({
  isOpen,
  onClose,
  setTabOpen,
  setSelectedTab,
}) => {
  const userId = store.getState().authUser.userId;
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const [specificationList, setSpecificationList] = useState<any>([]);
  const [selectedSpecId, setSelectedSpecId] = useState<any>(null);
  const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [finalTitle, setFinalTitle] = useState<string>("");
  const [finalDescription, setFinalDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const [active, setActive] = React.useState<
    "FIRST" | "SECOND" | "THIRD" | "FOURTH" | null | ""
  >("FIRST");
  const clickToTitle = () => {
    setActive("THIRD");
    resetFormState();
  };
  const BackToFirst = () => {
    setActive("FIRST");
  };
  const cancelFirst = () => {
    onClose();
    resetFormState();
  };
  const cancelSelection = () => {
    onClose();
    resetFormState();
  };
  const MoveToSelection = () => {
    setActive("THIRD");
  };
  const BacktoSecond = () => {
    setActive("FIRST");
  };
  const CancelFieldSelection = () => {
    onClose();
    resetFormState();
  };
  const CancelTitlePage = () => {
    onClose();
    resetFormState();
  };
  const CancelFinishPage = () => {
    onClose();
    resetFormState();
  };
  const BackToFieldSelection = () => {
    setActive("THIRD");
  };
  const NextToFinalPage = () => {
    setActive("FOURTH");
  };
  const BackToTab = () => {
    onClose();
  };
  const transformClientData = (item: any) => {
    return {
      customer_id: item.CustomerId,
      first_names: item.FirstNames,
      last_name: item.LastName,
      title: item.Title,
      email: item.Email || "",
      initials: item.Initials,
      former_last_name: item.FormerLastName,
      honours: item.Honours,
      known_as: item.KnownAs,
      address_1: item.Address1,
      address_2: item.Address2,
      address_3: item.Address3,
      address_name: item.AddressName,
      salutation: item.Salutation,
      joint_salutation: item.JointSalutation,
      joint_address_label: item.JointAddressLabel,
      town: item.Town,
      country: item.Country,
      county: item.County,
      postcode: item.Postcode || null,
      associated_type: item.AssociationType,
      associated_with: item.AssociatedWith || null,
      created_by: item.CreatedBy,
      modified_by: item.ModifiedBy || null,
      mobile: item.Mobile || "",
      date_of_birth: item.DateOfBirth || null,
      client_type: item.ClientType,
      sex: item.Sex,
      place_of_birth: item.PlaceOfBirth || "",
      nationality: item.Nationality || "",
      country_of_residence: item.CountryOfResidence || "",
      country_of_domicile: item.CountryOfDomicile || "",
      height: item.Height || "",
      weight: item.Weight || "",
      somker: item.Smoker || "", // Keep typo to match the requested format
      deceased: item.Deceased || "",
      preferred_retirement_age: item.PreferredRetirementAge || "",
      marital_status: item.MaritalStatus || "",
    };
  };

  const editClick = () => {
    const selectedSpec = specificationList.find(
      (spec: any) => spec.SearchId === selectedSpecId
    );

    if (selectedSpec) {
      setTitle(selectedSpec.Title || "");
      setDescription(selectedSpec.Description || "");

      const condition = JSON.parse(selectedSpec.Condition);

      // Initially, set selectedClients with only IDs, since clients might not be available yet
      const selectedClientsWithIds = condition.map((id: any) => ({
        id,
        fname: "", // fname will be updated later when clients become available
      }));

      setSelectedClients(selectedClientsWithIds);
      setSelectedClientIds(
        selectedClientsWithIds.map((client: any) => client.id)
      );
      setActive("THIRD");
    } else {
      console.warn("Selected specification not found");
    }
  };
  useEffect(() => {
    if (clients.length > 0 && selectedClients.length > 0) {
      // Update fname in selectedClients
      const updatedClients = selectedClients.map((selectedClient) => {
        const client = clients.find((c: Client) => c.id === selectedClient.id);
        return { ...selectedClient, fname: client?.fname || "" };
      });

      setSelectedClients(updatedClients);
    }
  }, [clients]); // Run when `clients` updates

  useEffect(() => {
    if (isOpen) {
      setActive("FIRST");
    }
  }, [isOpen]);
  const extractGetList = async () => {
    setLoading(true);
    try {
      const res = await apiServices.extract.getExtract();
      const filteredResponse = res.data?.data.filter(
        (item: any) => item.Type === "Selection"
      );
      if (filteredResponse.length === 0) {
        console.warn("No entries with Type: 'Selection' found.");
      }
      setSpecificationList(filteredResponse);
      dispatch(setLists(filteredResponse));
    } catch (error) {
      console.error("Error fetching extract list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    extractGetList();
  }, []);

  const deleteSpecification = async (id: any) => {
    setLoading(true);
    try {
      await apiServices.extract.delete(id);
      await extractGetList();
      setSelectedSpecId(null);
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      setLoading(false);
    }
  };
  const UpdateListing = async () => {
    const data = {
      title: title ? title : finalTitle,
      description: description ? description : finalDescription,
      condition: selectedClientIds,
      UserId: userId,
      type: "Selection",
    };
    setLoading(true);
    try {
      let response;
      response = await apiServices.exportExcel.updateList(data, selectedSpecId);
      dispatch(setSelectionType(data.type));

      await extractGetList();
      setTabOpen(false);
      callSelectionFilter();
    } catch (error) {
      console.error("Error during update:", error);
    } finally {
      setLoading(false);
      onClose();
      resetFormState();
    }
  };
  const AddSelection = async () => {
    const data = {
      title: title || finalTitle,
      description: description || finalDescription,
      condition: selectedClientIds,
      UserId: userId,
      type: "Selection",
    };

    setLoading(true);
    try {
      let response;
      if (selectedSpecId) {
        await UpdateListing();
      } else {
        response = await apiServices.exportExcel.addList(data);
        dispatch(setString(response.data.data.SearchId));
        dispatch(setSelectionType(response.data.data.Type));
      }

      await extractGetList();
      setTabOpen(false);
      callSelectionFilter();

      return response;
    } catch (error) {
      console.log("Error during AddSelection process:", error);
    } finally {
      setLoading(false);
      onClose();
      resetFormState();
    }
  };

  const callSelectionFilter = () => {
    const data = selectedClientIds;
    apiServices.exportExcel
      .clientList(data)
      .then((response) => {
        if (response.data && !response.data.isError) {
          const transformedData = response.data.data.map(transformClientData);
          dispatch(setFilterSelectionData(transformedData));
        } else {
          console.error("API Response Error:", response.data);
        }
      })
      .catch((e) => {
        console.error("API Error:", e);
      })
      .finally(() => {
        setSelectedTab(TabValues.ExportByCondition);
      });
  };

  const resetFormState = () => {
    setTitle("");
    setFinalTitle("");
    setDescription("");
    setFinalDescription("");
    setSelectedSpecId(null);
    setSelectedClients([]);
    setSelectedClientIds([]);
  };

  return (
    <div>
      {" "}
      <Dialog
        modalType="alert"
        open={isOpen}
        onOpenChange={(event, data) => {
          if (!data.open) onClose();
        }}
      >
        <DialogSurface>
          <OverlayLoader isLoading={loading} />
          <DialogBody>
            <DialogContent>
              {active === "FIRST" && (
                <InitialPage
                  clickToTitle={clickToTitle}
                  cancelFirst={cancelFirst}
                  BackToTab={BackToTab}
                  specificationList={specificationList}
                  setSelectedSpecId={setSelectedSpecId}
                  selectedSpecId={selectedSpecId}
                  deleteSpecification={deleteSpecification}
                  editClick={editClick}
                />
              )}
              {/* {active === "SECOND" && (
                <TitleDescription
                  MoveToSelection={MoveToSelection}
                  BackToFirst={BackToFirst}
                  CancelTitlePage={CancelTitlePage}
                  title={title}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  description={description}
                />
              )} */}
              {active === "THIRD" && (
                <FieldSelectionPage
                  BacktoSecond={BacktoSecond}
                  CancelFieldSelection={CancelFieldSelection}
                  NextToFinalPage={NextToFinalPage}
                  setSelectedClientIds={setSelectedClientIds}
                  setSelectedClients={setSelectedClients}
                  selectedClients={selectedClients}
                  clients={clients}
                  setClients={setClients}
                  title={title}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  description={description}
                  selectedClientIds={selectedClientIds}
                />
              )}
              {active === "FOURTH" && (
                <FinishPage
                  selectedClients={selectedClients}
                  CancelFinishPage={CancelFinishPage}
                  BackToFieldSelection={BackToFieldSelection}
                  title={title}
                  description={description}
                  setDescription={setDescription}
                  setTitle={setTitle}
                  finalTitle={finalTitle}
                  setFinalTitle={setFinalTitle}
                  finalDescription={finalDescription}
                  setFinalDescription={setFinalDescription}
                  finishSelection={AddSelection}
                />
              )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default MainPage;
