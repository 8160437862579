import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Button,
} from "@fluentui/react-components";
import React from "react";
import ASCButton from "../../components/Button";

const Introduction = ({
  nextToSelect,
  handleCancelIntro,
}: {
  nextToSelect: () => void;
  handleCancelIntro: () => void;
}) => {
  const [openItems, setOpenItems] = React.useState(["1"]);
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          maxHeight: "500px",
          overflow: "auto",
          marginBottom: "10px",
        }}
      >
        <div>
          <span style={{ fontSize: "16px", fontWeight: 700 }}>
            Introduction
          </span>
        </div>
        <div>
          <span>
            This walkthrough will help you split a client/partner record into
            one or two new client records.
          </span>
        </div>
        <div>
          <Accordion
            openItems={openItems}
            onToggle={handleToggle}
            multiple
            collapsible
          >
            <AccordionItem value="1">
              <AccordionHeader>
                <b>
                  Client-specific information will be copied from the source
                  client/partner to the new client record(s) without review:
                </b>
              </AccordionHeader>
              <AccordionPanel>
                <div className="accordionDots">
                  <ul>
                    <li>
                      Name,Address,Personal,Administration, and Compliance
                    </li>
                    <li>Employment</li>
                    <li>Identity</li>
                    <li>Service Type</li>
                    <li>Outgoings assigned specifically to client/Partner</li>
                  </ul>
                </div>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <b>These groups of records will NOT be copied:</b>
              </AccordionHeader>
              <AccordionPanel>
                <div className="accordionDots">
                  <ul>
                    <li>Attitude to Risk</li>
                    <li>Objectives</li>
                    <li>Case-related Commission/Other Fees & charges</li>
                    <li>Client Actions</li>
                    <li>Time Allocation</li>
                    <li>Documents</li>
                  </ul>
                </div>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <b>
                  The source client/partner records will not be removed but will
                  have some personal/administrative data altered to highlight
                  their changed state and help prevent inadvertent mailings.
                </b>
              </AccordionHeader>
              <AccordionPanel>
                <div></div>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <b>
                  You will need to review these groups of records to verify
                  correct assignment to the new client record(s):
                </b>
              </AccordionHeader>
              <AccordionPanel>
                <div className="accordionDots">
                  <ul>
                    <li>Income</li>
                    <li>Outgoings (Joint expenses only)</li>
                    <li>Addresses</li>
                    <li>Dependants</li>
                    <li>Assets</li>
                    <li>Liabilities</li>
                    <li>Policies</li>
                    <li>Standard Notes</li>
                    <li>Fact-find Notes</li>
                  </ul>
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <div>
          <ASCButton
            shape="rounded"
            size="small"
            appearance="primary"
            className="asc-button-primary"
            onClick={nextToSelect}
            label="Next"
          />
        </div>
        <div>
          <ASCButton
            shape="rounded"
            size="small"
            onClick={handleCancelIntro}
            label="Cancel"
          />
        </div>
      </div>
    </>
  );
};

export default Introduction;
