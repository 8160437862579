import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import FormContainerWithList from "../../../components/FormContainerWithList";
import apiServices from "../../../service";
import { camalize, convertStringToArray } from "../../../utils";
import OverlayLoader from "../../../components/OverlayLoader";
import ContentLoader from "../../../components/Loader/ContentLoader";
const ContactForm = (props: any) => {
  const [formContext, setFormContext] = useState("");
  const [configForm, setConfigForm] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [initialData, setInitialData] = useState({});
  const contextList: any = {
    "client-action": "CONTACTS_CLIENT_ACTION",
    notes: "CONTACTS_NOTES",
    "time-allocation": "CONTACTS_TIME_ALLOCATION",
  };
  useEffect(() => {
    if (props.section != undefined) {
      getContext(props.section);
    }
  }, [props.section]);
  useEffect(() => {
    if (formContext != "" && props.activeCustomerId) {
      setFormContextConfig(formContext);
      getDataList();
    }
  }, [formContext, props.activeCustomerId]);
  const getContext = (section: string) => {
    setFormContext(contextList[props.section]);
  };
  const setFormContextConfig = (context: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === context
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  };
  const getDataList = () => {
    if (formContext === "CONTACTS_CLIENT_ACTION") {
      setLoading(true);
      apiServices.contactClientAction
        .getList(props.activeCustomerId)
        .then((res) => {
          setLoading(false);
          if (res.data !== undefined) {
            if (res.data.data) {
              let singleData = {};
              singleData = {
                ...singleData,
                contactClientAction: res.data.data,
              };
              setInitialData(singleData);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (formContext === "CONTACTS_NOTES") {
      setLoading(true);
      apiServices.notes
        .getList(props.activeCustomerId)
        .then((res) => {
          setLoading(false);
          if (res.data !== undefined) {
            if (res.data.data) {
              let singleData = {};
              singleData = {
                ...singleData,
                notes: res.data.data,
              };
              setInitialData(singleData);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (formContext === "CONTACTS_TIME_ALLOCATION") {
      setLoading(true);
      apiServices.timeAllocation
        .getList(props.activeCustomerId)
        .then((res) => {
          setLoading(false);
          if (res.data !== undefined) {
            if (res.data.data) {
              let singleData = {};
              singleData = {
                ...singleData,
                timeAllocation: res.data.data,
              };
              setInitialData(singleData);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
    }
  };
  const loadListData = () => {
    getDataList();
  };
  return loading ? (
    <ContentLoader />
  ) : (
    <>
      <div className="form-container-wrapper">
        <FormContainerWithList
          context={formContext}
          config={configForm}
          activeCustomerId={props.activeCustomerId}
          notify={props.notify}
          initialData={initialData}
          reloadData={loadListData}
          section={props.section}
        />
      </div>
    </>
  );
};

export default ContactForm;
